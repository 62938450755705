import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import {
    getLoginUserPermission,
    setAddClass,
    getReportFilter,
    getChapterLead,
} from "../../Action";
import Select from "react-select";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import PageNotFound from "../Reports/PageNotFound";
import ChapterReviewTab from "./ChapterReviewTab";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import * as constants from "../../Config/Constant";
import './chapterreview.css';

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  customClass: {
    popup: "colored-toast",
    icon: "white-toast-icon",
  },
  timer: 3000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

class ChapterDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: new URLSearchParams(window.location.search),
      redirect: 0,
      loginUserData: {},
      loginUserPermission: {},
      selectedBu: null,
      selectedChapter: null,
      buList: [],
      serviceList: [],
      serviceListClone: [],
      chapterLeadList: [],
      chapterLeadListClone: [],
      defaultActiveKey: 'chapter_review',
      loading: false,
    };
  }

  componentDidMount() {
    let token =
      this.state.queryParams.get("token") === null
        ? Cookies.get("auth_data")
        : this.state.queryParams.get("token");
    let url =
      this.state.queryParams.get("url") == null
        ? Cookies.get("url_code")
        : this.state.queryParams.get("url");
    this.setState({
      token: token,
      apiLink: constants.API_LINK,
      angularUrl: constants.FRONT_LINK,
      excelUrl: constants.EXCEL_LINK,
      url: url,
    });
    Cookies.set("auth_data", token);
    Cookies.set("url_code", url);
    this.state.queryParams.delete("token");
    this.state.queryParams.delete("url");
    if (this.props.history != undefined) {
      this.props.history.replace({
        search: this.state.queryParams.toString(),
      });
    }
    if (token == "undefined" || token == undefined || token == null) {
      this.state.redirect = 1;
    } else {
      this.getUser();
    }
  }

  componentDidUpdate(prevProps) {
    if ( JSON.stringify(this.props.loginDetails) !== JSON.stringify(prevProps.loginDetails)) {
      this.getUser();
    }
  }
  
  /**
   * Get the login user details
   */
  getUser = () => {
    const role_id = this.props?.loginDetails?.loginDetails?.role_id;
    this.setState({
      loginUserData: this.props?.loginDetails?.loginDetails,
      loginUserPermission: this.props?.loginDetails?.loginUserPermission,
      role_id,
    }, () => {
      this.getPracticeService();
    });
  };

  getPracticeService = () => {
    this.setState({
      loading: true,
      buList: [],
    });

    let dataObject = {};
    dataObject.start_date = new Date();
    dataObject.end_date = new Date();

    this.props.getReportFilter(dataObject).then(
      (_response) => {
        let practiceHead = [];
        let serviceList = [];
        let serviceListClone = [];
        this.props.pmoFilter.directors?.forEach((dir) => {
          practiceHead.push(dir);
        });
        
        this.props.pmoFilter.services?.forEach((service) => {
          serviceList.push(service);
          serviceListClone.push(service);
        });

        let buList = [];
        let directorArray = practiceHead;
        
        directorArray.forEach((value) => {
          let opt = {};
          opt.label = value.bu;
          opt.value = value.bu;
          opt.bu_id = value.bu_id;
          opt.id = value.id;
          opt.bu = value.bu;
          opt.name = value.name;
          buList.push(opt);
        });
       
        serviceList = serviceList.filter((service) => service.bu==buList[0].bu_id);
        this.setState({
          loading: false,
          buList,
          serviceList,
          serviceListClone,
          selectedBu: buList[0]
        }, () =>{
          this.getChapterLead();
        });
      },
      (error) => {
        this.setState({
          buList: []
        });
      }
    );
  };
  
  /**
   * Get the chapter lead list
   */
  getChapterLead = () => {
    let data = [];
    let chapter = [];
    
    this.props.getChapterLead(data).then(
      (response) => {
        this.props.chapterLeads.length &&
          this.props.chapterLeads.forEach((item) => {
            chapter.push({
              label: item.chapter_name,
              value: item.id,
              service: item.service,
              reporting_to: item.reporting_to,
            });
          });

        chapter = chapter.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
        let chapterLead = this.getAllChapterOPtions(chapter);
        this.setState({
            chapterLeadList: chapterLead,
            chapterLeadListClone: chapterLead,
            selectedChapter: chapterLead?.[0],
        });
      },
      (error) => {
        this.ToastAlert("error", error);
      }
    );
  };

  getAllChapterOPtions = (chapter = []) => {
    const chapterLeadList = chapter.filter((item) => 
      this.state.serviceList.some((sitem) => sitem.id === item.service)
    );

    return chapterLeadList;
  };

  /* toast message function  */
  ToastAlert(icon, title) {
    return Toast.fire({
      target: document.getElementById("form-modal"),
      icon: `${icon}`,
      title: `${title}`,
    });
  }

  getClassname = () => {
    return {
      Completed: "success-li",
      "Not Yet Started": "warning-li",
      "Not Completed": "danger-li",
    };
  };

  changeTabView = (tabName) => {
    this.setState({
      defaultActiveKey: tabName,
    });
  };

  changeBU = (event) => {
    let serviceList = this.state.serviceListClone.filter((service) => service.bu==event.bu_id);
    this.setState({
      selectedBu: event,
      selectedChapter: null,
      serviceList
    }, () => {
      this.getChapterLead();
    });
  }
  
  changeChapter = (event) => {
    this.setState({
      selectedChapter: event
    });
  }

  render() {
    const { redirect, loginUserData, buList, selectedBu, chapterLeadList, selectedChapter } = this.state;
    
    const roleId = loginUserData?.role_id;
    if (redirect === 1 || loginUserData === null) {
      return <PageNotFound url={constants.FRONT_LINK} />;
    } else {
      return (
        <div
          className={`page-content px-4 m-t-4 pms-dashboard-container ${this.props.addClass ? "active" : ""
            }`}
        >
            {this.state.loading && (
              <div id="preloader">
                <div id="status"></div>
              </div>
            )}
            <Row className="p-l-15">
                <div className="col-xs-12 fourty-fluid fourty-fluid p-t-6">
                <Row className="row">
                    <div className="col-sm-4 col-sm-4 col-xs-12 col-md-12 text-left">
                    <h1 className="B-G-bold f-s-18 d-gray t-t-upper pull-left mt-15 mr-20 page-head lh-10">
                        CHAPTER REVIEW / <span className="text-orange">CHAPTER DETAILS</span>
                    </h1>
                    </div>
                </Row>
                </div>
            </Row>
            {[1, 8, 10, constants.HR].includes(roleId) &&
              <Row
                  xs={2}
                  md={4}
                  lg={6}
                  className="bgwhite service-container p-2 mt-3 mx-0 pb-3 multiselect-scrollbar"
              >
                  <Col className={`text-left d-flex flex-column w-25 text-start justify-content-end `}>
                      <label className="m-b-0 fs-12 p-t-5 style-label m-r-10">
                          Business Unit
                      </label>
                      <div className="selectpicker_dropdown w-100 hide_bar w-100 selectpicker_dropdown_orange">
                          <Select
                              className="basic-single-select"
                              classNamePrefix={"select_dropdown"}
                              placeholder="Select Business Unit"
                              value={selectedBu}
                              options={buList}
                              onChange={(e) => this.changeBU(e)}
                          ></Select>
                      </div>
                  </Col>
                  <Col className={`text-left d-flex flex-column w-25 text-start justify-content-end `}>
                      <label className="m-b-0 fs-12 p-t-5 style-label m-r-10">
                          Chapter
                      </label>
                      <div className="selectpicker_dropdown w-100 hide_bar w-100 selectpicker_dropdown_orange">
                          <Select
                              className="basic-single-select"
                              classNamePrefix={"select_dropdown"}
                              placeholder="Select chapter"
                              value={selectedChapter}
                              options={chapterLeadList}
                              onChange={(e) => this.changeChapter(e)}
                          ></Select>
                      </div>
                  </Col>
              </Row >
            }
            <div className="scrollable-content p-3 mt-3 chapter-tabs m-t-4">
                <Tabs
                activeKey={this.state.defaultActiveKey}
                id="main_view"
                className="mb-3"
                onSelect={(event) => {
                    this.changeTabView(event);
                }}
                >
                    <Tab
                        eventKey="chapter_review"
                        title="Chapter Review"
                    >
                        <ChapterReviewTab
                        loginUserData = {loginUserData}
                        selectedChapter={this.state.selectedChapter}
                        />
                    </Tab>
                    <Tab
                        eventKey="attendence"
                        title="Attendence"
                    >
                        <p>Coming Soon...</p>
                    </Tab>
                    <Tab
                        eventKey="competitency_tracker"
                        title="Competitency Tracker"
                    >
                        <p>Coming Soon...</p>
                    </Tab>
                    <Tab
                        eventKey="one_on_one"
                        title="One On One"
                    >
                        <p>Coming Soon...</p>
                    </Tab>
                    <Tab
                        eventKey="documents"
                        title="Documents"
                    >
                        <p>Coming Soon...</p>
                    </Tab>
                </Tabs>
            </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
    addClass: state.pmsDetails.addClass,
    pmoFilter: state.reportFilterDetails.reportFilter,
    chapterLeads: state.pmsDetails.chapterLead,
  };
};

const mapDispatchToProps = {
  getLoginUserPermission,
  getReportFilter,
  getChapterLead,
  setAddClass,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChapterDetails);
import * as types from "../Utilities/Type";
const INITIAL_STATE = {
  userListByRepId: [],
  userList: [],
};
export function getUserDetails(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_DEVELOPER_BY_REPORT_ID:
      return {
        ...state,
        userListByRepId: action.payload,
      };
    case types.GET_USER_LIST:
      return {
        ...state,
        userList: action.payload,
      };   
    case "3":
      break;
    default:
      return state;
  }
}
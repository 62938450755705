import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import {
    getLoginUserPermission,
    setAddClass,
    getChapterReviewInference,
    getReportFilter,
    getChapterLead,
} from "../../Action";
import { getBackEndConstant } from "../../Config/Constant";
import { Capitalize } from "../../Utilities/Helper";
import Select from "react-select";
import DatePicker from "react-datepicker";
import PageNotFound from "../Reports/PageNotFound";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import ChapterReviewInferenceView from "./ChapterReviewInferenceView";
import ChapterReviewInferenceUpdate from "./ChapterReviewInferenceUpdate";
import * as constants from "../../Config/Constant";
import './chapterreview.css';

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  customClass: {
    popup: "colored-toast",
    icon: "white-toast-icon",
  },
  timer: 3000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

class ChapterReviewInference extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: new URLSearchParams(window.location.search),
      redirect: 0,
      loginUserData: {},
      loginUserPermission: {},
      chapterYear: {},
      selectedBu: null,
      selectedChapter: null,
      chapterMonth: new Date(`01/01/${new Date().getFullYear()}`),
      viewYear: '',
      viewMonth: '',
      searchedValue: '',
      chapterMonthItems: [],
      chapterMonthItemsClone: [],
      inferenceLists: [],
      chapterMonthDetails: [],
      buList: [],
      serviceList: [],
      serviceListClone: [],
      chapterLeadList: [],
      chapterLeadListClone: [],
      isChapterViewed: false,
      isChapterUpdate: false,
      loading: false,
    };
  }

  componentDidMount() {
    let token =
      this.state.queryParams.get("token") === null
        ? Cookies.get("auth_data")
        : this.state.queryParams.get("token");
    let url =
      this.state.queryParams.get("url") == null
        ? Cookies.get("url_code")
        : this.state.queryParams.get("url");
    this.setState({
      token: token,
      apiLink: constants.API_LINK,
      angularUrl: constants.FRONT_LINK,
      excelUrl: constants.EXCEL_LINK,
      url: url,
    });
    Cookies.set("auth_data", token);
    Cookies.set("url_code", url);
    this.state.queryParams.delete("token");
    this.state.queryParams.delete("url");
    if (this.props.history != undefined) {
      this.props.history.replace({
        search: this.state.queryParams.toString(),
      });
    }
    if (token == "undefined" || token == undefined || token == null) {
      this.state.redirect = 1;
    } else {
      this.getUser();
    }
  }

  componentDidUpdate(prevProps) {
    if ( JSON.stringify(this.props.loginDetails) !== JSON.stringify(prevProps.loginDetails)) {
      this.getUser();
    }
  }

  /**
   * Get the login user details
   */
  getUser = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1 > 12 ? 1 : currentDate.getMonth() + 1;
    const currentYear = currentMonth == 12 ? currentDate.getFullYear() + 1 : currentDate.getFullYear();
    this.setState({
      loginUserData: this.props?.loginDetails?.loginDetails,
      loginUserPermission: this.props?.loginDetails?.loginUserPermission,
      chapterYear: { value: currentYear, label: currentYear}
    }, () => {
      this.monthLists(this.state.chapterYear.value, 0);
      this.getPracticeService();
    });
  };

  /* toast message function  */
  ToastAlert(icon, title) {
    return Toast.fire({
      target: document.getElementById("form-modal"),
      icon: `${icon}`,
      title: `${title}`,
    });
  }

  getClassname = () => {
    return {
      Completed: "success-li",
      "Not Yet Started": "warning-li",
      "Not Completed": "danger-li",
    };
  };

  getPracticeService = () => {
    this.setState({
      loading: true,
      buList: [],
    });

    let dataObject = {};
    dataObject.start_date = new Date();
    dataObject.end_date = new Date();

    this.props.getReportFilter(dataObject).then(
      (_response) => {
        let practiceHead = [];
        let serviceList = [];
        let serviceListClone = [];
        this.props.pmoFilter.directors?.forEach((dir) => {
          practiceHead.push(dir);
        });
        
        this.props.pmoFilter.services?.forEach((service) => {
          serviceList.push(service);
          serviceListClone.push(service);
        });

        let buList = [];
        let directorArray = practiceHead;
        
        directorArray.forEach((value) => {
          let opt = {};
          opt.label = value.bu;
          opt.value = value.bu;
          opt.bu_id = value.bu_id;
          opt.id = value.id;
          opt.bu = value.bu;
          opt.name = value.name;
          buList.push(opt);
        });
       
        serviceList = serviceList.filter((service) => service.bu==buList[0].bu_id);
        this.setState({
          buList,
          serviceList,
          serviceListClone,
          selectedBu: buList[0]
        }, () =>{
          this.getChapterLead();
        });
      },
      (error) => {
        this.setState({
          buList: []
        });
      }
    );
  };
  
  /**
   * Get the chapter lead list
   */
  getChapterLead = () => {
    let data = [];
    let chapter = [];
    
    this.props.getChapterLead(data).then(
      (response) => {
        this.props.chapterLeads.length &&
          this.props.chapterLeads.forEach((item) => {
            chapter.push({
              label: item.chapter_name,
              value: item.id,
              service: item.service,
              reporting_to: item.reporting_to,
            });
          });

        chapter = chapter.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));
        let chapterLead = this.getAllChapterOPtions(chapter);
        this.setState({
            chapterLeadList: chapterLead,
            chapterLeadListClone: chapterLead,
            selectedChapter: chapterLead?.[0],
        }, () => {
          this.getInference();
        });
      },
      (error) => {
        this.ToastAlert("error", error);
      }
    );
  };

  getAllChapterOPtions = (chapter = []) => {
    const chapterLeadList = chapter.filter((item) => 
      this.state.serviceList.some((sitem) => sitem.id === item.service)
    );

    return chapterLeadList;
  };

  filterYearHandler = (e) =>{
    const monthYear = e.value; 
    this.setState({
      chapterMonth: new Date(`01/01/${monthYear}`),
      chapterYear: e
    });
    this.monthLists(e.value, 0);
  }

  filterMonthHandler = (e) =>{
    const selectedMonth = e; 
    
    this.setState({
      chapterMonth: selectedMonth
    });
    this.monthLists(this.state.chapterYear.value, selectedMonth);
  }

  monthLists = (reqYear,reqMonth) =>{
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1 > 12 ? 0 : currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    reqYear = currentMonth == 0 ? reqYear + 1 : reqYear;
    let lastCondMonth = reqMonth != 0 ? new Date(reqMonth).getMonth() + 1 : 0;
    let lastCondYear = reqYear;
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const months = [];
    for(let cyear = reqYear; cyear <= lastCondYear; cyear++){
      const startMonth = lastCondMonth == 0 ? (cyear == 2024 ? 4 : 1) : lastCondMonth;
      const lastMonth = lastCondMonth == 0 ? (cyear == currentYear ? currentMonth + 1 : (currentMonth == 0 ? 1 : 12)) : lastCondMonth;
      for(let month = startMonth; month <= lastMonth; month++) {
        months.push({
          year: cyear,
          monthNumber: month,
          month: monthNames[month - 1],
        });
      }
    }
    this.setState({
      chapterMonthItems: [...months].reverse(),
      chapterMonthItemsClone: [...months].reverse(),
    });
  }
  clearFilter = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1 > 12 ? 1 : currentDate.getMonth() + 1;
    const currentYear = currentMonth == 12 ? currentDate.getFullYear() + 1 : currentDate.getFullYear();
    this.setState({  
      chapterYear: { value: currentYear, label: currentYear},
      chapterMonth: new Date(`01/01/${currentYear}`),
      searchedValue: '',
    }, () => {
      this.monthLists(this.state.chapterYear.value, 0);
      this.getInference();
    });
  }
  getInference = () =>{
    this.setState({ loading: true });
    let postData = {
      data: {
        chapterYear : this.state.chapterYear.value,
        chapter: this.state.selectedChapter
      }
    }
    this.props.getChapterReviewInference(postData).then(
      (response) => {
        this.setState({
          loading: false,
          inferenceLists: this.props.reviewDetails.inferenceLists
        });
      },
      (error) => {
        this.ToastAlert("error", "Something went wrong");
      }
    );
  }

  getMonthChapterView = (event , viewMonth, viewYear) =>{
    event.preventDefault();
    let chapterMonthView =  viewMonth+'_'+viewYear;
    this.setState({
      isChapterViewed: true,
      viewMonth: viewMonth,
      viewYear: viewYear,
      chapterMonthDetails: this.state.inferenceLists[chapterMonthView]?.lists
    });
  }
  
  setMonthChapterView = (event , viewMonth, viewYear) =>{
    event.preventDefault();
    let chapterMonthView =  viewMonth+'_'+viewYear;
    this.setState({
      isChapterUpdate: true,
      viewMonth: viewMonth,
      viewYear: viewYear,
      chapterMonthDetails: this.state.inferenceLists[chapterMonthView]?.lists
    });
  }
  
  backToMonthLists = () =>{
    this.setState({
      isChapterViewed: false,
      isChapterUpdate: false,
      viewMonth: '',
      viewYear: '',
      chapterMonthDetails: []
    },() => {
      this.getInference();
    })
  }

  handleSearchChange = (event) =>{
    let searchInput = event.target.value;
    let filteredData = [];
    
    if (searchInput) {
      filteredData = this.state.chapterMonthItemsClone.filter((item) => {
        return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase());
      })
      this.setState({
        searchedValue: searchInput
      });
    }
    else {
      this.setState({
        searchedValue: ''
      });
      filteredData = this.state.chapterMonthItemsClone;
    }
    this.setState({
      chapterMonthItems: filteredData,
    })
  }

  changeBU = (event) => {
    let serviceList = this.state.serviceListClone.filter((service) => service.bu==event.bu_id);
    this.setState({
      selectedBu: event,
      selectedChapter: null,
      serviceList
    }, () => {
      this.getChapterLead();
    });
  }
  
  changeChapter = (event) => {
    this.setState({
      selectedChapter: event
    }, () => {
      this.getInference();
    });
  }

  render() {
    const { redirect, loginUserData, chapterYear, chapterMonthItems, isChapterViewed, isChapterUpdate, buList, selectedBu, chapterLeadList, selectedChapter } = this.state;

    const roleId = loginUserData?.role_id;
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1 > 12 ? 1 : currentDate.getMonth() + 1;
    const currentYear = currentMonth == 12 ? currentDate.getFullYear() + 1 : currentDate.getFullYear();
    const startMonth = currentYear == 2024 ? 4 : 1;
    const filterYears = [{value: currentYear, label: currentYear}];
    
    if(currentYear > 2024 && currentYear < 2026){ 
      filterYears.push({value: currentYear - 1, label: currentYear - 1 }); 
    }
    else if(currentYear >= 2026){
      filterYears.push({value: currentYear - 1, label: currentYear - 1 }); 
      filterYears.push({value: currentYear - 2, label: currentYear - 2 }); 
    }
    
    if (redirect === 1 || loginUserData === null) {
      return <PageNotFound url={constants.FRONT_LINK} />;
    } else if(isChapterViewed){
      return (
        <>
          <ChapterReviewInferenceView
            backToMonthLists = {this.backToMonthLists}
            chapterMonthDetails = {this.state.chapterMonthDetails}
            viewMonth = {this.state.viewMonth}
          />
        </>
      );
    }else if(isChapterUpdate){
      const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      const monthInNumber = monthNames.indexOf(this.state.viewMonth);
      return (
        <>
          <ChapterReviewInferenceUpdate
            backToMonthLists = {this.backToMonthLists}
            chapterMonthDetails = {this.state.chapterMonthDetails}
            viewMonthInNumber = {monthInNumber + 1}
            viewMonth = {this.state.viewMonth}
            viewYear = {this.state.viewYear}
          />
        </>
      );
    } else {
      return (
        <div
          className={`page-content px-4 pms-dashboard-container ${this.props.addClass ? "active" : ""
            }`}
        >
            {this.state.loading && (
              <div id="preloader">
                <div id="status"></div>
              </div>
            )}
            <Row>
                <div className="col-xs-12 fourty-fluid fourty-fluid p-t-6">
                <Row className="row">
                    <div className="col-sm-4 col-sm-4 col-xs-12 col-md-12 text-left">
                    <h1 className="B-G-bold f-s-18 d-gray t-t-upper pull-left mt-15 mr-20 page-head lh-10"> 
                        CHAPTER REVIEW
                    </h1>
                    </div>
                </Row>
                </div>
            </Row>
            {[1, 8, 10, constants.HR].includes(roleId) &&
              <Row
                  xs={2}
                  md={4}
                  lg={6}
                  className="bgwhite service-container p-2 mt-3 mx-0 pb-3 multiselect-scrollbar"
              >
                  <Col className={`text-left d-flex flex-column w-25 text-start justify-content-end `}>
                      <label className="m-b-0 fs-12 p-t-5 style-label m-r-10">
                          Business Unit
                      </label>
                      <div className="selectpicker_dropdown w-100 hide_bar w-100 selectpicker_dropdown_orange">
                          <Select
                              className="basic-single-select"
                              classNamePrefix={"select_dropdown"}
                              placeholder="Select Business Unit"
                              value={selectedBu}
                              options={buList}
                              onChange={(e) => this.changeBU(e)}
                          ></Select>
                      </div>
                  </Col>
                  <Col className={`text-left d-flex flex-column w-25 text-start justify-content-end `}>
                      <label className="m-b-0 fs-12 p-t-5 style-label m-r-10">
                          Chapter
                      </label>
                      <div className="selectpicker_dropdown w-100 hide_bar w-100 selectpicker_dropdown_orange">
                          <Select
                              className="basic-single-select"
                              classNamePrefix={"select_dropdown"}
                              placeholder="Select chapter"
                              value={selectedChapter}
                              options={chapterLeadList}
                              onChange={(e) => this.changeChapter(e)}
                          ></Select>
                      </div>
                  </Col>
              </Row >
            }
            <Row
                xs={2}
                md={4}
                lg={6}
                className="bgwhite service-container p-2 mt-3 mx-0 pb-3 multiselect-scrollbar"
            >
                <Col className={`text-left d-flex flex-column w-25 text-start justify-content-end `}>
                    <label className="m-b-0 fs-12 p-t-5 style-label m-r-10">
                        Filter By Year
                    </label>
                    <div className="selectpicker_dropdown w-100 hide_bar w-100 selectpicker_dropdown_orange">
                        <Select
                            className="basic-single-select"
                            classNamePrefix={"select_dropdown"}
                            placeholder="All"
                            onChange={(e) => this.filterYearHandler(e)}
                            value={chapterYear}
                            options={filterYears}
                        ></Select>
                    </div>
                </Col>
                <Col className={`text-left d-flex flex-column text-start justify-content-end `}>
                    <label className="p-0 B-G-bold f-s-18 d-gray m-t-3 m-b-10 page-head text-end">Month Filter:</label>
                </Col>
                <Col className={`text-left d-flex flex-column text-start justify-content-end `}>
                    <div className="row align-items-center mb-2 date_picker_align">
                        <DatePicker
                            className="datepicker-control-section"
                            onChange={(e) => this.filterMonthHandler(e)}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            todayButton={"Today"}
                            name="start_date"
                            minDate = {new Date(`${startMonth}/01/${chapterYear.value}`)}
                            maxDate = {new Date(`${currentMonth}/31/${chapterYear.value}`)}
                            selected={this.state.chapterMonth}
                        />
                        <span
                            className="cal_icon_date"
                            role="button"
                            tabIndex="0"
                        >{" "}<i className="icon-calendar"></i>
                        </span>
                    </div>
                </Col>
                <Col className={`text-left d-flex flex-column text-start justify-content-end `}>                  
                <a href="javascript:void(0)" onClick={this.clearFilter} className="p-0 B-G-bold f-s-18 m-t-3 m-b-10 page-head"><i class="fs-16 fa fa-refresh text-theme" title="Clear Filter"></i></a>
                </Col>
            </Row >
            <div className="row m-0">
                <div className="col-6 p-0">
                    <div className="form-group search-icon m-t-14">
                        <input
                        type="text"
                        name="user_grid_filter"
                        value={this.state.searchedValue}
                        className="form-control f-s-16 d-gray pl-30 ng-valid ng-dirty ng-valid-parse ng-empty ng-touched"
                        placeholder="Search ..."
                        onChange={(e) => this.handleSearchChange(e)}
                        />
                        <i className="fa fa-search"></i>
                    </div>
                </div>
            </div>
            <Row>
                <div className="col-xs-12 table-responsive white-table-class m-t-4 p-0" style={{ width: "98%", marginLeft: "7px"}}>
                    <div className="scrollable">
                        <div className="scrollable-content">
                            <table className="table m-b-0">
                                <thead>
                                    <tr className="theadInner default-bg">
                                        <th className="text-start" style={{ width: "20%" }}>
                                            Form By Month
                                        </th>
                                        <th className="text-start" style={{ width: "15%" }}>
                                            Status
                                        </th>
                                        <th className="text-start" style={{ width: "15%" }}>
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                  {chapterMonthItems.length == 0 &&
                                    <tr>
                                      <td colSpan="3" className="r-no-data text-center">No data available</td>
                                    </tr>
                                  }
                                  {chapterMonthItems.length > 0 && chapterMonthItems.map((emonth) => (
                                  <tr className="table-row">
                                    <td className="name-cell table-cell d-flex">
                                      <span className="d-flex flex-column">
                                        <span>
                                          {emonth.month} &nbsp;{emonth.year}
                                        </span>
                                      </span>
                                    </td>
                                    <td style={{ position: "relative" }}>
                                      <span
                                        className={`intimate mr-5 ${getBackEndConstant().statusClasses[
                                          (this.state.inferenceLists[emonth.month+'_'+emonth.year]?.status || 'not yet started')
                                        ]
                                          }-li`}
                                      > </span> &nbsp;{Capitalize(this.state.inferenceLists[emonth.month+'_'+emonth.year]?.status) || Capitalize('not yet started')}
                                    </td>
                                    <td style={{ position: "relative" }}>
                                      <span onClick={(e) => {this.getMonthChapterView(e, emonth.month, emonth.year)}}><i className={`fa fa-eye text-orange btn-pointer m-r-9`}></i></span>
                                      { [currentMonth, currentMonth + 1].includes(emonth.monthNumber) && ![1, 8, 10, constants.HR].includes(roleId) &&
                                        <span onClick={(e) => {this.setMonthChapterView(e, emonth.month, emonth.year)}}><i className={`fa fa-pencil text-orange btn-pointer m-r-9`}></i></span>
                                      }
                                    </td>
                                  </tr>
                                  ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Row>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
    addClass: state.pmsDetails.addClass,
    reviewDetails: state.chapterReviewDetails,
    pmoFilter: state.reportFilterDetails.reportFilter,
    chapterLeads: state.pmsDetails.chapterLead,
  };
};

const mapDispatchToProps = {
  getLoginUserPermission,
  setAddClass,
  getChapterReviewInference,
  getReportFilter,
  getChapterLead,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChapterReviewInference);
import React, { Component } from "react";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { getSquadReviewReport } from "../../Action";

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    showCloseButton: true,
    customClass: {
      popup: "colored-toast",
      icon: "white-toast-icon",
    },
    timer: 3000,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});

class BusinessComplianceView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            businessClassification: 0,
            loading: false,
        };
    }

    render() {
        const { businessClassification } = this.props;
        return (
            <div className="table-responsive white-table-class reports--grid ">
                <div className="scrollable">
                    <div className="scrollable-content">
                        {(businessClassification==0 || businessClassification==1) && (
                            <table className="table m-b-0 table-wrap">
                                <thead className="bg-blue">
                                    <tr className="theadInner">
                                        <th className="text-start thead1" rowSpan="2">Project</th>
                                        <th className="text-start thead1" colSpan="6">Sprint 0</th>
                                        <th className="text-start thead1" colSpan="14">Agile ceremonies</th>
                                        <th className="text-start thead1" colSpan="2">Auto Calculated</th>
                                    </tr>
                                    <tr className="theadInner">
                                        <th className="text-start thead1">Usecase catalog</th>
                                        <th className="text-start thead1">User stories</th>
                                        <th className="text-start thead1">Process flow diagram</th>                
                                        <th className="text-start thead1">Sprint plan & Milestone</th>
                                        <th className="text-start thead1">Personal analysis & Wireframe</th>    
                                        <th className="text-start thead1">UI designs</th>      
                                        <th className="text-start thead1">Daily scrum</th>      
                                        <th className="text-start thead1">Sprint planning</th>      
                                        <th className="text-start thead1">Sprint review</th>      
                                        <th className="text-start thead1">Sprint refinement</th>      
                                        <th className="text-start thead1">Sprint retrospect</th>      
                                        <th className="text-start thead1">Status report</th>      
                                        <th className="text-start thead1">UAT</th>      
                                        <th className="text-start thead1">Go live</th>      
                                        <th className="text-start thead1">Closure report</th>      
                                        <th className="text-start thead1">Waranty kickoff</th>      
                                        <th className="text-start thead1">Squad size</th>      
                                        <th className="text-start thead1">PO</th>      
                                        <th className="text-start thead1">Peer review</th>      
                                        <th className="text-start thead1">Compliance percentage</th>      
                                        <th className="text-start thead1">Total count of Yes</th>      
                                        <th className="text-start thead1">Total count excluding NA</th>      
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan="23" className="r-no-data text-center">No data available</td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                        {businessClassification==2 && (
                            <table className="table m-b-0 table-wrap">
                                <thead className="bg-blue">
                                    <tr className="theadInner">
                                        <th className="text-start thead1" rowSpan="2">Project</th>
                                        <th className="text-start thead1" colSpan="20">Resource Modeling</th>
                                    </tr>
                                    <tr className="theadInner">
                                        <th className="text-start thead1">Projects</th>
                                        <th className="text-start thead1">Know your customer</th>
                                        <th className="text-start thead1"> Resource onboarding</th>                
                                        <th className="text-start thead1"> Address communication gap</th>
                                        <th className="text-start thead1">Task specifications</th>    
                                        <th className="text-start thead1"> Weekly or monthly Retro meeting</th>      
                                        <th className="text-start thead1"> Recruitment support</th>      
                                        <th className="text-start thead1"> Maintain healthy pipeline based on account</th>      
                                        <th className="text-start thead1">Monthly one on one</th>      
                                        <th className="text-start thead1">Address infra requirements</th>      
                                        <th className="text-start thead1">Arrange learning & Dev activity</th>      
                                        <th className="text-start thead1">Arrange tools within the account</th>      
                                        <th className="text-start thead1">Verify timesheets</th>      
                                        <th className="text-start thead1">Raise invoices</th>      
                                        <th className="text-start thead1">Squad size</th>      
                                        <th className="text-start thead1">PO</th>      
                                        <th className="text-start thead1">Peer review</th>      
                                        <th className="text-start thead1">Compliance %</th>      
                                        <th className="text-start thead1">Total count of Yes</th>      
                                        <th className="text-start thead1"> Total count excluding NA</th>      
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan="21" className="r-no-data text-center">No data available</td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                        {businessClassification==3 && (
                            <table className="table m-b-0 table-wrap">
                                <thead className="bg-blue">
                                    <tr className="theadInner">
                                            <th className="text-start thead1">Projects</th>
                                            <th className="text-start thead1">Project Kickoff meeting</th>
                                            <th className="text-start thead1"> Sprint planning</th>                
                                            <th className="text-start thead1"> User stories (or) Tickets</th>
                                            <th className="text-start thead1">Scrum meetings</th>    
                                            <th className="text-start thead1"> Sprint review</th>      
                                            <th className="text-start thead1"> Sprint acceptance</th>      
                                            <th className="text-start thead1"> Timesheet approval</th>      
                                            <th className="text-start thead1"> Monthly retro</th>      
                                            <th className="text-start thead1">Invoicing</th>      
                                            <th className="text-start thead1">Squad size</th>      
                                            <th className="text-start thead1">PO</th>      
                                            <th className="text-start thead1">Total count of yes</th>      
                                            <th className="text-start thead1">Total count excluding NA</th>     
                                        </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan="19" className="r-no-data text-center">No data available</td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>   
        )
    }
}

const mapStateToProps = state => {
    return {
        squadReportDetails: state.reportFilterDetails.squadReport,
    };
};
  
const mapDispatchToProps = {
    getSquadReviewReport,
};
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BusinessComplianceView);
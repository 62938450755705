import React from "react";
import { Urls } from "../../Config/Config";
import "../Reports/Report.css";
import Select from "react-select";
import {
  Row,
  Col,
  Button,
} from "react-bootstrap";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import PageNotFound from "../Reports/PageNotFound";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  getLoginUser, getReportFilter, getLoginUserPermission, getAcvReportData, acvExportExcel, setAddClass
} from "../../Action";
import { connect } from "react-redux";
import * as constants from "../../Config/Constant";
import { getReportFilterService, getUserService } from "../../Utilities/Helper";
import { Dropdown } from "rsuite";
import { getBackEndConstant } from "../../Config/Constant";
import "rsuite/dist/rsuite.min.css";
import accessdenied from "../../access-denied.png";
var _ = require('lodash');

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  customClass: {
    popup: "colored-toast",
    icon: "white-toast-icon",
  },
  timer: 3000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

class AcvReport extends React.Component {
  constructor(props) {
    super(props);
    // Get the current year

    const currentYear = new Date().getFullYear();
    // const years = [currentYear - 1, currentYear, currentYear + 1];
    const years = [currentYear - 2, currentYear - 1, currentYear, currentYear + 1];

    this.state = {
      queryParams: new URLSearchParams(window.location.search),
      redirect: 0,
      current_report: Urls.reports_lists[10],
      sname: [],
      sname1: [],
      report_practice: [],
      report_service: [],
      practiceHead: [],
      loginUserData: {},
      loginUserPermission: {},
      token: "",
      loading: false,
      bu_id: "",
      selectService: [],
      selectBu: [],
      selectBuFilter: [],
      reportList: [],
      serviceName: [],
      checkedList: [],
      reportF: 0,
      usersService: [],
      pmoReportData: [],
      totalAllocationValue: 0,
      pmoReportDataClone: [],
      report: {
        service: []
      },
      reportService: [],
      totalDevelopers: 0,
      rangeOptions: getBackEndConstant().rangeFilterValue,
      monthlyRateOptions: getBackEndConstant().runRateFilter,
      rangeValue: [],
      runRateValue: [],
      acvReportDataClone: [],
      selectedYear: currentYear,
      years: years,
    };
  }

  reset_page = () => {
    this.setState({
      report: {
        service: [],
        practice: [],
      },
      selectService: [],
      searchBy: '',
      reportService: []
    }, () => {
      this.getAcvReportData(this.state);
    });
  };

  componentDidMount() {
    let token = this.state.queryParams.get("token") == null ? Cookies.get("auth_data") :
      this.state.queryParams.get("token");
    this.setState({
      token: token,
      apiLink: constants.API_LINK,
      angularUrl: constants.FRONT_LINK,
      excelUrl: constants.EXCEL_LINK
    });
    this.getUser();
  }

  /**
   * Get the login user details
   */
  getUser = () => {
    this.setState({ loading: true });
    this.props.getLoginUser().then((response) => {
      this.props.getLoginUserPermission().then((response) => {
        this.setState({
          loading: false, loginUserData: this.props.loginDetails.loginDetails,
          loginUserPermission: this.props.loginDetails.loginUserPermission
        });
        this.getPracticeService();
      });
    });
  }

  //filter practice based on service and head
  filterService = (e) => {
    this.state.searchBy = '';
    if (e) {
      let selectedOption = e;
      this.setState({
        report: {
          service: selectedOption,
        },
        selectService: selectedOption,
        // runRateValue: [],
        // rangeValue: [],
      }, () => {
        this.getAcvReportData(this.state);
      });
    }
    else {
      this.getAcvReportData();
    }

  };

  getAcvReportData = () => {
    let dataObj = this.getInputData();
    this.setState({ loading: true });
    this.props.getAcvReportData(dataObj).then((response) => {
      this.setState({
        loading: false,
        acvReportData: this.props.acvReportDetails,
        acvReportDataClone: this.props.acvReportDetails,
      }, () => {
        this.applyFilters(this.state.rangeValue, this.state.runRateValue)
      });
    }, (error) => {
      this.setState({
        loading: false,
        acvReportData: [],
        acvReportDataClone: [],
      });
      this.ToastAlert("error", error);
    });


  };

  generateAcvReportAsExcel = (e) => {
    e.currentTarget.blur(); // removing focus
    let dataObj = this.getInputData();
    this.setState({ loading: true });
    this.props.acvExportExcel(dataObj).then((response) => {
      this.setState({ loading: false });
      this.ToastAlert("success", "Report generated Successfully");
      document.location = this.state.excelUrl + this.props.acvExport.filename;
    }, (error) => {
      this.setState({ loading: false });
      this.ToastAlert("error", error);
    });
  };

  getInputData = () => {
    let dataObj = {}, reportData = {};
    reportData.business_unit = this.state.bu_id;
    let ser = [];
    if (this.state.report.service.length > 0) {
      reportData.service = [this.state.report.service[0].id]
      this.state.report.service.forEach(svalue => {
        ser.push(svalue.id)
      })
      reportData.service = ser;
    }
    else {
      // getting user associated service list
      reportData.service = getReportFilterService(this.state.serviceName, this.state.loginUserData, this.state.usersService);
    }
    reportData.selectedYear = this.state.selectedYear;
    reportData.rangeFilter = this.state.rangeValue ? this.state?.rangeValue?.value : 'All';
    reportData.runRateFilter = this.state.runRateValue ? this.state?.runRateValue?.value : 'All';
    dataObj['reportData'] = reportData;
    return dataObj;
  }

  ToastAlert(icon, title) {
    return Toast.fire({
      target: document.getElementById("form-modal"),
      icon: `${icon}`,
      title: `${title}`,
    });
  }

  resetReport = (e) => {

    e.currentTarget.blur(); // removing focus
    this.setState({
      searchBy: '',
    });
    this.getAcvReportData();
  };

  searchBy = (e) => {
    let searchInput = e.target.value.trim();
    let filteredData = {};
    if (searchInput) {
      Object.entries(this.state.acvReportDataClone).forEach(([accountName, keys]) => {
        const filter = keys.projects.filter((value) => {
          return value.project_name.toLowerCase().includes(searchInput.toLowerCase())
            || value.range_filter.toLowerCase().includes(searchInput.toLowerCase())
            || value.account_name.toLowerCase().includes(searchInput.toLowerCase())
            || value.project_type.toLowerCase().includes(searchInput.toLowerCase());
        });

        if (filter.length > 0) {
          filteredData[accountName] = { ...this.state.acvReportDataClone[accountName], projects: filter };
        }
      });
      // this.setState({
      //   searchBy: e.target.value
      // });
    }
    else {
      // this.setState({
      //   searchBy: ''
      // });
      filteredData = this.state.acvReportDataClone;
    }
    this.setState({
      acvReportData: filteredData,
      searchBy: e.target.value,
    })
  }

  getPracticeService = (isAllPractice) => {
    this.setState({
      loading: true,
      isAllPractices: isAllPractice,
      filterService: [],
      report: {
        service: []
      },
      sname: [],
      allService: [],
      serviceName: [],
      runRateValue: []

    });
    let dateValue = new Date();
    let date = new Date(dateValue.getFullYear(), dateValue.getMonth(), 2);
    let start_date = this.state.filter_start_date
      ? this.state.filter_start_date
      : date;
    let end_date = this.state.filter_end_date
      ? this.state.filter_end_date
      : date;
    let dataObject = {};
    dataObject.start_date = start_date;
    dataObject.end_date = end_date;
    dataObject.filter = isAllPractice;

    let c = 0;
    this.props.getReportFilter(dataObject).then((response) => {
      this.props.reportFilter.directors.forEach((value) => {
        if (this.state.bu_id == value.bu_id) {
          c = 1;
        }
      });
      let bu_id = c == 1 ? this.state.bu_id : "";
      let selectBuFilter = c == 1 ? this.state.selectBuFilter : [];
      let sname = [];
      let allService = [], serviceName = [];
      if (
        this.state.bu_id != "" &&
        this.state.bu_id != undefined &&
        this.state.bu_id != 0
      ) {
        this.props.reportFilter.services.forEach((service) => {
          if (this.state.bu_id == service.bu) {
            sname.push(service);
          }
        });
        this.props.reportFilter.allservices.forEach((service) => {
          if (this.state.bu_id == service.bu) {
            allService.push(service);
          }
        });
      } else {
        sname = this.props.reportFilter.services;
        allService = this.props.reportFilter.allservices;
      }
      this.setState({
        usersService: this.props.reportFilter.services,
        sname1: this.props.reportFilter.services,
        bu_id: bu_id,
        selectBuFilter: selectBuFilter,
        sname: sname,
        allService: allService,
        runRateValue: []

      })
      this.state.sname.forEach((value) => {
        let opt = {};
        opt.label = value.value;
        opt.id = value.id;
        opt.value = value.value;
        opt.head = value.head;
        opt.bu = value.bu;
        serviceName.push(opt);
      });
      // for practicehead
      let practiceHead = [];
      this.props.reportFilter.directors.forEach((id) => {
        practiceHead.push(id);
      });
      let buName = [];
      this.props.reportFilter.directors.forEach((value) => {
        let opt = {};
        opt.label = value.bu;
        opt.value = value.bu;
        opt.bu_id = value.bu_id;
        opt.id = value.id;
        opt.bu = value.bu;
        opt.name = value.name;
        buName.push(opt);
      });
      this.setState({
        practiceHead: practiceHead,
        buName: buName,
        report_all_practices: this.props.reportFilter.practices,
        qes_list: this.props.reportFilter.qes_list,
        serviceName: serviceName,
        runRateValue: []

      });
      if (this.state.loginUserData.role_id == 8) {
        this.setState({
          searchByPracticeHead: this.state.loginUserData.role_id,
        })
      }
    }, (error) => {
      this.setState({
        usersService: [],
        sname1: [],
        bu_id: '',
        selectBuFilter: [],
        sname: [],
        allService: []
      })
    });
    this.getAcvReportData();
  };

  getReportByHead = (e) => {
    this.state.searchBy = '';
    if (e != undefined) {
      this.setState({
        bu_id: e.bu_id,
        head_id: e.label,
        selectBuFilter: e,
        // runRateValue: [],
        // rangeValue: [],

      }, () => {
        this.getBuFilter(this.state);
      });
    } else {
      this.setState({
        bu_id: '',
        head_id: 'All',
        selectBuFilter: [],
        // runRateValue: [],
        // rangeValue: [],

      }, () => {
        this.getBuFilter(this.state);
      });
    }
  };

  getBuFilter = () => {
    let serviceArray = [];
    let service_head = [];
    if (this.state.head_id != "All") {
      // for BU filter    
      this.state.sname1.forEach((service) => {
        if (this.state.bu_id == service.bu) {
          service_head.push(service);
        }
      });
      this.setState({
        serviceName: [],
        selectService: [],
        report: {
          service: []
        }
      })
    } else {
      // when All BU is selected
      this.setState({
        serviceName: [],
        selectService: [],
        selectBuFilter: []
      })
      if (this.state.report.service.length == 0) {
        this.state.sname1.forEach((service) => {
          service_head.push(service);
        });
      } else {
        service_head = this.state.sname;
        this.setState({
          report: {
            service: []
          }
        })
      }
    }
    service_head.forEach((value) => {
      let opt = {};
      opt.label = value.value;
      opt.id = value.id;
      opt.value = value.value;
      opt.head = value.head;
      opt.bu = value.bu;
      serviceArray.push(opt);
    });
    this.setState({
      serviceName: serviceArray,
    }, () => {
      this.getAcvReportData(this.state);
    });
  }

  rangeFilter = (e) => {
    let filtered = [];
    this.setState({
      rangeValue: e,
      runRateValue: []
    })
    if (e && e.value != "All") {
      Object.values(this.state.acvReportDataClone).map((keys) => {
        const filter = Object.values(keys).filter((value) => {
          return Object.values(value).join("").toLowerCase().includes(e.value.toLowerCase());
        });
        if ((filter.length) > 0) {
          filtered.push(filter);
        }
      });
      this.setState({
        acvReportData: filtered
      });
    } else {
      return this.setState({
        acvReportData: this.state.acvReportDataClone
      });
    }
  }

  monthRunRateFilter(e) {
    let filtered = [];
    this.setState({
      runRateValue: e,
      // rangeValue: []
    })
    if (e && e.value != "All") {
      Object.entries(this.state.acvReportDataClone).map(([key, value]) => {
        const filteredObject = Object.values(value).filter((item) => {
          return item.monthly_runrate_range === e.value
        });

        if (filteredObject.length > 0) {
          filtered.push(filteredObject);
        }
      });
      this.setState({
        acvReportData: filtered
      });
    } else {
      return this.setState({
        acvReportData: this.state.acvReportDataClone
      });
    }
  }

  // Assuming this is part of your React component class

  applyFilters = (rangeFilter, runRateFilter) => {
    this.setState({
      loading: true,
    });
    if (Array.isArray(rangeFilter) && rangeFilter.length === 0) {
      rangeFilter = null;
    }

    if (Array.isArray(runRateFilter) && runRateFilter.length === 0) {
      runRateFilter = null;
    }

    const { acvReportDataClone } = this.state;
    let filtered = {};

    Object.entries(acvReportDataClone).forEach(([accountName, keys]) => {

      const rangeFiltered = rangeFilter && rangeFilter.value !== "All"
        ? keys.projects.filter((value) =>
          // Object.values(value).join("").toLowerCase().includes((rangeFilter.value || '').toLowerCase())
          value.range_filter === rangeFilter.value
        )
        : keys.projects;

      const finalFiltered = runRateFilter && runRateFilter.value !== "All"
        ? rangeFiltered.filter((item) => item.monthly_runrate_range === runRateFilter.value)
        : rangeFiltered;
      if (finalFiltered.length > 0) {
        filtered[accountName] = { ...acvReportDataClone[accountName], projects: finalFiltered };
      }
    });
    setTimeout(() => {
      this.setState({
        loading: false,
      });
      // Your code to execute after the timeout
    }, 3000);

    this.setState({
      acvReportData: filtered,
      rangeValue: rangeFilter || null,
      runRateValue: runRateFilter || null,
    });
  };




  handleYearChange = (selectedOption) => {
    this.setState({ selectedYear: selectedOption });
    // Add your additional logic if needed
  };


  /**
   * main render
   * @returns
   */
  render() {
    const currentYear = new Date().getFullYear();
    const years = [currentYear - 2, currentYear - 1, currentYear, currentYear + 1];
    let index = 1;
    if (this.state.redirect == 1 || this.state.loginUserData == null) {
      return <PageNotFound url={this.state.angularUrl} />
    }
    else {
      return (
        <>
          <div className={"page-content px-4 " + (this.props.addClass ? "active" : "")}>
            <Row>
              <div className="col-xs-12 fourty-fluid fourty-fluid">
                <Row className="mb-3">
                  <div className="col-sm-4 col-sm-4 col-xs-12 text-left">
                    <h1 className="B-G-bold f-s-18 d-gray t-t-upper pull-left mt-10 mr-20 page-head">Reports</h1>
                  </div>
                  <div className="col-lg-3 col-sm-4 col-xs-12">
                    <div className="selectpicker_dropdown hide_cross hide_bar orange_arrow remove_border_btm select_dropdown_picker">
                      <Dropdown title={this.state.current_report.value} activeKey="11">
                        <Dropdown.Item as="a" eventKey="1" href="/pw_report" target="_blank">Practice Wise Allocation Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="2" href="/ms_report" target="_blank">Milestone Value Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="3" href="/collections_report" target="_blank">Collections Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="4" href="/collections_potential_report" target="_blank">Collection Potential Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="5" href="/inventory_report" target="_blank">Inventory Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="6" href="/inventory_allocation_report" target="_blank">Inventory Allocation Metrics</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="7" href="/inventory_milestone_report" target="_blank">Inventory Milestone Metrics</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="8" href="/utilization_report" target="_blank">Resource Utilization Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="9" href="/csm_report" target="_blank">Csm Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="10" href="/pmo_ms_report" target="_blank">Pmo Ms Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="11">Annual Contract Value Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="12" href="/ms-allocation-report" target="_blank">MS Allocation Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="13" href="/squad-review-report" target="_blank">Squad Review Report</Dropdown.Item>
                      </Dropdown>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col-lg-6 clearfix m-b-20">
                    <div className="row align-items-center">
                      <label class="f-s-12 text-muted pt-1 w-13 col-lg-3 col-md-6 col-sm-12 text-left">LINE OF SERVICE   :</label>
                      <div className="col-lg-7 col-sm-5 text-left ">
                        <Row>
                          <div className="col-lg-10 p-l-0">
                            <div className="selectpicker_dropdown w-100 hide_bar w-100 selectpicker_dropdown_orange">
                              <Select
                                className="basic-single-select"
                                classNamePrefix={"select_dropdown"}
                                placeholder="All"
                                isClearable
                                isSearchable={true}
                                onChange={(e) => this.filterService(e)}
                                value={this.state.selectService}
                                options={this.state.serviceName} isMulti>
                              </Select>
                            </div>
                          </div>
                          <div className="col-lg-2 p-1-0">
                            {this.state.report.service != undefined && this.state.report.service.length > 0 &&
                              <a href="javascript:void(0)" title="Reset" tooltip-placement="top" className="reset-icon_old  reset-color p-l-5 m-l-4" onClick={this.reset_page}><i class="fs-16 fa fa-refresh"></i></a>
                            }
                          </div>
                        </Row>
                      </div>
                    </div>
                  </div>
                  {this.state.loginUserData.role_id != 8 &&
                    <div className="col-lg-4 clearfix m-b-20">
                      <div className="row">
                        <div class="col-sm-5 col-xs-12">
                          <label className="text-uppercase m-b-0 fs-13 p-t-5">Business Unit :</label>
                        </div>
                        <div className="col-sm-7 col-xs-12">
                          <div className="selectpicker_dropdown hide_bar w-100 selectpicker_dropdown_orange">
                            <Select
                              className="basic-single-select"
                              classNamePrefix={"select_dropdown"}
                              placeholder="All"
                              isClearable
                              isSearchable={true}
                              onChange={(e) => this.getReportByHead(e)}
                              value={this.state.selectBuFilter}
                              options={this.state.buName} >
                            </Select>
                          </div>
                        </div>
                      </div>
                    </div>}
                  <div className="col-lg-2 col-sm-2 col-xs-12 justify-content-end text-end">
                    <a href={this.state.angularUrl} className="btn btn-primary">Back</a>
                  </div>
                </Row>
                <hr className="m-t-5 col-sm-12 m-b-8 p-0"></hr>
                {this.props.loginDetails.loginUserPermission && this.props.loginDetails.loginUserPermission.permission && this.props.loginDetails.loginUserPermission.permission.view_acv_report &&
                  <>
                    <div className="col-lg-12 col-sm-12 col-xs-12 p-b-15-xs p-r-15-xs">
                      <Row className=" mt-3 mb-3">
                        <Col className="col-lg-5 text-start">
                          <div className="form-group search-icon col-sm-12 col-xs-12">
                            <input type="text" name="project_filter" value={this.state.searchBy} className="form-control B-G-bold f-s-16 d-gray pl-30" placeholder="Search..." onChange={(e) => this.searchBy(e)} />
                            <i class="fa fa-search"></i>
                          </div>
                        </Col>
                        <Col className="col-lg-2">
                          <div className="d-flex align-items-baseline performance-year">
                            <label>
                              Year : </label>
                            <div className="selectpicker_dropdown hide_bar selectpicker_dropdown_orange">
                              <Select
                                className="basic-single-select"
                                classNamePrefix={"select_dropdown"}
                                value={{ label: this.state.selectedYear, value: this.state.selectedYear }}
                                onChange={(selectedOption) => this.handleYearChange(selectedOption.value)}
                                options={years.map((year) => ({ label: year, value: year }))}
                              ></Select>
                            </div>
                          </div>
                        </Col>
                        <Col className="col-lg-5 text-end">
                          <Row className="m-0 mt-1">
                            <Col className="col-lg-3">
                              <Button onClick={(e) => this.resetReport(e)} value="Generate" className="me-2" >Generate</Button>

                            </Col>
                            <Col className="col-lg-4 text-left">
                              <Button onClick={(e) => this.generateAcvReportAsExcel(e)} value="Excel" className="me-2" disabled={this.state.acvReportData && Object.keys(this.state.acvReportData).length == 0}>Export as Excel</Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className=" mt-3 mb-3">
                        <Col className="col-lg-12 ">
                          <Row className="m-0 mt-1">


                            <Col className="col-lg-6">
                              <div className="d-flex align-items-center">
                                <label className="text-uppercase m-b-0 fs-13 p-t-7">RunRate Filter:</label>
                                <div className="selectpicker_dropdown w-50 hide_bar w-50 selectpicker_dropdown_orange m-l-15">
                                  <Select
                                    className="basic-single-select"
                                    classNamePrefix={"select_dropdown"}
                                    placeholder="All"
                                    isClearable
                                    isSearchable={true}
                                    onChange={(e) => this.applyFilters(this.state.rangeValue, e)}
                                    value={this.state.runRateValue}
                                    options={this.state.monthlyRateOptions}>
                                  </Select>
                                </div>
                              </div>
                            </Col>
                            <Col className="col-lg-6">
                              <div className="d-flex align-items-center">
                                <label className="text-uppercase m-b-0 fs-13 p-t-5">Range Filter :</label>
                                <div className="selectpicker_dropdown w-50 hide_bar w-50 selectpicker_dropdown_orange m-l-15">
                                  <Select
                                    className="basic-single-select"
                                    classNamePrefix={"select_dropdown"}
                                    placeholder="All"
                                    isClearable
                                    isSearchable={true}
                                    onChange={(e) => this.applyFilters(e, this.state.runRateValue)}
                                    value={this.state.rangeValue}
                                    options={this.state.rangeOptions}>
                                  </Select>
                                </div>
                              </div>
                            </Col>

                          </Row>
                        </Col>
                      </Row>
                      <Row>
                      </Row>
                    </div>
                    <hr className="m-t-5 col-sm-12 m-b-8 p-0"></hr>
                    <div className="table-responsive white-table-class acv-report">
                      <div className="scrollable">
                        <div className="scrollable-content">
                          <table className="table m-b-0 table-wrap">
                            <thead>
                              <tr className="theadInner busi_unit_bg">
                                <th className="text-start" >S.NO</th>

                                <th className="text-start" >Account Name</th>
                                <th className="text-start" >Project Name</th>
                                <th className="text-start" >Project Type</th>
                                {/*  <th className="text-start" >Client Name</th> */}
                                <th className="text-start" >Contract Start Date</th>
                                <th className="text-start" >Contract End Date</th>
                                <th className="text-start" >No Of Months</th>
                                <th className="text-start" >Monthly RunRate</th>
                                <th className="text-start" >Awarded Value</th>
                                <th className="text-start" >Annual contract Value</th>
                                <th className="text-start" >ACV Range</th>
                              </tr>
                            </thead>
                            {this.state.loading &&
                              <div id="preloader">
                                <div id="status"></div>
                              </div>
                            }
                            {!this.state.loading && this.state.acvReportData && (Object.keys(this.state.acvReportData).length == 0 || Object.values(this.state.acvReportData).length == 0) &&
                              <tr>
                                <td colSpan="8" className="r-no-data text-center">No data available</td>
                              </tr>
                            }
                            {!this.state.loading &&
                              this.state.acvReportData &&
                              Object.keys(this.state.acvReportData).length > 0 &&
                              Object.entries(this.state.acvReportData).map(([accountName, report]) => {
                                return (
                                  <tbody key={accountName}>
                                    {report.projects.map((value, key) => {
                                      return (
                                        <tr key={key}>
                                          {key === 0 && (
                                            <>
                                              <td rowSpan={report.projects.length}>{index++}</td>
                                              <td className="text-start" rowSpan={report.projects.length}>
                                                <span
                                                  className="name p-l-5 pmo-truncate p-t-3 fs-13"
                                                  style={{ maxWidth: "150px", textAlign: "left" }}
                                                  title={accountName}
                                                >
                                                  {accountName}
                                                </span>
                                              </td>
                                            </>
                                          )}
                                          <td className="text-start">
                                            <span
                                              className="name p-l-5 pmo-truncate p-t-3 fs-13"
                                              style={{ maxWidth: "150px", textAlign: "left" }}
                                              title={value.project_name}
                                            >
                                              {value.project_name}
                                            </span>
                                          </td>
                                          <td className="text-start">
                                            <span>{value.project_type}</span>
                                          </td>
                                          <td className="text-start">
                                            <span>{value.start_date}</span>
                                          </td>
                                          <td className="text-start">
                                            <span>{value.end_date}</span>
                                          </td>
                                          <td className="text-start">{value.no_of_months}</td>
                                          <td className="text-start">
                                            {value.no_of_months !== 0
                                              ? (value.allocation_amount / value.no_of_months).toLocaleString(
                                                undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                }
                                              )
                                              : value.allocation_amount.toLocaleString(undefined,
                                                {
                                                  minimumFractionDigits: 2,
                                                  maximumFractionDigits: 2,
                                                })}
                                          </td>
                                          <td className="text-start">
                                            <span>{value.allocation_amount}</span>
                                          </td>
                                          {key === 0 && (
                                            <td
                                              className="text-start"
                                              rowSpan={report.projects.length}
                                            >
                                              {report.projects.length > 1 ? (
                                                <span>
                                                  {report.allocationValue.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                  })}
                                                </span>
                                              ) : (
                                                <span
                                                  className="name p-l-5 pmo-truncate p-t-3 fs-13"
                                                  style={{ maxWidth: "150px", textAlign: "left" }}
                                                  title={accountName}
                                                >
                                                  {value.allocation_amount}
                                                </span>
                                              )}
                                            </td>
                                          )}

                                          {key === 0 && (
                                            <>
                                              {report.projects.length > 1 ?
                                                <td
                                                  className="text-start"
                                                  rowSpan={report.projects.length}
                                                >
                                                  <span
                                                    className="name p-l-5 pmo-truncate p-t-3 fs-13"
                                                    style={{ maxWidth: "150px", textAlign: "left" }}
                                                    title={accountName}
                                                  >
                                                    {report.clientAmount}
                                                  </span>
                                                </td>
                                                : <td
                                                  className="text-start"
                                                  rowSpan={report.projects.length}
                                                >
                                                  <span
                                                    className="name p-l-5 pmo-truncate p-t-3 fs-13"
                                                    style={{ maxWidth: "150px", textAlign: "left" }}
                                                    title={accountName}
                                                  >
                                                    {value.range_filter}
                                                  </span>
                                                </td>}

                                            </>
                                          )}
                                        </tr>
                                      )
                                    })}
                                  </tbody>
                                )
                              })}
                          </table>
                        </div>
                      </div>
                    </div>
                  </>
                }
                {this.props.loginDetails.loginUserPermission && this.props.loginDetails.loginUserPermission.permission && !this.props.loginDetails.loginUserPermission.permission.view_acv_report &&
                  <div className={"page-content " + (this.props.addClass ? "active" : "")}>
                    <div className="col-md-12 m-b-30">
                      <div className="four_not_err access_denied">
                        <div className="for_content">
                          <img alt="sad" width="" src={accessdenied} />
                          <h1>You don't have permission to access this page</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </Row >
          </div >
        </>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    acvReportDetails: state.acvReportDetails.acvReport,
    acvExport: state.acvReportDetails.acvExport,
    loginDetails: state.loginDetails,
    reportFilter: state.reportFilterDetails.reportFilter,
    addClass: state.pmsDetails.addClass
  };
};

const mapDispatchToProps = {
  getAcvReportData,
  getLoginUser,
  getReportFilter,
  getLoginUserPermission,
  acvExportExcel,
  setAddClass
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcvReport);

import React, { Component } from "react";
import { Accordion } from "react-bootstrap";
import { connect } from "react-redux";

class BenchResource extends Component {

  constructor(props) {
    super(props);
  }


  allResourceView = (data, keys) => {
    return (
      <div>
        <Accordion defaultActiveKey="0"
          className="accor_alignment mb-2"
          alwaysopen="true">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="bg-white">
              <h5 className="d-inline-block w-100 text-start p-0 my-2" style={{ "color": "orange" }}>
                {data.month}
              </h5>
            </Accordion.Header>
            <>
              <div
                uib-accordion-group="true"
                className="panel-default"
              >
                <Accordion.Item eventKey="0">
                  {Object.values(data.data.service).map((units, serviceKey) => (
                    <Accordion.Body className="bg-white">
                      {/* start Bench Count */}
                      {(this.props.benchUserTab == 'bench' || this.props.benchUserTab == 'all_resource') && this.props.benchUserTab != 'allocated_resource' && <span className="me-3"><span className="badge badge-tag red-batch-bg fs-12 px-2 me-2"> R  </span>{
                        ((units && Object.values(units).reduce((sum, current) => {
                          return sum + (current.benchUserCount)
                        }, 0)))
                      } </span>}
                      {this.props.benchUserTab == 'allocated_resource' && <span className="me-3"><span className="badge badge-tag red-batch-bg fs-12 px-2 me-2"> B  </span>0</span>}
                      {/* End of bench count */}

                      {/* Start of intern */}
                      {/*  {(this.props.benchUserTab == 'allocated_resource' || this.props.benchUserTab == 'all_resource') && this.props.benchUserTab != 'bench' && <span className="me-3"><span className="badge badge-tag extern-bg fs-12 px-2 me-2">I  </span>{
                        ((units && Object.values(units).reduce((sum, current) => {
                          return sum + (current.internUserCount)
                        }, 0)))
                      } </span>}
                      {this.props.benchUserTab == 'bench' && <span className="me-3"><span className="badge badge-tag extern-bg fs-12 px-2 me-2">I  </span>0</span>} */}
                      {/* end of intern */}

                      {/* start of emp */}
                      {/* {(this.props.benchUserTab == 'allocated_resource' || this.props.benchUserTab == 'all_resource') && this.props.benchUserTab != 'bench' && <span className="me-3"><span className="badge badge-tag PersianGreen-bg fs-12 px-2 me-2"> A </span>{
                        ((units && Object.values(units).reduce((sum, current) => {
                          return sum + (current.allocatdUserCount)
                        }, 0)))
                      } </span>} */}
                      {/* {this.props.benchUserTab == 'bench' && <span className="me-3"><span className="badge badge-tag PersianGreen-bg fs-12 px-2 me-2"> A </span>0</span>} */}
                      {/* end of emp  */}

                      {/*  <span className="me-3"><span className="badge badge-tag yellow-batch-bg fs-12 px-2 me-2"> V</span> {
                        ((units && Object.values(units).reduce((sum, current) => {
                          return sum + (current.vendorUserCount)
                        }, 0)))
                      } </span>
 */}

                      {/*start  All user */}
                      {this.props.benchUserTab &&
                        this.props.benchUserTab == 'all_resource' && this.props.benchUserTab != 'bench' && this.props.benchUserTab != 'allocated_resource' && (
                          <div className="table-scroller invoice--report inventory_allo_table">
                            <table className="table table-striped m-b-0 border-bottom" >
                              <thead>
                                <tr className="bor-no">
                                  <th
                                    colSpan="9"
                                    className="p-l-0"
                                  >
                                  </th>
                                </tr>
                                <tr className="theadInner invntry-mw-th">
                                  <th className="thead1">S.No</th>
                                  <th className="thead1">
                                    Business Unit
                                  </th>
                                  <th className="thead1">
                                    Service
                                  </th>

                                  {this.props.levels && this.props.levels.map(
                                    (levels) => (
                                      <th className="thead1">
                                        {this.props.levels ==
                                          "vendor" && (
                                            <span className="PersianBlack-text p-t-5">
                                              {levels}
                                            </span>
                                          )}
                                        {this.props.levels !=
                                          "vendor" && (
                                            <span className="PersianBlack-text p-t-5">
                                              {levels}
                                            </span>
                                          )}
                                      </th>
                                    )
                                  )}
                                </tr>
                              </thead>
                              {/* start No data available */}
                              {Object.values(units)
                                .length == 0 && (
                                  <tbody>
                                    <tr>
                                      <td
                                        colSpan="11"
                                        className="r-no-data"
                                      >
                                        No data available
                                      </td>
                                    </tr>
                                  </tbody>
                                )}
                              {/* End No data available */}
                              <tbody>

                                {Object.values(units) && Object.values(units).map((services, key) => (//service
                                  <tr className="bor-no">
                                    <td>{key + 1}</td>
                                    <td><span className="text-truncate" > </span>{services.businessUnit}</td>
                                    <td><span className="text-truncate" >  {services.serviceName}</span></td>
                                    <>
                                      {this.props.levels.map(
                                        (levels) => (
                                          <td>
                                            {(services.allUser) && (services.allUser).map(
                                              (user, keyuser) =>
                                                user.empLevel ==
                                                levels && user.empCategory && user.empCategory != 'intern' &&
                                                (
                                                  <span className={"resourcenames_span text-truncate " + ((user.empCategory == 'permanent' && user.projectName == 'Bench') ? 'text-color-red' : (user.empCategory == 'intern' ? 'text-color-blue' : null))}>
                                                    {user.projectName == 'Bench' ? (user.first_name + '(A-' + (user.allotedHrs) + ')') + '(R-' + (user.userTotalBenchHrs) + ')' : null}
                                                  </span>
                                                )
                                            )}
                                            {(services.vendorUser) && (services.vendorUser).map(
                                              (vendorUser, keyuser) =>
                                                levels == 'vendor'
                                                && (
                                                  <span className="resourcenames_span text-truncate text-color-yellow">
                                                    {
                                                      vendorUser.first_name
                                                    }
                                                  </span>
                                                )
                                            )}
                                            {(services.internUser) && (services.internUser).map(
                                              (internUser, keyuser) =>
                                                levels == 'intern'
                                                && (
                                                  <span className="resourcenames_span text-truncate text-color-blue">
                                                    {
                                                      internUser.first_name
                                                    }
                                                  </span>
                                                )
                                            )}
                                          </td>
                                        ))}
                                    </>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      {/*end  All user */}
                      {/*start  Bench user */}
                      {this.props.benchUserTab &&
                        this.props.benchUserTab == 'bench' && this.props.benchUserTab != 'all_resource' && this.props.benchUserTab != 'allocated_resource' && (
                          <div className="table-scroller invoice--report inventory_allo_table">
                            <table className="table table-striped m-b-0 border-bottom" >
                              <thead>
                                <tr className="bor-no">
                                  <th
                                    colSpan="9"
                                    className="p-l-0"
                                  >
                                  </th>
                                </tr>
                                <tr className="theadInner invntry-mw-th">
                                  <th className="thead1">S.No</th>
                                  <th className="thead1">
                                    Business Unit
                                  </th>
                                  <th className="thead1">
                                    Service
                                  </th>

                                  {this.props.levels && this.props.levels.map(
                                    (levels) => (
                                      <th className="thead1">
                                        {this.props.levels ==
                                          "vendor" && (
                                            <span className="PersianBlack-text p-t-5">
                                              {levels}
                                            </span>
                                          )}
                                        {this.props.levels !=
                                          "vendor" && (
                                            <span className="PersianBlack-text p-t-5">
                                              {levels}
                                            </span>
                                          )}
                                      </th>
                                    )
                                  )}
                                </tr>
                              </thead>
                              {/* start No data available */}
                              {Object.values(units)
                                .length == 0 && (
                                  <tbody>
                                    <tr>
                                      <td
                                        colSpan="11"
                                        className="r-no-data text-center"
                                      >
                                        No data available
                                      </td>
                                    </tr>
                                  </tbody>
                                )}
                              {/* End No data available */}

                              <tbody>
                                {Object.values(units) && Object.values(units).map((services, key) => (//service

                                  <tr className="bor-no">
                                    <td>{key + 1}</td>
                                    <td><span className="text-truncate" > </span>{services.businessUnit}</td>
                                    <td><span className="text-truncate" > {services.serviceName}</span></td>
                                    <>
                                      {this.props.levels.map(
                                        (levels) => (
                                          <td>
                                            {(services.allUser) && (services.allUser).map(
                                              (user, keyuser) =>
                                                user.empLevel ==
                                                levels && user.projectName == 'Bench' && user.empCategory && user.empCategory != 'intern' &&
                                                (

                                                  <span className="resourcenames_span text-truncate text-color-red ">{user.first_name}({user.userTotalBenchHrs})</span>

                                                )
                                            )}
                                            {(services.vendorUser) && (services.vendorUser).map(
                                              (vendorUser, keyuser) =>
                                                levels == 'vendor'
                                                && vendorUser.projectName == 'Bench' && (
                                                  <span className="resourcenames_span text-truncate text-color-yellow">
                                                    {
                                                      vendorUser.first_name
                                                    }({vendorUser.userTotalBenchHrs})
                                                  </span>
                                                )
                                            )}
                                            {(services.internUser) && (services.internUser).map(
                                              (internUser, keyuser) =>
                                                levels == 'intern' && internUser.projectName == 'Bench'
                                                && (
                                                  <span className="resourcenames_span text-truncate text-color-blue">
                                                    {
                                                      internUser.first_name
                                                    }({internUser.userTotalBenchHrs})
                                                  </span>
                                                )
                                            )}
                                          </td>
                                        ))}
                                    </>



                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      {/*end  Bench user */}
                      {/*start allocated user */}
                      {this.props.benchUserTab &&
                        this.props.benchUserTab != 'bench' && this.props.benchUserTab != 'all_resource' &&
                        this.props.benchUserTab == 'allocated_resource' && (
                          <div className="table-scroller invoice--report inventory_allo_table">
                            <table className="table table-striped m-b-0 border-bottom" >
                              <thead>
                                <tr className="bor-no">
                                  <th
                                    colSpan="9"
                                    className="p-l-0"
                                  >
                                  </th>
                                </tr>
                                <tr className="theadInner invntry-mw-th">
                                  <th className="thead1">S.No</th>
                                  <th className="thead1">
                                    Business Unit
                                  </th>
                                  <th className="thead1">
                                    Service
                                  </th>

                                  {this.props.levels && this.props.levels.map(
                                    (levels) => (
                                      <th className="thead1">
                                        {this.props.levels ==
                                          "vendor" && (
                                            <span className="PersianBlack-text p-t-5">
                                              {levels}
                                            </span>
                                          )}
                                        {this.props.levels !=
                                          "vendor" && (
                                            <span className="PersianBlack-text p-t-5">
                                              {levels}
                                            </span>
                                          )}
                                      </th>
                                    )
                                  )}
                                </tr>
                              </thead>
                              {/* start No data available */}
                              {Object.values(units)
                                .length == 0 && (
                                  <tbody>
                                    <tr>
                                      <td
                                        colSpan="11"
                                        className="r-no-data text-center"
                                      >
                                        No data available
                                      </td>
                                    </tr>
                                  </tbody>
                                )}
                              {/* End No data available */}
                              <tbody>
                                {Object.values(units) && Object.values(units).map((services, key) => (//service
                                  <tr className="bor-no">
                                    <td>{key + 1}</td>
                                    <td><span className="text-truncate" > </span>{services.businessUnit}</td>
                                    <td><span className="text-truncate" > {services.serviceName}</span></td>
                                    <>
                                      {this.props.levels.map(
                                        (levels) => (
                                          <td>
                                            {(services.allUser) && (services.allUser).map(
                                              (user, keyuser) =>
                                                user.empLevel ==
                                                levels && user.projectName != 'Bench' && user.empCategory &&
                                                (
                                                  <span className={"resourcenames_span text-truncate " + ((user.empCategory == 'intern' ? 'text-color-blue' : (user.empCategory == 'permanent' && user.projectName != 'Bench') ? 'text-color-green' : ''))}> {user.first_name + '(' + (user.allotedHrs) + ')'}
                                                  </span>

                                                )
                                            )}
                                            {(services.vendorUser) && (services.vendorUser).map(
                                              (vendorUser, keyuser) =>
                                                levels == 'vendor'
                                                && vendorUser.projectName != 'Bench' && (
                                                  <span className="resourcenames_span text-truncate text-color-yellow">
                                                    {
                                                      vendorUser.first_name
                                                    }
                                                  </span>
                                                )
                                            )}
                                          </td>
                                        ))}
                                    </>



                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      {/*end allocated user */}
                    </Accordion.Body>
                  ))}
                </Accordion.Item>
              </div>


            </>


          </Accordion.Item>
        </Accordion>
      </div>
    )
  };

  render() {
    return (
      <>
        <div className="scrollable-content">
          {this.props.loading && (
            <div id="preloader">
              <div id="status"></div>
            </div>
          )}
          {!this.props.loading &&
            this.props.utilizationReport.length == 0 && (
              <div>
                <div className="r-no-data text-center">
                  No data available
                </div>
              </div>
            )}
          {!this.props.loading &&
            this.props.utilizationReport.length > 0 && this.props.utilizationReport.map((item, keys) => (
              <div className="reports--grid accordian_servicesdiv">
                {this.allResourceView(item, keys)}
              </div>
            ))}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    // checkSumUtilization :state.reportDetails.checkSumUtilization
  };
};

const mapDispatchToProps = {

};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BenchResource);
import * as types from "../Utilities/Type";
const INITIAL_STATE = {
  inferenceLists: [],
  pulseScoreLists: [],
  chapterAnswer: {},
  savePulseScoreRes: {},
  getCompetencyIndex: [],
  saveCompetencyIndex: {},
  pipLists: [], 
  attritionLists: [],
  recruitmentLists: [],
  saveChapterReviewDataRes: {},
  hitMissesLists: [],
  icrLists: [],
  actionItemsLists: [],
  saveReserveActivityRes: {},
  reserveActivityLists: [],
};
export function getChapterReview(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_CHAPTER_INFERENCE:
      return {
        ...state,
        inferenceLists: action.payload,
      };
    case types.SAVE_CHAPTER_ANSWER:
      return {
        ...state,
        chapterAnswer: action.payload,
      };
    case types.SAVE_PULSE_SCORE:
      return {
        ...state,
        savePulseScoreRes: action.payload,
      }; 
    case types.GET_PULSE_SCORE:
      return {
        ...state,
        pulseScoreLists: action.payload,
      };
    case types.SAVE_COMPETENCY_INDEX:
      return {
        ...state,
        savePulseScoreRes: action.payload,
      }; 
    case types.GET_COMPETENCY_INDEX:
      return {
        ...state,
        pulseScoreLists: action.payload,
      };  
    case types.GET_PIP:
      return {
        ...state,
        pipLists: action.payload,
      }; 
    case types.GET_ATTRITION:
      return {
        ...state,
        attritionLists: action.payload,
      }; 
    case types.GET_RECRUITMENT:
      return {
        ...state,
        recruitmentLists: action.payload,
      };
    case types.SAVE_RESERVE_ACTIVITY:
      return {
        ...state,
        saveReserveActivityRes: action.payload,
      }; 
    case types.GET_RESERVE_ACTIVITY:
      return {
        ...state,
        reserveActivityLists: action.payload,
      };
    case types.SAVE_CHAPTER_REVIEW:
      return {
        ...state,
        saveChapterReviewDataRes: action.payload,
      }; 
    case types.GET_HITS_MISSES:
      return {
        ...state,
        hitMissesLists: action.payload,
      };
    case types.GET_ICR:
      return {
        ...state,
        icrLists: action.payload,
      };     
      case types.GET_ACTION_ITEMS:
        return {
          ...state,
          actionItemsLists: action.payload,
        };
    case "3":
      break;
    default:
      return state;
  }
}
import React, { Component } from "react";
import { Accordion } from "react-bootstrap";
import { connect } from "react-redux";
import * as constants from "../../Config/Constant";

class BenchView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // loading: false,
    };
  }

  reportView = (data, keys) => {
    return (
      <div>
        <Accordion defaultActiveKey="0"
          className="accor_alignment mb-2"
          alwaysopen="true">
          <Accordion.Item eventKey="0">

            <Accordion.Header className="bg-white">
              <h5 className="d-inline-block w-100 text-start p-0 my-2" style={{ "color": "orange" }}>
                {data.month}

              </h5>
            </Accordion.Header>

            {Object.values(data.data.service).length && Object.values(data.data.service).map((units, serviceKey) => (

              <>
                {(this.props.businessUnits == constants.QUALITY_MANAGEMENT && Object.keys(data.data.service)[serviceKey] != constants.DELIVERY_MANAGEMENT) &&
                  <div
                    uib-accordion-group="true"
                    className="panel-default"
                  >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="bg-white">
                        <h5 className="d-inline-block w-100 text-start p-0 my-2">
                          {Object.keys(data.data.service)[serviceKey]}{" - "}
                          {
                            ((units && Object.values(units).reduce((sum, current) => {
                              return sum + (current.benchUserCount + current.vendorUserCount)
                            }, 0)))
                          }
                        </h5>
                      </Accordion.Header>
                      <Accordion.Body className="bg-white">
                        <div className="table-scroller invoice--report inventory_allo_table">
                          <table className="table table-striped m-b-0 border-bottom">
                            <thead>
                              <tr className="bor-no">
                                <th
                                  colspan="9"
                                  className="p-l-0"
                                >

                                </th>
                              </tr>
                              <tr className="theadInner invntry-mw-th">
                                <th className="thead1">S.No</th>
                                {/* <th className="thead1">
                                            Business Unit
                                          </th> */}
                                <th className="thead1">
                                  Service
                                </th>
                                <th className="thead1">
                                  Total Bench Count
                                </th>
                                {this.props.levels && this.props.levels.map(
                                  (levels) => (
                                    <th className="thead1">
                                      {this.props.levels ==
                                        "vendor" && (
                                          <span className="PersianBlack-text p-t-5">
                                            {levels}
                                          </span>
                                        )}
                                      {this.props.levels !=
                                        "vendor" && (
                                          <span className="PersianBlack-text p-t-5">
                                            {levels}
                                          </span>
                                        )}
                                    </th>
                                  )
                                )}
                              </tr>
                            </thead>

                            {Object.values(units)
                              .length == 0 && (
                                <tbody>
                                  <tr>
                                    <td
                                      colspan="11"
                                      className="r-no-data text-center"
                                    >
                                      No data available
                                    </td>
                                  </tr>
                                </tbody>
                              )}
                            <tbody>
                              {Object.values(units) && Object.values(units).map((services, key) => (
                                <tr className="bor-no">
                                  <td>{key + 1}</td>
                                  {/* <td><span className="text-truncate" > {services.businessUnit}</span></td> */}
                                  <td><span className="text-truncate" > {services.serviceName}</span></td>
                                  <td>
                                    <span className="">{services.benchUserCount + services.vendorUserCount} </span>
                                  </td>
                                  {this.props.levels.map(
                                    (levels) => (
                                      <td>
                                        {Object.keys(
                                          services.levelCount
                                        ).map(
                                          (levelKey) =>
                                            levelKey ==
                                            levels && (
                                              <span>
                                                {
                                                  services.levelCount[
                                                  levelKey
                                                  ]
                                                }
                                              </span>
                                            )
                                        )}

                                      </td>
                                    ))}
                                </tr>
                              )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                }
                {(this.props.businessUnits != constants.QUALITY_MANAGEMENT) &&
                  <div
                    uib-accordion-group="true"
                    className="panel-default"
                  >
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="bg-white">
                        <h5 className="d-inline-block w-100 text-start p-0 my-2">
                          {Object.keys(data.data.service)[serviceKey]}{" - "}
                          {
                            ((units && Object.values(units).reduce((sum, current) => {
                              return sum + (current.benchUserCount + current.vendorUserCount)
                            }, 0)))
                          }
                        </h5>
                      </Accordion.Header>
                      <Accordion.Body className="bg-white">
                        <div className="table-scroller invoice--report inventory_allo_table">
                          <table className="table table-striped m-b-0 border-bottom">
                            <thead>
                              <tr className="bor-no">
                                <th
                                  colspan="9"
                                  className="p-l-0"
                                >

                                </th>
                              </tr>
                              <tr className="theadInner invntry-mw-th">
                                <th className="thead1">S.No</th>
                                {/* <th className="thead1">
                                            Business Unit
                                          </th> */}
                                <th className="thead1">
                                  Service
                                </th>
                                <th className="thead1">
                                  Total Bench Count
                                </th>
                                {this.props.levels && this.props.levels.map(
                                  (levels) => (
                                    <th className="thead1">
                                      {this.props.levels ==
                                        "vendor" && (
                                          <span className="PersianBlack-text p-t-5">
                                            {levels}
                                          </span>
                                        )}
                                      {this.props.levels !=
                                        "vendor" && (
                                          <span className="PersianBlack-text p-t-5">
                                            {levels}
                                          </span>
                                        )}
                                    </th>
                                  )
                                )}
                              </tr>
                            </thead>

                            {Object.values(units)
                              .length == 0 && (
                                <tbody>
                                  <tr>
                                    <td
                                      colspan="11"
                                      className="r-no-data text-center"
                                    >
                                      No data available
                                    </td>
                                  </tr>
                                </tbody>
                              )}
                            <tbody>
                              {Object.values(units) && Object.values(units).map((services, key) => (
                                <tr className="bor-no">
                                  <td>{key + 1}</td>
                                  {/* <td><span className="text-truncate" > {services.businessUnit}</span></td> */}
                                  <td><span className="text-truncate" > {services.serviceName}</span></td>
                                  <td>
                                    <span className="">{services.benchUserCount + services.vendorUserCount} </span>
                                  </td>
                                  {this.props.levels.map(
                                    (levels) => (
                                      <td>
                                        {Object.keys(
                                          services.levelCount
                                        ).map(
                                          (levelKey) =>
                                            levelKey ==
                                            levels && (
                                              <span>
                                                {
                                                  services.levelCount[
                                                  levelKey
                                                  ]
                                                }
                                              </span>
                                            )
                                        )}

                                      </td>
                                    ))}
                                </tr>
                              )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                }
              </>

            )
            )}
          </Accordion.Item>
        </Accordion>
      </div>
    )
  };

  render() {
    return (
      <>
        <div className="scrollable-content">
          {this.props.loading && (
            <div id="preloader">
              <div id="status"></div>
            </div>
          )}
          {!this.props.loading &&
            this.props.utilizationReport.length == 0 && (
              <div>
                <div className="r-no-data text-center">
                  No data available
                </div>
              </div>
            )}
          {!this.props.loading &&
            this.props.utilizationReport.length > 0 && this.props.utilizationReport.map((item, key) => (
              <div className="reports--grid accordian_servicesdiv">
                {/* {this.props.selectBuFilter.length == 0 && Object.keys(this.props.selectService).length == 0 && */}
                {/* <Accordion
                    defaultActiveKey="0"
                    className="accor_alignment mb-2"
                    alwaysopen="true"
                  > */}
                <div>
                  {this.reportView(item, key)}</div>
                {/* </Accordion> */}
                {/* } */}

              </div>
            ))}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    checkSumUtilization: state.reportDetails.checkSumUtilization
  };
};

const mapDispatchToProps = {

};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BenchView);
import * as types from "../Utilities/Type"

const INITIAL_STATE = {
    squadReport: [],
    excelResult: {},
};
export function getSquadReport(state=INITIAL_STATE,action){
    switch (action.type){
        case types.GET_SQUAD_REPORT: 
            return{
                ...state,
                squadReport:action.payload
            }
        case types.SQUAD_EXPORT_EXCEL: 
            return{
                ...state,
                excelResult:action.payload
            } 
            
        case '3':  
             break;  
        default:return state        

    }
}

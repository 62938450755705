import { combineReducers } from "redux";

import { getUtilizationReport } from "./UtilizationReducer";
import { getLoginUser } from "./LoginReducer";
import { getReportFilter } from "./ReportReducer";
import { getCsmReport } from "./CsmReducer";
import { getPmoReportData } from "./PmoReducer";
import { getAcvReportData } from "./AcvReducer";
import { getMsAllocationReportData } from "./MsAllocationReportReducer";
import { getPmsData } from "./PmsReducer";
import { notificationDetails } from "./NotificationReducer";
import { getSquadReport } from "./SquadReducer";
import { getChapterReview } from "./ChapterReviewReducer";
import { getUserDetails } from "./UserReducer";
import { getBuList } from "./BuReducer";

export default combineReducers(
    {
        reportDetails: getUtilizationReport,
        loginDetails: getLoginUser,
        reportFilterDetails: getReportFilter,
        csmReportDetails: getCsmReport,
        pmoReportDetails: getPmoReportData,
        acvReportDetails: getAcvReportData,
        msAllocationReportDetails: getMsAllocationReportData,
        pmsDetails: getPmsData,
        notificationDetails: notificationDetails,
        squadReportDetails: getSquadReport,
        chapterReviewDetails: getChapterReview,
        getUserDetails : getUserDetails,
        getBuList : getBuList
    }
)
import React, { Component } from "react";
import {
  getPmsDashboard,
  getReportFilter,
  getLoginUserPermission,
  getReportFilterPmo,
  setAddClass,
  downloadStatistics,
  getUserData,
  savePmsPermission,
  getPmsPermission
} from "../../Action";
import { connect } from "react-redux";
import * as constants from "../../Config/Constant";
import PageNotFound from "../Reports/PageNotFound";
import { Row, Col, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import Select from "react-select";
import accessdenied from "../../access-denied.png";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import PmsSettingGrid from "./PmsSettingGrid";
import AddUpdateSettings from "./AddUpdateSettings";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  customClass: {
    popup: "colored-toast",
    icon: "white-toast-icon",
  },
  timer: 3000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

class PmsPermission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: new URLSearchParams(window.location.search),
      redirect: 0,
      sname: [],
      loginUserData: {},
      loginUserPermission: {},
      loading: false,

      selectedUser: null,
      userList: [],

      dashboardLoader: false,
    };
  }

  componentDidMount() {
    let token =
      this.state.queryParams.get("token") === null
        ? Cookies.get("auth_data")
        : this.state.queryParams.get("token");
    let url =
      this.state.queryParams.get("url") == null
        ? Cookies.get("url_code")
        : this.state.queryParams.get("url");
    this.setState({
      token: token,
      apiLink: constants.API_LINK,
      angularUrl: constants.FRONT_LINK,
      excelUrl: constants.EXCEL_LINK,
      url: url,
    });
    Cookies.set("auth_data", token);
    Cookies.set("url_code", url);
    this.state.queryParams.delete("token");
    this.state.queryParams.delete("url");
    if (this.props.history != undefined) {
      this.props.history.replace({
        search: this.state.queryParams.toString(),
      });
    }
    if (token == "undefined" || token == undefined || token == null) {
      this.state.redirect = 1;
    } else {
      this.getUser();
    }
    this.getUserData();    
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.props.loginDetails) !==
      JSON.stringify(prevProps.loginDetails)
    ) {
      this.getUser();
    }
  }
  pmsServiceAccess = (selectedOption) => {
    this.setState({ selectedUser: selectedOption });
    console.log(selectedOption);
    let postData = {
      data: {
        selectedOption
      }
    }
    this.props.savePmsPermission(postData)
    .then((response) => {
      let pmsPermissionResponse = this.props.pmsPermissionResponse;
      console.log(pmsPermissionResponse);
    })

  };
  getUserData = () => { 
    this.setState({ 
      loading: true,
    });
    this.props.getUserData()
    .then((response) =>{
      if(this.props.userList?.result){
        let userList = this.props.userList.result;
        userList = userList.map(users => ({...users,label : users.name, value : users.name}))
        this.setState({
            loading: false,
            userList
        });
      }
    });
    this.props.getPmsPermission()
    .then((response) =>{
      if(this.props.getPmsPermissionData?.result){
        let selectedUser = this.props.getPmsPermissionData.result;
        console.log(selectedUser);
        selectedUser = selectedUser.map(users => ({...users,label : users.name, value : users.name}))
        this.setState({
          selectedUser
        });
      }
    });
  }
  /**
   * Get the login user details
   */
  getUser = () => {
    const role_id = this.props?.loginDetails?.loginDetails?.role_id;
    this.setState({
      loginUserData: this.props?.loginDetails?.loginDetails,
      loginUserPermission: this.props?.loginDetails?.loginUserPermission,
      role_id,
      dashboardLoader: true,
    });
   
  };

  render() {
    const {
      isActionsPage,
      redirect,
      dashboardLoader,
      loginUserData,
      role_id,
    } = this.state;

    if (redirect === 1 || loginUserData === null) {
      return <PageNotFound url={constants.FRONT_LINK} />;
    } else if (isActionsPage) {
      return <></>;
    } else {
      return (
        <div
          className={`page-content px-4 pms-dashboard-container ${this.props.addClass ? "active" : ""
            }`}
        >
          <Row>
            <div className="col-xs-12 fourty-fluid fourty-fluid">
              <Row className="row">
                <div className="col-sm-4 col-sm-4 col-xs-12 col-md-12 text-left">
                  <h1 className="B-G-bold f-s-18 d-gray t-t-upper pull-left mt-15 mr-20 page-head lh-10">
                    PMS PERMISSION
                  </h1>
                </div>
              </Row>
              <div class="bgwhite service-container p-2 mt-3 mx-0 pb-3 multiselect-scrollbar row row-cols-lg-6 row-cols-md-4 row-cols-2">
                  <div class="text-left d-flex flex-column w-100 text-start justify-content-end  col">
                      <label class="m-b-0 fs-12 p-t-5 style-label m-r-10">PMS Service</label>
                      <div class="selectpicker_dropdown bu-name">
                          <div class="style-service fs-20">
                            <div className="selectpicker_dropdown hide_bar w-100 selectpicker_dropdown_orange">
                              <Select
                                className="basic-single-select"
                                classNamePrefix={"select_dropdown"}
                                placeholder="All"                      
                                isSearchable={true}
                                onChange={this.pmsServiceAccess}
                                value={this.state.selectedUser}
                                options={this.state.userList}
                                isMulti
                              ></Select> 
                            </div>
                          </div>
                      </div>
                  </div>              
              </div>          
            </div>
          </Row>      
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
    pmsDetails: state.pmsDetails,
    userList: state.getUserDetails.userList,
    pmsPermissionResponse: state.pmsDetails.pmsPermissionResponse,
    getPmsPermissionData: state.pmsDetails.getPmsPermissionData
  };
};

const mapDispatchToProps = {
  getPmsDashboard,
  getReportFilter,
  getLoginUserPermission,
  getReportFilterPmo,
  setAddClass,
  getUserData,
  downloadStatistics,
  savePmsPermission,
  getPmsPermission
};

export default connect(mapStateToProps, mapDispatchToProps)(PmsPermission);
import React from "react";
import { registerLocale } from "react-datepicker";
import NoPageImg from "../../404.svg";
import { Urls } from '../../Config/Config';

class PageNotFound extends React.Component {
    constructor(props) {
		super(props);
        this.state ={
            url : props.url
        }
    }
    render() {
        console.log("welcome")
        return (
            <div>
            <section class="industry-sec pb-5">
                <div class="container h-80vh">
                <div class="text-center px-2 px-md-5 mx-0 mx-5">
                    <img src={NoPageImg} class="img-fluid" alt="" />
                    <br />
                    <p class="text-muted">
                    Please visit&nbsp;&nbsp;<a href={this.state.url} class="btn_light_green pageerror_link">Home page</a>
                    </p>
                </div>
                </div>
            </section>
            {/* <footer>
                <div className="inner_footer">                
                <div className="col-md-12 copy-right text-center">
                    <p>&copy; 2022 <span>Reflections-</span> All rights Reserved.</p>
                </div>
                </div>
            </footer> */}
            </div>
        );
    }
};

export default PageNotFound;
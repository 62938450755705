import React, { Component } from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import "../Reports/Report.css";

export default class InputPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: this.props.item[this.props.name] ?? 0,
      defaultValue: 0
    }
  }

  handleKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;

    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
    }

    // Allow only one decimal point
    if (e.target.value.includes('.') && charCode === 46) {
      e.preventDefault();
    }
  }

  handleChange = (e) => {
    this.setState({
        defaultValue: this.state.inputValue,
        inputValue: e.target.value,
    });
  }

  closeModal = (e) => {
    this.setState({
        inputValue: this.state.defaultValue
    });
    this.props.closeModal(e);
  }

  handleSubmit = (e) => {
    this.props.item[this.props.name] = parseFloat(this.state.inputValue);
    this.props.handleSubmit(e);
  }

  render() {
    return (
      <>
        <Modal style={{ width: "25rem", marginLeft: "37rem" }} show={this.props.isOpen} onHide={(e) => this.closeModal(e)}>
          <Modal.Header >
            <button className="potential-button pull-right" onClick={(e) => this.closeModal(e)}>X</button>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Enter {this.props.title} :</Form.Label>
              <Form.Control type="text" name={this.props.name} value={this.state.inputValue} placeholder="Enter value " onChange={(e) => this.handleChange(e)} onKeyPress={(event) => this.handleKeyPress(event)}/>
            </Form.Group>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type='submit' disabled={this.state.formValid} onClick={(e) => this.handleSubmit(e)}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
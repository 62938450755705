import { postData,getData } from "../Services/Helper";
import { handleResponse } from "../Services/HandleResponse";
import { getBackEndConstant } from "../Config/Constant";
import * as types from "../Utilities/Type";

/**
 * get developer list by report id
 * @param {*} dataObj
 * @returns
 */
export function getDevelpersByReportId(dataObj) {
    return (dispatch) => {
        const requestOptions = postData(dataObj);
        let url = getBackEndConstant().backend_url + "get_developers_by_report_id";
        return fetch(url, requestOptions)
        .then((response) => handleResponse(response))
        .then((response) => {
            dispatch({
            type: types.GET_DEVELOPER_BY_REPORT_ID,
            payload: (response.result) ? response.result : [],
            });
            return response;
        });
    };
}
/**
 * get user list
 * @param {*} dataObj
 * @returns
 */
export function getUserData() {
    return (dispatch) => {
      const requestOptions = getData();
      let url = getBackEndConstant().backend_url + "get_users_data";
      return fetch(url, requestOptions)
        .then((response) => handleResponse(response))
        .then((response) => {
          dispatch({
            type: types.GET_USER_LIST,
            payload: response ? response : [],
          });
        });
    };
  }

import * as types from "../Utilities/Type"
const INITIAL_STATE = {
    utilizationReport: [],
    utilizationExport: [],
    utilizationValues: [],
    checkSumUtilization: false
};

export function getUtilizationReport(state=INITIAL_STATE,action){
    switch (action.type){
        case types.GET_UTILIZATION:
             return{
                ...state,
                utilizationReport:action.payload
             }
        case types.UTLIZATION_EXPORT_EXCEL:
             return{
                ...state,
                utilizationExport:action.payload
             }
        case types.GET_CHECK_SUM: 
             return{
                ...state,
                checkSumUtilization:action.payload
            }             
        case types.UPDATE_UTILIZATION_VALUES:
            return{
                ...state,
                utilizationValues:action.payload
            }
        case '3':  
             break;  
        default:return state        

    }
}

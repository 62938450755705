
import * as types from "../Utilities/Type"
const INITIAL_STATE = {
    msAllocationReport: [],
    msAllocationExport: [],
    msSummaryReport: [],
    msSummaryQuarterFilter: [],
    msPotentialProjectName: [],
    msPotentialProjectDetails: [],
    msPotentialProjectList: [],
    msDeletePotentialProject: [],
    msPotentialExport: [],
    msEditProjectPotential: [],
    msSummaryTargetValue: [],
};

export function getMsAllocationReportData(state = INITIAL_STATE, action) {
    switch (action.type) {
        case types.GET_MS_ALLOCATION_REPORT:
            return {
                ...state,
                msAllocationReport: action.payload
            }
        case types.GET_MS_SUMMARY_REPORT:

            return {
                ...state,
                msSummaryReport: action.payload
            }
        case types.MS_ALLOCATION_EXPORT_EXCEL:
            return {
                ...state,
                msAllocationExport: action.payload
            }
        case types.MS_POTENTIAL_PROJECT_NAME:
            return {
                ...state,
                msPotentialProjectName: action.payload
            }
        case types.MS_POTENTIAL_PROJECT_DETAILS:
            return {
                ...state,
                msPotentialProjectDetails: action.payload
            }
        case types.MS_POTENTIAL_PROJECT_LIST:
            return {
                ...state,
                msPotentialProjectList: action.payload
            }
        case types.MS_DELETE_CURRENT_POTENTIAL_PROJECT:
            return {
                ...state,
                msDeletePotentialProject: action.payload
            }
        case types.MS_POTENTIAL_EXPORT_EXCEL:
            return {
                ...state,
                msPotentialExport: action.payload
            }
        case types.EDIT_PROJECT_POTENTIAL:
            return {
                ...state,
                msEditProjectPotential: action.payload
            }
        case types.SAVE_TARGET_VALUE:
            return {
                ...state,
                msSummaryTargetValue: action.payload
            }
        case '3':
            break;
        default: return state
    }
}

import React, { Component } from "react";
import { returnFloatingValueforInventory } from "../../Services/CommonService";
import { connect } from "react-redux";
import { updateUtilizationValues } from "../../Action";
import PopUp from "./InputPopUp";
class ActualView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            title: "",
            name: "",
            item: {}
        };
    }

    handleSubmit = (e) => {
        e.currentTarget.blur(); // removing focus
        
        this.state.item['revenueGap'] = this.state.item['totalMSValue'] - this.state.item['targetRevenue'];

        let postData = {
            utilizationdata : this.state.item
        }

        this.props.updateUtilizationValues(postData)
        .then((result) => {
            console.log(result);
        });

        this.setState({
            item: this.state.item,
            isOpen: false
        }, () => {
            this.props.updateGrandTotal();
        });
    };

    openModal = (item, title, name) => {
        this.setState({
          isOpen: true,
          title: title,
          item: item,
          name: name
        });
    };

    closeModal = (e) => {
        e.currentTarget.blur(); // removing focus
        this.setState({
            isOpen: false
        });
    };
    getColorForUtilizationPercentage(allotedHrs, totalBenchHrs) {
        const percentage = (allotedHrs + totalBenchHrs) > 0 ? (((allotedHrs) / (allotedHrs + totalBenchHrs)) * 100) : 0;
        if (percentage >= 70 && percentage <= 80) {
          return "#FFBF00";
        } else if (percentage < 70) {
          return "red";
        } else {
          return "green";
        }
    }
    render() {
        return (
            <>
                <div className="scrollable-content">
                    {this.props.loading && (
                        <div id="preloader">
                            <div id="status"></div>
                        </div>
                    )}
                    {!this.props.loading &&
                        this.props.utilizationReport.length > 0 && this.props.utilizationReport.map((data, buKey) => (
                            <>
                                {data.data.service && Object.keys(data.data.service).length > 0 && (
                                    <div className="table-scroller invoice--report inventory_allo_table">
                                        <div>
                                            <table className="table table-striped m-b-0 border-bottom">
                                                {/* All and optisol header */}

                                                <thead className="bg-blue">
                                                    <tr className="theadInner invntry-mw-th">
                                                        <th
                                                            rowSpan="2"
                                                            width="9%"
                                                            className="thead1"
                                                        >
                                                            Month
                                                        </th>
                                                        <th
                                                            rowSpan="2"
                                                            width="13%"
                                                            className="thead1"
                                                        >
                                                            Business Units
                                                        </th>

                                                        <th
                                                            rowSpan="2"
                                                            width="13%"
                                                            className="thead1"
                                                        >
                                                            LOS
                                                        </th>
                                                        <th
                                                            rowSpan="2" width="10%"
                                                            className="thead1"
                                                        >
                                                            Total Available Developers
                                                        </th>
                                                        <th
                                                            rowSpan="2"
                                                            width="11%"
                                                            className="thead1"
                                                        >
                                                            MS Allocated Developers
                                                        </th>
                                                        <th
                                                            rowSpan="2"
                                                            width="11%"
                                                            className="thead1"
                                                        >
                                                            Reserved Developers
                                                        </th>
                                                        <th
                                                            rowSpan="2" width="13%"
                                                            className="thead1"
                                                        >
                                                            Utilization
                                                        </th>
                                                        <th
                                                            rowSpan="2" width="15%"
                                                            className="thead1"
                                                        >
                                                            Cost
                                                        </th>
                                                        <th
                                                        rowSpan="2" width="16%"
                                                        className="thead1"
                                                        >
                                                            MS Allocation
                                                        </th>
                                                        <th
                                                            rowSpan="2" width="16%"
                                                            className="thead1"
                                                        >
                                                            Invoiced Value
                                                        </th>
                                                        <th
                                                            rowSpan="2" width="15%"
                                                            className="thead1"
                                                        >
                                                            Achieved Margin
                                                        </th>
                                                        <th
                                                            rowSpan="2" width="11%"
                                                            className="thead1"
                                                        >
                                                            Expected Gross Margin
                                                        </th>
                                                        <th
                                                            rowSpan="2" width="15%"
                                                            className="thead1"
                                                        >
                                                            Allocated Yield
                                                        </th>
                                                        <th
                                                            rowSpan="2" width="11%"
                                                            className="thead1"
                                                        >
                                                        Target Revenue
                                                        </th>
                                                        <th
                                                            rowSpan="2" width="11%"
                                                            className="thead1"
                                                        >
                                                        Revenue Gap
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <>
                                                    <tbody className="bor-no">
                                                        <>
                                                            {Object.values(data.data.service).map((units, keys) => ( //Business unit
                                                                <>
                                                                    <tr className="bor-no main_top_one">
                                                                        <td rowSpan={(Object.values(units).length) + 1} className="PigmentIndigo-text p-t-5 p-b-5 text-center">
                                                                            <span style={{ "wordBreak": "break-word" }} title='month'>
                                                                                {data.month}
                                                                            </span>
                                                                        </td>
                                                                        <td rowSpan={(Object.values(units).length) + 1} className="PigmentIndigo-text p-t-5 p-b-5 text-center">
                                                                            <span style={{ "wordBreak": "break-word" }} title={Object.keys(data.data.service)[keys]}>
                                                                                {Object.keys(data.data.service)[keys]}
                                                                            </span>
                                                                        </td>
                                                                    </tr>

                                                                    {Object.values(units).map((item, key) => (//service
                                                                        <>
                                                                            <tr className="bor-no main_top_one">

                                                                                {/* LOS */}
                                                                                <td className="PigmentIndigo-text p-t-5 p-b-5 text-left">
                                                                                    <span className="text-truncate" title={item.serviceName}>
                                                                                        {item.serviceName}
                                                                                    </span>
                                                                                </td>

                                                                                {/* Total Available Developers */}
                                                                                <td className="PigmentIndigo-text p-t-5 p-b-5 text-center">
                                                                                    <span className="text-truncate p-t-5 fs-12 dis-b" >
                                                                                        {((item.allotedHrs + item.totalBenchHrs) / 160).toFixed(2)}

                                                                                    </span>
                                                                                </td>
                                                                                {/* MS Allocated Developers */}
                                                                                <td className="PigmentIndigo-text p-t-5 p-b-5 text-center">
                                                                                    <span className="text-truncate p-t-5 fs-12 dis-b">
                                                                                        {((item.allotedHrs) / 160).toFixed(2)}
                                                                                    </span>
                                                                                </td>
                                                                                {/* BENCH RESOURCE (Reserved Developers)*/}
                                                                                <td className="PigmentIndigo-text p-t-5 p-b-5 text-center">
                                                                                    <span className="text-truncate p-t-5 fs-12 dis-b">
                                                                                        <div onClick={() => this.props.showBenchResource('bench', 'bench')} className={item.reserveStrength > 0 ? 'reserve_data' : 'bench_data'} title='Please Click to see Total Bench Resources'>
                                                                                            {(item.totalBenchHrs / 160).toFixed(2)}
                                                                                        </div>
                                                                                    </span>
                                                                                </td>
                                                                                {/* TOTAL UTILIZATION % */}
                                                                                <td className="PigmentIndigo-text p-t-5 p-b-5 text-center">
                                                                                    <span className={item.reserveStrength > 0 ? "text-truncate p-t-5 fs-12 dis-b reserve_data" : "text-truncate p-t-5 fs-12 dis-b"} style={{ color : this.getColorForUtilizationPercentage(item.allotedHrs,item.totalBenchHrs) }}>
                                                                                        {returnFloatingValueforInventory(
                                                                                            //  (item.totalPlannedHrs + item.totalSupportHrs + item.totalBenchHrs) > 0 ? (((item.totalPlannedHrs + item.totalSupportHrs) / (item.totalPlannedHrs + item.totalSupportHrs + item.totalBenchHrs)) * 100) : 0
                                                                                            (item.allotedHrs + item.totalBenchHrs) > 0 ? (((item.allotedHrs) / (item.allotedHrs + item.totalBenchHrs)) * 100) : 0
                                                                                        )} %
                                                                                    </span>
                                                                                </td>
                                                                                {/* TOTAL COST */}
                                                                                <td className="PigmentIndigo-text p-t-5 p-b-5 text-right">
                                                                                    <span className="text-truncate p-t-5 fs-12 dis-b" title={item.totalCost}>
                                                                                    <span className=" p-t-5 fs-12 dis-b reserve_data text-primary">
                                                                                    $  {returnFloatingValueforInventory(
                                                                                        item.totalCost
                                                                                    )} <i className="fs-16 fa fa-edit" onClick={(e) => this.openModal(item, 'Cost', 'totalCost')} ></i>
                                                                                    </span>
                                                                                    </span>
                                                                                </td>
                                                                                {/* MS ALLOCATION */}
                                                                                <td className="PigmentIndigo-text p-t-5 p-b-5 text-right">
                                                                                <span className=" text-black p-t-5 fs-12 dis-b">
                                                                                    $  {
                                                                                    ((units && Object.values(units).reduce((sum, current) => {
                                                                                        return sum + current.totalMSValue
                                                                                    }, 0))).toLocaleString(undefined, {
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2
                                                                                    })
                                                                                    }
                                                                                </span>
                                                                                </td>
                                                                                {/* INVOICED VALUE */}
                                                                                <td className="PigmentIndigo-text p-t-5 p-b-5 text-right">
                                                                                    <span className={item.reserveStrength > 0 ? "text-truncate p-t-5 fs-12 dis-b reserve_data" : "text-truncate p-t-5 fs-12 dis-b"} title={item.totalMSAcheived}>
                                                                                        $ {(item.totalMSAcheived).toLocaleString(undefined, {
                                                                                            minimumFractionDigits: 2,
                                                                                            maximumFractionDigits: 2
                                                                                        })}
                                                                                    </span>
                                                                                </td>
                                                                                {/* MARGIN(gross margin) */}
                                                                                <td className="PigmentIndigo-text p-t-5 p-b-5 text-right">
                                                                                    {item.totalMSAcheived > 0 &&
                                                                                        <span className={item.reserveStrength > 0 ? "text-truncate p-t-5 fs-12 dis-b reserve_data" : "text-truncate p-t-5 fs-12 dis-b"} title={(((item.totalMSAcheived -
                                                                                            item.totalCost) /
                                                                                            item.totalMSAcheived) * 100)}>
                                                                                            {(((item.totalMSAcheived -
                                                                                                item.totalCost) /
                                                                                                item.totalMSAcheived) * 100).toFixed(2)} %
                                                                                        </span>
                                                                                    }
                                                                                    {item.totalMSAcheived == 0 &&
                                                                                        <span className="text-truncate p-t-5 fs-12 dis-b">0.00 %</span>
                                                                                    }
                                                                                </td>
                                                                                {/* Expected Gross Margin)*/}
                                                                                <td className="PigmentIndigo-text p-t-5 p-b-5 text-right">
                                                                                    <span className=" text-truncate p-t-5 fs-12 dis-b" title={item.totalCost * 1.25}>
                                                                                        <span className=" p-t-5 fs-12 dis-b reserve_data text-primary">
                                                                                            {item.expectedGrossMargin} % <i className="fs-16 fa fa-edit" onClick={(e) => this.openModal( item, 'Expected Gross Margin','expectedGrossMargin')} ></i>
                                                                                        </span>
                                                                                    </span>
                                                                                </td>
                                                                                {/* Yield (allocated yield*/}
                                                                                <td className="PigmentIndigo-text p-t-5 p-b-5 text-right">
                                                                                    {(item.allotedHrs) > 0 &&
                                                                                        <span className={item.reserveStrength > 0 ? "text-truncate p-t-5 fs-12 dis-b reserve_data" : "text-truncate p-t-5 fs-12 dis-b"} title={(
                                                                                            item.totalMSAcheived / ((item.allotedHrs) / 160))} >
                                                                                            $ {(
                                                                                                item.totalMSAcheived / ((item.allotedHrs) / 160)).toLocaleString(undefined, {
                                                                                                    minimumFractionDigits: 2,
                                                                                                    maximumFractionDigits: 2
                                                                                                })}
                                                                                        </span>
                                                                                    }
                                                                                    {(item.allotedHrs) == 0 &&
                                                                                        <span className="text-truncate p-t-5 fs-12 dis-b">$ 0.00 </span>
                                                                                    }
                                                                                </td>
                                                                                {/* Target Revenue*/}
                                                                                <td className="PigmentIndigo-text p-t-5 p-b-5 text-right">
                                                                                <span className=" text-truncate p-t-5 fs-12 dis-b" title={item.targetRevenue}>
                                                                                    <span className=" p-t-5 fs-12 dis-b reserve_data text-primary">
                                                                                    $ {item.targetRevenue} <i className="fs-16 fa fa-edit" onClick={(e) => this.openModal(item, 'Target Revenue', 'targetRevenue')} ></i>
                                                                                    </span>
                                                                                </span>
                                                                                </td>
                                                                                {/* Revenue Gap */}
                                                                                <td className="PigmentIndigo-text p-t-5 p-b-5 text-right">
                                                                                <span className=" text-truncate p-t-5 fs-12 dis-b">
                                                                                    $ {(item.revenueGap).toLocaleString(undefined, {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2
                                                                                    })}
                                                                                </span>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    ))}
                                                                    {/* start Sub total */}
                                                                    <tr className="bor-no busi_unit_bg sub_total-row">
                                                                        <td colSpan="3" className="PigmentIndigo-text p-t-5 p-b-5 text-center">
                                                                            <span className="" >
                                                                                SubTotal
                                                                            </span>
                                                                        </td>

                                                                        {/* TOTAL RESOURCE */}
                                                                        <td className="PigmentIndigo-text p-t-5 p-b-5 text-center">
                                                                            <span className="text-black p-t-5 fs-12 dis-b">
                                                                                {
                                                                                    ((units && Object.values(units).reduce((sum, current) => {
                                                                                        return sum + ((current.allotedHrs + current.totalBenchHrs) / 160)
                                                                                    }, 0))).toFixed(2)
                                                                                }
                                                                            </span>
                                                                        </td>
                                                                        {/* MS Allocated Developers */}
                                                                        <td className="PigmentIndigo-text p-t-5 p-b-5 text-center">
                                                                            <span className="text-black p-t-5 fs-12 dis-b">
                                                                                {
                                                                                    ((units && Object.values(units).reduce((sum, current) => {
                                                                                        return sum + ((current.allotedHrs) / 160)
                                                                                    }, 0))).toFixed(2)
                                                                                }
                                                                            </span>
                                                                        </td>
                                                                        {/* TOTAL BENCH RESOURCE(Reserved Strength) */}
                                                                        <td className="PigmentIndigo-text p-t-5 p-b-5 text-center">
                                                                            <span className="text-black p-t-5 fs-12 dis-b">
                                                                                {
                                                                                    ((units && Object.values(units).reduce((sum, current) => {
                                                                                        return sum + (current.totalBenchHrs / 160)
                                                                                    }, 0))).toFixed(2)
                                                                                }
                                                                            </span>
                                                                        </td>
                                                                        {/* TOTAL UTILIZATION */}
                                                                        <td className="PigmentIndigo-text p-t-5 p-b-5 text-center">
                                                                            <span className="text-black p-t-5 fs-12 dis-b">
                                                                                {

                                                                                    (units && Object.values(units).reduce((sum, current) => {
                                                                                        return sum +
                                                                                            current.allotedHrs + current.totalBenchHrs
                                                                                    }, 0)) > 0 ?
                                                                                        ((((units && Object.values(units).reduce((sum, current) => {
                                                                                            return sum +
                                                                                                current.allotedHrs
                                                                                        }, 0))) / ((units && Object.values(units).reduce((sum, current) => {
                                                                                            return sum +
                                                                                                current.allotedHrs + current.totalBenchHrs
                                                                                        }, 0)))) * 100).toLocaleString(undefined, {
                                                                                            minimumFractionDigits: 2,
                                                                                            maximumFractionDigits: 2
                                                                                        }) : 0.00
                                                                                } %
                                                                            </span>
                                                                        </td>
                                                                        {/* TOTAL COST  */}
                                                                        <td className="PigmentIndigo-text p-t-5 p-b-5 text-right">
                                                                            <span className=" text-black p-t-5 fs-12 dis-b  p-r-18">
                                                                                $ {
                                                                                    ((units && Object.values(units).reduce((sum, current) => {
                                                                                        return sum + (current.totalCost)
                                                                                    }, 0))).toLocaleString(undefined, {
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2
                                                                                    })
                                                                                }
                                                                            </span>
                                                                        </td>
                                                                        {/* MS ALLOCATION */}
                                                                        <td className="PigmentIndigo-text p-t-5 p-b-5 text-right">
                                                                        <span className=" text-black p-t-5 fs-12 dis-b">
                                                                            $  {
                                                                            ((units && Object.values(units).reduce((sum, current) => {
                                                                                return sum + current.totalMSValue
                                                                            }, 0))).toLocaleString(undefined, {
                                                                                minimumFractionDigits: 2,
                                                                                maximumFractionDigits: 2
                                                                            })
                                                                            }
                                                                        </span>
                                                                        </td>
                                                                        {/* INVOICED VALUE  */}
                                                                        <td className="PigmentIndigo-text p-t-5 p-b-5 text-right">
                                                                            <span className=" text-black p-t-5 fs-12 dis-b">
                                                                                $  {
                                                                                    ((units && Object.values(units).reduce((sum, current) => {
                                                                                        return sum + current.totalMSAcheived
                                                                                    }, 0))).toLocaleString(undefined, {
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2
                                                                                    })
                                                                                }
                                                                            </span>
                                                                        </td>
                                                                        {/* MARGIN (gross margin)*/}
                                                                        <td className="PigmentIndigo-text p-t-5 p-b-5 text-right">
                                                                            <span className="text-black p-t-5 fs-12 dis-b">
                                                                                {

                                                                                    (units && Object.values(units).reduce((sum, current) => {
                                                                                        return sum +
                                                                                            current.totalMSAcheived
                                                                                    }, 0)) > 0 ?
                                                                                        (((((units && Object.values(units).reduce((sum, current) => {
                                                                                            return sum +
                                                                                                current.totalMSAcheived
                                                                                        }, 0))) - ((units && Object.values(units).reduce((sum, current) => {
                                                                                            return sum +
                                                                                                current.totalCost
                                                                                        }, 0)))) / ((units && Object.values(units).reduce((sum, current) => {
                                                                                            return sum +
                                                                                                current.totalMSAcheived
                                                                                        }, 0)))) * 100).toFixed(2) : 0.00
                                                                                } %
                                                                            </span>
                                                                        </td>
                                                                        {/*   Expected Gross Margin */}
                                                                        <td className="PigmentIndigo-text p-t-5 p-b-5 text-right">
                                                                            <span className=" text-black p-t-5 fs-12 dis-b  p-r-18">
                                                                            {
                                                                            ((units && Object.values(units).reduce((sum, current) => {
                                                                                return sum + (current.expectedGrossMargin)
                                                                            }, 0)))
                                                                            } %
                                                                            </span>
                                                                        </td>
                                                                        {/* TOTAL YIELD(achieved yield) */}
                                                                        <td className="PigmentIndigo-text p-t-5 p-b-5 text-right">
                                                                            <span className="text-black p-t-5 fs-12 dis-b">
                                                                                $  {
                                                                                    (units && Object.values(units).reduce((sum, current) => {
                                                                                        return sum +
                                                                                            current.allotedHrs
                                                                                    }, 0)) > 0 ?
                                                                                        ((((units && Object.values(units).reduce((sum, current) => {
                                                                                            return sum +
                                                                                                current.totalMSAcheived
                                                                                        }, 0))) / ((units && Object.values(units).reduce((sum, current) => {
                                                                                            return sum +
                                                                                                current.allotedHrs
                                                                                        }, 0)) / 160))).toLocaleString(undefined, {
                                                                                            minimumFractionDigits: 2,
                                                                                            maximumFractionDigits: 2
                                                                                        }) : 0.00

                                                                                }
                                                                            </span>
                                                                        </td>
                                                                        {/* Target Revenue */}
                                                                        <td className="PigmentIndigo-text p-t-5 p-b-5 text-right">
                                                                        <span className=" text-black p-t-5 fs-12 dis-b p-r-18">
                                                                            $ {
                                                                            ((units && Object.values(units).reduce((sum, current) => {
                                                                                return sum + (current.targetRevenue)
                                                                            }, 0)))
                                                                            }
                                                                        </span>
                                                                        </td>
                                                                        {/* Revenue Gap */}
                                                                        <td className="PigmentIndigo-text p-t-5 p-b-5 text-right">
                                                                        <span className=" text-black p-t-5 fs-12 dis-b">
                                                                        $ {
                                                                            ((units && Object.values(units).reduce((sum, current) => {
                                                                                return sum + current.revenueGap
                                                                            }, 0))).toLocaleString(undefined, {
                                                                                minimumFractionDigits: 2,
                                                                                maximumFractionDigits: 2
                                                                            })
                                                                            }
                                                                        </span>
                                                                        </td>
                                                                    </tr>
                                                                    {/* end Sub total */}
                                                                </>
                                                            ))}
                                                        </>
                                                    </tbody>
                                                </>
                                                {/* start Grand total */}
                                                {
                                                    <tr className="bor-no busi_unit_bg grand_total-row">
                                                        <td colspan="3" className="PigmentIndigo-text p-t-5 p-b-5 text-center" >
                                                            <span className="text-truncate" >
                                                                Grand Total
                                                            </span>
                                                        </td>

                                                        {/* TOTAL RESOURCE */}
                                                        <td className="PigmentIndigo-text p-t-5 p-b-5 text-center">
                                                            <span className="text-truncate-grand p-t-5 fs-12 dis-b" title={data.data.grandtotal.grandTotalEmployee}>
                                                                {(data.data.grandtotal.grandTotalEmployee).toFixed(2)}
                                                            </span>
                                                        </td>
                                                        {/* MS ALLOCATED DEVELOPERS */}
                                                        <td className="PigmentIndigo-text p-t-5 p-b-5 text-center">
                                                            <span className="text-truncate-grand p-t-5 fs-12 dis-b" title={data.data.grandtotal.grandTotalAllocatedEmployee}>
                                                                {(data.data.grandtotal.grandTotalAllocatedEmployee).toFixed(2)}
                                                            </span>
                                                        </td>
                                                        {/* BENCH RESOURCE(Reserved Developers) */}
                                                        <td className="PigmentIndigo-text p-t-5 p-b-5 text-center">
                                                            <span className="text-truncate-grand p-t-5 fs-12 dis-b" title={data.data.grandtotal.grandBenchResource}>
                                                                {(data.data.grandtotal.grandBenchResource).toFixed(2)}
                                                            </span>
                                                        </td>
                                                        {/* TOTAL UTILIIZATION */}
                                                        <td className="PigmentIndigo-text p-t-5 p-b-5 text-center">
                                                            <span className="text-truncate-grand p-t-5 fs-12 dis-b" title={((data.data.grandtotal.grandMsHours / data.data.grandtotal.grandTotalAvailableHrs) * 100)}>
                                                                {returnFloatingValueforInventory(((data.data.grandtotal.grandMsHours / data.data.grandtotal.grandTotalAvailableHrs) * 100).toLocaleString(undefined, {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                }))} %
                                                            </span>
                                                        </td>
                                                        {/* TOTAL COST */}
                                                        <td className="PigmentIndigo-text p-t-5 p-b-5 text-right p-r-18">
                                                            <span className="text-truncate-grand p-t-5 fs-12 dis-b" title={data.data.grandtotal.grandTotalCost}>
                                                                $ {returnFloatingValueforInventory(data.data.grandtotal.grandTotalCost)}
                                                            </span>
                                                        </td>
                                                        {/* MS ALLOCATION */}
                                                        <td className="PigmentIndigo-text p-t-5 p-b-5 text-right">
                                                        <span className="text-truncate-grand p-t-5 fs-12 dis-b" title={data.data.grandtotal.grandMsAllocation}>
                                                            $ {returnFloatingValueforInventory((data.data.grandtotal.grandMsAllocation).toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                            }))}
                                                        </span>
                                                        </td>
                                                        {/* INVOICED VALUE  */}
                                                        <td className="PigmentIndigo-text p-t-5 p-b-5 text-right">
                                                            <span className="text-truncate-grand p-t-5 fs-12 dis-b" title={data.data.grandtotal.grandMsActual}>
                                                                $ {returnFloatingValueforInventory((data.data.grandtotal.grandMsActual).toLocaleString(undefined, {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                }))}
                                                            </span>
                                                        </td>
                                                        {/* MARGIN */}
                                                        <td className="PigmentIndigo-text p-t-5 p-b-5 text-right">
                                                            {data.data.grandtotal.grandMsActual > 0 &&
                                                                <span className="text-truncate-grand p-t-5 fs-12 dis-b" title={(((data.data.grandtotal.grandMsActual -
                                                                    data.data.grandtotal.grandTotalCost) /
                                                                    data.data.grandtotal.grandMsActual) * 100)}>
                                                                    {(((data.data.grandtotal.grandMsActual -
                                                                        data.data.grandtotal.grandTotalCost) /
                                                                        data.data.grandtotal.grandMsActual) * 100).toFixed(2)} %
                                                                </span>
                                                            }
                                                            {data.data.grandtotal.grandMsActual == 0 &&
                                                                <span className="text-truncate-grand p-t-5 fs-12 dis-b">0.00 %</span>
                                                            }
                                                        </td>
                                                        {/* Expected Gross Margin) */}
                                                        <td className="PigmentIndigo-text p-t-5 p-b-5 text-right p-r-18">
                                                            <span className="text-truncate-grand p-t-5 fs-12 dis-b" title={data.data.grandtotal.grandTargetMargin}>
                                                                {returnFloatingValueforInventory(data.data.grandtotal.grandExpectedGrossMargin)} %
                                                            </span>
                                                        </td>
                                                        {/* TOTAL YIELD(allocated yield) */}
                                                        <td className="PigmentIndigo-text p-t-5 p-b-5 text-right">
                                                            <span className="text-truncate-grand p-t-5 fs-12 dis-b" title={(data.data.grandtotal.grandMsActual / (data.data.grandtotal.grandMsHours / 160))}>
                                                                $ {returnFloatingValueforInventory(data.data.grandtotal.grandMsHours > 0 ? data.data.grandtotal.grandMsActual / (data.data.grandtotal.grandMsHours / 160).toLocaleString(undefined, {
                                                                    minimumFractionDigits: 2,
                                                                    maximumFractionDigits: 2
                                                                }) : 0.00)
                                                                }
                                                            </span>
                                                        </td>
                                                        {/* Target Revenue */}
                                                        <td className="PigmentIndigo-text p-t-5 p-b-5 text-right p-r-18">
                                                        <span className="text-truncate-grand p-t-5 fs-12 dis-b" title={data.data.grandtotal.grandTargetRevenue}>
                                                            $ {returnFloatingValueforInventory(data.data.grandtotal.grandTargetRevenue).toLocaleString(undefined, {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            })}
                                                        </span>
                                                        </td>
                                                        {/* Revenue Gap */}
                                                        <td className="PigmentIndigo-text p-t-5 p-b-5 text-right">
                                                        <span className="text-truncate-grand p-t-5 fs-12 dis-b" title={data.data.grandtotal.grandRevenueGap}>
                                                            $ {returnFloatingValueforInventory(data.data.grandtotal.grandRevenueGap).toLocaleString(undefined, {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2
                                                            })}
                                                        </span>
                                                        </td>
                                                    </tr>
                                                }
                                                {/* end Grand total */}
                                            </table>
                                        </div>
                                        <br />
                                    </div>
                                )}
                                {data.data.service && Object.keys(data.data.service).length == 0 && (
                                    <div>
                                        <div className="r-no-data text-center">
                                            No data available
                                        </div>
                                    </div>
                                )}
                            </>

                        ))}
                    {!this.props.loading && (!this.props.serviceName.length ||
                        !this.props.utilizationReport.length) && (
                            <div>
                                <div className="r-no-data text-center">
                                    No data available
                                </div>
                            </div>
                        )}
                </div>
                { this.state.isOpen && 
                    <PopUp
                    closeModal={(e) => this.closeModal(e)}
                    isOpen={this.state.isOpen}
                    handleSubmit={(e) => this.handleSubmit(e)}
                    item={this.state.item} 
                    title={this.state.title}
                    name={this.state.name}
                    /> 
                }
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        //checkSumUtilization :state.reportDetails.checkSumUtilization
    };
};

const mapDispatchToProps = {
    updateUtilizationValues
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActualView);
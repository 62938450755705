import Cookies from "js-cookie";

//Get the URL
export function getBackEndConstant() {
  let backendObject = {};
  let url = Cookies.get("url_code") && Cookies.get("url_code") != undefined && Cookies.get("url_code") != 'undefined' ?
   atob(Cookies.get("url_code")) : window.location.hostname;
  backendObject.utilisationBu = [8,9,10,11];
  if (url === "localhost") {
    //Local Host
    backendObject.backend_url = "http://127.0.0.1:8000/api/";
    backendObject.frontend_url = "http://localhost:22400/#!/";
    backendObject.excel_url = "http://localhost:8000/";
    backendObject.imageUrl = "http://localhost:22400/";
  } else if (url === "staging") {
    //staging
    backendObject.backend_url =
      "https://reflections-staging.optisolbusiness.com/api/";
    backendObject.frontend_url =
      "https://reflectionsstaging.optisolbusiness.com/#!/";
    backendObject.excel_url =
      "https://reflections-staging.optisolbusiness.com/";
    backendObject.imageUrl = "https://reflectionsstaging.optisolbusiness.com/";
    backendObject.utilisationBu = [5,8,9,10,11,12,13,14,15];
  } else if (url === "uat") {
    //UAT
    backendObject.backend_url =
      "https://reflectionsservicestg.optisolbusiness.com/api/";
    backendObject.frontend_url =
      "https://reflectionsstg.optisolbusiness.com/#!/";
    backendObject.excel_url =
      "https://reflectionsservicestg.optisolbusiness.com/";
    backendObject.imageUrl = "https://reflectionsstg.optisolbusiness.com/";
    backendObject.utilisationBu = [5,8,9,10,11,12,13,14,15];
  } else if (url === "live") {
    //Live
    backendObject.backend_url =
      "https://reflectionsservice.optisolbusiness.com/api/";
    backendObject.frontend_url = "https://reflections.optisolbusiness.com//#!/";
    backendObject.excel_url = "https://reflectionsservice.optisolbusiness.com/";
    backendObject.imageUrl = "https://reflections.optisolbusiness.com/";
    backendObject.utilisationBu = [5,8,9,10,11,12,13,14,15];
  } else {
    // do nothing
  }
  backendObject.predictionMsValue = 2400;
  backendObject.predictionMsHrs = 160;
  backendObject.baRoleId = 3;
  backendObject.financeRoleId = 12;
  backendObject.cloudService = 57;
  backendObject.filterDate = '2022-04-01';
  backendObject.renamedPracticeArray = [
    {'practice':'Mean', 'renamedPractice':'ME'},{'practice':'Machine Vantage', 'renamedPractice':'MV'},
    {'practice':'Java', 'renamedPractice':'JV'},{'practice':'Python', 'renamedPractice':'PY'},
    {'practice':'Engineering Lead', 'renamedPractice':'EL'},{'practice':'Finance', 'renamedPractice':'FIN'},
    {'practice':'Engineering Manager', 'renamedPractice':'EM'},{'practice':'Machine Learning', 'renamedPractice':'ML'},
    {'practice':'others', 'renamedPractice':'O'},{'practice':'Business Automation', 'renamedPractice':'BA'},
    {'practice':'Computer Vision', 'renamedPractice':'CV'},{'practice':'NLP & Text Analytics', 'renamedPractice':'NLP'},
    {'practice':'Data Analytics', 'renamedPractice':'DA'},{'practice':'Data Acquisition', 'renamedPractice':'DAC'},
    {'practice':'Power Tools', 'renamedPractice':'PT'},{'practice':'Data Engineering & ETL', 'renamedPractice':'DE'},
    {'practice':'Testing Manager', 'renamedPractice':'TM'},{'practice':'Testing Lead', 'renamedPractice':'TL'},
    {'practice':'DevOps', 'renamedPractice':'DOP'},{'practice':'Testing Lead', 'renamedPractice':'TL'},
    {'practice':'Testing', 'renamedPractice':'TE'},{'practice':'Cloud ERP', 'renamedPractice':'CE'},
    {'practice':'Android', 'renamedPractice':'AD'},{'practice':'Fullstack .Net', 'renamedPractice':'F.N'},
    {'practice':'Digital Marketing', 'renamedPractice':'DM'},{'practice':'Cross Platform', 'renamedPractice':'CP'},
    {'practice':'Business Development Representatives', 'renamedPractice':'BDR'},{'practice':'.Net', 'renamedPractice':'.N'},
    {'practice':'Sales Development Representatives', 'renamedPractice':'SDR'},{'practice':'PLSQL', 'renamedPractice':'PL'},
    {'practice':'Marketing Enablement Services', 'renamedPractice':'MES'},{'practice':'', 'renamedPractice':''},
    {'practice':'Talent Acquisition', 'renamedPractice':'TA'},{'practice':'Statutory & MIS', 'renamedPractice':'S&M'},
    {'practice':'Client Manager', 'renamedPractice':'CM'},{'practice':'Functional Testing', 'renamedPractice':'FT'},
    {'practice':'Data Management', 'renamedPractice':'DM'},{'practice':'', 'renamedPractice':''},
    {'practice':'Core Automation Testing', 'renamedPractice':'CAT'},{'practice':'Speciality Services', 'renamedPractice':'SPS'},
  ];
  backendObject.rangeFilterValue = [
    {'id':1, 'label':'All','value':'All'},
    {'id':2,'label':'Above 250K','value':'Above 250K'},
    {'id':3, 'label':'Below 50K','value':'Below 50K'},
    {'id':4, 'label':'Between 100K and 150K','value':'Between 100K and 150K'},
    {'id':5, 'label':'Between 50K and 100K','value':'Between 50K and 100K'},
    {'id':6, 'label':'Between 150K and 200K','value':'Between 150K and 200K'}
  ];
  backendObject.runRateFilter = [
    { 'id': '0', 'value': 'All', 'label': 'All' }, 
    { 'id': '1', 'value': 'Below 5K', 'label': 'Below 5K' }, 
    { 'id': '2', 'value': 'Between 5k and 10K', 'label': 'Between 5k and 10K' }, 
    { 'id': '3', 'value': 'Between 10k and 20K', 'label': 'Between 10k and 20K' }, 
    { 'id': '4', 'value': 'Between 20K and 50K', 'label': 'Between 20K and 50K' }, 
    { 'id': '5', 'value': 'Above 50K', 'label': 'Above 50K' }
  ];
  backendObject.potentialStatusFilter = [
    { 'id': 1, 'value': '0', 'label': 'All' },
    { 'id': 2, 'value': '1', 'label': 'In Progress' },
    { 'id': 3, 'value': '2', 'label': 'Awarded' }
  ];
  
   //Pms
   backendObject.questionType = [
    { 'id': 1, 'value': '0', 'label': 'All' },
    { 'id': 2, 'value': '1', 'label': 'Blanks' },
    { 'id': 3, 'value': '2', 'label': 'Rating' },
    { 'id': 4, 'value': '3', 'label': 'MCQ' }
  ];
  backendObject.statusClasses = {
    "completed": "success",
    "not yet started": "warning",
    "incomplete": "danger",
    "pending": "danger"
  }
  backendObject.employLevels = [
    {'id': 0, 'value': 'all', 'label': 'All'},
    {'id': 1, 'value': 'EL1', 'label': 'L1'},
    {'id': 2, 'value': 'EL2', 'label': 'L2'},
    {'id': 3, 'value': 'EL3', 'label': 'L3'},
    {'id': 4, 'value': 'EL4', 'label': 'L4'},
  ]
  backendObject.performanceMonthValue = {
    "04": [10,11,0,1,2,3],
    "10": [4,5,6,7,8,9]
  }

  backendObject.field = [
    { 'id': 1, 'value': '1', 'label': 'Mandatory' },
    { 'id': 2, 'value': '2', 'label': 'Option' },
  ];
 
  return backendObject;
}

//constant values
export const STAGING = "staging";
export const LIVE = "live";
export const UAT = "uat";
export const API_LINK = getBackEndConstant().backend_url;
export const FRONT_LINK = getBackEndConstant().frontend_url;
export const EXCEL_LINK = getBackEndConstant().excel_url;
export const SUB_ADMIN_ROLE = 26;
export const CSM_HEAD_ROLE = 27;
export const FINANCE_TEAM_ROLE = 12;
export const DOLLAR_RATE = 72;
export const DELIVERY_MANAGEMENT = 'Delivery Management';
export const QUALITY_MANAGEMENT = 10;
export const FRONT_END_IMAGE="http://localhost:22400/";
export const HR=29;
export const allowedRoleIds = [1, 10, 8, 29]; //admin,pm,director,hr
export const allowId = [1, 8, 29];
export const notificationRoles = [1, 3, 4, 10, 12]; // admin, TL, manager, director,squad lead
export const reportURLS = ['/pw_report','/ms_report','/collections_report','/collections_potential_report','/inventory_report','/inventory_allocation_report','/inventory_milestone_report','/utilization_report','/csm_report','/pmo_ms_report','/acv_report','/ms-allocation-report'];
export const PMSURLS = ['/pms_dashboard', '/pms_settings', '/pms_review', '/pms_manage'];
export const ckeditorConfig = {
  toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ]
};
import React, { Component, useEffect, useState } from 'react';
import { Urls } from '../../Config/Config';
import './Report.css';
import Select from 'react-select';
import { Grid, Row, Col, Button, Form, Spinner } from 'react-bootstrap';
import moment from 'moment';
import Swal from "sweetalert2";
import Cookies from 'js-cookie';
import PageNotFound from './PageNotFound';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import exchange from '../../exchange.png';
import "antd/dist/antd.css";
import { getUserService, getReportFilterService, practiceRename } from "../../Utilities/Helper";
import { Dropdown } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { setAddClass } from "../../Action";
import { connect } from "react-redux";
var _ = require('lodash');

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    showCloseButton: true,
    customClass: {
        popup: 'colored-toast',
        icon: 'white-toast-icon'
    },
    timer: 3000,
    onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});
class MileStoneReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            queryParams: new URLSearchParams(window.location.search),
            token: "",
            reportF: 0,
            redirectUrl: '',
            redirect: 0,
            user_types: Urls.user_types,
            reports_lists: Urls.reports_lists,
            reports_lists1: Urls.reports_lists1,
            current_report: Urls.reports_lists[1],
            report: {},
            projectPractice: [],
            project_practice: [],
            isAllPractices: false,
            getUserscount: '',
            practiceleadName: '',
            report_practice: [],
            gridListBackup: [],
            gridList: [],
            planned_hrs: 0,
            support_hrs: 0,
            unallocated_hrs: 0,
            effective_yield: 0,
            expected_yield: 0,
            gap_in_allocation: 0,
            deficit: 0,
            userTypeId: 0,
            totalResAvHrCal: 0,
            msAlloc: 0,
            supphrs: 0,
            totalResourceAvailHrsFrontendCalcution: 0,
            msAllocatedHrs: 0,
            supportHrs: 0,
            benchHrs: 0,
            userTypes: Urls.user_types,
            isGanttView: false,
            updateLoader: false,
            current_Gant_popover: {},
            head_id: '',
            // for inventory report
            support_practice: [6, 12, 13, 21],
            inventory_isDetailedView: true,
            searchByPracticeHead: '',
            searchBy: '',
            // for TL and BA    
            techLeadList: [],
            businessAnalystList: [],
            techLeadId: '',
            businessAnalystId: '',
            searchByTechLead: '',
            searchByBusinessAnalyst: '',
            isChecked: false,
            // for milestone report    
            myPracticeMileStone: 0,
            allPracticeMileStone: 0,
            mileStoneValueReport: {},
            filteredMilestoneData: [],
            filteredMilestoneDatas: [],
            mileStoneReportPractice: 1,
            filterByPractice: '',
            filterMileStoneValue: '',
            milestone_achieved_dropdown_value: Urls.milestone_achieved_dropdown_value,
            invoice_dropdown_value: Urls.milestone_achieved_dropdown_value,
            // for collection-potential report    
            currentFilter: { 'id': 0, 'value': 'All' },
            filterNotSure: '',
            true_data: [],
            checked_data: {},
            sumoffilterMileStoneValue: 0,
            SumofMileStoneValue: 0,
            collection_potential_invoicestatus: Urls.invoice_status,
            return_to_pwreport: 0,
            resource_types: Urls.resource_types,
            resource_types_new: Urls.resource_types_new,
            resourceFilter: { 'id': 0, 'value': 'All' },
            filter_start_date: new Date(),
            // filter_start_date : '2022-01-01',
            filter_end_date: new Date(),
            sname: [],
            sname1: [],
            inventory_isDetailedView: false,
            report_practice: [],
            report_service: [],
            projectPractice: [],
            practiceHead: [],
            totalMileStoneValue: 0,
            totalKickoffValue: 0,
            totalCarryValue: 0,
            filterMileStoneValue: 0,
            filteredMilestoneData: [],
            milestoneReport: [],
            mileStoneValueReport: {},
            submitted: false,
            practiceReport: [],
            overallMileStoneValue: 0,
            sumOfNotSureValue: 0,
            notUpdatedValue: 0,
            totalNewValue: 0,
            totalRaisedValue: 0,
            totalCollectedValue: 0,
            totalPendingValue: 0,
            mileStoneReportMonth: '',
            mileStoneReportYear: '',
            myPracticeMileStone: 0,
            allPracticeMileStone: 0,
            filter: [],
            filterByPractice: [],
            filterMileStoneValue: 0,
            filterNotSure: 0,
            filterNotUpdated: 0,
            filterNewValue: 0,
            filterRaisedValue: 0,
            filterCollectedValue: 0,
            filterPendingValue: 0,
            selectedInvoiceStatus: 'All',
            selectedInvoice: 'All',
            loginUserData: {},
            //token : JSON.parse(Cookies.get('token')),
            loading: false,
            selectService: [],
            start_month: moment()
                .utcOffset('+05:30')
                .format('YYYY-MM'),
            inv_class: "text-start",
            filter_class: "",
            prac_filter_class: "",
            reportList: [],
            checkedList: [],
            selectService: [],
            serviceName: [],
            selectBuFilter: [],
            view_all: 1,
            buName: [],
            bu_id: '',
            bu_qes: Urls.bu_qes,
            practice_qes: Urls.practice_qes,
            service_qes: Urls.service_qes,
            practiceListArray: [],
            resultValue: [],
            usersService: [],
            oldFilterData: []
        }
    }

    reset_page = () => {
        this.state.report.service = [];
        this.state.report.practice = [];
        this.state.selectService = [];
        this.generateMileStoneValueReport();
    }

    componentDidMount() {
        this.state.reports_lists.forEach(value => {
            var opt = {};
            opt.label = value.value;
            opt.id = value.id;
            opt.value = value.value;
            this.state.reportList.push(opt);
        })
        this.state.current_report = this.state.reportList[1];
        this.state.token = (this.state.queryParams.get('token') == null) ? Cookies.get('auth_data') : this.state.queryParams.get('token');
        this.state.url = (this.state.queryParams.get('url') == null) ? Cookies.get('url_code') : this.state.queryParams.get('url');
        if (atob(this.state.url) === 'localhost') {
            this.state.apiLink = 'http://127.0.0.1:8000/api/'
            this.state.angularUrl = 'http://localhost:22400/#!/'
            this.state.excelUrl = "http://localhost:8000/"
        }
        else if (atob(this.state.url) === 'staging') {
            this.state.apiLink = 'https://reflections-staging.optisolbusiness.com/api/'
            this.state.angularUrl = 'https://reflectionsstaging.optisolbusiness.com/#!/'
            this.state.excelUrl = "https://reflections-staging.optisolbusiness.com/"
        }
        else if (atob(this.state.url) === 'uat') {
            // this.state.reportList = [];
            this.state.apiLink = 'https://reflectionsservicestg.optisolbusiness.com/api/'
            this.state.angularUrl = 'https://reflectionsstg.optisolbusiness.com/#!/'
            this.state.excelUrl = "https://reflectionsservicestg.optisolbusiness.com/"
            // this.state.reports_lists1.forEach(value => {
            //     var opt = {};
            //     opt.label = value.value;
            //     opt.id = value.id;
            //     opt.value = value.value;
            //     this.state.reportList.push(opt);
            // })
        }
        else if (atob(this.state.url) === 'live') {
            // this.state.reportList = [];
            this.state.apiLink = 'https://reflectionsservice.optisolbusiness.com/api/'
            this.state.angularUrl = 'https://reflections.optisolbusiness.com//#!/'
            this.state.excelUrl = "https://reflectionsservice.optisolbusiness.com/"
            // this.state.reports_lists1.forEach(value => {
            //     var opt = {};
            //     opt.label = value.value;
            //     opt.id = value.id;
            //     opt.value = value.value;
            //     this.state.reportList.push(opt);
            // })
        }
        else {
            // do nothing
        }

        this.setState({
            token: this.state.token
        })
        this.getUser();
        this.getPracticeService();
    }

    getUser = () => {
        var link = this.state.apiLink + 'get_login_user';

        if (this.state.token == null) {
            this.state.token = Cookies.get('auth_data');
        }
        if (this.state.token == 'undefined') {
            this.state.redirect = 1;
        }
        else {
            return fetch(link, {
                method: "GET",

                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": "Bearer " + this.state.token
                }
            }).then(res => res.json()).then(res => {
                this.state.loginUserData = res.result;
            })
        }

    }

    setStartDateForFilter = (e) => {
        var today = new Date(e);
        var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        this.state.filter_start_date = today;
        this.setState({
            filter_start_date: this.state.filter_start_date,
            filter_end_date: lastDayOfMonth
        })
    }

    setEndDateForFilter = (end_date) => {
        this.setState({
            filter_end_date: end_date
        })

    }

    selectFilter = (e, id) => {
        //this.state.currentFilter = e.target.value;
        this.state.selectedInvoiceStatus = e.target.value
        this.setState({
            selectedInvoiceStatus: this.state.selectedInvoiceStatus
        })
        this.generateMileStoneValueReport(true)
    }


    getPracticeService = (e, isAllPractice) => {
        this.state.isAllPractices = isAllPractice;
        console.log(this.state.isAllPractices)
        this.setState({ loading: true });
        this.state.filterService = [];
        var date = new Date();
        var date = new Date(date.getFullYear(), date.getMonth(), 2);
        var start_date = (this.state.filter_start_date) ? this.state.filter_start_date : date;
        var end_date = (this.state.filter_end_date) ? this.state.filter_end_date : date;
        // this.state.report.service = [];
        var isAllPractices = isAllPractice;
        var link = this.state.apiLink + 'get_report_filters_new';
        this.state.sname = [];
        this.state.allService = [];
        this.state.serviceName = [];
        if (this.state.token == null) {
            this.state.token = Cookies.get('auth_data');
        }
        if (this.state.token == 'undefined') {
            this.state.redirect = 1;
        }
        else {
            return fetch(link, {
                method: "POST",
                body: JSON.stringify({
                    start_date,
                    end_date,
                    filter: isAllPractice
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": "Bearer " + this.state.token
                }
            }).then(res => res.json()).then(res => {
                this.setState({
                    loading: false,
                    // sname : res.result.services,
                    // sname1 : res.result.services,
                    // allService : res.result.allservices,
                })

                console.log(this.state.head_id)
                var c = 0;
                res.result.directors.forEach(value => {
                    if (this.state.bu_id == value.bu_id) {
                        c = 1;
                    }
                })
                this.state.bu_id = (c == 1) ? this.state.bu_id : '';
                this.state.selectBuFilter = (c == 1) ? this.state.selectBuFilter : [];
                if (this.state.bu_id != '' && this.state.bu_id != undefined && this.state.bu_id != 0) {
                    res.result.services.forEach(service => {
                        if (this.state.bu_id == service.bu) {
                            this.state.sname.push(service);
                        }
                    });
                    res.result.allservices.forEach(service => {
                        if (this.state.bu_id == service.bu) {
                            this.state.allService.push(service);
                        }
                    });
                }
                else {
                    this.state.sname = res.result.services;
                    this.state.allService = res.result.allservices;
                }
                this.state.sname1 = res.result.services;
                this.state.usersService = res.result.usersService;
                this.state.report.project_practice = [];
                console.log("this.state.report.project_practice", this.state.report.project_practice)
                this.state.sname.forEach(value => {
                    var opt = {};
                    opt.label = value.value;
                    opt.id = value.id;
                    opt.value = value.value;
                    opt.head = value.head;
                    opt.bu = value.bu;
                    this.state.serviceName.push(opt);
                })
                if (isAllPractice) {
                    this.state.selectService = [];
                    this.state.report.service = [];
                    this.state.report.practice = [];
                    this.state.projectPractice = [];
                    this.state.selectBuFilter = [];
                    this.state.head_id = 'All';
                    this.state.bu_id = '';
                    this.state.selectBU = [];
                    res.result.practices.forEach(pvalue => {
                        this.state.projectPractice.push(pvalue)
                    })

                }
                else {
                    var count = 0;
                    if (this.state.report.service) {
                        this.state.report.service.forEach(value => {
                            this.state.sname.forEach(svalue => {
                                if (svalue.id == value.id) {
                                    count = 1;
                                }
                            })
                        })
                        if (count == 0) {
                            this.state.report.service = [];
                            let service = getUserService(this.state.serviceName, this.state.loginUserData, this.state.filter_start_date);
                            this.state.report.service.push(service);
                            this.state.selectService = service;
                        }
                    }
                    else {
                        this.state.report.service = [];
                        let service = getUserService(this.state.serviceName, this.state.loginUserData, this.state.filter_start_date);
                        this.state.report.service.push(service);
                        this.state.selectService = service;
                    }
                }


                this.setState({
                    report: {
                        service: this.state.report.service,
                        practice: this.state.report.practice,
                        practiceReport: this.state.practiceReport
                    },
                    //selectService : this.state.selectService
                })


                var service1 = [];
                service1.push(this.state.sname[0]);
                this.state.qes_list = res.result.qes_list;
                var head = {};

                this.setState({
                    report_all_practices: res.result.practices,
                    practiceHead: this.state.practiceHead
                })
                // for practicehead      
                res.result.directors.forEach(id => {
                    this.state.practiceHead.push(id)
                });

                var buName = [];
                res.result.directors.forEach(value => {
                    var opt = {};
                    opt.label = value.bu;
                    opt.value = value.bu;
                    opt.bu_id = value.bu_id;
                    opt.id = value.id;
                    opt.bu = value.bu;
                    opt.name = value.name;
                    buName.push(opt);
                })
                this.setState({
                    practiceHead: this.state.practiceHead,
                    buName: buName
                })
                // // this.state.practiceHeadId = this.state.practiceHead[1]['id'];
                if (this.state.loginUserData.role_id == 8) {
                    this.state.searchByPracticeHead = this.state.loginUserData.role_id;
                } else {
                    this.state.searchByPracticeHead = this.state.searchByPracticeHead;
                }

                if (!this.state.isAllPractices) {
                    this.filterService('');
                }
                this.changeViewPracite(this.state.isAllPractices);
            })
        }

    }

    //filter practice based on service and head
    filterService = (e) => {
        this.state.searchBy = '';
        this.state.SumofMileStoneValue = 0;
        if (e) {
            let selectedOption = (e);
            this.state.selectService = selectedOption;
            this.state.report.service = selectedOption;
        }
        this.setState({
            report: {
                service: this.state.report.service
            }
        })
        if (this.state.reports_lists[1].value === this.state.current_report.value) {
            this.state.report.practice = [];
            this.state.projectPractice = [];
            if (this.state.isAllPractices) {
                this.state.projectPractice = this.state.report_all_practices
            } else {
                var service = (this.state.report.service);
                this.state.projectPractice = [];
                if (service.length == 0) {
                    this.state.projectPractice = [];
                }
                else {
                    var proj_practice = [];
                    service.forEach(svalue => {
                        this.state.report_all_practices.forEach(value => {
                            if (svalue.id == value.serviceId) {
                                this.state.projectPractice.push(value);
                            }
                        })
                    })
                }

            }
            //   // for sending all practice while reset
            if (this.state.projectPractice.length > 0) {
                this.state.projectPractice.forEach(value => {
                    this.state.report.practice.push(value.id);
                });
            }
            this.setState({
                report: {
                    practice: [this.state.report.practice],
                    service: this.state.report.service
                }
            })
            this.generateMileStoneValueReport(true);
        }

    }


    filterPractice = (e, practice, list, isDefaultChecked = false) => {
        if (isDefaultChecked) {
            //load data of checked items
            this.setState({
                filteredMilestoneData: [],
                practiceListArray: [],
            })

            let me = this;
            this.state.practiceListArray.map(function (practiceList) {
                let getPraticeData = _.filter(me.state.milestoneReport, function (data) {
                    return data.practiceId == practiceList;
                });
                me.state.filteredMilestoneData = [...me.state.filteredMilestoneData, ...getPraticeData];

            })

            this.state.checkedList.forEach(value => {
                this.state.practiceReport.forEach(data => {
                    if (value == data.practiceName) {
                        data.isChecked = true;
                        data.prac_class = "col-sm-1 active";
                        //  console.log("data filter if",data)
                    } else {
                        // data.isChecked = false;
                        data.prac_class = "col-sm-1";
                    }
                })
            })
        } else {
            //normal flow

            if (practice) {
                practice.isChecked = !practice.isChecked;
            }

            this.state.filteredMilestoneData = [];
            var filteredData = [];
            var array = [];
            var resultArray = [];
            this.state.generatefilteredMsData = [];


            if (practice) {
                this.state.filter_class = '';
                if (e.target.checked)      //if checked (true), then add this id into checkedList
                {
                    resultArray = this.state.checkedList.filter(CheckedId => CheckedId !== practice.practiceId
                    )
                    resultArray.push(practice.practiceName);
                    this.state.resultValue.push(practice.practiceId);
                }
                else   //if not checked (false), then remove this id from checkedList
                {
                    resultArray = this.state.checkedList.filter(CheckedId =>
                        CheckedId !== practice.practiceId
                    )
                    console.log("this.state.checkedList", this.state.checkedList)
                    let checkListIndex = _.findIndex(this.state.checkedList, function (data) {
                        return data == practice.practiceName;
                    });
                    let slicedCheckList = resultArray.slice(0, checkListIndex);
                    resultArray = slicedCheckList;
                    let practiceListIndex = _.findIndex(this.state.resultValue, function (dataPractice) {
                        return dataPractice == practice.practiceId;
                    });
                    let slicedPracticeList = resultArray.slice(0, practiceListIndex);
                    this.state.resultValue = slicedPracticeList;
                }

                this.state.checkedList = resultArray;
                this.state.practiceListArray = this.state.resultValue;
                this.setState({
                    checkedList: this.state.checkedList
                })
                var array = [];
                if (practice.isChecked == true) {
                    this.state.sumoffilterMileStoneValue += practice.mileStoneValue;
                    this.state.filter.push(practice.practiceId);
                }
                else {
                    this.state.sumoffilterMileStoneValue -= practice.mileStoneValue;
                    this.state.filter.forEach(value => {
                        if (value != practice.practiceId) {
                            array.push(value);
                        }
                    })
                    // this.state.filter.pop(practice.practiceId);
                    this.state.filter = array;
                }
                if (this.state.filter.length == 0 && !practice) {
                    this.state.filterByPractice = '';
                    this.state.filteredMilestoneData = this.state.milestoneReport;
                }
                else {
                    if (this.state.filter.length == 0) {
                        this.state.filteredMilestoneData = this.state.milestoneReport;
                    } else {
                        this.state.filter.forEach(result => {
                            this.setState({
                                filterByPractice: result,
                                filterMileStoneValue: practice.mileStoneValue,
                                filterNotSure: practice.sumOfNotSureValue,
                                filterNotUpdated: practice.notUpdatedValue,
                                filterNewValue: practice.totalNewValue,
                                filterRaisedValue: practice.totalRaisedValue,
                                filterCollectedValue: practice.totalCollectedValue,
                                filterPendingValue: practice.totalPendingValue
                            })


                            //Generate 
                            let getPraticeData = _.filter(this.state.milestoneReport, function (data) { return data.practiceId == result; });

                            this.state.filteredMilestoneData = [...this.state.filteredMilestoneData, ...getPraticeData];


                            this.state.checkedList.forEach(value => {
                                this.state.practiceReport.forEach(data => {
                                    if (value == data.practiceName) {
                                        data.isChecked = true;
                                        data.prac_class = "col-sm-1 active";
                                    } else {
                                        data.prac_class = "col-sm-1";
                                        // console.log("datagene else",data)
                                    }
                                })
                            })
                        })
                    }
                }
                if (this.state.filter.length == 0) {
                    this.state.filter_class = 'active';
                }
            }
            else {
                this.state.filter_class = 'active';
                this.state.filter = [];
                this.state.checkedList.forEach(value => {
                    if (value != null && document.getElementById(value) != null)
                        document.getElementById(value).checked = false;
                    // document.getElementById(value).style.background="";
                })
                this.state.filteredMilestoneData = this.state.filteredMilestoneDatas
                this.state.checkedList.forEach(value => {
                    this.state.practiceReport.forEach(data => {
                        data.isChecked = false;
                    })
                })
                this.state.SumofMileStoneValue = 0;
                this.state.sumoffilterMileStoneValue = 0;
            }
            if (this.state.searchBy != '') {
                filteredData = this.state.filteredMilestoneData.filter((item) => {
                    return Object.values(item).join('').toLowerCase().includes(this.state.searchBy.toLowerCase())
                })
            }
            else {
                filteredData = this.state.filteredMilestoneData;
            }
            this.state.filteredMilestoneData = filteredData;
            this.state.generatefilteredMsData = filteredData;

            this.setState({
                filteredMilestoneData: this.state.filteredMilestoneData,
                generatefilteredMsData: this.state.filteredMilestoneData,
                practiceReport: this.state.practiceReport,
                filter_class: this.state.filter_class
            })
        }

    }

    generateMileStoneValueReport = () => {
        this.setState({ loading: true });
        var link = this.state.apiLink + 'get_milestone_value_report';

        var invoice_status = {};
        this.state.invoice_dropdown_value.forEach(invoice => {
            if (invoice.value == this.state.selectedInvoiceStatus) {
                invoice_status.id = invoice.id
                invoice_status.value = invoice.value
            }
        })
        var services = [];
        services.push(this.state.report.service);
        if (this.state.report.service.length == 0) { // getting user associated service list
            this.state.report.service = getReportFilterService(this.state.serviceName, this.state.loginUserData, this.state.usersService);
        }
        var prac = [], ser = [], bu = [];
        this.state.qes_list.forEach(val => {
            prac.push(val.practice_id);
            ser.push(val.service_id);
            bu.push(val.business_unit);
        })
        if (this.state.token == null) {
            this.state.token = Cookies.get('auth_data');
        }
        return fetch(link, {
            method: "POST",
            body: JSON.stringify({
                reportDetails: {
                    end_date: moment(this.state.filter_end_date)
                        .utcOffset('+05:30')
                        .format('YYYY-MM-01'),
                    head_id: this.state.bu_id,
                    isCollectionPotential: false,
                    practice: [],
                    project_practice: this.state.report.practice,
                    selectedInvoiceStatus: invoice_status,
                    service: this.state.report.service,
                    allPractice: this.state.isAllPractices,
                    start_date: moment(this.state.filter_start_date)
                        .utcOffset('+05:30')
                        .format('YYYY-MM-01'),
                    userTypeId: this.state.userTypeId,
                    viewAll: this.state.view_all
                }
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Authorization": "Bearer " + this.state.token
            }
        }).then(res => res.json()).then(res => {
            var array = [], ms_array = [], array1 = [], array2 = [];
            var milestone_value = 0, ach = 0, uniqueIdValue = 0;
            this.setState({ loading: false });
            if (res == null) {
                this.ToastAlert("warning", "Server Timed Out ! Please Try Again Later");
                this.setState({
                    loading: false,
                    milestoneReport: [],
                    mileStoneValueReport: [],
                    practiceReport: [],
                    overallMileStoneValue: 0,
                    totalMileStoneValue: 0,
                    totalKickoffValue: 0,
                    totalCarryValue: 0,
                    sumOfNotSureValue: 0,
                    notUpdatedValue: 0,
                    totalNewValue: 0,
                    totalRaisedValue: 0,
                    totalCollectedValue: 0,
                    totalPendingValue: 0,
                    allPracticeMileStone: 0,
                    mileStoneReportMonth: res.result.monthName,
                    mileStoneReportYear: res.result.year,
                    filteredMilestoneData: [],
                    filteredMilestoneDatas: [],
                    monthName: res.result.monthName,
                    Year: res.result.year,
                    generatefilteredMsData: []
                })
            }
            else if (res.status) {
                this.state.milestoneReport = res.result.milestoneReportPractice;
                var milestoneReport = res.result.milestoneReportPractice;
                var mile_report = res.result.milestoneReport;
                if (mile_report.length > 0) {
                    Object.keys(this.state.milestoneReport).forEach(value => {
                        uniqueIdValue = 0;
                        Object.keys(milestoneReport[value]).forEach(valueId => {
                            uniqueIdValue = 0;
                            var achieved = 0;
                            Object.keys(milestoneReport[value][valueId]).forEach(uniqueId => {
                                uniqueIdValue += milestoneReport[value][valueId][uniqueId].userMilestoneValue;
                                achieved += milestoneReport[value][valueId][uniqueId].userAchievedMilestoneValue;
                                milestoneReport[value][valueId][uniqueId].userMilestoneValue = uniqueIdValue;
                                milestoneReport[value][valueId][uniqueId].userAchievedMilestoneValue = achieved;
                                array1 = milestoneReport[value][valueId][uniqueId];
                            });
                            ms_array.push(array1);
                        });

                    });
                    mile_report.forEach(value => {
                        if (value.kickoff_status) {
                            ms_array.push(value);
                        }
                    })
                    this.state.milestoneReport = ms_array;
                    var dataObj = {
                        'businessUnit': res.result.businessUnit,
                        'milestoneReport': ms_array,
                        'monthName': res.result.monthName,
                        'practiceReport': res.result.practiceReport,
                        'totalCarryValue': res.result.totalCarryValue,
                        'totalKickoffValue': res.result.totalKickoffValue,
                        'totalMileStoneValue': res.result.totalMileStoneValue,
                        'year': res.result.year,
                    };
                    this.state.mileStoneValueReport = dataObj; //for excel
                    this.state.mileStoneValueReport.fileName = 'Milestone-value-report';
                    this.state.practiceReport = res.result.practiceReport;
                    this.state.practiceReport.forEach(value => {
                        if (prac.includes(value.practiceId)) {
                            milestone_value = parseInt(milestone_value) + parseInt(value.mileStoneValue);
                        }
                        else {
                            array.push(value);
                        }
                    })

                    array.forEach(value => {
                        if (value.practiceId == this.state.practice_qes) {
                            value.mileStoneValue = parseInt(value.mileStoneValue) + parseInt(milestone_value);
                        }
                        array2.push(value);
                    })
                    this.setState({
                        loading: false,
                        milestoneReport: this.state.milestoneReport,
                        mileStoneValueReport: this.state.mileStoneValueReport,
                        practiceReport: array,
                        filteredMilestoneData: this.state.milestoneReport,
                        overallMileStoneValue: res.result.overallMileStoneValue,
                        totalMileStoneValue: res.result.totalMileStoneValue,
                        totalKickoffValue: res.result.totalKickoffValue,
                        totalCarryValue: res.result.totalCarryValue,
                        sumOfNotSureValue: res.result.sumOfNotSureValue,
                        notUpdatedValue: res.result.notUpdatedValue,
                        totalNewValue: res.result.totalNewValue,
                        totalRaisedValue: res.result.totalRaisedValue,
                        totalCollectedValue: res.result.totalCollectedValue,
                        totalPendingValue: res.result.totalPendingValue,
                        mileStoneReportMonth: res.result.monthName,
                        mileStoneReportYear: res.result.year,
                        monthName: res.result.monthName,
                        Year: res.result.year,
                    });
                }
                else {

                    this.setState({
                        loading: false,
                        milestoneReport: [],
                        mileStoneValueReport: [],
                        filteredMilestoneData: [],
                        practiceReport: [],
                        overallMileStoneValue: 0,
                        totalMileStoneValue: 0,
                        totalKickoffValue: 0,
                        totalCarryValue: 0,
                        sumOfNotSureValue: 0,
                        notUpdatedValue: 0,
                        totalNewValue: 0,
                        totalRaisedValue: 0,
                        totalCollectedValue: 0,
                        allPracticeMileStone: 0,
                        totalPendingValue: 0,
                        myPracticeMileStone: 0,
                        filteredMilestoneDatas: [],
                        mileStoneReportMonth: res.result.monthName,
                        mileStoneReportYear: res.result.year,
                        monthName: res.result.monthName,
                        Year: res.result.year,
                        generatefilteredMsData: []
                    });

                }
            }
            else {
                this.ToastAlert("error", res.message);
                this.setState({
                    loading: false,
                    milestoneReport: [],
                    mileStoneValueReport: [],
                    practiceReport: [],
                    overallMileStoneValue: 0,
                    allPracticeMileStone: 0,
                    totalMileStoneValue: 0,
                    totalKickoffValue: 0,
                    totalCarryValue: 0,
                    sumOfNotSureValue: 0,
                    notUpdatedValue: 0,
                    totalNewValue: 0,
                    totalRaisedValue: 0,
                    totalCollectedValue: 0,
                    totalPendingValue: 0,
                    mileStoneReportMonth: res.result.monthName,
                    mileStoneReportYear: res.result.year,
                    filteredMilestoneData: [],
                    monthName: res.result.monthName,
                    Year: res.result.year,
                    generatefilteredMsData: []
                })
            }

            if (this.state.practiceReport.length > 0) {
                this.calculatePracticeWiseMileStone(this.state.practiceReport);
            }

            if (this.state.filteredMilestoneData.length > 0) {
                this.state.filteredMilestoneData.forEach(value => {
                    this.state.invoice_dropdown_value.forEach(invoice => {
                        if (value.invoice_status == invoice.id) {
                            value.invoice = invoice.value;
                        }
                    })
                    if (this.state.invoice_dropdown_value[2].id == value.invoice_status) {
                        value.inv_class = "text-start alert_report_row ";
                    }
                    else if (this.state.invoice_dropdown_value[4].id == value.invoice_status) {
                        value.inv_class = "text-start alert_report_row ";
                    }
                    else if (this.state.invoice_dropdown_value[3].id == value.invoice_status) {
                        value.inv_class = "text-start green_report_row ";
                    }
                    else {
                        value.inv_class = "text-start";
                    }
                })
                this.setState({
                    filteredMilestoneDatas: this.state.filteredMilestoneData,
                    loading: false
                })
            }
            if (this.state.generatefilteredMsData.length > 0) {
                this.setState({
                    filteredMilestoneData: this.state.generatefilteredMsData,
                    practiceReport: this.state.practiceReport,
                })
            }

        }).catch(err => {
            this.ToastAlert("warning", "Taking too time to load. apply other filter to load the data");
            this.setState({
                loading: false,
                milestoneReport: [],
                mileStoneValueReport: [],
                practiceReport: [],
                overallMileStoneValue: 0,
                totalMileStoneValue: 0,
                totalKickoffValue: 0,
                totalCarryValue: 0,
                sumOfNotSureValue: 0,
                notUpdatedValue: 0,
                totalNewValue: 0,
                totalRaisedValue: 0,
                totalCollectedValue: 0,
                totalPendingValue: 0,
                allPracticeMileStone: 0,
                mileStoneReportMonth: '',
                mileStoneReportYear: '',
                filteredMilestoneData: [],
                filteredMilestoneDatas: [],
                monthName: '',
                Year: '',
                generatefilteredMsData : []
            })
        });

    }

    calculatePracticeWiseMileStone = (practiceList) => {
        this.state.myPracticeMileStone = 0;
        this.state.allPracticeMileStone = 0;
        console.log(this.state.allPracticeMileStone)
        practiceList.forEach(practicesValue => {

            if (practicesValue.myPractice) {
                this.setState({
                    myPracticeMileStone: this.state.myPracticeMileStone + practicesValue.mileStoneValue
                })

            } else {
                this.setState({
                    allPracticeMileStone: this.state.allPracticeMileStone + practicesValue.mileStoneValue
                })
            }
        });
    };

    changeViewPracite = (flag) => {
        if (this.state.loginUserData.role_id == 8 && this.state.isAllPractices) {
            this.state.isAllPractices = flag;
        }
        else {
            this.state.isAllPractices = flag;
        }
        this.selectReport(this.state.current_report);
    }

    selectReport = (report) => {
        this.state.previous_report = this.state.current_report;
        this.state.current_report = report;
        this.state.project_filter = {};
        if (this.state.reports_lists[0].value == this.state.current_report) {
            this.state.searchBy = 'name';
            var date = new Date();
            var date = new Date(date.getFullYear(), date.getMonth(), 2);
            this.state.filter_end_date = (this.state.filter_end_date) ? this.state.filter_end_date : date;
            this.state.filter_start_date = (this.state.filter_start_date) ? this.state.filter_start_date : date;
            this.state.report.practice = (this.state.report.practice) ? this.state.report.practice : [];
            this.state.report.head_id = '';
            // this.state.selectPractice('');
            // this.state
        }
        else if (this.state.reports_lists[1].value === this.state.current_report) {
            this.state.filter_start_date = new Date();
            this.state.techLeadId = "";
            this.state.businessAnalystId = "";
            this.state.searchByTechLead = "";
            this.state.searchByBusinessAnalyst = "";
            this.state.report.head_id = "";
            this.state.submitted = false;
            this.state.mileStoneReportPractice = 1;
            this.state.filterByPractice = '';
            this.state.filterMileStoneValue = '';

            // this.getTechLeadList();
            this.generateMileStoneValueReport(true);
        }
        else {
            //
        }

    }

    //for filtering service by head
    getReportByHead = (e) => {
        this.state.searchBy = '';
        if (e != undefined) {
            //  e.selected = true;
            this.state.bu_id = e.bu_id;
            this.state.head_id = e.label
            this.state.selectBuFilter = e;
        }
        else {
            this.state.head_id = 'All';
            this.state.bu_id = '';
            this.state.selectBuFilter = [];
        }
        if (this.state.head_id != 'All') { // for BU filter
            this.state.report.project_practice = [];
            var service_head = [];

            this.state.report.service = [];
            this.state.report.service = [];
            this.state.sname1.forEach(service => {
                if (this.state.bu_id == service.bu) {
                    service_head.push(service);

                }
            })
            this.state.serviceName = [];
            this.state.selectService = [];
            service_head.forEach(value => {
                var opt = {};
                opt.label = value.value;
                opt.id = value.id;
                opt.value = value.value;
                opt.head = value.head;
                opt.bu = value.bu;
                this.state.serviceName.push(opt);
            })
            this.state.selectService = [];

            var service = this.state.report.service;
            if (service.length == 0) {
                this.state.projectPractice = [];
                this.state.report.practice = [];
            }
            else {
                service.forEach(svalue => {
                    this.state.report_all_practices.forEach(value => {
                        if (svalue.id == value.serviceId) {
                            this.state.projectPractice.push(value);
                        }
                    })
                })
                if (this.state.projectPractice.length > 0) {
                    this.state.projectPractice.forEach(value => {
                        this.state.report.practice.push(value.id);
                    });
                }
            }
        } else { // when All BU is selected	
            var service_head = [];
            this.state.selectBuFilter = [];
            if (this.state.report.service.length == 0) {
                //nothing
                this.state.sname1.forEach(service => {
                    service_head.push(service);
                })
                this.state.serviceName = [];
                this.state.selectService = [];
                service_head.forEach(value => {
                    var opt = {};
                    opt.label = value.value;
                    opt.id = value.id;
                    opt.value = value.value;
                    opt.head = value.head;
                    opt.bu = value.bu;
                    this.state.serviceName.push(opt);
                })
                this.state.selectService = [];
            }
            else {
                this.state.report.service = [];
                this.state.sname.forEach(value => {
                    var opt = {};
                    opt.label = value.value;
                    opt.id = value.id;
                    opt.value = value.value;
                    opt.head = value.head;
                    opt.bu = value.bu;
                    this.state.serviceName.push(opt);
                })
                this.state.selectService = (this.state.serviceName[0]);
                this.state.report.service.push(this.state.serviceName[0])
            }
        }
        this.setState({
            selectBuFilter: this.state.selectBuFilter
        })
        this.generateMileStoneValueReport(true);

    }

    msvalueSum = (e, data) => {
        let resultArray = []

        data.isChecked = !data.isChecked;
        if (data.isChecked == true) {
            this.state.SumofMileStoneValue += (data.userAchievedMilestoneValue) ? data.userAchievedMilestoneValue : data.userMilestoneValue;
        }
        else {
            this.state.SumofMileStoneValue -= (data.userAchievedMilestoneValue) ? data.userAchievedMilestoneValue : data.userMilestoneValue;
        }
        this.setState({
            SumofMileStoneValue: this.state.SumofMileStoneValue
        })
    }

    ToastAlert(icon, title) {
        return Toast.fire({
            target: document.getElementById("form-modal"),
            icon: `${icon}`,
            title: `${title}`,
        });
    }

    HandleClick() {
        Swal.fire({
            title: 'Success',
            type: 'success',
            text: 'Your work has been saved.',
        });
    }

    selectMileStoneReportPractice = (e, selectedPractice) => {
        this.state.mileStoneReportPractice = selectedPractice;
        var allService = this.state.allService;
        this.state.allService = [];
        this.state.sname = [];
        console.log(this.state.sname1)
        this.state.serviceName = [];
        console.log(allService)
        if (this.state.bu_id != '' && this.state.bu_id != undefined && this.state.bu_id != 0) {
            allService.forEach(service => {
                if (this.state.bu_id == service.bu) {
                    this.state.allService.push(service);
                }
            });
        }
        else {
            this.state.allService = allService;
        }
        this.state.filterByPractice = '';
        if (selectedPractice == 0) {//to reset service when view all practice is selected
            this.state.sname = [];
            this.state.report.service = [];
            this.state.isAllPractices = true;
            this.state.report.practice = [];
            this.state.report.service = [];
            this.state.view_all = 0;
            this.state.selectService = [];
            this.state.selectBuFilter = [];
            this.state.sname = this.state.allService;
        } else {
            this.state.sname = this.state.sname1;
            this.state.isAllPractices = false;
            this.state.view_all = 1;
        }
        this.state.sname.forEach(value => {
            var opt = {};
            opt.label = value.value;
            opt.id = value.id;
            opt.value = value.value;
            opt.head = value.head;
            opt.bu = value.bu;
            this.state.serviceName.push(opt);
        })
        this.state.filterByPractice = '';
        this.generateMileStoneValueReport(true);

    };

    //generate milestone/collections/collection-potential report excel
    generateMileStoneValueReportAsExcel = (e) => {
        e.currentTarget.blur(); // removing focus
        this.state.milestoneReport = [];
        this.state.showNodata = false;
        this.setState({ loading: true });
        //  if(valid){
        const FileDownload = require("js-file-download");
        var link = this.state.apiLink + 'export_as_excel_generate_milestone_report';
        this.state.milestoneReport = this.state.filteredMilestoneData;
        this.state.mileStoneValueReport.milestoneReport = this.state.milestoneReport;//with filter data export
        if (this.state.token == null) {
            this.state.token = Cookies.get('auth_data');
        }
        return fetch(link, {
            method: "POST",
            body: JSON.stringify({
                reportDetails: {
                    fileName: "Milestone_value-report",
                    milestoneReport: this.state.milestoneReport,
                    monthName: this.state.monthName,
                    practiceReport: this.state.practiceReport,
                    totalCarryValue: this.state.totalCarryValue,
                    totalKickoffValue: this.state.totalKickoffValue,
                    totalMileStoneValue: this.state.totalMileStoneValue,
                    year: this.state.Year
                }
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Authorization": "Bearer " + this.state.token
            }
        }).then(res => res.json()).then(res => {
            //console.log(result)
            this.setState({ loading: false });
            this.ToastAlert("success", "Report generated Successfully");
            var result = res.result;
            document.location = this.state.excelUrl + result.filename;
        })
    }

    resetReport = (e) => {
        e.currentTarget.blur(); // removing focus
        this.state.selectedInvoiceStatus = this.state.selectedInvoice;
        this.state.searchBy = '';
        this.state.mileStoneReportPractice = 1;

        this.state.view_all = 1;
        this.setState({
            selectedInvoiceStatus: this.state.selectedInvoiceStatus
        })
        this.getPracticeService();
        // this.filterPractice();

    }

    // class applying based on invoice status
    msPercentageClass = (allocated, achieved) => {
        var percentate = (((achieved / allocated) * 100));
        var returnClass;
        if ((!achieved || achieved == 0)) //user not given achieved value
        {
            returnClass = "m-t-5";
        } else {
            if (percentate >= 80) {
                returnClass = "green_report_row m-t-5"; //for good
            } else {
                returnClass = "danger_report_row m-t-5"; //for average
            }
        }
        return returnClass;
    }

    searchBy = (e) => {
        let searchInput = e.target.value;
        let filteredData = [];
        let data = (this.state.generatefilteredMsData.length) ? this.state.generatefilteredMsData :
            this.state.filteredMilestoneDatas;
        if (searchInput) {
            filteredData = data.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            this.state.searchBy = searchInput;
        }
        else {
            this.state.searchBy = '';
            filteredData = data;
        }
        this.state.filteredMilestoneData = filteredData;
        this.setState({ filteredMilestoneData: this.state.filteredMilestoneData })

    }

    /**
     * main render
     * @returns 
     */
    render() {
        const { invoice_dropdown_value } = this.state;
        var myPracList = [];
        var allPracList = [];
        this.state.practiceReport.forEach(pracValue => {
            if (pracValue.myPractice == 0) {
                allPracList.push(pracValue);
            }
            else {
                myPracList.push(pracValue);
            }
        })
        if (this.state.mileStoneReportPractice == 0) {
            myPracList.forEach(pvalue => {
                if (pvalue.isChecked == true) {
                    pvalue.prac_class = "col-sm-3 active";
                }
                else {
                    pvalue.prac_class = "col-sm-3"
                }
            })
        }
        else {
            myPracList.forEach(pvalue => {
                if (pvalue.isChecked == true) {
                    pvalue.prac_class = "col-sm-1 active";
                }
                else {
                    pvalue.prac_class = "col-sm-1"
                }
            })
        }


        if (this.state.redirect == 1 || this.state.loginUserData == null) {
            return <PageNotFound url={this.state.angularUrl} />
        }
        else {
            return (
                <>
                    <div className={"page-content px-4 " + (this.props.addClass ? "active" : "")}>
                        <Row>
                            <div className="col-xs-12 fourty-fluid fourty-fluid">
                                <Row className="mb-3">
                                    <div className="col-sm-4 col-sm-4 col-xs-12 text-left">
                                        <h1 className="B-G-bold f-s-18 d-gray t-t-upper pull-left mt-10 mr-20 page-head">Reports</h1>
                                    </div>
                                    <div className="col-lg-3 col-sm-4 col-xs-12">
                                        <div className="selectpicker_dropdown hide_cross hide_bar orange_arrow remove_border_btm select_dropdown_picker">
                                            <Dropdown title={this.state.current_report.value} activeKey="2">
                                                <Dropdown.Item as="a" eventKey="1" href="/pw_report" target="_blank">Practice Wise Allocation Report</Dropdown.Item>
                                                <Dropdown.Item as="a" eventKey="2">Milestone Value Report</Dropdown.Item>
                                                <Dropdown.Item as="a" eventKey="3" href="/collections_report" target="_blank">Collections Report</Dropdown.Item>
                                                <Dropdown.Item as="a" eventKey="4" href="/collections_potential_report" target="_blank">Collection Potential Report</Dropdown.Item>
                                                <Dropdown.Item as="a" eventKey="5" href="/inventory_report" target="_blank">Inventory Report</Dropdown.Item>
                                                <Dropdown.Item as="a" eventKey="6" href="/inventory_allocation_report" target="_blank">Inventory Allocation Metrics</Dropdown.Item>
                                                <Dropdown.Item as="a" eventKey="7" href="/inventory_milestone_report" target="_blank">Inventory Milestone Metrics</Dropdown.Item>
                                                <Dropdown.Item as="a" eventKey="8" href="/utilization_report" target="_blank">Resource Utilization Report</Dropdown.Item>
                                                <Dropdown.Item as="a" eventKey="9" href="/csm_report" target="_blank">Csm Report</Dropdown.Item>
                                                <Dropdown.Item as="a" eventKey="10" href="/pmo_ms_report" target="_blank">Pmo Ms Report</Dropdown.Item>
                                                <Dropdown.Item as="a" eventKey="11" href="/acv_report" target="_blank">Annual Contract Value Report</Dropdown.Item>
                                                <Dropdown.Item as="a" eventKey="12" href="/ms-allocation-report" target="_blank">MS Allocation Report</Dropdown.Item>
                                                <Dropdown.Item as="a" eventKey="13" href="/squad-review-report" target="_blank">Squad Review Report</Dropdown.Item>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-5 col-sm-4 col-xs-12 justify-content-end text-end">
                                        <span class="hidden-xs l-gray B-G-bold f-s-16">Welcome, <b class="d-gray">{this.state.loginUserData.first_name} {this.state.loginUserData.last_name}</b></span>
                                    </div> */}
                                </Row>
                                <Row>
                                    <div className="col-lg-6 clearfix m-b-20">
                                        <div className="row align-items-center">
                                            <label class="f-s-12 text-muted pt-1 w-13 col-lg-2 col-md-6 col-sm-12 text-left">Line of Service :</label>
                                            <div className="col-lg-7 col-sm-5 text-left ">
                                                <Row>
                                                    <div className="col-lg-10 p-l-0">
                                                        <div className="selectpicker_dropdown w-100 hide_bar w-100 selectpicker_dropdown_orange">
                                                            <Select
                                                                //  menuIsOpen={true}
                                                                className="basic-single-select"
                                                                classNamePrefix={"select_dropdown"}
                                                                placeholder="All"
                                                                isClearable
                                                                isSearchable={true}
                                                                onChange={(e) => this.filterService(e)}
                                                                value={this.state.selectService}
                                                                options={this.state.serviceName} isMulti>
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 p-1-0">
                                                        {this.state.report.service != undefined && this.state.report.service.length > 0 &&
                                                            <a href="javascript:void(0)" title="Reset" tooltip-placement="top" className="reset-icon_old  reset-color p-l-5 m-l-4" onClick={this.reset_page}><i class="fs-16 fa fa-refresh"></i></a>
                                                        }
                                                    </div>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.loginUserData.role_id != 8 &&
                                        <div className="col-lg-4 clearfix m-b-20">
                                            <div className="row">
                                                <div class="col-sm-5 col-xs-12">
                                                    <label className="text-uppercase m-b-0 fs-13 p-t-5">Business Unit :</label>
                                                </div>
                                                <div className="col-sm-7 col-xs-12">
                                                    <div className="selectpicker_dropdown hide_bar w-100 selectpicker_dropdown_orange">
                                                        <Select
                                                            //menuIsOpen={true}
                                                            className="basic-single-select"
                                                            classNamePrefix={"select_dropdown"}
                                                            placeholder="All"
                                                            isClearable
                                                            isSearchable={true}
                                                            onChange={(e) => this.getReportByHead(e)}
                                                            value={this.state.selectBuFilter}
                                                            options={this.state.buName} >
                                                        </Select>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                    <div className="col-lg-2 col-sm-2 col-xs-12 justify-content-end text-end">
                                        <a href={this.state.angularUrl} className="btn btn-primary">Back</a>
                                    </div>
                                </Row>
                                <hr className="m-t-5 col-sm-12 m-b-8 p-0"></hr>
                                <div className="col-lg-12 col-sm-12 col-xs-12 p-b-15-xs p-r-15-xs">
                                    <Row className=" mt-3 mb-3">
                                        <Col className="col-lg-8 text-start col p-0">
                                            <Row className="m-0 mt-1">
                                                <Col className="col-lg-2 text-start">
                                                    <label className="p-0 B-G-bold f-s-18 d-gray m-t-3 m-b-10 page-head pull-left">Month Filter:</label>
                                                </Col>
                                                <Col className="col-lg-1 px-0 general-content text-center">
                                                    <label className="pt-2">Month :</label>
                                                </Col>
                                                <Col className="col-lg-2">
                                                    <div className="row align-items-center mb-2 date_picker_align"  >
                                                        <DatePicker
                                                            className="datepicker-control-section"
                                                            onChange={(e) => this.setStartDateForFilter(e)}
                                                            dateFormat="MM/yyyy"
                                                            showMonthYearPicker
                                                            todayButton={"Today"}
                                                            name="start_date"
                                                            selected={this.state.filter_start_date}
                                                        />
                                                        <span class="cal_icon_date" role="button" tabindex="0"> <i class="icon-calendar"></i></span>


                                                    </div>
                                                </Col>
                                                <Col className="col-lg-6 text-end">
                                                    <Button onClick={(e) => this.resetReport(e)} value="Generate" className="me-2" >Generate</Button>
                                                    <Button onClick={(e) => this.generateMileStoneValueReportAsExcel(e)} value="Excel" className="me-2" disabled={this.state.filteredMilestoneData.length == 0 ? true : false}>Export as Excel</Button>
                                                    {/* {this.state.loginUserData.role_id != 1 && (this.state.mileStoneReportPractice == 1) &&
                                                        <Button onClick={(e) => this.selectMileStoneReportPractice(e, 0)} value="allpractice" className="me-2">View all practice</Button>
                                                    }
                                                    {this.state.loginUserData.role_id != 1 && (this.state.mileStoneReportPractice == 0) &&
                                                        <Button onClick={(e) => this.selectMileStoneReportPractice(e, 1)} value="mypractice" className="me-2">View my practice</Button>
                                                    } */}
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="col-lg-4 text-end">
                                            <div className="form-group search-icon col-sm-12 col-xs-12">
                                                <input type="text" name="project_filter" value={this.state.searchBy} className="form-control B-G-bold f-s-16 d-gray pl-30" placeholder="Search..." onChange={(e) => this.searchBy(e)} />
                                                <i class="fa fa-search"></i>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="center-block text-center">
                                    <div className="col-lg-12 col-sm-12 col-xs-12 p-b-15-xs">
                                        <div className="p-0 B-G-bold f-s-16 d-gray m-t-8 m-b-0 page-head">
                                            <Row className="my-3">
                                                <Col className="col-lg-3" style={{ "font-size": "15px" }}><div>Expected MS Value: $ {this.state.totalMileStoneValue}</div></Col>
                                                <Col className="col-lg-2" style={{ "font-size": "15px" }}><div if="!filterByPractice">Kickoff Value: $ {this.state.totalKickoffValue}</div></Col>
                                                <Col className="col-lg-3" style={{ "font-size": "15px" }}> <div if="!filterByPractice">Carry Forward Value: $ {this.state.totalCarryValue}</div></Col>
                                                <Col className="col-lg-4" style={{ "font-size": "15px" }}>
                                                    <div className="row m-0 justify-content-end">
                                                        <label className="text-uppercase m-b-0 fs-13 p-t-5 col-lg-5 p-0">Invoice Status :</label>
                                                        <div className="selectpicker_dropdown col-lg-6 p-0">
                                                            <select
                                                                value={this.state.selectedInvoiceStatus}
                                                                onChange={(e) => this.selectFilter(e)}
                                                                name="invoice_status"
                                                                required
                                                                autofocus="true"
                                                            >
                                                                {
                                                                    invoice_dropdown_value.map((list) => (
                                                                        <option id={list.id} value={list.value} >{list.value}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                            <span class="icon-arrow-down pull-right pt-5 p-l-10"></span>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="my-3">
                                                <Col className="col-lg-3" style={{ "font-size": "15px" }}><div>Total Selected MS Value: $ {this.state.SumofMileStoneValue}</div></Col>
                                                <Col className="col-lg-3" style={{ "font-size": "15px" }}><div>Total MS Value by practice: $ {this.state.sumoffilterMileStoneValue}</div>  </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                                <Row>
                                    <div className={this.state.mileStoneReportPractice == 0 ? "col-md-6 p-r-0" : ""} >
                                        <div className="col-lg-12 col-sm-12 col-xs-12 p-b-15-xs" >
                                            <h3 className="text-left text-uppercase heading--table m-t-10 m-b-5 fs-15" style={{ "font-size": "18px" }}>My Practice</h3>
                                            <div className="white-table-class utilize-box p-t-10 p-b-10 clearfix practicebox--list scrollbar-design my-collection min-h100">
                                                <Row className="text-start list_clr">
                                                    <div className={this.state.mileStoneReportPractice == 0 ? "col-sm-3" : "col-sm-1"} onClick={(e) => this.filterPractice(e, '', myPracList)} >
                                                        <div class="col-sm-12 p-l-0 p-r-5" className={this.state.filter_class}>
                                                            <p className="m-b-5 m-t-5 fs-12">All</p>
                                                            <h3 className="fs-14" style={{ "font-size": "15px" }}>$ {this.state.myPracticeMileStone}</h3>
                                                            <hr className="m-t-7 m-b-0"></hr>
                                                        </div>
                                                    </div>

                                                    {myPracList.map((practiceItem) => (
                                                        <div className={practiceItem.prac_class}   >
                                                            <div className="col-sm-12 p-l-0 p-r-5 text-start" id="class_grey_row_">
                                                                <input type="checkbox" id={practiceItem.practiceName} value={practiceItem.practiceName} className={practiceItem.isChecked ? 'B-G-bold f-s-12 l-gray pl-5 pr-5 pr-list mr-5 active' : 'B-G-bold f-s-12 l-gray pl-5 pr-5 pr-list mr-5'} style={{ "float": "left", "marginRight": "7px", "verticalAlign": "top" }} onClick={(e) => this.filterPractice(e, practiceItem, myPracList)}
                                                                />
                                                                <p class="m-b-5 m-t-5 fs-12 text-truncate" title={`${practiceItem.practiceName}`}>{practiceItem.practiceName}</p>
                                                                <h3 class="fs-14" style={{ "font-size": "15px" }}>$ {practiceItem.mileStoneValue}</h3>
                                                                <hr class="m-t-7 m-b-0"></hr>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 p-r-0" >
                                        {this.state.mileStoneReportPractice == 0 &&
                                            <div className="col-lg-12 col-sm-12 col-xs-12 p-b-15-xs">
                                                <h3 className="text-left text-uppercase heading--table m-t-10 m-b-5 fs-15" style={{ "font-size": "18px" }}>Other Practices</h3>
                                                <div className="white-table-class utilize-box p-t-10 p-b-10 clearfix practicebox--list scrollbar-design my-collection min-h100">
                                                    <Row className="text-start list_clr">
                                                        <div className="col-sm-3" onClick={(e) => this.filterPractice(e, '', allPracList)} >
                                                            <div class="col-sm-12 p-l-0 p-r-5" className={this.state.filter_class}>
                                                                <p className="m-b-5 m-t-5 fs-12">All</p>
                                                                <h3 className="fs-14" style={{ "font-size": "15px" }}>$ {this.state.allPracticeMileStone}</h3>
                                                                <hr className="m-t-7 m-b-0"></hr>
                                                            </div>
                                                        </div>

                                                        {allPracList.map((practiceItem) => (
                                                            <div className={practiceItem.isChecked ? 'col-sm-3 active' : 'col-sm-3'}   >
                                                                <div className="col-sm-12 p-l-0 p-r-5 text-start" id="class_grey_row_">
                                                                    <input type="checkbox" id={practiceItem.practiceName} value={practiceItem.practiceName} className={practiceItem.isChecked ? 'B-G-bold f-s-12 l-gray pl-5 pr-5 pr-list mr-5 active' : 'B-G-bold f-s-12 l-gray pl-5 pr-5 pr-list mr-5'} style={{ "float": "left", "marginRight": "7px", "verticalAlign": "top" }} onClick={(e) => this.filterPractice(e, practiceItem, allPracList)}
                                                                    />
                                                                    <p class="m-b-5 m-t-5 fs-12 text-truncate" title={`${practiceItem.practiceName}`}>{practiceItem.practiceName}</p>
                                                                    <h3 class="fs-14" style={{ "font-size": "15px" }}>$ {practiceItem.mileStoneValue}</h3>
                                                                    <hr class="m-t-7 m-b-0"></hr>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </Row>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </Row>
                                <hr className="m-t-5 col-sm-12 m-b-8 p-0"></hr>

                                <div className="table-responsive white-table-class reports--grid">
                                    <div className="scrollable">
                                        <div className="scrollable-content">
                                            <table className="table m-b-0 table-wrap">
                                                <thead>
                                                    <tr className="theadInner">
                                                        {/* <th className="" style={{"width": "1%"}}>#</th> */}
                                                        <th className="text-center" style={{ "width": "2%" }}>&nbsp;</th>
                                                        <th className="text-start" style={{ "width": "16%" }}>Project Name</th>
                                                        <th className="text-start" style={{ "width": "14%" }}>Milestone</th>
                                                        <th className="text-start" style={{ "width": "10%" }}>Allocated MS</th>
                                                        <th className="text-start" style={{ "width": "11%" }}>Achieved MS Value</th>
                                                        <th className="text-start" style={{ "width": "11%" }}>Invoice status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.loading == true &&
                                                        <div id="preloader">
                                                            <div id="status"></div>
                                                        </div>
                                                    }
                                                    {this.state.loading == false &&
                                                        this.state.filteredMilestoneData.length == 0 &&
                                                        <tr>
                                                            <td colSpan="8" className="r-no-data text-center">No data available</td>
                                                        </tr>
                                                    }
                                                    {this.state.loading == false && this.state.filteredMilestoneData.length > 0 && this.state.filteredMilestoneData.map((item) => (
                                                        <tr id="class_grey_rows_" className="{'active':item.is_checked}" init="idx = $index">
                                                            {/* <td>
                                                    {index++}
                                                </td> */}
                                                            <td className="text-center">
                                                                <input type="checkbox" id="msvaluesum_{{idx}}" value={item.is_checked} onChange={(e) => this.msvalueSum(e, item)} />
                                                            </td>
                                                            <td className="text-start">
                                                                <span title={`${item.practiceName}`} className="t-t-upper practise-li python name p-l-5 text-truncate txt-black p-t-3 fs-13" style={{ "float": "left" }}>{practiceRename(item.practiceName)}</span>
                                                                <Row>
                                                                    <span className="name p-l-5 text-truncate txt-black p-t-3 fs-13" style={{ "maxWidth": "200px", "textAlign": "left" }} title={`${item.projectName}`}>{item.projectName}</span>
                                                                </Row>
                                                                <Row>
                                                                    <span className="s-gray lead-name">{item.techLead} / {item.businessAnalyst}</span>
                                                                </Row>
                                                            </td>
                                                            <td className="text-start">
                                                                <span>
                                                                    {item.iterationNumber && !item.carryforward_target && !item.is_carryforward && !item.iteration_name &&
                                                                        <span>Sprint {item.iterationNumber}</span>
                                                                    }
                                                                    {item.iterationNumber && !item.carryforward_target && !item.is_carryforward && item.iteration_name &&
                                                                        <span >{item.iteration_name}</span>
                                                                    }
                                                                    {!item.iterationNumber &&
                                                                        <span >Kickoff</span>
                                                                    }
                                                                    {item.is_carryforward &&
                                                                        <span>
                                                                            Sprint {item.iterationNumber}
                                                                            {item.is_carryforward &&
                                                                                <img title="carryforwarded Sprint" tooltip-placement="left" src={exchange} alt="noImg" />}
                                                                        </span>
                                                                    }
                                                                </span>
                                                            </td>
                                                            <td className="text-start">
                                                                <span>${item.userMilestoneValue}</span>
                                                            </td>
                                                            <td className="text-start">
                                                                {item.userAchievedMilestoneValue &&
                                                                    <span className={this.msPercentageClass(item.userMilestoneValue, item.userAchievedMilestoneValue)}  >${item.userAchievedMilestoneValue}</span>}
                                                            </td>

                                                            {item.invoice_status && this.state.currentFilter &&
                                                                <td className={item.inv_class}>
                                                                    <span >
                                                                        <span class="pull-left">
                                                                            <span title="Invoice NO: {{item.invoice_no}}" tooltip-placement="left">{item.invoice}</span></span>
                                                                    </span></td>
                                                            }
                                                            {
                                                                !item.invoice_status &&
                                                                <td className={item.inv_class}>
                                                                    <span >NA</span> </td>
                                                            }

                                                        </tr>
                                                    ))}</tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>
                </>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        addClass: state.pmsDetails.addClass,
    };
};

const mapDispatchToProps = {
    setAddClass
};

export default connect(mapStateToProps, mapDispatchToProps)(MileStoneReport);

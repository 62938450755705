import *  as  constants from "../Config/Constant";
import { postData } from "../Services/Helper";
import { handleResponse } from "../Services/HandleResponse";
import * as types from "../Utilities/Type"
import { getBackEndConstant } from "../Config/Constant";

export function getUtilizationReport(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "get_monthwise_utilization_report";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.GET_UTILIZATION,
          payload: (response.result) ? response.result : {},
        });
      });
  };
}

export function utilizationExportExcel(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url  + "get_monthwise_utilization_report_excel";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.UTLIZATION_EXPORT_EXCEL,
          payload: (response.result) ? response.result : {},
        });
      });
  };
}

export function getCheckSum(checkSumFlag) {
  return (dispatch, getState) => {
    dispatch({
      type: types.GET_CHECK_SUM,
      payload: checkSumFlag
    });
  }
}

export function updateUtilizationValues(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url  + "update_utilization_values";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.UPDATE_UTILIZATION_VALUES,
          payload: (response.result) ? response.result : {},
        });
      });
  };
}

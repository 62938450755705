import *  as  constants from "../Config/Constant";
import { postData, getData } from "../Services/Helper";
import { handleResponse } from "../Services/HandleResponse";
import * as types from "../Utilities/Type"
import { getBackEndConstant } from "../Config/Constant";

export function getReportFilter(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "get_report_filters_new";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.GET_REPORT_FILTER,
          payload: (response.result) ? response.result : {},
        });
      });
  };
}

export function getReportFilterPmo(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "get_pmo_filter";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.GET_PMO_FILTER,
          payload: (response.result) ? response.result : {},
        });
      });
  };
}

export function saveInvoiceComments(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "save_invoice_comments";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.SAVE_COMMENTS,
          payload: (response.result) ? response.result : "",
        });
      });
  };
}

export function getUserList() {
  return (dispatch) => {
    const requestOptions = getData();
    let url = getBackEndConstant().backend_url + "get_user_list";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.REPORT_LEAD,
          payload: response ? response : [],
        });
      });
  };
}

export function getInvoiceComments(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "get_invoice_comments";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.ITERATION_COMMENTS,
          payload: response ? response : [],
        });
      });
  };
}
export const GET_UTILIZATION = "getUtilizationReport";
export const GET_LOGIN_USER = "getLoginUser";
export const GET_REPORT_FILTER = "getReportFilter";
export const GET_LOGIN_USER_PERMISSION = "getLoginUserPermission";
export const UTLIZATION_EXPORT_EXCEL = "utilizationExportExcel";
export const GET_CSM_REPORT = "getCsmReport";
export const CSM_EXPORT_EXCEL = "csmExportExcel";
export const GET_CSM_SUMMARY_REPORT = "getCsmSummaryReport";
export const GET_CHECK_SUM = "getCheckSum";
export const SEND_CSM_EMAIL = "sendCsmEmail";
export const CSM_APPROVE_STATUS = "saveCsmApprovalStatus";
export const GET_PMO_REPORT = "getPmoReportData";
export const PMO_EXPORT_EXCEL = "pmoExportExcel";
export const GET_PMO_FILTER = "getReportFilterPmo";
export const GET_ACV_REPORT = "getAcvReportData";
export const ACV_EXPORT_EXCEL = "acvExportExcel";
export const GET_MS_ALLOCATION_REPORT = "getMsAllocationReportData";
export const MS_ALLOCATION_EXPORT_EXCEL = "msAllocationExportExcel";
export const GET_MS_SUMMARY_REPORT = "getMsSummaryReport";
export const MS_POTENTIAL_PROJECT_NAME = "getCurrentMonthProjectName";
export const MS_POTENTIAL_PROJECT_DETAILS = "getCurrentProjectDetails";
export const MS_POTENTIAL_PROJECT_LIST = "getPotentialProjectList";
export const MS_DELETE_CURRENT_POTENTIAL_PROJECT = "deleteCurrentPotentialProject";
export const MS_POTENTIAL_EXPORT_EXCEL = "msPotentialExportExcel";
export const MS_SAVE_CURRENT_POTENTIAL_PROJECT = "saveCurrentPotentialProject";
export const PMS_DASHBOARD = "getPmsDashboard";
export const CHAPTER_LEADER = "getChapterLead";
export const SAVE_QUESTION = "saveQusetion";
export const GET_QUESTION = "getPmsQuestion";
export const DELETE_QUESTION = "deletePmsQuestion";
export const EDIT_QUESTION = "editPmsQuestion";
export const SAVE_ANSWER = "saveAnswer";
export const GET_ANSWER = "getAnswer";
export const ADD_CLASS = "addClass";
export const GET_USER_GRID = "getPmsUserStatus";
export const GET_LEVEL = "getLevel";
export const GET_DATE = "getStartEndDate";
export const GET_DATES = "getDates";
export const SAVE_SETTINGS = "saveSettings";
export const DOWNLOAD_PMS_REPORT = "downloadPmsReport";
export const DOWNLOAD_ZIP = "downloadZip";
export const SAVE_COMMENTS = "saveComments";
export const REPORT_LEAD = "reportLead";
export const ITERATION_COMMENTS = "iterationComments";
export const GET_RSR_REPORT = "getRsrReportData";
export const RSR_EXPORT_EXCEL = "rsrExportExcel";
export const RSR_CHAPTER_LEADER = "getChapterName";
export const EDIT_PROJECT_POTENTIAL = "editProjectPotential";
export const SAVE_TARGET_VALUE = "saveMsSummaryTargetValue";
export const GET_NOTIFICATIONS = "getNotifications";
export const MARK_NOTIFICATION = "markNotification";
export const GET_SQUAD_REPORT = "getSquadReport";
export const SQUAD_EXPORT_EXCEL = "squadExportExcel";
export const UPDATE_UTILIZATION_VALUES = "updateUtilizationValues";
export const SAVE_CHAPTER_ANSWER = "saveChapterAnswer";
export const GET_CHAPTER_INFERENCE = "getChapterInference";
export const SAVE_PULSE_SCORE = "savePulseScore";
export const GET_PULSE_SCORE = "GetPulseScore";
export const SAVE_COMPETENCY_INDEX = "saveCompetencyIndex";
export const GET_COMPETENCY_INDEX = "getCompetencyIndex";
export const GET_PIP = "getPip";
export const GET_ATTRITION = "getAttrition";
export const GET_RECRUITMENT = "getRecruitment";
export const SAVE_RESERVE_ACTIVITY = "saveReserveActivity";
export const GET_RESERVE_ACTIVITY = "getReserveActivity";
export const SAVE_CHAPTER_REVIEW = "saveChapterReviewData";
export const GET_HITS_MISSES = "getHitsMisses";
export const GET_ICR = "getIcr";
export const GET_ACTION_ITEMS = "getActionItems";
export const GET_DEVELOPER_BY_REPORT_ID = "getDevelperByReportId";
export const PMS_EXPORT_EXCEL = "pmsExportExcel";
export const UPDATE_PMS_QUESTION_ORDER = "updatePmsQuestionOrder";
export const GET_USER_LIST = "getUserList";
export const SAVE_PMS_PERMISSION = "savePmsPermission";
export const GET_PMS_PERMISSION = "getPmsPermission";
export const GET_BU_LIST = "getBuList";
export const GET_ALL_SELECTED_BUS = "getAllSelectedBUs";
export const GET_STARTED_SURVEY_BU = "getStartedSurveyBu";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Modal, Button, Form } from "react-bootstrap";
import ProgressBar from 'react-bootstrap/ProgressBar';
import {
    getLoginUserPermission,
    getDevelpersByReportId,
    getPulseScore,
    savePulseScore, 
    saveCompetencyIndex,
    getPip,
    getAttrition, 
    getRecruitment,
    getReserveActivity,
    saveReserveActivity,
    getHitsMisses,
    saveChapterReviewData,
    getIcr,
    getActionItems,
} from "../../Action";
import Select from "react-select";
import Swal from "sweetalert2";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import * as constants from "../../Config/Constant";
import './chapterreview.css';

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  customClass: {
    popup: "colored-toast",
    icon: "white-toast-icon",
  },
  timer: 3000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

class ChapterReviewTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chapterReviewTabNames: ['Pulse Score','Competency Index','ICR','PIP','Attrition','Requirement','Reserve Strength','Hits & Misses','Action Items'],
      surveyNames: ['MES Survey','ACT','Chapter Huddle','One on One','Micro Networking'],
      chapterMonthItems: [],
      chapterMonthItemsTab: [],
      chapterMonthItemsClone: [],
      developers: [],
      filteredDevelopers: [],
      hitsMissesDetailsList: [],
      icrDetailsList:[],
      reserveStrengthDetailsList: [],
      pulseScoreDetails: [],
      isModalOpen: [],
      pulseScoreList: {},
      pipList: {},
      attritionList: {},
      recruitmentList: {},
      hitMissesList: {},
      actionItemsList: {},
      icrList: {},
      reserveActivityList: {},
      competencyDetails: {
        lead: '',
        tools: '',
        AssessmentDate: '',
        AssessmentScore: '',
        DevelopmentLevel: '',
        LearningPath: '',
        PostAssessmentDate: '',
        PostAssessmentScore: '',
      },
      pipDetails: {
        teamSize: 0,
        pipCount: 0,
        level1: 0,
        level2: 0,
        level3: 0,
        pipPercentage: 0,
        comments: '',
      },
      attritionDetails: {
        teamSize: 0,
        noOfNP: 0,
        voluntoryCount: 0,
        nonVoluntoryCount: 0,
        level1: 0,
        level2: 0,
        level3: 0,
        attritionPercentage: 0,
        comments: '',
      },
      recruitmentDetails: {
        noOfOP: 0,
        levels: 0,
        profileSourced: 0,
        interviews: 0,
        shortListed: 0,
        rejected: 0,
      },
      hitsMissesDetails: {
        developers: { id: 0, name: ''},
        hits: '',
        misses: '',
        opportunity: ''
      },
      icrDetails: {
        developers: { id: 0, name: ''},
        level: '',
        total_experience: '',
        skillset: '',
        interview_attended: '',
        interview_cleared: '',
        interview_rejected: '',
        comments: '',
      },
      icrTotalDetails: {
        total_interview_attended: '',
        total_interview_cleared: '',
        total_interview_rejected: ''
      },
      reserveStrengthSummary:{
        strengthValue: 0,
        operations: {
            noOfDev: 0,
        },
        projectSupport: {
            noOfDev: 0,
        },
        learning: {
            noOfDev: 0,
        },
        initiatives: {
            noOfDev: 0,
        }
      },
      reserveStrengthDetails: {
        developers: { id: 0, name: ''},
        bucket: [],
        note: '',
        allocatedHours: 0,
        expectedOutcome: '',
        ETA: '',
        comments: '',
      },
      actionItemDetails: {
        actionItems: '',
        status: '',
        outcome: '',
        comments: '',
      },
      chapterYear: {},
      validationErrors: {},
      chapterMonth: null,
      selectedChapter: null,
      viewMonth: '',
      viewYear: '',
      selectedDeveloper: '',
      defaultStrengthKey: 'reserve_strength_summary',
      defaultActiveKey: 'detailed_view',
      summaryModalName: '',
      summaryModalKey: '',
      reviewIndex: -1,
      developerIndex: 0,
      chapterReviewActiveIndex: 0,
      monthTabIndexActive: 0,
      isStrengthSummaryModal: false,
      loading: false
    };
  }

  componentDidMount(){
    const currentYear = this.getCurrentYearValue().currentYear;

    let pusleScoreValues = { responseRate: 0, score: 0, benchMarkScore: 0, targetScore: 0,comments: '', detailedReportLink: ''};
    let pulseScoreDetails = Array(this.state.surveyNames.length).fill(pusleScoreValues);
    let isModalOpen = Array(this.state.chapterReviewTabNames.length).fill(false);

    this.setState({
        chapterYear: { value: currentYear, label: currentYear},
        pulseScoreDetails,
        isModalOpen
    }, () => {
        this.monthLists(this.state.chapterYear.value, 0);
    });

    let requestData = {
        id : 770
    }
    this.props.getDevelpersByReportId(requestData).then((response) => { 
        let developerList = response.result;
        let hitsMissesDetailsList = [];
        let icrDetailsList = [];
        let reserveStrengthDetailsList = [];

        if(developerList?.length > 0){
            developerList.forEach(developer => {
                hitsMissesDetailsList.push({...this.state.hitsMissesDetails, developers: { id: developer.id, name: developer.first_name}});
                icrDetailsList.push({...this.state.icrDetails, developers: { id: developer.id, name: developer.first_name}});
                reserveStrengthDetailsList.push({...this.state.reserveStrengthDetails, developers: { id: developer.id, name: developer.first_name}});
            });
        }
        this.setState({
            developers: developerList,
            hitsMissesDetails: {...this.state.hitsMissesDetails, developers: { id: developerList?.[0].id, name: developerList?.[0].first_name}},
            hitsMissesDetailsList,
            icrDetails: {...this.state.icrDetails, developers: { id: developerList?.[0].id, name: developerList?.[0].first_name}},
            icrDetailsList,
            reserveStrengthDetails: {...this.state.reserveStrengthDetails, developers: { id: developerList?.[0].id, name: developerList?.[0].first_name}},
            reserveStrengthDetailsList
        });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedChapter!== this.props.selectedChapter) {
        switch (this.state.chapterReviewActiveIndex) {
            case 0: this.getPulseScore(); break;
            case 2: this.getIcr(); break;
            case 3: this.getPip(); break;
            case 4: this.getAttrition(); break;
            case 5: this.getRecruitment(); break;
            case 6: this.getReserveActivity(); break;
            case 7: this.getHitsMisses(); break;
            case 8: this.getActionItems(); break;
            default: this.getPulseScore(); break;
        }
    }
  }

  getCurrentYearValue = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1 > 12 ? 1 : currentDate.getMonth() + 1;
    const currentYear = currentMonth == 12 ? currentDate.getFullYear() + 1 : currentDate.getFullYear();

    return { currentYear : currentYear,  currentMonth: currentMonth};
  }

  /* toast message function  */
  ToastAlert(icon, title) {
    return Toast.fire({
      target: document.getElementById("form-modal"),
      icon: `${icon}`,
      title: `${title}`,
    });
  }
  
  handleChapterReview = (index) =>{
    const currentYear = this.getCurrentYearValue().currentYear;

    this.setState({
        chapterReviewActiveIndex : index,
        chapterMonth: null,
        reviewIndex: -1,
        developerIndex: 0,
        defaultStrengthKey: 'reserve_strength_summary',
        chapterYear: { value: currentYear, label: currentYear},
    }, () =>{
        this.monthLists(this.state.chapterYear.value, 0);
    });
  }
  
  isModalOpen = () =>{
    switch (this.state.chapterReviewActiveIndex) {
        case 0: if(Object.keys(this.state.pulseScoreList).length > 0){ this.setState({ pulseScoreDetails: this.state.pulseScoreList?.review_values }) } ;break;
        case 2: 
        if(this.state.reviewIndex==-1){
            const nonNullDevelopers = this.state.filteredDevelopers.filter(developer => developer !== null);
            if(Object.keys(this.state.filteredDevelopers).length > 0){
                const firstSelectDeveloper = this.state.filteredDevelopers.find(developer => developer !== null);          
                this.setState({   
                    icrDetails: {  developers: { id:  firstSelectDeveloper.id, name:  firstSelectDeveloper.first_name}, level: '', total_experience: '',skillset: '',interview_attended: '',    interview_cleared: '',interview_rejected: '',comments: '' }
                });
            } else {
                this.setState({   
                    icrDetails: {  developers: { id: this.state.developers?.[0].id, name: this.state.developers?.[0].first_name }, level: '', total_experience: '',skillset: '',interview_attended: '',    interview_cleared: '',interview_rejected: '',comments: '' }
                });
            }
        }
        else{
            this.setState({
                icrDetails: this.state.icrList.review_values[this.state.reviewIndex]
            });
        }
        break;
        case 3: 
        if(this.state.reviewIndex==-1){
            this.setState({
                pipDetails: { teamSize: 0, pipCount: 0, level1: 0, level2: 0, level3: 0,pipPercentage: 0, comments: ''}
            });
        }
        else{
            this.setState({
                pipDetails: this.state.pipList.review_values[this.state.reviewIndex]
            });
        }
        break;
        case 4: 
        if(this.state.reviewIndex==-1){
            this.setState({
                attritionDetails: { teamSize: 0, noOfNP: 0, voluntoryCount: 0, nonVoluntoryCount: 0, level1: 0, level2: 0, level3: 0, attritionPercentage: 0,comments: ''}
            });
        }
        else{
            this.setState({
                attritionDetails: this.state.attritionList.review_values[this.state.reviewIndex]
            });
        }
        break;
        case 5: 
        if(this.state.reviewIndex==-1){
            this.setState({
                recruitmentDetails: { noOfOP: 0, levels: 0, profileSourced: 0, interviews: 0, shortListed: 0, rejected: 0 }
            });
        }
        else{
            this.setState({
                recruitmentDetails: this.state.recruitmentList.review_values[this.state.reviewIndex]
            });
        }
        break;
        case 6: 
        if(this.state.reviewIndex==-1){
            this.setState({
                reserveStrengthDetails: { developers: { id: this.state.developers?.[0].id, name: this.state.developers?.[0].first_name}, bucket: [], note: '', allocatedHours: 0, expectedOutcome: '', ETA: '', comments: ''}
            });
        }
        else{
            this.setState({
                reserveStrengthDetails: this.state.reserveActivityList.review_values.details[this.state.reviewIndex]
            });
        }
        break;
        case 7: 
        if(this.state.reviewIndex==-1){
            this.setState({
                hitsMissesDetails: { developers: { id: this.state.developers?.[0].id, name: this.state.developers?.[0].first_name}, hits: '', misses: '',opportunity: ''}
            });
        }
        else{
            this.setState({
                hitsMissesDetails: this.state.hitMissesList.review_values[this.state.reviewIndex]
            });
        }
        break;
        case 8: 
        if(this.state.reviewIndex==-1){
            this.setState({
                actionItemDetails: { actionItems: '', status: '', outcome: '', comments: '', }
            });
        }
        else{
            this.setState({
                actionItemDetails: this.state.actionItemsList.review_values[this.state.reviewIndex]
            });
        }
        break;
        default: break;
    }

    let isModalOpen = [...this.state.isModalOpen];
    isModalOpen[this.state.chapterReviewActiveIndex] = true;
    this.setState({ isModalOpen });
  }
  
  isModalClose = () =>{
    switch (this.state.chapterReviewActiveIndex) {
        case 2: 
        if(this.state.developerIndex > -1){ this.getIcr() };
        this.setState({reviewIndex: -1});
        break;
        case 6: if(this.state.developerIndex > -1){ this.getReserveActivity() } ; break;
        case 7: if(this.state.developerIndex > -1){ this.getHitsMisses() } ; break;
        default: break;
    }

    let isModalOpen = [...this.state.isModalOpen];
    isModalOpen = isModalOpen.fill(false);
    this.setState({ isModalOpen, validationErrors: {}});
  }

  openSummaryModal = (modalKey, modalName) =>{
    this.setState({
        isStrengthSummaryModal: true,
        summaryModalName: modalName,
        summaryModalKey: modalKey, 
        validationErrors: {}
    });
  }
  
  filterMonthHandler = (e) =>{
    this.state.chapterMonthItemsTab = this.state.chapterMonthItemsClone;
    let chapterMonthItemsTab = e ? this.state.chapterMonthItemsTab.filter(mItems => mItems == e) : this.state.chapterMonthItemsClone;
    
    this.setState({
      monthTabIndexActive: 0,
      chapterMonth: e,
      chapterMonthItemsTab
    },() =>{
        switch (this.state.chapterReviewActiveIndex) {
            case 0: this.getPulseScore(); break;
            case 2: this.getIcr(); break;
            case 3: this.getPip(); break;
            case 4: this.getAttrition(); break;
            case 5: this.getRecruitment(); break;
            case 6: this.getReserveActivity(); break;
            case 7: this.getHitsMisses(); break;
            case 8: this.getActionItems(); break;
            default: this.getPulseScore(); break;
        }
    });
  }
  
  filterYearHandler = (e) =>{
    this.setState({
      chapterYear: e,
      chapterMonth: null,
    });
    this.monthLists(e.value, 0);
  }
  
  monthChangeHandler = (index) =>{
    this.setState({
        monthTabIndexActive: index
    }, () => {
        switch (this.state.chapterReviewActiveIndex) {
            case 0: this.getPulseScore(); break;
            case 2: this.getIcr(); break;
            case 3: this.getPip(); break;
            case 4: this.getAttrition(); break;
            case 5: this.getRecruitment(); break;
            case 6: this.getReserveActivity(); break;
            case 7: this.getHitsMisses(); break;
            case 8: this.getActionItems(); break;
            default: this.getPulseScore(); break;
        }
    });
  }
  clearFilter = () => {
    const currentMonth = this.getCurrentYearValue().currentMonth;
    const currentYear = this.getCurrentYearValue().currentYear;
    
    this.setState({  
      chapterYear: { value: currentYear, label: currentYear},
      chapterMonth: null,
    });
      this.monthLists(this.state.chapterYear.value, 0);
  }
  validateForm = (inputValue, sName) => {
    if (inputValue.trim() !== "" && !/^\d+$/.test(inputValue)) {
        let errorMessage = "Please enter a valid number.";
        this.setState((prevState) =>({
            validationErrors: {
                ...prevState.validationErrors,
                [sName]: errorMessage,
            }
        }));
        return false;
    }
    else{
        return isNaN(parseInt(inputValue)) ? 0 : parseInt(inputValue);
    }
  }
  
  handleDeveloperChange = (event) => {   
    const selectedOptionValue = event.target.value;
    const selectedOptionKey = event.target.selectedOptions[0].id;
    this.setState({ selectedDeveloper: { id: selectedOptionKey, name: selectedOptionValue } });
  }

  inputChangeHandler = (e, sName, sIndex) =>{
    let inputValue = sName == 'developers' ? {id: e.target.selectedOptions[0].id, name: e.target.value} : (sName=='bucket' ? e : e.target.value);
    let numberInputs = ['responseRate', 'score', 'benchMarkScore', 'targetScore', 'teamSize', 'pipCount', 'level1', 'level2', 'level3', 'pipPercentage', 'noOfNP', 'voluntoryCount', 'nonVoluntoryCount', 'attritionPercentage', 'noOfOP', 'levels', 'profileSourced', 'interviews', 'shortListed', 'rejected', 'allocatedHours','level','total_experience','interview_attended','interview_rejected','interview_cleared', 'strengthValue', 'operations', 'projectSupport', 'learning', 'initiatives'];
    let value = numberInputs.includes(sName) ? this.validateForm(inputValue, sName) :inputValue;
    
    if(value !== false){
        let pulseScoreDetails = [...this.state.pulseScoreDetails];
        let pipDetails = {...this.state.pipDetails};
        let attritionDetails = {...this.state.attritionDetails};
        let recruitmentDetails = {...this.state.recruitmentDetails};
        let reserveStrengthDetails = {...this.state.reserveStrengthDetails};
        let reserveStrengthDetailsList = [...this.state.reserveStrengthDetailsList]; 
        let reserveStrengthSummary = {...this.state.reserveStrengthSummary}; 
        let hitsMissesDetails = {...this.state.hitsMissesDetails};
        let hitsMissesDetailsList = [...this.state.hitsMissesDetailsList];
        let icrDetailsList = [...this.state.icrDetailsList];
        let icrDetails = {...this.state.icrDetails};
        let actionItemDetails = {...this.state.actionItemDetails};

        switch (this.state.chapterReviewActiveIndex) {
            case 0:
                pulseScoreDetails = pulseScoreDetails.map((pScore, index) =>
                    index == sIndex ? { ...pScore, [sName]: value } : pScore
                );
                break;
             case 2:
                icrDetails = {...icrDetails, [sName]: value};
                icrDetailsList[this.state.developerIndex] = icrDetails;
                break;
            case 3:
                pipDetails = {...pipDetails, [sName]: value};
                break;
            case 4:
                attritionDetails = {...attritionDetails, [sName]: value};
                break;
            case 5:
                recruitmentDetails = {...recruitmentDetails, [sName]: value};
                break;
            case 6:
                if(this.state.isStrengthSummaryModal){
                    ['operations','projectSupport','learning','initiatives'].includes(this.state.summaryModalKey) ? reserveStrengthSummary =  {...reserveStrengthSummary, [sName]: {noOfDev: value}} : reserveStrengthSummary = {...reserveStrengthSummary, [sName]: value};
                }
                else{
                    reserveStrengthDetails = {...reserveStrengthDetails, [sName]: value};
                    reserveStrengthDetailsList[this.state.developerIndex] = reserveStrengthDetails;
                }
                break;
            case 7:
                hitsMissesDetails = {...hitsMissesDetails, [sName]: value};
                hitsMissesDetailsList[this.state.developerIndex] = hitsMissesDetails;
                break;
            case 8:
                actionItemDetails = {...actionItemDetails, [sName]: value};
                break;
        }
        
        this.setState((prevState) =>({
            validationErrors: {
                ...prevState.validationErrors,
                [sName]: null,
            },
            pulseScoreDetails,
            pipDetails,
            attritionDetails,
            recruitmentDetails,
            reserveStrengthDetails,
            reserveStrengthSummary,
            reserveStrengthDetailsList,
            hitsMissesDetails,
            hitsMissesDetailsList,
            icrDetails,
            icrDetailsList,
            actionItemDetails
        }));
    }
  }

  competencyinputChangeHandler = (event) => {
    const { name, value } = event.target;
    let numberInputs = ['AssessmentScore', 'DevelopmentLevel', 'PostAssessmentScore'];
    let valueCheck = numberInputs.includes(name) ? this.validateForm(value, name) :value;
    if(valueCheck !== false){
        this.setState(prevState => ({
            competencyDetails: {
                ...prevState.competencyDetails,
                [name]: value
            }
        }));
        this.setState((prevState) =>({
            validationErrors: {
                ...prevState.validationErrors,
                [name]: null,
            }
        }));
    } 
  }

  monthLists = (reqYear,reqMonth) =>{
    const currentMonth = this.getCurrentYearValue().currentMonth;
    const currentYear = this.getCurrentYearValue().currentYear;
    reqYear = currentMonth == 0 ? reqYear + 1 : reqYear;
    let lastCondMonth = reqMonth != 0 ? new Date(reqMonth).getMonth() + 1 : 0;
    let lastCondYear = reqYear;
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const months = [];
    for(let cyear = reqYear; cyear <= lastCondYear; cyear++){
      const startMonth = lastCondMonth == 0 ? (cyear == 2024 ? 4 : 1) : lastCondMonth;
      const lastMonth = lastCondMonth == 0 ? (cyear == currentYear ? currentMonth + 1 : (currentMonth == 0 ? 1 : 12)) : lastCondMonth;
      for(let month = startMonth; month <= lastMonth; month++) {
        months.push({
          year: cyear,
          value: month,
          label: monthNames[month - 1],
        });
      }
    }
    this.setState({
      chapterMonthItems: [...months],
      chapterMonthItemsTab: [...months],
      chapterMonthItemsClone: [...months],
    }, () => {
        switch (this.state.chapterReviewActiveIndex) {
            case 0: this.getPulseScore(); break;
            case 2: this.getIcr(); break;
            case 3: this.getPip(); break;
            case 4: this.getAttrition(); break;
            case 5: this.getRecruitment(); break;
            case 6: this.getReserveActivity(); break;
            case 7: this.getHitsMisses(); break;
            case 8: this.getActionItems(); break;
            default: this.getPulseScore(); break;
        }
    });
  }

  changeStrengthTabView = (tabName) =>{
    this.setState({
        defaultStrengthKey: tabName
    });
  }

  getPulseScore = () =>{
    this.setState({ loading: true});
    let { chapterYear, chapterMonthItemsTab, monthTabIndexActive} = this.state;

    let postData = {
        data: {
            chapterYear: chapterYear.value,
            chapterMonth: chapterMonthItemsTab[monthTabIndexActive]?.value, 
            chapter: this.props.selectedChapter
        }
    }

    this.props.getPulseScore(postData)
    .then((response) =>{
        if(this.props.pulseScoreLists?.review_values){
            this.setState({ 
                loading: false,
                pulseScoreList: this.props.pulseScoreLists,
                pulseScoreDetails: this.props.pulseScoreLists.review_values
            });
        }
        else{
            let pusleScoreValues = { responseRate: 0, score: 0, benchMarkScore: 0, targetScore: 0,comments: '', detailedReportLink: ''};
            let pulseScoreDetails = Array(this.state.surveyNames.length).fill(pusleScoreValues);
            this.setState({ 
                loading: false,
                pulseScoreList: {},
                pulseScoreDetails
            });
        }
    }, (error) => {
        this.setState({ loading: false});
        this.ToastAlert("error", "Something went wrong.");
    });
  }
  
  getPip = () =>{
    this.setState({ loading: true});
    let { chapterYear, chapterMonthItemsTab, monthTabIndexActive} = this.state;

    let postData = {
        data: {
            chapterYear: chapterYear.value,
            chapterMonth: chapterMonthItemsTab[monthTabIndexActive]?.value, 
            chapter: this.props.selectedChapter
        }
    }

    this.props.getPip(postData)
    .then((response) =>{
        if(this.props.pipLists?.review_values){
            this.setState({ 
                loading: false,
                pipList: this.props.pipLists,
                pipDetails: this.props.pipLists.review_values
            });
        }
        else{
            let pipDetails =  { teamSize: 0, pipCount: 0, level1: 0, level2: 0, level3: 0,pipPercentage: 0,comments: ''};
            this.setState({ 
                loading: false,
                pipList: {},
                pipDetails
            });
        }
    }, (error) => {
        this.setState({ loading: false});
        this.ToastAlert("error", "Something went wrong.");
    });
  }
  
  getAttrition = () =>{
    this.setState({ loading: true});
    let { chapterYear, chapterMonthItemsTab, monthTabIndexActive} = this.state;

    let postData = {
        data: {
            chapterYear: chapterYear.value,
            chapterMonth: chapterMonthItemsTab[monthTabIndexActive]?.value, 
            chapter: this.props.selectedChapter
        }
    }

    this.props.getAttrition(postData)
    .then((response) =>{
        if(this.props.attritionLists?.review_values){
            this.setState({ 
                loading: false,
                attritionList: this.props.attritionLists,
                attritionDetails: this.props.attritionLists.review_values
            });
        }
        else{
            let attritionDetails = { teamSize: 0, noOfNP: 0, voluntoryCount: 0,
                nonVoluntoryCount: 0, level1: 0, level2: 0, level3: 0, attritionPercentage: 0,comments: ''};
            this.setState({ 
                loading: false,
                attritionList: {},
                attritionDetails
            });
        }
    }, (error) => {
        this.setState({ loading: false});
        this.ToastAlert("error", "Something went wrong.");
    });
  } 
  
  getRecruitment = () =>{
    this.setState({ loading: true});
    let { chapterYear, chapterMonthItemsTab, monthTabIndexActive} = this.state;

    let postData = {
        data: {
            chapterYear: chapterYear.value,
            chapterMonth: chapterMonthItemsTab[monthTabIndexActive]?.value, 
            chapter: this.props.selectedChapter
        }
    }

    this.props.getRecruitment(postData)
    .then((response) =>{
        if(this.props.recruitmentLists?.review_values){
            this.setState({ 
                loading: false,
                recruitmentList: this.props.recruitmentLists,
                recruitmentDetails: this.props.recruitmentLists.review_values
            });
        }
        else{
            let recruitmentDetails = { noOfOP: 0, levels: 0, profileSourced: 0, interviews: 0, shortListed: 0, rejected: 0};
            this.setState({ 
                loading: false,
                recruitmentList: {},
                recruitmentDetails
            });
        }
    }, (error) => {
        this.setState({ loading: false});
        this.ToastAlert("error", "Something went wrong.");
    });
  }
  
  getReserveActivity = () =>{
    this.setState({ loading: true});
    let { chapterYear, chapterMonthItemsTab, monthTabIndexActive} = this.state;

    let postData = {
        data: {
            chapterYear: chapterYear.value,
            chapterMonth: chapterMonthItemsTab[monthTabIndexActive]?.value, 
            chapter: this.props.selectedChapter
        }
    }

    this.props.getReserveActivity(postData)
    .then((response) =>{
        if(this.props.reserveActivityLists?.review_values){
            let reserveStrengthDetails =  { developers: { id: 0, name: ''}, bucket: [], note: '', allocatedHours: 0, expectedOutcome: '', ETA: '', comments: '' };
            if(this.props.reserveActivityLists.review_values?.details){
                reserveStrengthDetails = this.props.reserveActivityLists.review_values?.details[0];
            }

            this.setState({ 
                loading: false,
                reserveActivityList: this.props.reserveActivityLists,
                reserveStrengthSummary: this.props.reserveActivityLists.review_values.summary,
                reserveStrengthDetails,
            });
        }
        else{
            let reserveStrengthDetails = { developers: { id: 0, name: ''}, bucket: [], note: '', allocatedHours: 0, expectedOutcome: '', ETA: '', comments: '' };

            let reserveStrengthSummary = { strengthValue: 0, operations: { noOfDev: 0}, projectSupport: { noOfDev: 0}, learning: { noOfDev: 0}, initiatives: { noOfDev: 0} };

            this.setState({ 
                loading: false,
                reserveActivityList: {},
                reserveStrengthDetails,
                reserveStrengthSummary
            });
        }
    }, (error) => {
        this.setState({ loading: false});
        this.ToastAlert("error", "Something went wrong.");
    });
  }
  
  getHitsMisses = () =>{
    this.setState({ loading: true});
    let { chapterYear, chapterMonthItemsTab, monthTabIndexActive} = this.state;

    let postData = {
        data: {
            chapterYear: chapterYear.value,
            chapterMonth: chapterMonthItemsTab[monthTabIndexActive]?.value, 
            chapter: this.props.selectedChapter
        }
    }

    this.props.getHitsMisses(postData)
    .then((response) =>{
        if(this.props.hitMissesLists?.review_values){
            this.setState({ 
                loading: false,
                hitMissesList: this.props.hitMissesLists,
                hitsMissesDetails: this.props.hitMissesLists.review_values
            });
        }
        else{
            let hitsMissesDetails = { developers: { id: 0, name: ''}, hits: '', misses: '', opportunity: ''};
            this.setState({ 
                loading: false,
                hitMissesList: {},
                hitsMissesDetails
            });
        }
    }, (error) => {
        this.setState({ loading: false});
        this.ToastAlert("error", "Something went wrong.");
    });
  }

  getIcr = () =>{
    this.setState({ loading: true});
    let { chapterYear, chapterMonthItemsTab, monthTabIndexActive} = this.state;

    let postData = {
        data: {
            chapterYear: chapterYear.value,
            chapterMonth: chapterMonthItemsTab[monthTabIndexActive]?.value, 
            chapter: this.props.selectedChapter
        }
    }

    this.props.getIcr(postData)
    .then((response) =>{
        if(this.props.icrLists?.review_values){
            this.setState({ 
                loading: false,
                icrList: this.props.icrLists,
                icrDetails: this.props.icrLists.review_values,
                icrTotalDetails: this.props.icrLists.total
            });
            let removedId = [];
           let review_values = this.props.icrLists.review_values 
            review_values.forEach((review_value,inc) => {
               removedId[inc] = Number(review_value.developers.id);
            })
            const filteredDevelopers = this.state.developers.map(developer => removedId.includes(developer.id) ? null : developer);
            const allNull = filteredDevelopers.every(developer => developer === null);
            if(allNull){
                this.setState({filteredDevelopers : [] });
            } else {
                this.setState({filteredDevelopers : filteredDevelopers });
            }
            
        }
        else{
            let icrDetails = {  developers: { id: 0, name: ''}, level: '', total_experience: '',skillset: '',interview_attended: '',    interview_cleared: '',interview_rejected: '',comments: '' };
            let icrTotalDetails = { total_interview_attended: '', total_interview_cleared: '',total_interview_rejected: ''}
            this.setState({ 
                loading: false,
                icrList: {},
                filteredDevelopers: [],
                icrDetails,
                icrTotalDetails
            });
        }
    }, (error) => {
        this.setState({ loading: false});
        this.ToastAlert("error", "Something went wrong.");
    });
  }

  getActionItems = () =>{
    this.setState({ loading: true});
    let { chapterYear, chapterMonthItemsTab, monthTabIndexActive} = this.state;

    let postData = {
        data: {
            chapterYear: chapterYear.value,
            chapterMonth: chapterMonthItemsTab[monthTabIndexActive]?.value, 
            chapter: this.props.selectedChapter
        }
    }

    this.props.getActionItems(postData)
    .then((response) =>{
        if(this.props.actionItemsLists?.review_values){
            this.setState({ 
                loading: false,
                actionItemsList: this.props.actionItemsLists,
                actionItemDetails: this.props.actionItemsLists.review_values
            });
        }
        else{
            let actionItemDetails = { actionItems: '', status: '', outcome: '', comments: ''};
            this.setState({ 
                loading: false,
                actionItemsList: {},
                actionItemDetails
            });
        }
    }, (error) => {
        this.setState({ loading: false});
        this.ToastAlert("error", "Something went wrong.");
    });
  }

  changeIcrTabView = (tabName) => {
    this.setState({
      defaultActiveKey: tabName,
    });
  };

  setIndexValue = (rIndex) =>{
    this.setState({
        reviewIndex: rIndex
    }, () =>{
        this.isModalOpen();
    });
  }

  handlePrevious = (event) =>{
    let developerIndex = this.state.developerIndex;
    developerIndex -= 1;
    let hitsMissesDetails = this.state.hitsMissesDetailsList[developerIndex];
    let icrDetails = this.state.icrDetailsList[developerIndex];
    let reserveStrengthDetails = this.state.reserveStrengthDetailsList[developerIndex];

    this.setState({
        developerIndex,
        hitsMissesDetails,
        icrDetails,
        reserveStrengthDetails
    });
  }

  handleSubmit = (event) =>{
    event.preventDefault();
    if(Object.values(this.state.validationErrors).every(value => value && value !== null)){
        return;
    }

    let { chapterYear, chapterMonthItemsTab, chapterReviewActiveIndex, monthTabIndexActive, pulseScoreDetails, pipDetails, attritionDetails, recruitmentDetails, hitsMissesDetails,reserveStrengthSummary, reserveStrengthDetails, icrDetails, actionItemDetails, reviewIndex } = this.state;
    
    let reviewValues = [];
    const formData = {
        id: this.state.selectedDeveloper.id,
        developer: this.state.selectedDeveloper.name,
        ...this.state.competencyDetails           
    };

    switch (chapterReviewActiveIndex) {
        case 0: reviewValues = pulseScoreDetails; break;
        case 1: reviewValues = formData; break;
        case 2: reviewValues = icrDetails; break;
        case 3: reviewValues = pipDetails; break;
        case 4: reviewValues = attritionDetails; break;
        case 5: reviewValues = recruitmentDetails; break;
        case 6: reviewValues = {summary: reserveStrengthSummary, details: reserveStrengthDetails}; break;
        case 7: reviewValues = hitsMissesDetails; break;
        case 8: reviewValues = actionItemDetails; break;
        default: reviewValues = pulseScoreDetails; break;
    }

    let postData = {
        data : {
            chapterYear: chapterYear?.value, 
            chapterMonth: chapterMonthItemsTab[monthTabIndexActive]?.value, 
            detailsType: chapterReviewActiveIndex, 
            reviewIndex: reviewIndex, 
            reviewValues, 
        }
    }

    if(chapterReviewActiveIndex == 0){
        this.setState({ loading: true});
        this.props.savePulseScore(postData)
        .then((response) =>{
            this.getPulseScore();
            this.isModalClose();
            this.ToastAlert("success", "Successfully Submitted.");
        }, (error) => {
            this.setState({ loading: false});
            this.ToastAlert("error", "Unable to submit the data.");
        });
    }
    else if(chapterReviewActiveIndex == 1){
        this.props.saveCompetencyIndex(postData)
        .then((response) =>{
            this.ToastAlert("success", "Successfully Submitted.");
        }, (error) => {
            this.setState({ loading: false});
            this.ToastAlert("error", "Unable to submit the data.");
        });
    }
    else if(chapterReviewActiveIndex == 2){
        if(Object.keys(this.state.icrList).length > 0 || this.state.reviewIndex > -1){
            this.setState({ loading: true});
        }
        else{
            let developerIndex = this.state.developerIndex;
            developerIndex += 1;

            let icrDetails = this.state.icrDetailsList[developerIndex];

            this.setState({
                developerIndex,
                icrDetails
            });
        }        
        this.props.saveChapterReviewData(postData)
        .then((response) =>{
            if(Object.keys(this.state.icrList).length > 0 || this.state.reviewIndex > -1){
                this.getIcr();
                this.isModalClose();
                this.ToastAlert("success", "Successfully Submitted.");
            }
        }, (error) => {
            this.setState({ loading: false});
            this.ToastAlert("error", "Unable to submit the data.");
        });
    }
    else if(chapterReviewActiveIndex == 3){
        this.setState({ loading: true});
        this.props.saveChapterReviewData(postData)
        .then((response) =>{
            this.getPip();
            this.isModalClose();
            this.ToastAlert("success", "Successfully Submitted.");
        }, (error) => {
            this.setState({ loading: false});
            this.ToastAlert("error", "Unable to submit the data.");
        });
    }
    else if(chapterReviewActiveIndex == 4){
        this.setState({ loading: true});
        this.props.saveChapterReviewData(postData)
        .then((response) =>{
            this.getAttrition();
            this.isModalClose();
            this.ToastAlert("success", "Successfully Submitted.");
        }, (error) => {
            this.setState({ loading: false});
            this.ToastAlert("error", "Unable to submit the data.");
        });
    }
    else if(chapterReviewActiveIndex == 5){
        this.setState({ loading: true});
        this.props.saveChapterReviewData(postData)
        .then((response) =>{
            this.getRecruitment();
            this.isModalClose();
            this.ToastAlert("success", "Successfully Submitted.");
        }, (error) => {
            this.setState({ loading: false});
            this.ToastAlert("error", "Unable to submit the data.");
        });
    }
    else if(chapterReviewActiveIndex == 6){
        if(this.state.reserveActivityList?.review_values?.details?.length > 0 || this.state.reviewIndex > -1){
            this.setState({ loading: true});
        }
        else if(!this.state.isStrengthSummaryModal){
            let developerIndex = this.state.developerIndex;
            developerIndex += 1;

            let reserveStrengthDetails = this.state.reserveStrengthDetailsList[developerIndex];

            this.setState({
                developerIndex,
                reserveStrengthDetails
            });
        }

        this.props.saveReserveActivity(postData)
        .then((response) =>{
            this.setState({isStrengthSummaryModal: false });
            if(this.state.reserveActivityList?.review_values?.details?.length > 0 || this.state.reviewIndex > -1){
                this.getReserveActivity();
                this.isModalClose();
                this.ToastAlert("success", "Successfully Submitted.");
            }
        }, (error) => {
            this.setState({ loading: false});
            this.ToastAlert("error", "Unable to submit the data.");
        });
    }
    else if(chapterReviewActiveIndex == 7){
        if(Object.keys(this.state.hitMissesList).length > 0 || this.state.reviewIndex > -1){
            this.setState({ loading: true});
        }
        else{
            let developerIndex = this.state.developerIndex;
            developerIndex += 1;

            let hitsMissesDetails = this.state.hitsMissesDetailsList[developerIndex];

            this.setState({
                developerIndex,
                hitsMissesDetails
            });
        }

        this.props.saveChapterReviewData(postData)
        .then((response) =>{
            if(Object.keys(this.state.hitMissesList).length > 0 || this.state.reviewIndex > -1){
                this.getHitsMisses();
                this.isModalClose();
                this.ToastAlert("success", "Successfully Submitted.");
            }
        }, (error) => {
            this.setState({ loading: false});
            this.ToastAlert("error", "Unable to submit the data.");
        });
    }
    else if(chapterReviewActiveIndex == 8){
        this.setState({ loading: true});
        this.props.saveChapterReviewData(postData)
        .then((response) =>{
            this.getActionItems();
            this.isModalClose();
            this.ToastAlert("success", "Successfully Submitted.");
        }, (error) => {
            this.setState({ loading: false});
            this.ToastAlert("error", "Unable to submit the data.");
        });
    }
  }

  render() {
    const { chapterReviewTabNames, chapterReviewActiveIndex, isModalOpen, isStrengthSummaryModal, surveyNames, chapterYear, chapterMonthItems, chapterMonthItemsTab, chapterMonth, monthTabIndexActive,reviewIndex, developerIndex,  defaultStrengthKey, developers, pulseScoreList, pipList, attritionList, recruitmentList, reserveActivityList, hitMissesList, icrList,  actionItemsList, pulseScoreDetails, pipDetails, attritionDetails, recruitmentDetails, reserveStrengthSummary,reserveStrengthDetails, summaryModalKey, summaryModalName, hitsMissesDetails, icrDetails, icrTotalDetails, actionItemDetails} = this.state;

    const roleId = this.props.loginUserData?.role_id;
    const hitMissessListLength = Object.keys(hitMissesList).length;
    const btnWidth = hitMissessListLength==0 ? '150px' : '230px';
    
    const reserveActivityListLength = reserveActivityList?.review_values?.details?.length;
    const btnReserveWidth = !reserveActivityListLength ? '150px' : '230px';

    const icrListLength = Object.keys(icrList).length;
    const btnIcrWidth = icrListLength==0 ? '150px' : '230px';

    const buckets = [{value: 'operations',label: 'Operations'},{value: 'projectSupport',label: 'Project Support'},{value: 'learning',label: 'Learning'},{value: 'initiatives', label: 'Initiatives'}];

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1 > 12 ? 1 : currentDate.getMonth() + 1;
    const currentYear = currentMonth == 12 ? currentDate.getFullYear() + 1 : currentDate.getFullYear();
    const filterYears = [{value: currentYear, label: currentYear}];
    
    if(currentYear > 2024 && currentYear < 2026){ 
      filterYears.push({value: currentYear - 1, label: currentYear - 1 }); 
    }
    else if(currentYear >= 2026){
      filterYears.push({value: currentYear - 1, label: currentYear - 1 }); 
      filterYears.push({value: currentYear - 2, label: currentYear - 2 }); 
    }
    
    return (
        <div className="col-sm-4 col-sm-4 col-xs-12 col-md-12 text-left">
            {this.state.loading && (
              <div id="preloader">
                <div id="status"></div>
              </div>
            )}

            <div className="project-list text-left custom-li-size m-t-25">
                <ul className="p-l-0 d-flex text-center">
                    { chapterReviewTabNames.map((names, index) => (
                        <li
                            className={`B-G-bold f-s-12 px-3 mr-5 py-2 c-pointer ${index === chapterReviewActiveIndex
                            ? "pr-l-active"
                            : "pr-l-gray-transparent"
                            }`}
                            onClick={() => this.handleChapterReview(index)}
                        >
                            { names }
                        </li>
                        ))
                    }
                </ul>
            </div>
            <Row
                xs={2}
                md={4}
                lg={6}
                className="bgwhite service-container p-2 m-t-25 mx-0 pb-3 multiselect-scrollbar"
            >
                <Col className={`text-left d-flex flex-column w-25 text-start justify-content-end `}>
                    <label className="m-b-0 fs-12 p-t-5 style-label p-b-0 m-r-10">
                        Filter By Year
                    </label>
                    <div className="selectpicker_dropdown w-100 hide_bar w-100 selectpicker_dropdown_orange">
                        <Select
                            className="basic-single-select"
                            classNamePrefix={"select_dropdown"}
                            placeholder="All"
                            onChange={(e) => this.filterYearHandler(e)}
                            value={chapterYear}
                            options={filterYears}
                        ></Select>
                    </div>
                </Col>
                <Col className={`text-left d-flex flex-column w-25 text-start justify-content-end `}>
                    <label className="m-b-0 fs-12 p-t-5 style-label p-b-0 m-r-10">
                        Time Period
                    </label>
                    <div className="selectpicker_dropdown w-100 hide_bar w-100 selectpicker_dropdown_orange">
                        <Select
                            className="basic-single-select"
                            classNamePrefix={"select_dropdown"}
                            placeholder="Month"
                            onChange={(e) => this.filterMonthHandler(e)}
                            isClearable={true}
                            value={chapterMonth}
                            options={chapterMonthItems}
                        ></Select>
                    </div>
                </Col>
                <Col className={`text-left d-flex flex-column w-15 text-start justify-content-end `}>                  
                    <a href="javascript:void(0)" onClick={this.clearFilter} className="p-0 B-G-bold f-s-18 m-t-3 m-b-10 page-head"><i class="fs-16 fa fa-refresh text-theme" title="Clear Filter"></i></a>
                </Col>
                <Col className={`text-left d-flex flex-column w-35 text-end justify-content-end`}>
                    <label className="m-b-0 fs-15 p-t-5 m-r-10">
                       { chapterReviewActiveIndex == 0 && ![1, 8, 10, constants.HR].includes(roleId) &&
                            <>
                                {(Object.keys(pulseScoreList).length === 0) && (
                                    <a href="javascript:void(0)" className="btn-add" onClick={this.isModalOpen}>Create New Pulse Score <span>+</span></a>
                                )}
                                {(Object.keys(pulseScoreList).length > 0) && (
                                    <a href="javascript:void(0)" className="btn-add" onClick={this.isModalOpen}>Edit Pulse Score <i className="icon-pencil text-orange"></i></a>
                                )}
                           </>
                        }
                        { chapterReviewActiveIndex == 2 && (icrList?.review_values?.length == 0 || icrList?.review_values?.length != developers.length) && ![1, 8, 10, constants.HR].includes(roleId) &&
                            <a href="javascript:void(0)" className="btn-add" onClick={this.isModalOpen}>Create New ICR <span>+</span></a>
                        }
                        { chapterReviewActiveIndex == 1 && ![1, 8, 10, constants.HR].includes(roleId) &&
                            <a href="javascript:void(0)" className="btn-add" onClick={this.isModalOpen}>Create New Competency Index <span>+</span></a>
                        }

                        { chapterReviewActiveIndex == 3 && ![1, 8, 10, constants.HR].includes(roleId) &&
                            <a href="javascript:void(0)" className="btn-add" onClick={() => this.setIndexValue(-1)}>Create New PIP <span>+</span></a>
                        }
                        { chapterReviewActiveIndex == 4 && ![1, 8, 10, constants.HR].includes(roleId) &&
                            <a href="javascript:void(0)" className="btn-add" onClick={() => this.setIndexValue(-1)}>Create New Attrition <span>+</span></a>
                        }
                        { chapterReviewActiveIndex == 5 && ![1, 8, 10, constants.HR].includes(roleId) &&
                            <a href="javascript:void(0)" className="btn-add" onClick={() => this.setIndexValue(-1)}>Create New Recruitment <span>+</span></a>
                        }
                        { chapterReviewActiveIndex == 6 && ![1, 8, 10, constants.HR].includes(roleId) &&defaultStrengthKey=='reserve_strength_activities' &&
                            <a href="javascript:void(0)" className="btn-add" onClick={() => this.setIndexValue(-1)}>Create New Activity <span>+</span></a>
                        }
                        { chapterReviewActiveIndex == 7 && ![1, 8, 10, constants.HR].includes(roleId) &&
                            <a href="javascript:void(0)" className="btn-add" onClick={() => this.setIndexValue(-1)}>Create Hits & Misses <span>+</span></a>
                        }
                        { chapterReviewActiveIndex == 8 && ![1, 8, 10, constants.HR].includes(roleId) &&
                            <a href="javascript:void(0)" className="btn-add" onClick={() => this.setIndexValue(-1)}>Create New Action Item <span>+</span></a>
                        }
                    </label>
                </Col>
            </Row>
            <div>
                <Row className="my-4 chapter-filter">
                    <Col className="d-flex">
                    { chapterMonthItemsTab.map((mItem, index) => (
                        <div
                            key={index}
                            className={`level-filter ${index==monthTabIndexActive ? 'active' : '' }`}
                            onClick={() => this.monthChangeHandler(index)}
                        >
                            {mItem.label} {mItem.year}
                        </div> 
                        ))
                    }
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col className="col-md-8 chapter-progress">
                        { chapterReviewActiveIndex == 0 && (
                            <div>
                                <label className="fs-16 font-weight-bold">Average Pulse Score<span>{pulseScoreList?.average_pulse_score || 0}%</span></label>
                                <ProgressBar now={pulseScoreList?.average_pulse_score || 0} variant="warning" />
                            </div>
                        )}
                    </Col>
                </Row>
                <Row>
                    <div className="col-md-12">
                        <div className="scrollable table-responsive white-table-class m-t-4 p-0">
                            <div className="scrollable-content">
                                { chapterReviewActiveIndex == 0 && (
                                    <table className="table m-b-0">
                                        <thead>
                                            <tr className="theadInner default-bg">
                                                <th className="text-start">
                                                    Sno
                                                </th>
                                                <th className="text-start">
                                                    Pulse Metrics
                                                </th>
                                                <th className="text-start">
                                                    Response Rate/Attendance
                                                </th>
                                                <th className="text-start">
                                                    Score
                                                </th>
                                                <th className="text-start">
                                                    Bench Mark Score
                                                </th>
                                                <th className="text-start">
                                                    Target Score
                                                </th>
                                                <th className="text-start">
                                                    Comments
                                                </th>
                                                <th className="text-start">
                                                    Detailed Report Link
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>   
                                        { Object.keys(pulseScoreList).length === 0 && (
                                            <tr className="table-row">
                                                <td className="table-cell text-center" colSpan="8">
                                                    No data available
                                                </td>
                                            </tr>
                                        )}  
                                        { pulseScoreList && pulseScoreList?.review_values?.map((value, rindex) => (
                                            <tr className="table-row">
                                                <td className="table-cell text-start">
                                                    { rindex+ 1}
                                                </td> 
                                                <td className="table-cell text-start">
                                                    { surveyNames[rindex]}
                                                </td> 
                                                <td className="table-cell text-start">
                                                    { value.responseRate}%
                                                </td>
                                                <td className="table-cell text-start">
                                                    { value.score}%
                                                </td>
                                                <td className="table-cell text-start">
                                                    { value.benchMarkScore}%
                                                </td>
                                                <td className="table-cell text-start">
                                                    { value.targetScore}%
                                                </td>
                                                <td className="table-cell text-start text-truncate" title={value.comments || 'NA'} style={{ whiteSpace:"pre-wrap", wordWrap:"break-word"}}>
                                                    { value.comments || 'NA'}
                                                </td>
                                                <td className="table-cell text-start">
                                                    { value.detailedReportLink && 
                                                        <a href={value.detailedReportLink}>{value.detailedReportLink}</a>
                                                    }
                                                    { !value.detailedReportLink && 
                                                        'NA'
                                                    }
                                                </td>
                                            </tr>
                                        ))} 
                                        </tbody>
                                    </table>
                                )}
                                { chapterReviewActiveIndex == 1 && (
                                    <div>                                        
                                        <Row className="mb-4">
                                            <Col className="col-md-8 chapter-progress">
                                                <label className="fs-16 font-weight-bold">Average Pulse Score<span>92%</span></label>
                                                <ProgressBar now={92} variant="warning" />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className="col-md-12">
                                                <div className="scrollable table-responsive white-table-class m-t-4 p-0">
                                                    <div className="scrollable-content">
                                                        <table className="table m-b-0">
                                                            <thead>
                                                                <tr className="theadInner default-bg">
                                                                    <th className="text-start">
                                                                        Sno
                                                                    </th>
                                                                    <th className="text-start">
                                                                        Developers
                                                                    </th>
                                                                    <th className="text-start">
                                                                        Levels
                                                                    </th>
                                                                    <th className="text-start">
                                                                        Tools
                                                                    </th>
                                                                    <th className="text-start">
                                                                        Assessment Date
                                                                    </th>
                                                                    <th className="text-start">
                                                                        Assessment Score
                                                                    </th>
                                                                    <th className="text-start">
                                                                        Development Level
                                                                    </th>
                                                                    <th className="text-start">
                                                                        Learning Path
                                                                    </th>
                                                                    <th className="text-start">
                                                                        Post Learning Path Assessment Date 
                                                                    </th>
                                                                    <th className="text-start">
                                                                        Post Learning Path Assessment Score 
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>             
                                                                <tr className="table-row">
                                                                    <td className="table-cell text-center" colSpan="10">
                                                                        No data available
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </div>
                                )}
                                { chapterReviewActiveIndex == 2 && (
                                    <div className="scrollable-content p-3 mt-3 chapter-tabs m-t-4">
                                        <Tabs
                                            activeKey={this.state.defaultActiveKey}
                                            id="main_view"
                                            className="mb-3"
                                            onSelect={(event) => {
                                                this.changeIcrTabView(event);
                                            }}
                                        >
                                            <Tab
                                                eventKey="detailed_view"
                                                title="Detailed View"
                                            >
                                                <table className="table m-b-0">
                                                    <thead>
                                                        <tr className="theadInner default-bg">    
                                                            <th className="text-start">
                                                                Total Interviews Attended
                                                            </th>
                                                            <th className="text-start">
                                                                Total Interviews Cleared
                                                            </th>
                                                            <th className="text-start">
                                                                Total Interviews Rejected
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        { !icrTotalDetails.total_interview_attended && (
                                                            <tr className="table-row">
                                                                <td className="table-cell text-center" colSpan="3">
                                                                    No data available
                                                                </td>
                                                            </tr>
                                                        )}
                                                        { icrTotalDetails && icrTotalDetails.total_interview_attended &&(
                                                            <tr className="table-row">
                                                                <td className="table-cell text-start">
                                                                    { icrTotalDetails.total_interview_attended }
                                                                </td>
                                                                <td className="table-cell text-start">
                                                                    { icrTotalDetails.total_interview_cleared }
                                                                </td>
                                                                <td className="table-cell text-start">
                                                                    { icrTotalDetails.total_interview_rejected }
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </Tab>
                                            <Tab
                                                eventKey="summary_view"
                                                title="Summary View"
                                            >
                                                <table className="table m-b-0">
                                                    <thead>
                                                        <tr className="theadInner default-bg">
                                                            <th className="text-start">
                                                                S.No
                                                            </th>
                                                            <th className="text-start">
                                                                Name
                                                            </th>
                                                            <th className="text-start">
                                                                Level
                                                            </th>
                                                            <th className="text-start">
                                                                Total Experience
                                                            </th>
                                                            <th className="text-start">
                                                                Skillset
                                                            </th>
                                                            <th className="text-start">
                                                                Interview Attended
                                                            </th>
                                                            <th className="text-start">
                                                                Interviews Cleared
                                                            </th>
                                                            <th className="text-start">
                                                                Interview Rejected
                                                            </th>
                                                            <th className="text-start">
                                                                Comments
                                                            </th>
                                                            { ![1, 8, 10, constants.HR].includes(roleId) &&
                                                                <th className="text-start">
                                                                    Action
                                                                </th>
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>   
                                                    { Object.keys(icrList).length === 0 && (
                                                        <tr className="table-row">
                                                            <td className="table-cell text-center" colSpan="10">
                                                                No data available
                                                            </td>
                                                        </tr>
                                                    )}
                                                    { icrList && icrList?.review_values?.map((value, rindex) => (
                                                        <tr className="table-row">
                                                            <td className="table-cell text-start">
                                                                { rindex+1 }
                                                            </td>
                                                            <td className="table-cell text-start">
                                                                { value.developers.name}
                                                            </td>
                                                            <td className="table-cell text-start">
                                                                { value.level}
                                                            </td>
                                                            <td className="table-cell text-start">
                                                                { value.total_experience}
                                                            </td>
                                                            <td className="table-cell text-start">
                                                                { value.skillset}
                                                            </td>
                                                            <td className="table-cell text-start">
                                                                { value.interview_attended}
                                                            </td>
                                                            <td className="table-cell text-start">
                                                                { value.interview_cleared}
                                                            </td>
                                                            <td className="table-cell text-start">
                                                                { value.interview_rejected}
                                                            </td>
                                                            <td className="table-cell text-start">
                                                                { value?.comments || 'NA'}
                                                            </td>
                                                            { ![1, 8, 10, constants.HR].includes(roleId) &&
                                                                <td className="table-cell text-start">
                                                                    <span onClick={() => this.setIndexValue(rindex) }><i className={`fa fa-pencil text-orange btn-pointer`}></i></span>
                                                                </td>
                                                            }
                                                        </tr>
                                                    ))} 
                                                    </tbody>
                                                </table>
                                            </Tab>
                                        </Tabs>
                                    </div>
                                    
                                )}
                                { chapterReviewActiveIndex == 3 && (
                                    <table className="table m-b-0">
                                        <thead>
                                            <tr className="theadInner default-bg">
                                                <th className="text-start">
                                                    Team Size
                                                </th>
                                                <th className="text-start">
                                                    PIP Count
                                                </th>
                                                <th className="text-start">
                                                    Level 1
                                                </th>
                                                <th className="text-start">
                                                    Level 2
                                                </th>
                                                <th className="text-start">
                                                    Level 3
                                                </th>
                                                <th className="text-start">
                                                    PIP%
                                                </th>
                                                <th className="text-start">
                                                    Comments
                                                </th> 
                                                { ![1, 8, 10, constants.HR].includes(roleId) &&
                                                    <th className="text-start">
                                                        Actions
                                                    </th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>   
                                        { Object.keys(pipList).length === 0 && (
                                            <tr className="table-row">
                                                <td className="table-cell text-center" colSpan="8">
                                                    No data available
                                                </td>
                                            </tr>
                                        )}  
                                        { pipList && pipList?.review_values?.map((value, rindex) => (
                                            <tr className="table-row">
                                                <td className="table-cell text-start">
                                                    { value.teamSize}
                                                </td>
                                                <td className="table-cell text-start">
                                                    { value.pipCount}
                                                </td>
                                                <td className="table-cell text-start">
                                                    { value.level1}
                                                </td>
                                                <td className="table-cell text-start">
                                                    { value.level2}
                                                </td>
                                                <td className="table-cell text-start">
                                                    { value.level3}
                                                </td>
                                                <td className="table-cell text-start">
                                                    { value.pipPercentage}%
                                                </td>
                                                <td className="table-cell text-start text-truncate" title={value.comments || 'NA'} style={{ whiteSpace:"pre-wrap", wordWrap:"break-word"}}>
                                                    { value.comments || 'NA'}
                                                </td>
                                                { ![1, 8, 10, constants.HR].includes(roleId) &&
                                                    <td className="table-cell text-start">
                                                        <span onClick={() => this.setIndexValue(rindex) }><i className={`fa fa-pencil text-orange btn-pointer`}></i></span>
                                                    </td>
                                                }
                                            </tr>
                                        ))} 
                                        </tbody>
                                    </table>
                                )} 
                                { chapterReviewActiveIndex == 4 && (
                                    <table className="table m-b-0">
                                        <thead>
                                            <tr className="theadInner default-bg">
                                                <th className="text-start">
                                                    Team Size
                                                </th>
                                                <th className="text-start">
                                                    No Of People serving notice period
                                                </th>
                                                <th className="text-start">
                                                    Voluntary count
                                                </th>
                                                <th className="text-start">
                                                    Non Voluntary count
                                                </th>
                                                <th className="text-start">
                                                    Level 1
                                                </th>
                                                <th className="text-start">
                                                    Level 2
                                                </th>
                                                <th className="text-start">
                                                    Level 3
                                                </th>
                                                <th className="text-start">
                                                    Attrition%
                                                </th>
                                                <th className="text-start">
                                                    Comments
                                                </th> 
                                                { ![1, 8, 10, constants.HR].includes(roleId) &&
                                                    <th className="text-start">
                                                        Actions
                                                    </th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>   
                                        { Object.keys(attritionList).length === 0 && (
                                            <tr className="table-row">
                                                <td className="table-cell text-center" colSpan="10">
                                                    No data available
                                                </td>
                                            </tr>
                                        )}  
                                        { attritionList && attritionList?.review_values?.map((value, rindex) => (
                                            <tr className="table-row">
                                                <td className="table-cell text-start">
                                                    { value.teamSize}
                                                </td>
                                                <td className="table-cell text-start">
                                                    { value.noOfNP}
                                                </td>
                                                <td className="table-cell text-start">
                                                    { value.voluntoryCount}
                                                </td>
                                                <td className="table-cell text-start">
                                                    { value.nonVoluntoryCount}
                                                </td>
                                                <td className="table-cell text-start">
                                                    { value.level1}
                                                </td>
                                                <td className="table-cell text-start">
                                                    { value.level2}
                                                </td>
                                                <td className="table-cell text-start">
                                                    { value.level3}
                                                </td>
                                                <td className="table-cell text-start">
                                                    { value.attritionPercentage}%
                                                </td>
                                                <td className="table-cell text-start text-truncate" title={value.comments || 'NA'} style={{ whiteSpace:"pre-wrap", wordWrap:"break-word"}}>
                                                    { value.comments || 'NA'}
                                                </td>
                                                { ![1, 8, 10, constants.HR].includes(roleId) &&
                                                    <td className="table-cell text-start">
                                                        <span onClick={() => this.setIndexValue(rindex) }><i className={`fa fa-pencil text-orange btn-pointer`}></i></span>
                                                    </td>
                                                }
                                            </tr>
                                        ))} 
                                        </tbody>
                                    </table>
                                )}
                                { chapterReviewActiveIndex == 5 && (
                                    <table className="table m-b-0">
                                        <thead>
                                            <tr className="theadInner default-bg">
                                                <th className="text-start">
                                                    No of open positions
                                                </th>
                                                <th className="text-start">
                                                    Levels
                                                </th>
                                                <th className="text-start">
                                                    Profile sourced
                                                </th>
                                                <th className="text-start">
                                                    Interviews
                                                </th>
                                                <th className="text-start">
                                                    Shortlisted
                                                </th>
                                                <th className="text-start">
                                                    Rejected
                                                </th>
                                                { ![1, 8, 10, constants.HR].includes(roleId) &&
                                                    <th className="text-start">
                                                        Actions
                                                    </th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>   
                                        { Object.keys(recruitmentList).length === 0 && (
                                            <tr className="table-row">
                                                <td className="table-cell text-center" colSpan="7">
                                                    No data available
                                                </td>
                                            </tr>
                                        )}  
                                        { recruitmentList && recruitmentList?.review_values?.map((value, rindex) => (
                                            <tr className="table-row">
                                                <td className="table-cell text-start">
                                                    { value.noOfOP}
                                                </td>
                                                <td className="table-cell text-start">
                                                    { value.levels}
                                                </td>
                                                <td className="table-cell text-start">
                                                    { value.profileSourced}
                                                </td>
                                                <td className="table-cell text-start">
                                                    { value.interviews}
                                                </td>
                                                <td className="table-cell text-start">
                                                    { value.shortListed}
                                                </td>
                                                <td className="table-cell text-start">
                                                    { value.rejected}
                                                </td>
                                                { ![1, 8, 10, constants.HR].includes(roleId) &&
                                                    <td className="table-cell text-start">
                                                        <span onClick={() => this.setIndexValue(rindex) }><i className={`fa fa-pencil text-orange btn-pointer`}></i></span>
                                                    </td>
                                                }
                                            </tr>
                                        ))} 
                                        </tbody>
                                    </table>
                                )}
                                {chapterReviewActiveIndex == 6 && ( 
                                    <div className="scrollable-content p-3 mt-3 chapter-tabs m-t-4">
                                        <Tabs
                                            activeKey={defaultStrengthKey}
                                            id="main_view"
                                            className="mb-3"
                                            onSelect={(event) => {
                                                this.changeStrengthTabView(event);
                                            }}
                                        >
                                            <Tab
                                                eventKey="reserve_strength_summary"
                                                title="Reserve Strength Summary"
                                            >
                                                <table className="table m-b-0">
                                                    <thead>
                                                        <tr className="theadInner default-bg">
                                                            <th className="text-start">
                                                                Chapter Size
                                                            </th>
                                                            <th className="text-start">
                                                                Reserve Strength
                                                            </th>
                                                            <th className="text-start">
                                                                Reserve strength %
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr className="table-row">
                                                            <td className="table-cell">
                                                               { developers.length }
                                                            </td>
                                                            <td className="table-cell">
                                                                { reserveStrengthSummary?.strengthValue } <i className="fs-16 fa fa-edit text-theme c-pointer" onClick={(e) => this.openSummaryModal('strengthValue','Reserve Strength')} ></i>
                                                            </td>
                                                            <td className="table-cell">
                                                                {parseInt((reserveStrengthSummary?.strengthValue/developers.length) * 100)}%
                                                            </td>
                                                        </tr>
                                                        <tr className="table-row">
                                                            <td className="table-cell font-weight-bold">
                                                               Buckets
                                                            </td>
                                                            <td className="table-cell font-weight-bold">
                                                               Developers
                                                            </td>
                                                            <td className="table-cell font-weight-bold">
                                                               Percentage
                                                            </td>
                                                        </tr>
                                                        {buckets.map((bucket) => (
                                                            <tr className="table-row">
                                                                <td className="table-cell">
                                                                    { bucket?.label }
                                                                </td>
                                                                <td className="table-cell">
                                                                    { reserveStrengthSummary[bucket?.value]?.noOfDev } <i className="fs-16 fa fa-edit text-theme c-pointer" onClick={(e) => this.openSummaryModal(bucket?.value,bucket?.label)} ></i>
                                                                </td>
                                                                <td className="table-cell">
                                                                    {isNaN(parseInt((reserveStrengthSummary[bucket?.value]?.noOfDev/reserveStrengthSummary?.strengthValue) * 100)) ? 0 : parseInt((reserveStrengthSummary[bucket?.value]?.noOfDev/reserveStrengthSummary?.strengthValue) * 100)}%
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </Tab>
                                            <Tab
                                                eventKey="reserve_strength_activities"
                                                title="Reserve Strength Activities"
                                            >
                                                <table className="table m-b-0">
                                                    <thead>
                                                        <tr className="theadInner default-bg">
                                                            <th className="text-start">
                                                                S.No
                                                            </th>
                                                            <th className="text-start">
                                                                Developers
                                                            </th>
                                                            <th className="text-start">
                                                                Bucket
                                                            </th>
                                                            <th className="text-start">
                                                                Note
                                                            </th>
                                                            <th className="text-start">
                                                                Allocated Hours
                                                            </th>
                                                            <th className="text-start">
                                                                Expected outcome
                                                            </th>
                                                            <th className="text-start">
                                                                ETA
                                                            </th>
                                                            <th className="text-start">
                                                                Comments
                                                            </th>
                                                            { ![1, 8, 10, constants.HR].includes(roleId) &&
                                                                <th className="text-start">
                                                                    Actions
                                                                </th>
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>   
                                                    { (!reserveActivityList?.review_values?.details || reserveActivityList?.review_values?.details?.length === 0) && (
                                                        <tr className="table-row">
                                                            <td className="table-cell text-center" colSpan="9">
                                                                No data available
                                                            </td>
                                                        </tr>
                                                    )}  
                                                    { reserveActivityList && reserveActivityList?.review_values?.details?.map((value, rindex) => (
                                                        <tr className="table-row">
                                                            <td className="table-cell text-start">
                                                                { rindex+1 }
                                                            </td>
                                                            <td className="table-cell text-start">
                                                                { value.developers.name}
                                                            </td>
                                                            <td className="table-cell text-start">
                                                                { value.bucket?.map(({ label }) => label + ',') }
                                                            </td>
                                                            <td className="table-cell text-start">
                                                                { value.note}
                                                            </td>
                                                            <td className="table-cell text-start">
                                                                { value.allocatedHours}
                                                            </td>
                                                            <td className="table-cell text-start">
                                                                { value.expectedOutcome}
                                                            </td>
                                                            <td className="table-cell text-start">
                                                                { value.ETA}
                                                            </td>
                                                            <td className="table-cell text-start text-truncate" title={value.comments || 'NA'} style={{ whiteSpace:"pre-wrap", wordWrap:"break-word"}}>
                                                                { value.comments}
                                                            </td>
                                                            { ![1, 8, 10, constants.HR].includes(roleId) &&
                                                                <td className="table-cell text-start">
                                                                    <span onClick={() => this.setIndexValue(rindex) }><i className={`fa fa-pencil text-orange btn-pointer`}></i></span>
                                                                </td>
                                                            }
                                                        </tr>
                                                    ))} 
                                                    </tbody>
                                                </table>
                                            </Tab>
                                    </Tabs>
                                </div>
                                )}
                                { chapterReviewActiveIndex == 7 && (
                                    <table className="table m-b-0">
                                        <thead>
                                            <tr className="theadInner default-bg">
                                                <th className="text-start">
                                                    S.No
                                                </th>
                                                <th className="text-start">
                                                    Employee Name
                                                </th>
                                                <th className="text-start">
                                                    Hits
                                                </th>
                                                <th className="text-start">
                                                    Misses
                                                </th>
                                                <th className="text-start">
                                                    Opportunity
                                                </th>
                                                { ![1, 8, 10, constants.HR].includes(roleId) &&
                                                    <th className="text-start">
                                                        Actions
                                                    </th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>   
                                        { Object.keys(hitMissesList).length === 0 && (
                                            <tr className="table-row">
                                                <td className="table-cell text-center" colSpan="6">
                                                    No data available
                                                </td>
                                            </tr>
                                        )}  
                                        { hitMissesList && hitMissesList?.review_values?.map((value, rindex) => (
                                            <tr className="table-row">
                                                <td className="table-cell text-start">
                                                    { rindex+1 }
                                                </td>
                                                <td className="table-cell text-start">
                                                    { value.developers.name}
                                                </td>
                                                <td className="table-cell text-start">
                                                    <span class="text-truncate" title={ value.hits}>{ value.hits}</span>
                                                </td>
                                                <td className="table-cell text-start">
                                                     <span class="text-truncate" title={ value.misses}>{ value.misses}</span>
                                                </td>
                                                <td className="table-cell text-start">
                                                    <span class="text-truncate" title={ value.opportunity}>{ value.opportunity}</span>
                                                </td>
                                                { ![1, 8, 10, constants.HR].includes(roleId) &&
                                                    <td className="table-cell text-start">
                                                        <span onClick={() => this.setIndexValue(rindex) }><i className={`fa fa-pencil text-orange btn-pointer`}></i></span>
                                                    </td>
                                                }
                                            </tr>
                                        ))} 
                                        </tbody>
                                    </table>
                                )}
                                { chapterReviewActiveIndex == 8 && (
                                    <table className="table m-b-0">
                                        <thead>
                                            <tr className="theadInner default-bg">
                                                <th className="text-start">
                                                    Action Item
                                                </th>
                                                <th className="text-start">
                                                    Status
                                                </th>
                                                <th className="text-start">
                                                    Outcome
                                                </th>
                                                <th className="text-start">
                                                    Comments
                                                </th> 
                                                { ![1, 8, 10, constants.HR].includes(roleId) &&
                                                    <th className="text-start">
                                                        Actions
                                                    </th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>   
                                        { Object.keys(actionItemsList).length === 0 && (
                                            <tr className="table-row">
                                                <td className="table-cell text-center" colSpan="5">
                                                    No data available
                                                </td>
                                            </tr>
                                        )}  
                                        { actionItemsList && actionItemsList?.review_values?.map((value, rindex) => (
                                            <tr className="table-row">
                                                <td className="table-cell text-start">
                                                    { value.actionItems}
                                                </td>
                                                <td className="table-cell text-start">
                                                    { value.status}
                                                </td>
                                                <td className="table-cell text-start">
                                                    { value.outcome}
                                                </td>
                                                <td className="table-cell text-start text-truncate" title={value.comments || 'NA'} style={{ whiteSpace:"pre-wrap", wordWrap:"break-word"}}>
                                                    { value.comments || 'NA'}
                                                </td>
                                                { ![1, 8, 10, constants.HR].includes(roleId) &&
                                                    <td className="table-cell text-start">
                                                        <span onClick={() => this.setIndexValue(rindex) }><i className={`fa fa-pencil text-orange btn-pointer`}></i></span>
                                                    </td>
                                                }
                                            </tr>
                                        ))} 
                                        </tbody>
                                    </table>
                                )} 
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
            <div id="pulseScoreModal">
                <Modal show={isModalOpen[0]} >
                    <Modal.Header >
                        <Modal.Title className='custom-modal-title chapter-modal f-14 h5 text-uppercase'>
                            CREATE PULSE SCORE - {chapterMonthItemsTab[monthTabIndexActive]?.label} {chapterMonthItemsTab[monthTabIndexActive]?.year}
                        </Modal.Title>
                        <button className="potential-button pull-right" onClick={() => this.isModalClose()}>X</button>
                    </Modal.Header>
                    <Modal.Body>
                        { surveyNames.map((sName, sindex) => (
                            <>
                            <Form.Group className="p-t-8">
                                <Form.Label className="fs-20 text-orange">{ sName }</Form.Label>
                            </Form.Group>
                            <Form.Group >
                                <Form.Label>Response rate/attendance:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={`responseRate${sindex}`}
                                    placeholder="Enter Response rate or attendance"
                                    defaultValue={pulseScoreDetails?.[sindex]?.responseRate}
                                    onChange={(e) => this.inputChangeHandler(e,'responseRate',sindex)}
                                />
                                {this.state.validationErrors.responseRate && (
                                    <Form.Text className="text-danger">
                                        {this.state.validationErrors.responseRate}
                                    </Form.Text>
                                )}
                                <br/>
                                <Form.Label>Score:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={`score${sindex}`}
                                    placeholder="Enter Score"
                                    defaultValue={pulseScoreDetails?.[sindex]?.score}
                                    onChange={(e) => this.inputChangeHandler(e,'score',sindex)}
                                />
                                {this.state.validationErrors.score && (
                                    <Form.Text className="text-danger">
                                        {this.state.validationErrors.score}
                                    </Form.Text>
                                )}
                                <br/>
                                <Form.Label>Bench Mark Score:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={`benchMarkScore${sindex}`}
                                    placeholder="Enter Bench Mark Score"
                                    defaultValue={pulseScoreDetails?.[sindex]?.benchMarkScore}
                                    onChange={(e) => this.inputChangeHandler(e,'benchMarkScore',sindex)}
                                />
                                {this.state.validationErrors.benchMarkScore && (
                                    <Form.Text className="text-danger">
                                        {this.state.validationErrors.benchMarkScore}
                                    </Form.Text>
                                )}
                                <br/>
                                <Form.Label>Target Score:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={`targetScore${sindex}`}
                                    placeholder="Enter Target Score"
                                    defaultValue={pulseScoreDetails?.[sindex]?.targetScore}
                                    onChange={(e) => this.inputChangeHandler(e,'targetScore',sindex)}
                                />
                                {this.state.validationErrors.targetScore && (
                                    <Form.Text className="text-danger">
                                        {this.state.validationErrors.targetScore}
                                    </Form.Text>
                                )}
                                <br/>
                                <Form.Label>Comments:</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name={`comments${sindex}`}
                                    rows={3}
                                    placeholder="Enter Comments"
                                    defaultValue={pulseScoreDetails?.[sindex]?.comments}
                                    onChange={(e) => this.inputChangeHandler(e,'comments',sindex)}
                                />
                                <br/>
                                <Form.Label>Detailed Report Link:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={`detailedReportLink${sindex}`}
                                    placeholder="Enter Detailed Report Link"
                                    defaultValue={pulseScoreDetails?.[sindex]?.detailedReportLink}
                                    onChange={(e) => this.inputChangeHandler(e,'detailedReportLink',sindex)}
                                />
                            </Form.Group>
                            <hr/>
                            </>
                          ))
                        }
                    </Modal.Body>
                    <Modal.Footer className='potential-footer' style={{ backgroundColor: "#434040"}}>
                        <Button
                            variant="primary"
                            type="reset"
                            style={{ backgroundColor: '#e7b487', borderColor: '#e7b487', width: '230px', height: '42px', fontSize: '14px' }}
                            onClick={() => this.isModalClose()}
                        >
                            CANCEL
                        </Button>
                        <Button
                            variant="primary"
                            type="submit"
                            style={{ backgroundColor: '#f5821f', borderColor: '#f5821f', width: '230px', height: '42px', fontSize: '14px' }}
                            onClick={this.handleSubmit}
                        >
                            SUBMIT
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div id="competencyIndexModal">
                <Modal show={isModalOpen[1]} >
                    <Modal.Header >
                        <Modal.Title className='custom-modal-title chapter-modal f-14 h5'>
                            CREATE COMPETENCY INDEX  - {chapterMonthItemsTab[monthTabIndexActive]?.label} {chapterMonthItemsTab[monthTabIndexActive]?.year}
                        </Modal.Title>
                        <button className="potential-button pull-right" onClick={() => this.isModalClose()}>X</button>
                    </Modal.Header>
                    <Modal.Body>                     
                            <Form.Group >
                                <Form.Label>Developers:</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="developers"
                                    placeholder="Select developer"
                                    value={this.state.selectedDeveloper.name}
                                    onChange={this.handleDeveloperChange}
                                >
                                    <option value="" disabled>Select developer</option>
                                    {this.state.developers.map(developer =>
                                        <option id={developer.id} key={developer.id} value={developer.first_name}>{developer.first_name}</option>
                                    )}
                                </Form.Control>
                                <br/>
                                <Form.Label>Levels:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name= "lead"
                                    placeholder="Enter Levels"
                                    value={this.state.competencyDetails.lead}
                                    onChange={this.competencyinputChangeHandler}
                                />
                                 {this.state.validationErrors.responseRate && (
                                    <Form.Text className="text-danger">
                                        {this.state.validationErrors.lead}
                                    </Form.Text>
                                )}
                                <br/>
                                <Form.Label>Tools:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name= "tools"
                                    placeholder="Enter Tools"
                                    value={this.state.competencyDetails.tools}
                                    onChange={this.competencyinputChangeHandler}
                                />
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.tools}
                                </Form.Text>
                                <br/>
                                <Form.Label>Assessment Date:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="AssessmentDate"
                                    placeholder="Enter Assessment Date"
                                    value={this.state.competencyDetails.AssessmentDate}
                                    onChange={this.competencyinputChangeHandler}
                                />
                                 <Form.Text className="text-danger">
                                    {this.state.validationErrors.AssessmentDate}
                                </Form.Text>
                                <br/>
                                <Form.Label>Assessment Score:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="AssessmentScore"
                                    placeholder="Enter Assessment Score"
                                    value={this.state.competencyDetails.AssessmentScore}
                                    onChange={this.competencyinputChangeHandler}
                                />
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.AssessmentScore}
                                </Form.Text>
                                <br/>
                                <Form.Label>Development Level:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="DevelopmentLevel"
                                    placeholder="Enter Development Level"
                                    value={this.state.competencyDetails.DevelopmentLevel}
                                    onChange={this.competencyinputChangeHandler}
                                />
                                 <Form.Text className="text-danger">
                                    {this.state.validationErrors.DevelopmentLevel}
                                </Form.Text>
                                <br/>
                                <Form.Label>Learning Path:</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="LearningPath"
                                    rows={3}
                                    placeholder="Enter Learning Path"
                                    value={this.state.competencyDetails.LearningPath}
                                    onChange={this.competencyinputChangeHandler}
                                />
                                 <Form.Text className="text-danger">
                                    {this.state.validationErrors.LearningPath}
                                </Form.Text>
                                <br/>
                                <Form.Label>Post Learning Path Assessment Date</Form.Label>
                                <Form.Control
                                    type="text"
                                    name= "PostAssessmentDate"
                                    placeholder="Enter Post Learning Path Assessment Date"
                                    value={this.state.competencyDetails.PostAssessmentDate}
                                    onChange={this.competencyinputChangeHandler}
                                />
                                 <Form.Text className="text-danger">
                                    {this.state.validationErrors.PostAssessmentDate}
                                </Form.Text>
                                 <br/>
                                <Form.Label>Post Learning Path Assessment Score</Form.Label>
                                <Form.Control
                                    type="text"
                                    name= "PostAssessmentScore"
                                    placeholder="Enter Post Learning Path Assessment Score"
                                    value={this.state.competencyDetails.PostAssessmentScore}
                                    onChange={this.competencyinputChangeHandler}
                                />
                                 <Form.Text className="text-danger">
                                    {this.state.validationErrors.PostAssessmentScore}
                                </Form.Text>
                            </Form.Group>                           
                    </Modal.Body>
                    <Modal.Footer className='potential-footer' style={{ backgroundColor: "#434040"}}>
                        <Button
                            variant="primary"
                            type="reset"
                            style={{ backgroundColor: '#e7b487', borderColor: '#e7b487', width: '230px', height: '42px', fontSize: '14px' }}
                            onClick={() => this.isModalClose()}
                        >
                            CANCEL
                        </Button>
                        <Button
                            variant="primary"
                            type="submit"
                            style={{ backgroundColor: '#f5821f', borderColor: '#f5821f', width: '230px', height: '42px', fontSize: '14px' }}
                            onClick={this.handleSubmit}
                        >
                            SUBMIT
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div id="ICRModal">
                <Modal show={isModalOpen[2]} >
                    <Modal.Header >
                        <Modal.Title className='custom-modal-title chapter-modal f-14 h5'>
                            CREATE ICR  - {chapterMonthItemsTab[monthTabIndexActive]?.label} {chapterMonthItemsTab[monthTabIndexActive]?.year}
                        </Modal.Title>
                        <button className="potential-button pull-right" onClick={() => this.isModalClose()}>X</button>
                    </Modal.Header>
                    <Modal.Body>                     
                            <Form.Group >
                                <Form.Label>Name:</Form.Label>
                                {(icrList?.review_values?.length > 0 && icrList?.review_values?.length != developers.length && reviewIndex == -1) &&
                                    <Form.Control
                                        as="select"
                                        name="developers"
                                        placeholder="Select developer"
                                        value={icrDetails.developers?.name}
                                        onChange={(e) => this.inputChangeHandler(e,'developers',0)}
                                    >
                                        <option value="" disabled>Select Name</option>
                                        {this.state.filteredDevelopers.map(developer =>
                                        developer && (
                                             <option
                                             id={developer.id}
                                             key={developer.id}
                                             value={developer.first_name}
                                         >
                                             {developer.first_name}
                                             </option>
                                        )
                                        
                                        )}
                                    </Form.Control>
                                }
                                {(Object.keys(icrList).length === 0 || reviewIndex > -1) && 
                                    <Form.Control
                                        type="text"
                                        name={`developers`}
                                        placeholder="Enter Name"
                                        value={icrDetails.developers?.name}
                                        disabled
                                    />
                                }
                                <br/>
                                <Form.Label>Level:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="level"
                                    placeholder="Enter Level"
                                    value={icrDetails.level}
                                    onChange={(e) => this.inputChangeHandler(e,'level',0)}
                                />
                                 <Form.Text className="text-danger">
                                    {this.state.validationErrors.level}
                                </Form.Text>
                                <br/>
                                <Form.Label>Total Experience:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="total_experience"
                                    placeholder="Enter Total Experience"
                                    value={icrDetails.total_experience}
                                    onChange={(e) => this.inputChangeHandler(e,'total_experience',0)}
                                />
                                 <Form.Text className="text-danger">
                                    {this.state.validationErrors.total_experience}
                                </Form.Text>
                                <br/>
                                <Form.Label>Skillset:</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="skillset"
                                    rows={3}
                                    placeholder="Enter Skillset"
                                    value={icrDetails.skillset}
                                    onChange={(e) => this.inputChangeHandler(e,'skillset',0)}
                                />
                                 <Form.Text className="text-danger">
                                    {this.state.validationErrors.skillset}
                                </Form.Text>
                                <br/>
                                <Form.Label>Interview Attended:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="interview_attended"
                                    placeholder="Enter Interview Attended"
                                    value={icrDetails.interview_attended}
                                    onChange={(e) => this.inputChangeHandler(e,'interview_attended',0)}
                                />
                                 <Form.Text className="text-danger">
                                    {this.state.validationErrors.interview_attended}
                                </Form.Text>
                                <br/>
                                <Form.Label>Interviews Cleared:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name=" interview_cleared"
                                    placeholder="Enter Interview Cleared"
                                    value={icrDetails.interview_cleared}
                                    onChange={(e) => this.inputChangeHandler(e,'interview_cleared',0)}
                                />
                                 <Form.Text className="text-danger">
                                    {this.state.validationErrors.interview_cleared}
                                </Form.Text>
                                <br/>
                                <Form.Label>Interview Rejected:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="interview_rejected"
                                    placeholder="Enter Interview Rejected"
                                    value={icrDetails.interview_rejected}
                                    onChange={(e) => this.inputChangeHandler(e,'interview_rejected',0)}
                                />
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.interview_rejected}
                                </Form.Text>
                                <br/>
                                <Form.Label>Comments:</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name={`comments`}
                                    rows={3}
                                    placeholder="Enter Comments"
                                    defaultValue={icrDetails?.comments}
                                    onChange={(e) => this.inputChangeHandler(e,'comments',null)}
                                />
                            </Form.Group>                           
                    </Modal.Body>
                    <Modal.Footer className='potential-footer' style={{ backgroundColor: "#434040"}}>
                        <Button
                            variant="primary"
                            type="reset"
                            style={{ backgroundColor: '#e7b487', borderColor: '#e7b487', width: btnIcrWidth, height: '42px', fontSize: '14px' }}
                            onClick={() => this.isModalClose()}
                        >
                            CANCEL
                        </Button>
                        { (icrListLength == 0) &&
                            <Button
                                variant="primary"
                                type="button"
                                style={{ backgroundColor: '#f5821f', borderColor: '#f5821f', width: '150px', height: '42px', fontSize: '14px' }}
                                onClick={this.handlePrevious}
                                disabled={developerIndex==0}
                            >
                                PREVIOUS
                            </Button>
                        }
                        <Button
                            variant="primary"
                            type="submit"
                            style={{ backgroundColor: '#f5821f', borderColor: '#f5821f', width: btnIcrWidth, height: '42px', fontSize: '14px' }}
                            onClick={this.handleSubmit}
                        >
                            { (icrListLength > 0 || reviewIndex > -1) ? 'SUBMIT' : 'NEXT'}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div id="pipModal">
                <Modal show={isModalOpen[3]} >
                    <Modal.Header >
                        <Modal.Title className='custom-modal-title chapter-modal f-14 h5 text-uppercase'>
                            PIP - {chapterMonthItemsTab[monthTabIndexActive]?.label} {chapterMonthItemsTab[monthTabIndexActive]?.year}
                        </Modal.Title>
                        <button className="potential-button pull-right" onClick={() => this.isModalClose()}>X</button>
                    </Modal.Header>
                    <Modal.Body>
                        <>
                        <Form.Group >
                            <Form.Label>Team Size:</Form.Label>
                            <Form.Control
                                type="text"
                                name={`teamSize`}
                                placeholder="Enter Team Size"
                                defaultValue={pipDetails?.teamSize}
                                onChange={(e) => this.inputChangeHandler(e,'teamSize',null)}
                            />
                            {this.state.validationErrors.teamSize && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.teamSize}
                                </Form.Text>
                            )}
                            <br/>
                            <Form.Label>PIP count:</Form.Label>
                            <Form.Control
                                type="text"
                                name={`pipCount`}
                                placeholder="Enter PIP count"
                                defaultValue={pipDetails?.pipCount}
                                onChange={(e) => this.inputChangeHandler(e,'pipCount',null)}
                            />
                            {this.state.validationErrors.pipCount && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.pipCount}
                                </Form.Text>
                            )}
                            <br/>
                            <Form.Label>Level 1:</Form.Label>
                            <Form.Control
                                type="text"
                                name={`level1`}
                                placeholder="Enter Level 1"
                                defaultValue={pipDetails?.level1}
                                onChange={(e) => this.inputChangeHandler(e,'level1',null)}
                            />
                            {this.state.validationErrors.level1 && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.level1}
                                </Form.Text>
                            )}
                            <br/>
                            <Form.Label>Level 2:</Form.Label>
                            <Form.Control
                                type="text"
                                name={`level2`}
                                placeholder="Enter Level 2"
                                defaultValue={pipDetails?.level2}
                                onChange={(e) => this.inputChangeHandler(e,'level2',null)}
                            />
                            {this.state.validationErrors.level2 && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.level2}
                                </Form.Text>
                            )}
                            <br/>
                            <Form.Label>Level 3:</Form.Label>
                            <Form.Control
                                type="text"
                                name={`level3`}
                                placeholder="Enter Level 3"
                                defaultValue={pipDetails?.level3}
                                onChange={(e) => this.inputChangeHandler(e,'level3',null)}
                            />
                            {this.state.validationErrors.level3 && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.level3}
                                </Form.Text>
                            )}
                            <br/>
                            <Form.Label>PIP %:</Form.Label>
                            <Form.Control
                                type="text"
                                name={`pipPercentage`}
                                placeholder="Enter PIP%"
                                defaultValue={pipDetails?.pipPercentage}
                                onChange={(e) => this.inputChangeHandler(e,'pipPercentage',null)}
                            />
                            {this.state.validationErrors.pipPercentage && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.pipPercentage}
                                </Form.Text>
                            )}
                            <br/>
                            <Form.Label>Comments:</Form.Label>
                            <Form.Control
                                as="textarea"
                                name={`comments`}
                                rows={3}
                                placeholder="Enter Comments"
                                defaultValue={pipDetails?.comments}
                                onChange={(e) => this.inputChangeHandler(e,'comments',null)}
                            />
                        </Form.Group>
                        <hr/>
                        </>
                    </Modal.Body>
                    <Modal.Footer className='potential-footer' style={{ backgroundColor: "#434040"}}>
                        <Button
                            variant="primary"
                            type="reset"
                            style={{ backgroundColor: '#e7b487', borderColor: '#e7b487', width: '230px', height: '42px', fontSize: '14px' }}
                            onClick={() => this.isModalClose()}
                        >
                            CANCEL
                        </Button>
                        <Button
                            variant="primary"
                            type="submit"
                            style={{ backgroundColor: '#f5821f', borderColor: '#f5821f', width: '230px', height: '42px', fontSize: '14px' }}
                            onClick={this.handleSubmit}
                        >
                            SUBMIT
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div id="attritionModal">
                <Modal show={isModalOpen[4]} >
                    <Modal.Header >
                        <Modal.Title className='custom-modal-title chapter-modal f-14 h5 text-uppercase'>
                            Attrition - {chapterMonthItemsTab[monthTabIndexActive]?.label} {chapterMonthItemsTab[monthTabIndexActive]?.year}
                        </Modal.Title>
                        <button className="potential-button pull-right" onClick={() => this.isModalClose()}>X</button>
                    </Modal.Header>
                    <Modal.Body>
                        <>
                        <Form.Group >
                            <Form.Label>Team Size:</Form.Label>
                            <Form.Control
                                type="text"
                                name={`teamSize`}
                                placeholder="Enter Team Size"
                                defaultValue={attritionDetails?.teamSize}
                                onChange={(e) => this.inputChangeHandler(e,'teamSize',null)}
                            />
                            {this.state.validationErrors.teamSize && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.teamSize}
                                </Form.Text>
                            )}
                            <br/>
                            <Form.Label>No Of People serving notice period:</Form.Label>
                            <Form.Control
                                type="text"
                                name={`noOfNP`}
                                placeholder="Enter No Of People serving notice period"
                                defaultValue={attritionDetails?.noOfNP}
                                onChange={(e) => this.inputChangeHandler(e,'noOfNP',null)}
                            />
                            {this.state.validationErrors.noOfNP && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.noOfNP}
                                </Form.Text>
                            )}
                            <br/>
                            <Form.Label>Voluntory Count:</Form.Label>
                            <Form.Control
                                type="text"
                                name={`voluntoryCount`}
                                placeholder="Voluntory Count"
                                defaultValue={attritionDetails?.voluntoryCount}
                                onChange={(e) => this.inputChangeHandler(e,'voluntoryCount',null)}
                            />
                            {this.state.validationErrors.voluntoryCount && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.voluntoryCount}
                                </Form.Text>
                            )}
                            <br/>
                            <Form.Label>Non Voluntory Count:</Form.Label>
                            <Form.Control
                                type="text"
                                name={`nonVoluntoryCount`}
                                placeholder="Non Voluntory Count"
                                defaultValue={attritionDetails?.nonVoluntoryCount}
                                onChange={(e) => this.inputChangeHandler(e,'nonVoluntoryCount',null)}
                            />
                            {this.state.validationErrors.nonVoluntoryCount && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.nonVoluntoryCount}
                                </Form.Text>
                            )}
                            <br/>
                            <Form.Label>Level 1:</Form.Label>
                            <Form.Control
                                type="text"
                                name={`level1`}
                                placeholder="Enter Level 1"
                                defaultValue={attritionDetails?.level1}
                                onChange={(e) => this.inputChangeHandler(e,'level1',null)}
                            />
                            {this.state.validationErrors.level1 && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.level1}
                                </Form.Text>
                            )}
                            <br/>
                            <Form.Label>Level 2:</Form.Label>
                            <Form.Control
                                type="text"
                                name={`level2`}
                                placeholder="Enter Level 2"
                                defaultValue={attritionDetails?.level2}
                                onChange={(e) => this.inputChangeHandler(e,'level2',null)}
                            />
                            {this.state.validationErrors.level2 && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.level2}
                                </Form.Text>
                            )}
                            <br/>
                            <Form.Label>Level 3:</Form.Label>
                            <Form.Control
                                type="text"
                                name={`level3`}
                                placeholder="Enter Level 3"
                                defaultValue={attritionDetails?.level3}
                                onChange={(e) => this.inputChangeHandler(e,'level3',null)}
                            />
                            {this.state.validationErrors.level3 && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.level3}
                                </Form.Text>
                            )}
                            <br/>
                            <Form.Label>Attrition %:</Form.Label>
                            <Form.Control
                                type="text"
                                name={`attritionPercentage`}
                                placeholder="Enter Attrition%"
                                defaultValue={attritionDetails?.attritionPercentage}
                                onChange={(e) => this.inputChangeHandler(e,'attritionPercentage',null)}
                            />
                            {this.state.validationErrors.attritionPercentage && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.attritionPercentage}
                                </Form.Text>
                            )}
                            <br/>
                            <Form.Label>Comments:</Form.Label>
                            <Form.Control
                                as="textarea"
                                name={`comments`}
                                rows={3}
                                placeholder="Enter Comments"
                                defaultValue={attritionDetails?.comments}
                                onChange={(e) => this.inputChangeHandler(e,'comments',null)}
                            />
                        </Form.Group>
                        <hr/>
                        </>
                    </Modal.Body>
                    <Modal.Footer className='potential-footer' style={{ backgroundColor: "#434040"}}>
                        <Button
                            variant="primary"
                            type="reset"
                            style={{ backgroundColor: '#e7b487', borderColor: '#e7b487', width: '230px', height: '42px', fontSize: '14px' }}
                            onClick={() => this.isModalClose()}
                        >
                            CANCEL
                        </Button>
                        <Button
                            variant="primary"
                            type="submit"
                            style={{ backgroundColor: '#f5821f', borderColor: '#f5821f', width: '230px', height: '42px', fontSize: '14px' }}
                            onClick={this.handleSubmit}
                        >
                            SUBMIT
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div id="recruitmentModal">
                <Modal show={isModalOpen[5]} >
                    <Modal.Header >
                        <Modal.Title className='custom-modal-title chapter-modal f-14 h5 text-uppercase'>
                            Recruitment   - {chapterMonthItemsTab[monthTabIndexActive]?.label} {chapterMonthItemsTab[monthTabIndexActive]?.year}
                        </Modal.Title>
                        <button className="potential-button pull-right" onClick={() => this.isModalClose()}>X</button>
                    </Modal.Header>
                    <Modal.Body>
                        <>
                        <Form.Group >
                            <Form.Label>No of open positions:</Form.Label>
                            <Form.Control
                                type="text"
                                name={`noOfOP`}
                                placeholder="Enter No of open positions"
                                defaultValue={recruitmentDetails?.noOfOP}
                                onChange={(e) => this.inputChangeHandler(e,'noOfOP',null)}
                            />
                            {this.state.validationErrors.noOfOP && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.noOfOP}
                                </Form.Text>
                            )}
                            <br/>
                            <Form.Label>Levels:</Form.Label>
                            <Form.Control
                                type="text"
                                name={`levels`}
                                placeholder="Enter Levels"
                                defaultValue={recruitmentDetails?.levels}
                                onChange={(e) => this.inputChangeHandler(e,'levels',null)}
                            />
                            {this.state.validationErrors.levels && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.levels}
                                </Form.Text>
                            )}
                            <br/>
                            <Form.Label>Profile Sourced:</Form.Label>
                            <Form.Control
                                type="text"
                                name={`profileSourced`}
                                placeholder="Enter Profile Sourced"
                                defaultValue={recruitmentDetails?.profileSourced}
                                onChange={(e) => this.inputChangeHandler(e,'profileSourced',null)}
                            />
                            {this.state.validationErrors.profileSourced && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.profileSourced}
                                </Form.Text>
                            )}
                            <br/>
                            <Form.Label>Interviews:</Form.Label>
                            <Form.Control
                                type="text"
                                name={`interviews`}
                                placeholder="Enter Interviews"
                                defaultValue={recruitmentDetails?.interviews}
                                onChange={(e) => this.inputChangeHandler(e,'interviews',null)}
                            />
                            {this.state.validationErrors.interviews && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.interviews}
                                </Form.Text>
                            )}
                            <br/>
                            <Form.Label>Short Listed:</Form.Label>
                            <Form.Control
                                type="text"
                                name={`shortListed`}
                                placeholder="Enter Short Listed"
                                defaultValue={recruitmentDetails?.shortListed}
                                onChange={(e) => this.inputChangeHandler(e,'shortListed',null)}
                            />
                            {this.state.validationErrors.shortListed && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.shortListed}
                                </Form.Text>
                            )}
                            <br/>
                            <Form.Label>Rejected:</Form.Label>
                            <Form.Control
                                type="text"
                                name={`rejected`}
                                placeholder="Enter Rejected"
                                defaultValue={recruitmentDetails?.rejected}
                                onChange={(e) => this.inputChangeHandler(e,'rejected',null)}
                            />
                            {this.state.validationErrors.rejected && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.rejected}
                                </Form.Text>
                            )}
                        </Form.Group>
                        <hr/>
                        </>
                    </Modal.Body>
                    <Modal.Footer className='potential-footer' style={{ backgroundColor: "#434040"}}>
                        <Button
                            variant="primary"
                            type="reset"
                            style={{ backgroundColor: '#e7b487', borderColor: '#e7b487', width: '230px', height: '42px', fontSize: '14px' }}
                            onClick={() => this.isModalClose()}
                        >
                            CANCEL
                        </Button>
                        <Button
                            variant="primary"
                            type="submit"
                            style={{ backgroundColor: '#f5821f', borderColor: '#f5821f', width: '230px', height: '42px', fontSize: '14px' }}
                            onClick={this.handleSubmit}
                        >
                            SUBMIT
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div id="reserveStrengthModal">
                <Modal show={isModalOpen[6]} >
                    <Modal.Header >
                        <Modal.Title className='custom-modal-title chapter-modal f-14 h5'>
                            RESERVE STRENGTH ACTIVITY  - {chapterMonthItemsTab[monthTabIndexActive]?.label} {chapterMonthItemsTab[monthTabIndexActive]?.year}
                        </Modal.Title>
                        <button className="potential-button pull-right" onClick={() => this.isModalClose()}>X</button>
                    </Modal.Header>
                    <Modal.Body>                   
                            <Form.Group >
                                <Form.Label>Developers:</Form.Label>
                                {(reserveActivityList?.review_values?.details?.length > 0 && reserveActivityList?.review_values.details?.length != developers.length && reviewIndex == -1) &&
                                    <Form.Control
                                        as="select"
                                        name="developers"
                                        placeholder="Select developer"
                                        value={reserveStrengthDetails.developers?.name}
                                        onChange={(e) => this.inputChangeHandler(e,'developers',0)}
                                    >
                                        <option value="" disabled>Select Developers</option>
                                        {this.state.developers.map(developer =>
                                            <option id={developer.id} key={developer.id} value={developer.first_name}>{developer.first_name}</option>
                                        )}
                                    </Form.Control>
                                }
                                {(!reserveActivityList?.review_values?.details || reserveActivityList?.review_values?.details?.length === 0 || reviewIndex > -1) && 
                                    <Form.Control
                                        type="text"
                                        name={`developers`}
                                        placeholder="Enter Employee Name"
                                        value={reserveStrengthDetails.developers?.name}
                                        disabled
                                    />
                                }
                                <br/>
                                <Form.Label>Bucket:</Form.Label>
                                <div className="selectpicker_dropdown w-100 hide_bar w-100 selectpicker_dropdown_orange">
                                    <Select
                                        className="basic-single-select"
                                        classNamePrefix={"select_dropdown"}
                                        placeholder="Select Bucket"
                                        isClearable
                                        onChange={(e) => this.inputChangeHandler(e,'bucket',0)}
                                        value={reserveStrengthDetails.bucket}
                                        options={buckets}
                                        isMulti
                                    ></Select>
                                </div>
                                <br/>
                                <Form.Label>Note:</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="note"
                                    rows={3}
                                    placeholder="Enter Note"
                                    value={reserveStrengthDetails.note}
                                    onChange={(e) => this.inputChangeHandler(e,'note',0)}
                                />
                                 <Form.Text className="text-danger">
                                    {this.state.validationErrors.note}
                                </Form.Text>
                                <br/>
                                <Form.Label>Allocated hours:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={`allocatedHours`}
                                    placeholder="Enter Allocated hours"
                                    value={reserveStrengthDetails?.allocatedHours}
                                    onChange={(e) => this.inputChangeHandler(e,'allocatedHours',null)}
                                />
                                {this.state.validationErrors.allocatedHours && (
                                    <Form.Text className="text-danger">
                                        {this.state.validationErrors.allocatedHours}
                                    </Form.Text>
                                )}
                                <br/>
                                <Form.Label>Expected outcome:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={`expectedOutcome`}
                                    placeholder="Enter Expected outcome"
                                    value={reserveStrengthDetails?.expectedOutcome}
                                    onChange={(e) => this.inputChangeHandler(e,'expectedOutcome',null)}
                                />
                                {this.state.validationErrors.expectedOutcome && (
                                    <Form.Text className="text-danger">
                                        {this.state.validationErrors.expectedOutcome}
                                    </Form.Text>
                                )}
                                <br/>
                                <Form.Label>ETA:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name={`ETA`}
                                    placeholder="Enter ETA"
                                    value={reserveStrengthDetails?.ETA}
                                    onChange={(e) => this.inputChangeHandler(e,'ETA',null)}
                                />
                                {this.state.validationErrors.ETA && (
                                    <Form.Text className="text-danger">
                                        {this.state.validationErrors.ETA}
                                    </Form.Text>
                                )}
                                <br/>
                                <Form.Label>Comments:</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="comments"
                                    rows={3}
                                    placeholder="Enter Comments"
                                    value={reserveStrengthDetails.comments}
                                    onChange={(e) => this.inputChangeHandler(e,'comments',0)}
                                />
                                 <Form.Text className="text-danger">
                                    {this.state.validationErrors.comments}
                                </Form.Text>
                                <br/>
                            </Form.Group>                           
                    </Modal.Body>
                    <Modal.Footer className='potential-footer' style={{ backgroundColor: "#434040"}}>
                        <Button
                            variant="primary"
                            type="reset"
                            style={{ backgroundColor: '#e7b487', borderColor: '#e7b487', width: btnReserveWidth, height: '42px', fontSize: '14px' }}
                            onClick={() => this.isModalClose()}
                        >
                            CANCEL
                        </Button>
                        { (!reserveActivityListLength) &&
                            <Button
                                variant="primary"
                                type="button"
                                style={{ backgroundColor: '#f5821f', borderColor: '#f5821f', width: '150px', height: '42px', fontSize: '14px' }}
                                onClick={this.handlePrevious}
                                disabled={developerIndex==0}
                            >
                                PREVIOUS
                            </Button>
                        }
                        <Button
                            variant="primary"
                            type="submit"
                            style={{ backgroundColor: '#f5821f', borderColor: '#f5821f', width: btnReserveWidth, height: '42px', fontSize: '14px' }}
                            onClick={this.handleSubmit}
                        >
                            { ( reserveActivityList?.review_values?.details?.length > 0 || reviewIndex > -1) ? 'SUBMIT' : 'NEXT'}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div id="hitsMissesModal">
                <Modal show={isModalOpen[7]} >
                    <Modal.Header >
                        <Modal.Title className='custom-modal-title chapter-modal f-14 h5'>
                            CREATE HITS & MISSES  - {chapterMonthItemsTab[monthTabIndexActive]?.label} {chapterMonthItemsTab[monthTabIndexActive]?.year}
                        </Modal.Title>
                        <button className="potential-button pull-right" onClick={() => this.isModalClose()}>X</button>
                    </Modal.Header>
                    <Modal.Body>                     
                            <Form.Group >
                                <Form.Label>Employee Name:</Form.Label>
                                {(hitMissesList?.review_values?.length > 0 && hitMissesList?.review_values?.length != developers.length && reviewIndex == -1) &&
                                    <Form.Control
                                        as="select"
                                        name="developers"
                                        placeholder="Select developer"
                                        value={hitsMissesDetails.developers?.name}
                                        onChange={(e) => this.inputChangeHandler(e,'developers',0)}
                                    >
                                        <option value="" disabled>Select Employee Name</option>
                                        {this.state.developers.map(developer =>
                                            <option id={developer.id} key={developer.id} value={developer.first_name}>{developer.first_name}</option>
                                        )}
                                    </Form.Control>
                                }
                                {(Object.keys(hitMissesList).length === 0 || reviewIndex > -1) && 
                                    <Form.Control
                                        type="text"
                                        name={`developers`}
                                        placeholder="Enter Employee Name"
                                        value={hitsMissesDetails.developers?.name}
                                        disabled
                                    />
                                }
                                <br/>
                                <Form.Label>Hits:</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="Hits"
                                    rows={3}
                                    placeholder="Enter Hits"
                                    value={hitsMissesDetails.hits}
                                    onChange={(e) => this.inputChangeHandler(e,'hits',0)}
                                />
                                 <Form.Text className="text-danger">
                                    {this.state.validationErrors.hits}
                                </Form.Text>
                                <br/>
                                <Form.Label>Misses:</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="Misses"
                                    rows={3}
                                    placeholder="Enter Misses"
                                    value={hitsMissesDetails.misses}
                                    onChange={(e) => this.inputChangeHandler(e,'misses',0)}
                                />
                                 <Form.Text className="text-danger">
                                    {this.state.validationErrors.misses}
                                </Form.Text>
                                <br/>
                                <Form.Label>Opportunity:</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="Opportunity"
                                    rows={3}
                                    placeholder="Enter Opportunity"
                                    value={hitsMissesDetails.opportunity}
                                    onChange={(e) => this.inputChangeHandler(e,'opportunity',0)}
                                />
                                 <Form.Text className="text-danger">
                                    {this.state.validationErrors.opportunity}
                                </Form.Text>
                                <br/>
                            </Form.Group>                           
                    </Modal.Body>
                    <Modal.Footer className='potential-footer' style={{ backgroundColor: "#434040"}}>
                        <Button
                            variant="primary"
                            type="reset"
                            style={{ backgroundColor: '#e7b487', borderColor: '#e7b487', width: btnWidth, height: '42px', fontSize: '14px' }}
                            onClick={() => this.isModalClose()}
                        >
                            CANCEL
                        </Button>
                        { (hitMissessListLength == 0) &&
                            <Button
                                variant="primary"
                                type="button"
                                style={{ backgroundColor: '#f5821f', borderColor: '#f5821f', width: '150px', height: '42px', fontSize: '14px' }}
                                onClick={this.handlePrevious}
                                disabled={developerIndex==0}
                            >
                                PREVIOUS
                            </Button>
                        }
                        <Button
                            variant="primary"
                            type="submit"
                            style={{ backgroundColor: '#f5821f', borderColor: '#f5821f', width: btnWidth, height: '42px', fontSize: '14px' }}
                            onClick={this.handleSubmit}
                        >
                            { (hitMissessListLength > 0 || reviewIndex > -1) ? 'SUBMIT' : 'NEXT'}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div id="reserveSummarypModal">
                <Modal style={{width:"25rem",marginLeft: "37rem"}} show={isStrengthSummaryModal} >
                    <Modal.Header >
                        <Modal.Title className='custom-modal-title chapter-modal f-14 h5'>
                            
                        </Modal.Title>
                        <button className="potential-button pull-right" onClick={()=> this.setState({isStrengthSummaryModal: false })}>X</button>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Enter  {summaryModalName}:</Form.Label>
                            <Form.Control
                                type="text"
                                name={summaryModalName}
                                placeholder={`Enter  ${summaryModalName}`}
                                value={['operations','projectSupport','learning','initiatives'].includes(summaryModalKey) ?reserveStrengthSummary[summaryModalKey].noOfDev : reserveStrengthSummary[summaryModalKey]}
                                onChange={(e) => this.inputChangeHandler(e, summaryModalKey,null)}
                            />
                            <Form.Text className="text-danger">
                                {this.state.validationErrors[summaryModalKey]}
                            </Form.Text>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type='submit' onClick={this.handleSubmit}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div id="actionItemsModal">
                <Modal show={isModalOpen[8]} >
                    <Modal.Header >
                        <Modal.Title className='custom-modal-title chapter-modal f-14 h5 text-uppercase'>
                            ACTION ITEMS - {chapterMonthItemsTab[monthTabIndexActive]?.label} {chapterMonthItemsTab[monthTabIndexActive]?.year}
                        </Modal.Title>
                        <button className="potential-button pull-right" onClick={() => this.isModalClose()}>X</button>
                    </Modal.Header>
                    <Modal.Body>
                        <>
                        <Form.Group >
                            <Form.Label>Action Items:</Form.Label>
                            <Form.Control
                                type="text"
                                name={`actionItems`}
                                placeholder="Enter Action Items"
                                defaultValue={actionItemDetails?.actionItems}
                                onChange={(e) => this.inputChangeHandler(e,'actionItems',null)}
                            />
                            {this.state.validationErrors.actionItems && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.actionItems}
                                </Form.Text>
                            )}
                            <br/>
                            <Form.Label>Status:</Form.Label>
                            <Form.Control
                                as="select"
                                name="status"
                                placeholder="Select status"
                                value={actionItemDetails.status}
                                onChange={(e) => this.inputChangeHandler(e,'status',0)}
                            >
                                <option value="" disabled>Select status</option>
                                {['To Do', 'In Progress', 'Done'].map(status =>
                                    <option value={status}>{status}</option>
                                )}
                            </Form.Control>
                            <br/>
                            <Form.Label>Outcome:</Form.Label>
                            <Form.Control
                                type="text"
                                name={`outcome`}
                                placeholder="Enter Outcome"
                                defaultValue={actionItemDetails?.outcome}
                                onChange={(e) => this.inputChangeHandler(e,'outcome',null)}
                            />
                            {this.state.validationErrors.outcome && (
                                <Form.Text className="text-danger">
                                    {this.state.validationErrors.outcome}
                                </Form.Text>
                            )}
                            <br/>
                            <Form.Label>Comments:</Form.Label>
                            <Form.Control
                                as="textarea"
                                name={`comments`}
                                rows={3}
                                placeholder="Enter Comments"
                                defaultValue={actionItemDetails?.comments}
                                onChange={(e) => this.inputChangeHandler(e,'comments',null)}
                            />
                        </Form.Group>
                        <hr/>
                        </>
                    </Modal.Body>
                    <Modal.Footer className='potential-footer' style={{ backgroundColor: "#434040"}}>
                        <Button
                            variant="primary"
                            type="reset"
                            style={{ backgroundColor: '#e7b487', borderColor: '#e7b487', width: '230px', height: '42px', fontSize: '14px' }}
                            onClick={() => this.isModalClose()}
                        >
                            CANCEL
                        </Button>
                        <Button
                            variant="primary"
                            type="submit"
                            style={{ backgroundColor: '#f5821f', borderColor: '#f5821f', width: '230px', height: '42px', fontSize: '14px' }}
                            onClick={this.handleSubmit}
                        >
                            SUBMIT
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
    pulseScoreLists: state.chapterReviewDetails.pulseScoreLists,
    pipLists: state.chapterReviewDetails.pipLists,
    attritionLists: state.chapterReviewDetails.attritionLists,
    recruitmentLists: state.chapterReviewDetails.recruitmentLists,
    reserveActivityLists: state.chapterReviewDetails.reserveActivityLists,
    hitMissesLists: state.chapterReviewDetails.hitMissesLists,
    icrLists: state.chapterReviewDetails.icrLists,
    actionItemsLists: state.chapterReviewDetails.actionItemsLists,
    saveIcrRes: state.chapterReviewDetails.saveIcrRes,
    savePulseScoreRes: state.chapterReviewDetails.savePulseScoreRes,
  };
};

const mapDispatchToProps = {
  getLoginUserPermission,
  getPulseScore,
  savePulseScore,
  saveCompetencyIndex,
  getPip,
  getAttrition,
  getRecruitment,
  getReserveActivity,
  saveReserveActivity,
  getHitsMisses,
  saveChapterReviewData,
  getIcr,
  getActionItems,
  getDevelpersByReportId
};

export default connect(mapStateToProps, mapDispatchToProps)(ChapterReviewTab);
import React, { Component } from 'react';
import Cookies from "js-cookie";
import * as constants from "../../Config/Constant";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import PmsGuideUser from "../../videos/pms_guide_users.mp4";
import PmsGuideTL from "../../videos/pms_guide_tl.mp4";
import PmsGuideManager from "../../videos/pms_guide_manager.mp4";
import manageQuestion from "../../videos/manage_question.mp4";
import editQuestion from "../../videos/edit_question.mp4";
import {
    getLoginUserPermission,
} from "../../Action";

class PmsGuide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            queryParams: new URLSearchParams(window.location.search),
            loginUserData: {},
            loginUserPermission: {},
            loading: false,
            dataloading: false,
            isLoaded: true,
            role_id: 0,
            guideVideos: PmsGuideUser,
        };
    }

    componentDidMount() {
        let token =
          this.state.queryParams.get("token") === null
            ? Cookies.get("auth_data")
            : this.state.queryParams.get("token");
        let url =
          this.state.queryParams.get("url") == null
            ? Cookies.get("url_code")
            : this.state.queryParams.get("url");
        this.setState({
          token: token,
          apiLink: constants.API_LINK,
          angularUrl: constants.FRONT_LINK,
          excelUrl: constants.EXCEL_LINK,
          url: url,
        });
        Cookies.set("auth_data", token);
        Cookies.set("url_code", url);
        this.state.queryParams.delete("token");
        this.state.queryParams.delete("url");
        if (this.props.history != undefined) {
          this.props.history.replace({
            search: this.state.queryParams.toString(),
          });
        }
        if (token == "undefined" || token == undefined || token == null) {
          this.state.redirect = 1;
        } else {
          this.getUser();
        }

        //this.getNotificationList('initial');
    }
    
    componentDidUpdate(prevProps) {
        if (
            JSON.stringify(this.props.loginDetails) !==
            JSON.stringify(prevProps.loginDetails)
        ) {
            this.getUser();
        }
    }
    getUser = () => {
        const role_id = this.props?.loginDetails?.loginDetails?.role_id;
        this.setState({
            loginUserData: this.props?.loginDetails?.loginDetails,
            loginUserPermission: this.props?.loginDetails?.loginUserPermission,
            role_id
        });
        console.log(this.state.role_id);
        if (role_id != null || role_id != undefined || role_id != "undefined") {
            this.setState({ loading: false});
        }      
    };   

    render() {
        return (
            <div className="page-content px-4" style={{ maxHeight: "650px" }}>
                <Row>
                <div className="col-xs-12 fourty-fluid">
                    <Row className="row">
                    <div className="col-sm-4 col-xs-12 col-md-12 text-left">
                        <h1 className="B-G-bold f-s-18 d-gray t-t-upper pull-left mt-15 mr-20 page-head lh-10">
                        PMS Guide
                        </h1>
                    </div>
                    </Row>
                </div>
                </Row>
                <Row>
                <div className="col-xs-4 col-md-4 mt-5">
                    <h4>PMS Form</h4>
                    <div className="scrollable-content bgwhite text-center mx-1" style={{ padding: "15px" }}>
                    <video width="100%" controls>
                        <source src={PmsGuideUser} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    </div>
                </div>
                {(this.state.role_id === 4  || this.state.role_id === 10) &&
                    <>
                    <div className="col-xs-4 col-md-4 mt-5">
                        <h4>PMS Comments</h4>
                        <div className="scrollable-content bgwhite text-center mx-1" style={{ padding: "15px" }}>
                        <video width="100%" controls>
                            <source src={PmsGuideTL} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        </div>
                    </div>
                    {this.state.role_id === 10 &&
                        <div className="col-xs-4 col-md-4 mt-5">
                            <h4>PMS Manager Comments</h4>
                            <div className="scrollable-content bgwhite text-center mx-1" style={{ padding: "15px" }}>
                            <video width="100%" controls>
                                <source src={PmsGuideManager} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            </div>
                        </div>
                    }
                    <div className="col-xs-4 col-md-4 mt-5">
                        <h4>Manage Question</h4>
                        <div className="scrollable-content bgwhite text-center mx-1" style={{ padding: "15px" }}>
                        <video width="100%" controls>
                            <source src={manageQuestion} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        </div>
                    </div>
                    <div className="col-xs-4 col-md-4 mt-5">
                        <h4>Edit Question</h4>
                        <div className="scrollable-content bgwhite text-center mx-1" style={{ padding: "15px" }}>
                        <video width="100%" controls>
                            <source src={editQuestion} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        </div>
                    </div>
                    </>
                }                    
                </Row>
          </div>    
        );
    }
}

const mapStateToProps = (state) => {
    return {
      loginDetails: state.loginDetails
    };
  };
  
const mapDispatchToProps = {
    getLoginUserPermission,
};

export default connect(mapStateToProps, mapDispatchToProps)(PmsGuide);
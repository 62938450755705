import React from "react";
import Select from "react-select";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import PageNotFound from "../Reports/PageNotFound";
import DatePicker from "react-datepicker";
import accessdenied from "../../access-denied.png";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import moment from "moment";
import { Urls } from "../../Config/Config";
import { Row, Col, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { Dropdown } from "rsuite";
import { getLoginUser, getLoginUserPermission, getSquadReviewReport, getReportFilterPmo, squadReportExportExcel } from "../../Action";
import { getReportFilterService } from "../../Utilities/Helper";
import "../Reports/Report.css";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "rsuite/dist/rsuite.min.css";
import * as constants from "../../Config/Constant";
import BusinessComplianceView from "./BusinessComplianceView";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  customClass: {
    popup: "colored-toast",
    icon: "white-toast-icon",
  },
  timer: 3000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

class SquadReviewReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: new URLSearchParams(window.location.search),
      redirect: 0,
      current_report: Urls.reports_lists[12],
      sname: [],
      snameclone: [],
      practiceHead: [],
      loginUserData: {},
      loginUserPermission: {},
      token: "",
      loading: false,
      defaultActiveKey: 'squad_view',
      bu_id: "",
      selectService: [],
      selectBu: [],
      selectBuFilter: [],
      serviceName: [],
      usersService: [],
      totalAllocationValue: 0,
      filterStartDate: new Date(),
      filterEndDate: new Date(),
      report: {
        service: []
      },
      reportService: [],
      squadReports: [],
      squadReportsClone: [],
      businessClassification: 0
    };
  }

  reset_page = () => {
    this.setState({
      report: {
        service: [],
        practice: [],
      },
      selectService: [],
      searchBy: '',
      reportService: []
    }, () => {
      this.getSquadReport();
    });
  };

  componentDidMount() {
    let token = this.state.queryParams.get("token") == null ? Cookies.get("auth_data") :
      this.state.queryParams.get("token");
    this.setState({
      token: token,
      apiLink: constants.API_LINK,
      angularUrl: constants.FRONT_LINK,
      excelUrl: constants.EXCEL_LINK
    });
    this.getUser();
  }

  /**
   * Get the login user details
   */
  getUser = () => {
    this.setState({ loading: true });
    this.props.getLoginUser().then((response) => {
      this.props.getLoginUserPermission().then((response) => {
        this.setState({
          loading: false, loginUserData: this.props.loginDetails.loginDetails,
          loginUserPermission: this.props.loginDetails.loginUserPermission
        });
        this.getPracticeService();
      });
    });
  }

  //filter practice based on service and head
  filterService = (e) => {
    this.state.searchBy = '';
    if (e) {
      let selectedOption = e;
      this.setState({
        report: {
          service: selectedOption,
        },
        selectService: selectedOption,
      }, () => {
        this.getSquadReport();
      });
    }
    else {
      this.getSquadReport();
    }
  };

  ToastAlert(icon, title) {
    return Toast.fire({
      target: document.getElementById("form-modal"),
      icon: `${icon}`,
      title: `${title}`,
    });
  }

  resetReport = (e) => {
    e.currentTarget.blur(); // removing focus
    this.setState({
      searchBy: '',
    });
    this.getSquadReport();
  };

  generateSquadReviewAsExcel = (e) => {
    this.setState({ loading: true });

    let postData = {
      reportDetails:{
        fileName: "Squad-review-report",
        squadReports : this.state.squadReports,
      }
    }

    this.props.squadReportExportExcel(postData).then((response) => {
      this.setState({ loading: false });
      this.ToastAlert("success", "Report generated Successfully");
      document.location = this.state.excelUrl + this.props.excelResult.filename;
    });
  }

  searchBy = (e) => {
    let searchInput = e.target.value;
    let filteredData = [];
    
    if (searchInput) {
      filteredData = this.state.squadReportsClone.filter((item) => {
        return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase());
      })
      this.setState({
        searchBy: e.target.value
      });
    }
    else {
      this.setState({
        searchBy: ''
      });
      filteredData = this.state.squadReportsClone;
    }
    this.setState({
      squadReports: filteredData,
    })
  }

  businessFilter = () => {
    let dateValue = new Date();
    let date = new Date(dateValue.getFullYear(), dateValue.getMonth(), 2);
    let startDate, endDate;
    startDate = this.state.filterStartDate || date;
    endDate = this.state.filterEndDate || date;

    let dataObject = {
      start_date: startDate,
      end_date: endDate,
    };

    let c = 0;
    this.setState({
      loading: true,
    });

    this.props.getReportFilterPmo(dataObject).then((response) => {
      this.props.pmoFilter.directors.forEach((value) => {
        if (this.state.bu_id == value.bu_id) {
          c = 1;
        }
      });

      let bu_id = c == 1 ? this.state.bu_id : "";
      let selectBuFilter = c == 1 ? this.state.selectBuFilter : [];
      let sname = [];
      let allService = [], serviceName = [];
      if (this.state.bu_id != "" && this.state.bu_id != undefined && this.state.bu_id != 0) {
        this.props.pmoFilter.services.forEach((service) => {
          if (this.state.bu_id == service.bu) {
            sname.push(service);
          }
        });
        this.props.pmoFilter.allservices.forEach((service) => {
          if (this.state.bu_id == service.bu) {
            allService.push(service);
          }
        });
      } else {
        sname = this.props.pmoFilter.services;
        allService = this.props.pmoFilter.allservices;
      }

      this.setState({
        usersService: this.props.pmoFilter.services,
        snameclone: this.props.pmoFilter.services,
        bu_id: bu_id,
        selectBuFilter: selectBuFilter,
        sname: sname,
        allService: allService
      });

      this.state.snameclone.forEach((value) => {
        let opt = {};
        opt.label = value.value;
        opt.id = value.id;
        opt.value = value.value;
        opt.head = value.head;
        opt.bu = value.bu;
        serviceName.push(opt);
      });

      // for practicehead
      let practiceHead = [];
      this.props.pmoFilter.directors.forEach((id) => {
        practiceHead.push(id);
      });
      
      let buName = [];
      this.props.pmoFilter.directors.forEach((value) => {
        let opt = {};
        opt.label = value.bu;
        opt.value = value.bu;
        opt.bu_id = value.bu_id;
        opt.id = value.id;
        opt.bu = value.bu;
        opt.name = value.name;
        buName.push(opt);
      });

      this.setState({
        practiceHead: practiceHead,
        buName: buName,
        serviceName: serviceName
      });

      if (this.state.loginUserData.role_id == 8) {
        this.setState({
          searchByPracticeHead: this.state.loginUserData.role_id,
        })
      }
    }, (error) => {
      this.setState({
        usersService: [],
        snameclone: [],
        bu_id: '',
        selectBuFilter: [],
        sname: [],
        allService: []
      })
    });
  }

  getPracticeService = async (isAllPractice) => {
    this.setState({
      loading: true,
      isAllPractices: isAllPractice,
      filterService: [],
      report: {
        service: []
      },
      sname: [],
      allService: [],
      serviceName: []
    });
    await this.businessFilter();
    await this.getSquadReport();
  };

  getReportByHead = (e) => {
    this.state.searchBy = '';
    if (e != undefined) {
      this.setState({
        bu_id: e.bu_id,
        head_id: e.label,
        selectBuFilter: e
      }, () => {
        this.getBuFilter(this.state);
      });
    } else {
      this.setState({
        bu_id: '',
        head_id: 'All',
        selectBuFilter: []
      }, () => {
        this.getBuFilter(this.state);
      });
    }
  };

  getBuFilter = () => {
    let serviceArray = [];
    let service_head = [];
    if (this.state.head_id != "All") {
      // for BU filter    
      this.state.snameclone.forEach((service) => {
        if (this.state.bu_id == service.bu) {
          service_head.push(service);
        }
      });
      this.setState({
        serviceName: [],
        selectService: [],
        report: {
          service: []
        }
      })
    } else {
      // when All BU is selected
      this.setState({
        serviceName: [],
        selectService: [],
        selectBuFilter: []
      })
      if (this.state.report.service.length == 0) {
        this.state.snameclone.forEach((service) => {
          service_head.push(service);
        });
      } else {
        service_head = this.state.sname;
        this.setState({
          report: {
            service: []
          }
        })
      }
    }
    service_head.forEach((value) => {
      let opt = {};
      opt.label = value.value;
      opt.id = value.id;
      opt.value = value.value;
      opt.head = value.head;
      opt.bu = value.bu;
      serviceArray.push(opt);
    });
    this.setState({
      serviceName: serviceArray,
    }, () => {
      this.getSquadReport();
    });
  }

  changeTabView = (tabName) => {
    this.setState({
      businessClassification: 0,
      defaultActiveKey: tabName,
    });
  };

  showBusinessComplience = (businessClassification) =>{
    this.setState({
      businessClassification, 
      defaultActiveKey: 'business_view',
    });
  }

  setStartDateForFilter = (e) => {
    let today = new Date(e);
    this.setState({
      filterStartDate: today,
    });
  };

  getSquadReport = () => {
    this.setState({ loading: true });
    let dataObj = this.getInputData();
    this.props.getSquadReviewReport(dataObj)
      .then((response) => {
        this.setState({
          loading: false,
          squadReports: this.props.squadReportDetails.squadReport,
          squadReportsClone: this.props.squadReportDetails.squadReport,
        });
    }, (error) => {
      this.setState({
        loading: false,
        squadReports: [],
        squadReportsClone: [],
      });
      this.ToastAlert("error", error);
    });
  }

  getInputData = () => {
    let dataObj = {}, reportData = {};
    reportData.business_unit = this.state.bu_id;
    if (this.state.report.service.length > 0) {
      reportData.service = this.state.report.service;
    }
    else {
      // getting user associated service list
      reportData.service = getReportFilterService(this.state.serviceName, this.state.loginUserData, this.state.usersService);
    }
    reportData.start_date = moment(this.state.filterStartDate)
      .utcOffset('+05:30')
      .format('YYYY-MM-01');
    dataObj['reportData'] = reportData;
    return dataObj;
  }

  /**
   * main render
   * @returns
   */
  render() {
    if (this.state.redirect == 1 || this.state.loginUserData == null) {
      return <PageNotFound url={this.state.angularUrl} />
    }
    else {
      return (
        <>
          {this.state.loading &&
            <div id="preloader">
              <div id="status"></div>
            </div>
          }
          <div className="page-content px-4">
            <Row>
              <div className="col-xs-12 fourty-fluid fourty-fluid">
                <Row className="mb-3">
                  <div className="col-sm-4 col-sm-4 col-xs-12 text-left">
                    <h1 className="B-G-bold f-s-18 d-gray t-t-upper pull-left mt-10 mr-20 page-head">Reports</h1>
                  </div>
                  <div className="col-lg-3 col-sm-4 col-xs-12">
                    <div className="selectpicker_dropdown hide_cross hide_bar orange_arrow remove_border_btm select_dropdown_picker">
                      <Dropdown title={this.state.current_report.value} activeKey="13">
                        <Dropdown.Item as="a" eventKey="1" href="/pw_report" target="_blank">Practice Wise Allocation Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="2" href="/ms_report" target="_blank">Milestone Value Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="3" href="/collections_report" target="_blank">Collections Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="4" href="/collections_potential_report" target="_blank">Collection Potential Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="5" href="/inventory_report" target="_blank">Inventory Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="6" href="/inventory_allocation_report" target="_blank">Inventory Allocation Metrics</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="7" href="/inventory_milestone_report" target="_blank">Inventory Milestone Metrics</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="8" href="/utilization_report" target="_blank">Resource Utilization Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="9" href="/csm_report" target="_blank">Csm Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="10" href="/pmo_ms_report" target="_blank">Pmo Ms Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="11" href="/acv_report" target="_blank">Annual Contract Value Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="12" href="/ms-allocation-report" target="_blank">Ms Allocation Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="13">Squad Review Report</Dropdown.Item>
                      </Dropdown>
                    </div>
                  </div>
                </Row>
                {this.props.loginDetails.loginUserPermission && this.props.loginDetails.loginUserPermission.permission && this.props.loginDetails.loginUserPermission.permission.view_squad_review_report &&
                  <>
                    <Row>
                      <div className="col-lg-6 clearfix m-b-20">
                        <div className="row align-items-center">
                          {<div class="col-sm-4 col-xs-12 text-left"><label class="text-uppercase m-b-0 fs-13 p-t-5">Line of service :</label></div>}
                          <div className="col-lg-7 col-sm-5 text-left ">
                            <Row>
                              {<div className="col-lg-10 p-l-0">
                                <div className="selectpicker_dropdown w-100 hide_bar w-100 selectpicker_dropdown_orange">
                                  <Select
                                    className="basic-single-select"
                                    classNamePrefix={"select_dropdown"}
                                    placeholder="All"
                                    isClearable
                                    isSearchable={true}
                                    onChange={(e) => this.filterService(e)}
                                    value={this.state.selectService}
                                    options={this.state.serviceName} isMulti>
                                  </Select>
                                </div>
                              </div>}
                              <div className="col-lg-2 p-1-0">
                                {this.state.report.service != undefined && this.state.report.service.length > 0 &&
                                  <a href="javascript:void(0)" title="Reset" tooltip-placement="top" className="reset-icon_old  reset-color p-l-5 m-l-4" onClick={this.reset_page}><i class="fs-16 fa fa-refresh"></i></a>
                                }
                              </div>
                            </Row>
                          </div>
                        </div>
                      </div>
                      {this.state.loginUserData.role_id != 8 &&
                        <div className="col-lg-4 clearfix m-b-20">
                          {<div className="row">
                            <div class="col-sm-5 col-xs-12">
                              <label className="text-uppercase m-b-0 fs-13 p-t-5">Business Unit :</label>
                            </div>
                            <div className="col-sm-7 col-xs-12">
                              <div className="selectpicker_dropdown hide_bar w-100 selectpicker_dropdown_orange">
                                <Select
                                  className="basic-single-select"
                                  classNamePrefix={"select_dropdown"}
                                  placeholder="All"
                                  isClearable
                                  isSearchable={true}
                                  onChange={(e) => this.getReportByHead(e)}
                                  value={this.state.selectBuFilter}
                                  options={this.state.buName} >
                                </Select>

                              </div>
                            </div>
                          </div>}
                        </div>}
                      <div className="col-lg-2 col-sm-2 col-xs-12 justify-content-end text-end">
                        <a href={this.state.angularUrl} className="btn btn-primary">Back</a>
                      </div>
                    </Row>
                    <hr className="m-t-5 col-sm-12 m-b-8 p-0"></hr>
                    <Row className=" mt-3">
                      <Col className="col-lg-12 text-start col p-0">
                        <Row className="m-0 mt-1">
                          <Col className="col-lg-2 text-start p-r-0">
                            <label className="p-0 B-G-bold f-s-2 d-gray m-t-3 m-b-10 page-head pull-left">Month Filter:</label>
                          </Col>
                          <Col className="col-lg-2 text-start">
                            <div className="row mb-2 date_picker_align">
                              <DatePicker
                                className="datepicker-control-section"
                                onChange={this.setStartDateForFilter}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                todayButton={"Today"}
                                name="start_date"
                                selected={this.state.filterStartDate}
                              />
                              <span className="cal_icon_date" role="button" tabIndex="0">
                                <i className="icon-calendar"></i>
                              </span>
                            </div>
                          </Col>
                          <Col className="col-lg-4 text-end">
                            <Row className="m-0 mt-1">
                              <Col className="col-lg-6">
                                <Button onClick={(e) => this.resetReport(e)} value="Generate Report" className="me-2" >Generate Report</Button>
                              </Col>
                              { this.state.defaultActiveKey == 'squad_view' && 
                                <Col className="col-lg-6 text-left">
                                  <Button onClick={(e) => this.generateSquadReviewAsExcel(e)} value="Excel" className="me-2" disabled={this.state.squadReports.length == 0 ? true : false}>Export as Excel</Button>
                                </Col>
                              }
                            </Row>
                          </Col>
                          <Col className="col-lg-4 text-start">
                            <div className="form-group search-icon col-sm-12 col-xs-12">
                              <input type="text" name="project_filter" value={this.state.searchBy} className="form-control B-G-bold f-s-16 d-gray pl-30" placeholder="Search..." onChange={(e) => this.searchBy(e)} />
                              <i class="fa fa-search"></i>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <div className="invoice--report bg-transparent inventory_allo_table inventory_allo_tablereact p-l-0 p-r-0 p-t-0 p-b-0 m-t-5 squad-report-table">
                                <Tabs
                                activeKey={this.state.defaultActiveKey}
                                id="main_view"
                                className="mb-3"
                                onSelect={(event) => {
                                    this.changeTabView(event);
                                }}
                                >
                                    <Tab
                                    eventKey="squad_view"
                                    title="Main View"
                                    >
                                      <div className="table-responsive white-table-class">
                                            <div className="scrollable">
                                                <div className="scrollable-content">
                                                    <table className="table m-b-0 table-wrap">
                                                      <thead className="bg-blue">
                                                        <tr className="theadInner">
                                                              <th className="text-start thead1">S.No</th>
                                                              <th className="text-start thead1" >Projects</th>
                                                              <th className="text-start thead1" >Business Unit	</th>                
                                                              <th className="text-start thead1" >Squad Lead</th>
                                                              <th className="text-start thead1" >PO</th>    
                                                              <th className="text-start thead1" >Velocity</th>                    
                                                              <th className="text-start thead1" >Velocity Gap</th>
                                                              <th className="text-start thead1" >Margin</th>
                                                              <th className="text-start thead1" >Yield</th>
                                                              <th className="text-start thead1" >Cost on Month</th>
                                                              <th className="text-start thead1" >MS Allocation</th>
                                                              <th className="text-start thead1" >Squad Count</th>
                                                              <th className="text-start thead1" >Support Count</th>
                                                              <th className="text-start thead1" >Business compliance (% value)</th>
                                                              <th className="text-start thead1" >Engineering compliance (% value)</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {!this.state.loading && this.state.squadReports && Object.keys(this.state.squadReports).length == 0 &&
                                                            <tr>
                                                              <td colSpan="15" className="r-no-data text-center">No data available</td>
                                                            </tr>
                                                          }
                                                          {!this.state.loading && this.state.squadReports && Object.keys(this.state.squadReports).length > 0 && Object.values(this.state.squadReports).map((report) => (
                                                              <>
                                                                <tr>
                                                                  <td className="text-start">
                                                                    {report.Sno}
                                                                  </td>
                                                                  <td className="text-start">
                                                                    {report.projectName}
                                                                  </td>
                                                                  <td className="text-start">
                                                                    {report.buName}
                                                                  </td>
                                                                  <td className="text-start">
                                                                    {report.squadLead}
                                                                  </td>
                                                                  <td className="text-start">
                                                                    {report.productOwner}
                                                                  </td>
                                                                  <td className="text-start">{report.velocity}
                                                                  </td>
                                                                  <td className="text-start">{report.velocityGap}
                                                                  </td>
                                                                  <td className="text-start">{report.margin + '%'}
                                                                  </td>
                                                                  <td className="text-start">{report.yeildValue}
                                                                  </td>
                                                                  <td className="text-start">{report.plannedCost}
                                                                  </td>
                                                                  <td className="text-start">{report.userMilestoneValue}
                                                                  </td>
                                                                  <td className="text-start">{report.squadCount}
                                                                  </td>
                                                                  <td className="text-start">{report.supportCount}
                                                                  </td>
                                                                  <td className="text-start">
                                                                    <div onClick={() => this.showBusinessComplience(report.projectClassification)} className="bench_data" title='Please click to see business complience'>0%</div>
                                                                  </td>
                                                                  <td className="text-start">0%
                                                                  </td>
                                                                </tr>
                                                              </>
                                                          ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>  
                                    </Tab>
                                    <Tab eventKey="business_view" title="Business compliance">
                                        <BusinessComplianceView 
                                          businessClassification={this.state.businessClassification}
                                        />
                                    </Tab>
                                    {/* <Tab
                                    eventKey="engineering_view"
                                    title="Engineering compliance"
                                    >
                                        <div className="table-responsive white-table-class reports--grid ">
                                            <div className="scrollable">
                                                <div className="scrollable-content">
                                                    <table className="table m-b-0 table-wrap">
                                                        <thead className="bg-blue">
                                                            <tr className="theadInner">
                                                                    <th className="text-start thead1" style={{"width": "20%","padding": "10px"}}>Client Name</th>
                                                                    <th className="text-start thead1" style={{"width": "20%","padding": "10px"}}>Project Name</th>
                                                                    <th className="text-start thead1" style={{"width": "10%","padding": "10px"}}>Invoice No</th>                
                                                                    <th className="text-start thead1" style={{"width": "15%","padding": "10px"}}>Invoice Pending</th>
                                                                    <th className="text-start thead1" style={{"width": "10%","padding": "10px"}}>Due Date</th>    
                                                                    <th className="text-start thead1" style={{"width": "10%","padding": "10px"}}>Ageing Period</th>                    
                                                                    <th className="text-start thead1" style={{"width": "30%"}}>Milestone status</th>
                                                                </tr>
                                                        </thead>
                                                        <tbody>
                                                            
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>   
                                    </Tab> */}
                                </Tabs>
                            </div>
                  </>
                }
                {this.props.loginDetails.loginUserPermission && this.props.loginDetails.loginUserPermission.permission && !this.props.loginDetails.loginUserPermission.permission.view_squad_review_report &&
                  <div className="page-content">
                    <div className="col-md-12 m-b-30">
                      <div className="four_not_err access_denied">
                        <div className="for_content">
                          <img alt="sad" width="" src={accessdenied} />
                          <h1>You don't have permission to access this page</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </Row>
          </div>
        </>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    loginDetails: state.loginDetails,
    pmoFilter: state.reportFilterDetails.pmoFilter,
    squadReportDetails: state.squadReportDetails.squadReport,
    excelResult: state.squadReportDetails.excelResult,
  };
};

const mapDispatchToProps = {
  getLoginUser,
  getLoginUserPermission,
  getSquadReviewReport,
  getReportFilterPmo,
  squadReportExportExcel,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SquadReviewReport);

import React, { Component, useEffect, useState } from 'react';
import { Urls } from '../../Config/Config';
import './Report.css';
import Select from 'react-select';
import {Grid,Row,Col,Button,Form,Spinner} from 'react-bootstrap';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import moment from 'moment';
import Swal from "sweetalert2"; 
import Cookies from 'js-cookie';
import PageNotFound from './PageNotFound';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";  
import 'bootstrap/dist/css/bootstrap.min.css';  
import exchange from '../../exchange.png';
import "antd/dist/antd.css";
import {getUserService, getReportFilterService, practiceRename } from "../../Utilities/Helper";
import { Dropdown } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { setAddClass} from "../../Action";
import { connect } from "react-redux";
import CommentSection from './CommentSection';
var _ = require("lodash");

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    showCloseButton: true,
    customClass: {
      popup: 'colored-toast',
      icon: 'white-toast-icon'
    },
    timer: 3000,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
  });
class CollectionPotentialReport extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
            queryParams : new URLSearchParams(window.location.search), 
            token:"",
            redirectUrl: '',     
            redirect: 0,  
            user_types : Urls.user_types,
            reports_lists : Urls.reports_lists,
            reports_lists1: Urls.reports_lists1,
            current_report : Urls.reports_lists[3],
            collection_potential_invoicestatus : Urls.invoice_status,
            report : {},
            projectPractice : [],
            project_practice : [],
            isAllPractices : false,
            getUserscount : '',
            practiceleadName : '',
            report_practice : [],    
            gridListBackup : [],
            gridList : [],
            planned_hrs : 0,
            support_hrs : 0,
            unallocated_hrs : 0,
            effective_yield : 0,
            expected_yield : 0,
            gap_in_allocation : 0,
            deficit : 0,    
            userTypeId : 0,
            totalResAvHrCal : 0,
            msAlloc : 0,
            supphrs : 0,
            totalResourceAvailHrsFrontendCalcution : 0,
            msAllocatedHrs : 0,
            supportHrs : 0,
            benchHrs : 0,
            userTypes : Urls.user_types,
            isGanttView : false,
            updateLoader : false,
            current_Gant_popover : {},
            head_id :'',
            // for inventory report
            support_practice : [6,12,13,21],
            inventory_isDetailedView : true,
            searchByPracticeHead: '',
            // for TL and BA    
            techLeadList : [],
            businessAnalystList : [],
            clientNameList : [],
            techLeadId : '',
            businessAnalystId : '',
            searchByTechLead : '',
            searchByBusinessAnalyst : '',
            selectClientName : {},
            isChecked :false,
            // for milestone report    
            myPracticeMileStone : 0,
            allPracticeMileStone : 0,
            mileStoneValueReport : {},
            filteredMilestoneData : [],
            filteredMilestoneDatas : [],
            filteredMilestoneClone : [],
            mileStoneReportPractice : 1,
            filterMileStoneValue : '',
            milestone_achieved_dropdown_value : Urls.milestone_achieved_dropdown_value,
            invoice_dropdown_value : Urls.milestone_achieved_dropdown_value,
            // for collection-potential report    
            currentFilter : {'id':0, 'value':'All'},
            true_data : [],
            checked_data : {},
            sumoffilterMileStoneValue : 0,
            SumofMileStoneValue : 0,
            collection_potential_invoicestatus : Urls.invoice_status,
            return_to_pwreport : 0,
            resource_types : Urls.resource_types,
            resource_types_new : Urls.resource_types_new,
            resourceFilter : {'id':0, 'value':'All'},
            filter_start_date : new Date(),
            filter_end_date :new Date(),
            sname :[],
            sname1 : [],
            inventory_isDetailedView:false,
            report_practice:[],
            report_service : [],
            projectPractice :[],
            practiceHead : [],
            totalMileStoneValue : 0,
            totalKickoffValue : 0,
            totalCarryValue : 0,
            filterMileStoneValue :0,
            filteredMilestoneData:[],
            milestoneReport :[],
            mileStoneValueReport :  {},
            submitted :false,
            practiceReport : [],
            overallMileStoneValue : 0,
            sumOfNotSureValue : 0,
            notUpdatedValue : 0,
            totalNewValue : 0,
            totalRaisedValue : 0,
            totalCollectedValue : 0,
            totalPendingValue : 0,
            totalNotYetDueValue : 0,
            totalOnDueValue : 0,
            mileStoneReportMonth :'',
            mileStoneReportYear : '',
            myPracticeMileStone:0,
            allPracticeMileStone:0,
            filter:[],
            filterByPractice :[],
            filterMileStoneValue:0,
            filterNotSure:0,
            filterNotUpdated:0,
            filterNewValue:0,
            filterRaisedValue:0,
            filterCollectedValue:0,
            filterPendingValue:0,
            selectedInvoiceStatus:'All',
            selectedInvoice:'All',
            loginUserData:{},
            loading: false,
            selectService:[],
            start_month:moment()
            .utcOffset('+05:30')
            .format('YYYY-MM'),
            inv_class :"text-start",
            filter_class :"",
            prac_filter_class :"",
            reportList:[],
            checkedList:[],
            selectService:[],
            serviceName:[],
            selectBuFilter:[],
            view_all:1,
            searchBy:'',
            reportF:0,
            true_data : [],
            checked_data : {},
            techLeadList:[],
            selectBusinessAnalyst :[],
            selectTechLead : [],
            invoiceStatusOptions:[],
            selectInvoiceStatus : null,
            dueDateOptions:[],
            invoiceValue:[],
            selectDueDate : null,
            selectInvoiceValue : null,
            selectTechLeadId : '',
            selectBusinessAnalystId :'',
            bu_id :'',
            bu_qes : Urls.bu_qes,
            practice_qes : Urls.practice_qes,
            service_qes : Urls.service_qes,
            usersService : [],
            showCommentModal: false,
            iterationDetails: [],
            defaultActiveKey: 'pending_view',
            invoiceDetails: [],
            commonInvCritAcc: 0,
            filterId: this.props.match.params.filter_id,
            filterName: this.props.match.params.filter_name
		}
	}

    reset_page = () =>{
        this.state.report.service = [];
        this.state.report.practice = [];
        this.state.selectService = [];
        this.getTechLeadList();
        this.generateCollectionPotentialReport();
    }

    setStartDateForFilter = (e) =>{
        var today = new Date(e);
        var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth()+1, 0); 
        this.state.filter_start_date =today;
        this.setState({
            filter_start_date : this.state.filter_start_date,
            filter_end_date : lastDayOfMonth
        })  
    }

    setEndDateForFilter = (end_date) => {
        this.setState({
            filter_end_date : end_date
        })
    }

    componentDidMount() {   
        this.state.reports_lists.forEach(value=>{
            var opt ={};
           opt.label = value.value;
           opt.id = value.id;        
           opt.value = value.value;
           this.state.reportList.push(opt);
        })
        
        this.state.token =
            this.state.queryParams.get("token") == null
                ? Cookies.get("auth_data")
                : this.state.queryParams.get("token");
            this.state.url =
            this.state.queryParams.get("url") == null
                ? Cookies.get("url_code")
                : this.state.queryParams.get("url");
            Cookies.set("auth_data", this.state.token);
            Cookies.set("url_code", this.state.url);
            this.state.queryParams.delete("token");
            this.state.queryParams.delete("url");
            if (this.state.url == undefined || this.state.url === "undefined") {
                this.state.redirect = 1;
            } else {
                if (atob(this.state.url) === "localhost") {
                    this.state.apiLink = "http://127.0.0.1:8000/api/";
                    this.state.angularUrl = "http://localhost:22400/#!/";
                    this.state.excelUrl = "http://localhost:8000/";
                } else if (atob(this.state.url) === "staging") {
                    this.state.apiLink =
                    "https://reflections-staging.optisolbusiness.com/api/";
                    this.state.angularUrl =
                    "https://reflectionsstaging.optisolbusiness.com/#!/";
                    this.state.excelUrl =
                    "https://reflections-staging.optisolbusiness.com/";
                } else if (atob(this.state.url) === "uat") {
                    this.state.apiLink =
                    "https://reflectionsservicestg.optisolbusiness.com/api/";
                    this.state.angularUrl =
                    "https://reflectionsstg.optisolbusiness.com/#!/";
                    this.state.excelUrl =
                    "https://reflectionsservicestg.optisolbusiness.com/";
                } else if (atob(this.state.url) === "live") {
                    this.state.apiLink =
                    "https://reflectionsservice.optisolbusiness.com/api/";
                    this.state.angularUrl = "https://reflections.optisolbusiness.com//#!/";
                    this.state.excelUrl = "https://reflectionsservice.optisolbusiness.com/";
                } else {
                    // do nothing
                }
            }
            if (this.props.history != undefined) {
                this.props.history.replace({
                    search: this.state.queryParams.toString(),
                });
            }
        
        this.setState({
            token :this.state.token,
        })
       this.getUser();
       this.getPracticeService();
	}

    componentWillMount() {
        this.props.history.listen((location, action) => {
            if(location?.state){
                this.setState({
                    filterId: location.state.filter_id,
                    filterName: location.state.filter_name
                });
                this.getPracticeService();
            }
        });
    }

    getUser =() =>{
        var link = this.state.apiLink+'get_login_user';
        
        if(this.state.token == null){
            this.state.token = Cookies.get('auth_data');
        }
        if(this.state.token == 'undefined'){
           this.state.redirect=1;
        }    
        else{
            return fetch(link,{
                method: "GET",                
                        
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization" :"Bearer "+ this.state.token 
                }
            }).then(res => res.json()).then(res => {
               this.state.loginUserData =res.result; 
            })
       }   
       
    }
      
    selectFilter = (e,id) =>{       
        this.state.selectedInvoiceStatus = e.target.value
        this.setState({
            selectedInvoiceStatus : this.state.selectedInvoiceStatus
        })
        this.generateCollectionPotentialReport(true)
    }

    getPracticeService = (e,isAllPractice) => {
        this.state.isAllPractices = isAllPractice;
        this.setState({ loading: true });
        this.state.filterService = [];
        var date = new Date();
        var date = new Date(date.getFullYear(), date.getMonth(), 2);
        var  start_date = (this.state.filter_start_date) ? this.state.filter_start_date : date;
        var  end_date = (this.state.filter_end_date) ? this.state.filter_end_date : date;
        var isAllPractices = isAllPractice;
        var link = this.state.apiLink+'get_report_filters_new';
        this.state.sname = [];
        this.state.allService = [];
        this.state.serviceName = [];
        if(this.state.token == null){
            this.state.token = Cookies.get('auth_data');
        }
        if(this.state.token == 'undefined'){
           this.state.redirect=1;
        }       
        else{
            return fetch(link,{
                method: "POST",                
                body:JSON.stringify( {
                    start_date,
                    end_date,
                    filter:isAllPractice
                }),               
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization" :"Bearer "+ this.state.token 
                }
            }).then(res => res.json()).then(res => {
                this.setState({
                    loading: false,
                })
                
                var c = 0;
                res.result.directors.forEach(value =>{
                        if(this.state.bu_id == value.bu_id){
                            c = 1;
                        }
                })
                this.state.bu_id = (c==1) ? this.state.bu_id : '';
                this.state.selectBuFilter = (c==1) ? this.state.selectBuFilter : [];
                if(this.state.bu_id != '' && this.state.bu_id != undefined && this.state.bu_id !=0 ){
                    res.result.services.forEach(service=>{
                        if(this.state.bu_id == service.bu){
                            this.state.sname.push(service);
                        }
                    }); 
                    res.result.allservices.forEach(service=>{
                        if(this.state.bu_id == service.bu){
                            this.state.allService.push(service);
                        }
                    }); 
                }
                else{
                    this.state.sname = res.result.services;
                    this.state.allService = res.result.allservices;
                }
                this.state.sname1 = res.result.services;
                this.state.usersService = res.result.usersService;
                this.state.report.project_practice = [];
                this.state.sname.forEach(value=>{
                    var opt ={};
                   opt.label = value.value;
                   opt.id = value.id;
                   opt.value = value.value;
                   opt.head = value.head;
                   opt.bu = value.bu;
                   this.state.serviceName.push(opt);
                })
                if(isAllPractice){
                    this.state.selectService = [] ;
                    this.state.report.service =[];
                    this.state.report.practice =[];
                    this.state.projectPractice =[];
                    this.state.selectBuFilter = [];
                    this.state.head_id = 'All'; 
                    this.state.bu_id = '';
                    this.state.selectBU = [] ;
                    res.result.practices.forEach(pvalue =>{
                        this.state.projectPractice.push(pvalue)
                    })

                }
                else{
                    var count = 0;

                    if(this.state.report.service){
                        this.state.report.service.forEach(value =>{
                            this.state.sname.forEach(svalue=>{
                                if(svalue.id == value.id){
                                    count = 1;
                                }
                            })
                        })
                        if(count == 0){
                            this.state.report.service = [];
                            let service = getUserService(this.state.serviceName,this.state.loginUserData,this.state.filter_start_date);
                            this.state.report.service.push(service);
                            this.state.selectService = service; 
                        }
                    }
                    else{
                        this.state.report.service = [];
                        let service = getUserService(this.state.serviceName,this.state.loginUserData,this.state.filter_start_date);
                        this.state.report.service.push(service);
                        this.state.selectService = service; 
                    }
                }
                this.setState({
                report: {
                    service:this.state.report.service,
                    practice:this.state.report.practice
                    },
                })
                var service1 = [];
                service1.push(this.state.sname[0]);
                this.state.qes_list = res.result.qes_list;
                var head ={};
               
                this.setState({
                    report_all_practices: res.result.practices,
                    practiceHead : this.state.practiceHead
                })
                // for practicehead      
                res.result.directors.forEach(id => {
                  this.state.practiceHead.push(id)          
                });
               
                var buName = [];
                res.result.directors.forEach(value=>{
                    var opt ={};
                   opt.label = value.bu;
                   opt.value = value.bu;
                   opt.bu_id = value.bu_id;
                   opt.id = value.id;
                   opt.bu = value.bu;
                   opt.name = value.name;
                   buName.push(opt);
                })
                this.setState({
                    practiceHead : this.state.practiceHead,
                    buName : buName
                });

                if(this.state.loginUserData.role_id == 8){
                    this.state.searchByPracticeHead = this.state.loginUserData.role_id;
                }else{
                    this.state.searchByPracticeHead = this.state.searchByPracticeHead;
                }
             
                if(!this.state.isAllPractices){
                    this.filterService('');
                }
                this.changeViewPracite(this.state.isAllPractices);
            })
       }
      
    }

     //filter practice based on service and head
     filterService = (e) =>{
        var array = [];
        this.state.searchBy = '';
         this.state.SumofMileStoneValue = 0;
         if(e){
            let selectedOption=(e);
            this.state.selectService = selectedOption;
            this.state.report.service = selectedOption;
         }
         this.setState({
            report :{
                service : this.state.report.service
            }
         })
        var ser = [];
        var report = [];
        if(this.state.reports_lists[3].value === this.state.current_report.value){
            this.state.report.practice = [];
            this.state.projectPractice = [];
            if(this.state.isAllPractices)
            {
             this.state.projectPractice = this.state.report_all_practices             
            }else{
              var service =  (this.state.report.service);
              this.state.projectPractice=[];
              if(service.length == 0){
                  this.state.projectPractice=[];                  
              }
              else{      
                var proj_practice=[];       
                service.forEach(svalue =>{
                    this.state.report_all_practices.forEach(value =>{
                        if(svalue?.id == value.serviceId){
                            this.state.projectPractice.push(value);  
                        }
                    })
                })
              }
             }
             // for sending all practice while reset
              if(this.state.projectPractice.length>0){
                this.state.projectPractice.forEach(value => {
                    this.state.report.practice.push(value.id);               
                 });
              }
              this.setState({
                report : {
                    practice : [this.state.report.practice],
                    service : this.state.report.service
                }
             })
           this.getTechLeadList();
           if(!this.state.selectInvoiceStatus && !this.state.selectDueDate && !this.state.selectInvoiceValue){
                this.getStatusList();
                this.getDueList();
                this.getInvoiceValueList();
           }
           this.generateCollectionPotentialReport(true);
       }
       
    }

    filterPractice = (e,practice,list) =>{
       if(practice){
          practice.isChecked = !practice.isChecked;
       }
       this.state.filteredMilestoneData = [];
       var filteredData =[];
        var array =[];
        var resultArray=[];
        if(practice){
            this.state.filter_class ='';
            if(e.target.checked)      //if checked (true), then add this id into checkedList
            {
                resultArray = this.state.checkedList.filter(CheckedId=>
                CheckedId !== practice.practiceId
                )
                resultArray.push(practice.practiceName) 
            }
            else                    //if not checked (false), then remove this id from checkedList
            {
            resultArray = this.state.checkedList.filter(CheckedId=>
                CheckedId !== practice.practiceId
            )
            }
            this.state.checkedList = resultArray;
            this.setState({
            checkedList:this.state.checkedList
            })
          var array = [];
          if(practice.isChecked==true){           
              this.state.sumoffilterMileStoneValue += practice.mileStoneValue;
              this.state.filterMileStoneValue +=practice.mileStoneValue;
              this.state.filterNotSure +=practice.sumOfNotSureValue;
              this.state.filterNotUpdated  +=practice.notUpdatedValue;
              this.state.filterNewValue  +=practice.totalNewValue;
              this.state.filterRaisedValue  +=practice.totalRaisedValue;
              this.state.filterCollectedValue  +=practice.totalCollectedValue;
              this.state.filterPendingValue  +=practice.totalPendingValue;
              this.state.filter.push(practice.practiceId);
          }
          else{
              this.state.sumoffilterMileStoneValue -= practice.mileStoneValue;  
              this.state.filterMileStoneValue -=practice.mileStoneValue;
              this.state.filterNotSure -=practice.sumOfNotSureValue;
              this.state.filterNotUpdated  -=practice.notUpdatedValue;
              this.state.filterNewValue  -=practice.totalNewValue;
              this.state.filterRaisedValue  -=practice.totalRaisedValue;
              this.state.filterCollectedValue  -=practice.totalCollectedValue;
              this.state.filterPendingValue  -=practice.totalPendingValue;
              this.state.filter.forEach( value => {
                  if(value != practice.practiceId){
                      array.push(value);
                  }
              })
              this.state.filter = array;
          }
          if(this.state.filter.length == 0 && !practice){
            this.state.filterByPractice = [];
            this.state.filteredMilestoneData = this.state.milestoneReport;
          }
          else{
              if(this.state.filter.length == 0 ){
                this.state.filteredMilestoneData = this.state.milestoneReport;
                this.state.filterByPractice = [];
              }else{
                  if(practice.isChecked == true)
                    this.state.filterByPractice.push(practice);
               
                    this.state.filter.forEach(result => {
                        this.state.milestoneReport.forEach(data =>{
                            if(data.practiceId == result){
                                this.state.filteredMilestoneData.push(data);
                            }
                        }) 
                    })
                    if(this.state.filteredMilestoneData.length == 0){
                        this.state.sumoffilterMileStoneValue = 0;
                        this.state.filterMileStoneValue =0;
                        this.state.filterNotSure =0;
                        this.state.filterNotUpdated  =0;
                        this.state.filterNewValue  =0;
                        this.state.filterRaisedValue  =0;
                        this.state.filterCollectedValue  =0;
                        this.state.filterPendingValue  =0;
                    }
                }
          }
          if(this.state.filter.length == 0){
            this.state.filter_class ='active';
          }
          this.state.filteredMilestoneDatas1 = this.state.filteredMilestoneData;
        }
        else{
            this.state.filter_class ='active';
            this.state.filter = [];
            this.state.filterByPractice = [];
            this.state.checkedList.forEach(value =>{
                document.getElementById(value).checked = false;
            })
            this.state.filteredMilestoneData = this.state.filteredMilestoneDatas
            this.state.practiceReport.forEach(data =>{
                    data.isChecked = false;
            }) 
            this.state.filteredMilestoneDatas1 = this.state.filteredMilestoneData;
            this.state.SumofMileStoneValue = 0;
            this.state.sumoffilterMileStoneValue = 0;
            this.state.filterMileStoneValue = 0;
            this.state.filterNotSure =0;
            this.state.filterNotUpdated  =0;
            this.state.filterNewValue  =0;
            this.state.filterRaisedValue  =0;
            this.state.filterCollectedValue  =0;
            this.state.filterPendingValue  =0;
        }   
        if(this.state.searchBy != ''){
            filteredData = this.state.filteredMilestoneData.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(this.state.searchBy.toLowerCase())
            })
        }
        else{
            filteredData = this.state.filteredMilestoneData;
        }
        this.state.filteredMilestoneData = filteredData;
        this.setState({
            filteredMilestoneData:this.state.filteredMilestoneData,
            practiceReport : this.state.practiceReport,
            filter_class : this.state.filter_class
        })
      }

    calculatePracticeWiseMileStone = (practiceList) =>{
        this.state.myPracticeMileStone = 0;
        this.state.allPracticeMileStone = 0;
        practiceList.forEach(practicesValue => {
       
        if(practicesValue.myPractice){
            this.setState({
                myPracticeMileStone : this.state.myPracticeMileStone + practicesValue.mileStoneValue
            })
          
        }else{
            this.setState({
                allPracticeMileStone : this.state.allPracticeMileStone + practicesValue.mileStoneValue
            })
        }
        });
    };
 
    generateCollectionPotentialReport = () =>{
        this.setState({ loading: true });
        var link = this.state.apiLink+'get_collection_potential_report';
      
        var invoice_status ={};
        this.state.invoice_dropdown_value.forEach(invoice =>{
            if(invoice.value == this.state.selectedInvoiceStatus){
                invoice_status.id =invoice.id
                invoice_status.value =invoice.value
            }
        })
        this.setState({ isLoading: true });
        var services = [];
        services.push(this.state.report.service);
        if(this.state.report.service.length == 0) { // getting user associated service list
            this.state.report.service = getReportFilterService(this.state.serviceName,this.state.loginUserData,this.state.usersService);
        }
        var prac=[],ser=[],bu=[];
        this.state.qes_list.forEach(val=>{
            prac.push(val.practice_id);
            ser.push(val.service_id);
            bu.push(val.business_unit);
        })
        if(this.state.token == null){
            this.state.token = Cookies.get('auth_data');
        }
        return fetch(link,{
            method: "POST",                
            body: JSON.stringify({
                reportDetails:{
                    end_date:  moment()
                    .endOf('month')
                    .utcOffset('+05:30')
                    .format('YYYY-MM-DD'),
                    head_id: this.state.bu_id,
                    isCollectionPotential: true,
                    practice: [],
                    project_practice: this.state.report.practice,
                    selectedInvoiceStatus: invoice_status,
                    service: this.state.report.service,
                    start_date: moment(this.state.filter_start_date)
                    .utcOffset('+05:30')
                    .format('YYYY-MM-01'),
                    selectedBusinessAnalystId : this.state.selectBusinessAnalystId ,
                    selectedTechLeadId : this.state.selectTechLeadId,
                    userTypeId: this.state.userTypeId,
                    viewAll: this.state.view_all,
                    invoice_status: this.state.selectInvoiceStatus,
                    due_date: this.state.selectDueDate,
                    invoice_value: this.state.selectInvoiceValue,
                    selectedClientName: this.state.selectClientName,
                    filter_id: this.state.filterId,
                    filter_name: this.state.filterName
                }
            }),               
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Authorization" :"Bearer "+ this.state.token 
            }
        }).then(res => res.json()).then(res => {
            var practiceReport =[];
            var array=[],ms_array = [],array1=[],array2=[];
            var milestone_value =0,ach=0,uniqueIdValue=0;
            this.setState({ loading: false });
            res.result.practiceReport.forEach(value =>{
                value.isChecked = false;
                practiceReport.push(value);
            })
            if(res == null){
                this.ToastAlert("warning", "Server Timed Out ! Please Try Again Later");           
                this.setState({
                    loading: false,
                    milestoneReport :[],
                    mileStoneValueReport :  [],
                    practiceReport : [],
                    overallMileStoneValue : 0,
                    totalMileStoneValue : 0,
                    totalKickoffValue : 0,
                    totalCarryValue : 0,
                    sumOfNotSureValue : 0,
                    notUpdatedValue : 0,
                    totalNewValue : 0,
                    totalRaisedValue : 0,
                    totalCollectedValue : 0,
                    totalPendingValue : 0,
                    totalNotYetDueValue : 0,
                    totalOnDueValue : 0,
                    mileStoneReportMonth : res.result.monthName,
                    mileStoneReportYear : res.result.year,
                    filteredMilestoneData : [],
                    filteredMilestoneDatas : [],
                    filteredMilestoneDatas1 : [],
                    filteredMilestoneClone : [],
                    monthName : res.result.monthName,
                    allPracticeMileStone : 0,
                    Year : res.result.year,              
                })              
            }
            else if (res.status) {
                this.state.milestoneReport= res.result.milestoneReportPractice;
                var  milestoneReport= res.result.milestoneReportPractice;

                var mile_report = res.result.milestoneReport;
               
                if(mile_report.length > 0 ){ 
                    Object.keys(this.state.milestoneReport).forEach(value =>{
                        uniqueIdValue=0;
                        Object.keys(milestoneReport[value]).forEach(valueId =>{
                            uniqueIdValue=0;
                            var achieved = 0;
                            Object.keys(milestoneReport[value][valueId]).forEach(uniqueId =>{
                                uniqueIdValue +=  milestoneReport[value][valueId][uniqueId].userMilestoneValue;
                                achieved +=  milestoneReport[value][valueId][uniqueId].userAchievedMilestoneValue;
                                milestoneReport[value][valueId][uniqueId].userMilestoneValue=uniqueIdValue;
                                milestoneReport[value][valueId][uniqueId].userAchievedMilestoneValue = achieved;
                                array1=milestoneReport[value][valueId][uniqueId];
                            });
                            ms_array.push(array1);                      
                        });

                    });
                    this.state.milestoneReport = ms_array;
                    var dataObj = {
                        'businessUnit': res.result.businessUnit,
                        'milestoneReport': ms_array,
                        'monthName': res.result.monthName,
                        'practiceReport': res.result.practiceReport,
                        'totalCarryValue': res.result.totalCarryValue,
                        'totalKickoffValue': res.result.totalKickoffValue,
                        'totalMileStoneValue': res.result.totalMileStoneValue,
                        'year': res.result.year,
                    };
                    this.state.mileStoneValueReport = dataObj; //for excel
                    this.state.mileStoneValueReport.fileName = 'Collection-potential-report';
                    this.state.practiceReport = res.result.practiceReport;

                    this.state.practiceReport.forEach(value=>{
                        if(prac.includes(value.practiceId)){
                            milestone_value = parseInt(milestone_value) + parseInt(value.mileStoneValue);
                        }
                        else{
                            array.push(value);
                        }
                    })
                    array.forEach(value=>{
                        if(value.practiceId == this.state.practice_qes){
                            value.mileStoneValue = parseInt(value.mileStoneValue) + parseInt(milestone_value);
                        }
                        array2.push(value);
                    })
                    this.setState({ 
                        loading: false,
                        milestoneReport :this.state.milestoneReport,
                        mileStoneValueReport :this.state.mileStoneValueReport,
                        practiceReport : array,
                        filteredMilestoneData : this.state.milestoneReport,
                        overallMileStoneValue : res.result.overallMileStoneValue,
                        totalMileStoneValue : res.result.totalMileStoneValue,
                        totalKickoffValue : res.result.totalKickoffValue,
                        totalCarryValue : res.result.totalCarryValue,
                        sumOfNotSureValue :res.result.sumOfNotSureValue,
                        notUpdatedValue : res.result.notUpdatedValue,
                        totalNewValue : res.result.totalNewValue,
                        totalRaisedValue : res.result.totalRaisedValue,
                        totalCollectedValue : res.result.totalCollectedValue,
                        totalPendingValue : res.result.totalPendingValue,
                        totalNotYetDueValue : res.result.totalNotYetDueValue,
                        totalOnDueValue : res.result.totalOnDueValue,
                        mileStoneReportMonth : res.result.monthName,
                        mileStoneReportYear : res.result.year
                     });
                }
                else{
                    this.setState({ 
                        loading: false,
                        milestoneReport : [],
                        mileStoneValueReport :[],
                        filteredMilestoneData : [],
                        filteredMilestoneDatas : [],
                        filteredMilestoneDatas1 : [],
                        filteredMilestoneClone : [],
                        practiceReport : [],
                        overallMileStoneValue : 0,
                        totalMileStoneValue : 0,
                        totalKickoffValue : 0,
                        totalCarryValue : 0,
                        sumOfNotSureValue :0,
                        allPracticeMileStone : 0,
                        notUpdatedValue : 0,
                        totalNewValue : 0,
                        totalRaisedValue : 0,
                        totalCollectedValue : 0,
                        totalPendingValue : 0,
                        totalNotYetDueValue : 0,
                        totalOnDueValue : 0,
                        myPracticeMileStone : 0,
                        mileStoneReportMonth : res.result.monthName,
                        mileStoneReportYear : res.result.year
                     });
                   
                }
            }
            else{
                this.ToastAlert("error", res.message);           
                this.setState({
                    loading: false,
                    milestoneReport :[],
                    mileStoneValueReport :  [],
                    practiceReport : [],
                    overallMileStoneValue : 0,
                    totalMileStoneValue : 0,
                    totalKickoffValue : 0,
                    totalCarryValue : 0,
                    sumOfNotSureValue : 0,
                    notUpdatedValue : 0,
                    totalNewValue : 0,
                    totalRaisedValue : 0,
                    allPracticeMileStone : 0,
                    totalCollectedValue : 0,
                    totalPendingValue : 0,
                    totalNotYetDueValue : 0,
                    totalOnDueValue : 0,
                    mileStoneReportMonth : res.result.monthName,
                    mileStoneReportYear : res.result.year,
                    filteredMilestoneData : [],
                    filteredMilestoneDatas : [],
                    filteredMilestoneDatas1 : [],
                    filteredMilestoneClone : [],
                    monthName : res.result.monthName,
                    Year : res.result.year,
                   
                })
            }
           if(this.state.practiceReport.length > 0){
                this.calculatePracticeWiseMileStone(this.state.practiceReport);
           }

           if(this.state.filteredMilestoneData.length > 0){
            this.state.filteredMilestoneData.forEach(value =>{
                this.state.invoice_dropdown_value.forEach(invoice =>{
                    if(value.invoice_status == invoice.id){
                        value.invoice = invoice.value;
                    }
                })
             
                if( value.invoice_status == this.state.collection_potential_invoicestatus[2].id){
                    value.inv_class ="alert_report_row ";
                }
                else if( value.invoice_status == this.state.collection_potential_invoicestatus[4].id){
                    value.inv_class ="alert_report_row ";
                }
                else if(value.invoice_status  == this.state.collection_potential_invoicestatus[3].id){
                    value.inv_class ="green_report_row ";
                }
                else{
                    value.inv_class ="";
                }
              
              
            });
            
            this.setState({
                filteredMilestoneDatas : this.state.filteredMilestoneData,
                filteredMilestoneClone : this.state.filteredMilestoneData,
                overallMileStoneValue : res.result.overallMileStoneValue,
                totalMileStoneValue : res.result.totalMileStoneValue,
                totalMileStoneValues : res.result.totalMileStoneValue,
                totalKickoffValue : res.result.totalKickoffValue,
                totalCarryValue : res.result.totalCarryValue,
                sumOfNotSureValue : res.result.sumOfNotSureValue,
                sumOfNotSureValues : res.result.sumOfNotSureValue,
                notUpdatedValue : res.result.notUpdatedValue,
                totalNewValue : res.result.totalNewValue,
                totalRaisedValue : res.result.totalRaisedValue,
                totalCollectedValue : res.result.totalCollectedValue,
                totalPendingValue : res.result.totalPendingValue,
                totalNotYetDueValue : res.result.totalNotYetDueValue,
                totalOnDueValue : res.result.totalOnDueValue,
            }) 
            this.filterPractice();
           }
           else{
            this.setState({
                overallMileStoneValue :0,
                totalMileStoneValue :0,
                totalKickoffValue : 0,
                totalCarryValue : 0,
                sumOfNotSureValue : 0,
                notUpdatedValue : 0,
                totalNewValue : 0,
                totalRaisedValue : 0,
                totalCollectedValue : 0,
                totalPendingValue : 0,
                totalNotYetDueValue : 0,
                totalOnDueValue : 0,
            })  
           }

           if(this.state.filterId){
                this.addComments(this.state.filteredMilestoneData[0]);
           }

            this.changeInventoryView('pending_view');
        }).catch((error) => {
            this.setState({ loading: false });
        });
    }

    getTechLeadList = () =>{
        this.setState({ loading: true });
        var link = this.state.apiLink+'get_tech_lead_list';
  
        if(this.state.token == null){
            this.state.token = Cookies.get('auth_data');
        }
        return fetch(link,{
            method: "GET",                           
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Authorization" :"Bearer "+ this.state.token 
            }
        }).then(res => res.json()).then(res => {           
               this.state.techLeadList= [];
               this.state.techLeadList[0]={id: '',label:'All', first_name: 'All',last_name: ''};
               res.result.techLead.forEach(techLeads =>{
                    var opt ={};                   
                    opt.id = techLeads.id;
                    opt.label = techLeads.first_name;
                    opt.value = techLeads.first_name;
                    opt.first_name = techLeads.first_name;
                    opt.last_name = techLeads.last_name;
                    this.state.techLeadList.push(opt)
               })
               this.state.selectTechLead =  this.state.techLeadList[0];
               this.state.selectTech =  this.state.techLeadList[0];
               this.state.searchByTechLead ='';
                //business analyst list
                this.state.businessAnalystList= [];
                this.state.businessAnalystList[0]= {id: '',label:'All', first_name: 'All',last_name: ''};
                res.result.businessAnalyst.forEach(businessAnalysts =>{
                    var opt ={};                   
                    opt.id = businessAnalysts.id;
                    opt.label = businessAnalysts.first_name;
                    opt.value = businessAnalysts.first_name;
                    opt.first_name = businessAnalysts.first_name;
                    opt.last_name = businessAnalysts.last_name;
                    this.state.businessAnalystList.push(opt);
                });    

                this.state.clientNameList = res.result.clientNames.map(names => ({ ...names, label: names.client_name, value: names.client_name }));
                this.state.clientNameList.unshift({client_name: 'all', label: 'All', value: 'all'});
                this.state.selectBusinessAnalyst =  this.state.businessAnalystList[0];
                this.state.selectBusinessAnal =  this.state.businessAnalystList[0];
                this.state.selectClientName =  this.state.clientNameList[0];
                this.state.searchByBusinessAnalyst = '';
                this.setState({
                    selectTechLead :this.state.selectTechLead,
                    selectClientName :this.state.selectClientName,
                    techLeadList : this.state.techLeadList,
                    businessAnalystList: this.state.businessAnalystList,
                    clientNameList: this.state.clientNameList,
                    selectBusinessAnalyst: this.state.selectBusinessAnalyst
                })
            
        })
      
    }

    getStatusList = () => {
        const indexes = [1, 5];
        
        var invoiceStatusOptions = Urls.invoice_status.map(iv => ({...iv, label: iv.value}));
        invoiceStatusOptions = invoiceStatusOptions.filter((value, index) => !indexes.includes(index));
        this.setState({invoiceStatusOptions, selectInvoiceStatus: invoiceStatusOptions[0]});
    }
    
    getDueList = () => {
        var dueDateOptions = Urls.due_date_status.map(iv => ({...iv, label: iv.value}));
        this.setState({dueDateOptions, selectDueDate: dueDateOptions[0]});
    }
    
    getInvoiceValueList = () => {
        var invoiceValue = Urls.invoice_value.map(iv => ({...iv, label: iv.value}));
        this.setState({invoiceValue, selectInvoiceValue: invoiceValue[0]});
    }

    handleInvStatusChanged = (evt) =>{
        let selectInvoiceStatus = evt ? evt :this.state.invoiceStatusOptions[0];
        this.setState({ selectInvoiceStatus });
        setTimeout(() => { this.generateCollectionPotentialReport(); }, 100); 
    }
    
    handleDuedateChanged = (evt) =>{
        let selectDueDate = evt ? evt :this.state.dueDateOptions[0];
        this.setState({ selectDueDate});
        setTimeout(() => { this.generateCollectionPotentialReport(); }, 100); 
    }
    
    handleInvValueChanged = (evt) =>{
        let selectInvoiceValue = evt ? evt :this.state.invoiceValue[0];
        this.setState({ selectInvoiceValue});
        setTimeout(() => { this.generateCollectionPotentialReport(); }, 100); 
    }

    getClientName = (evt) => {
        let selectClientName = evt ? evt :this.state.clientNameList[0];
        this.setState({ selectClientName});
        setTimeout(() => { this.generateCollectionPotentialReport(); }, 100); 
    }

   changeViewPracite = (flag) =>
    {
        if(this.state.loginUserData.role_id == 8 && this.state.isAllPractices){
            this.state.isAllPractices = flag;
            }
        else{
            this.state.isAllPractices = flag;
        }
        this.selectReport(this.state.current_report);
    }
    
    selectReport = (report) =>
    {
        this.state.previous_report = this.state.current_report;
        this.state.current_report = report;
        this.state.project_filter = {};
        if(this.state.reports_lists[0].value == this.state.current_report){
            this.state.searchBy = 'name';
            var date = new Date();
            var date = new Date(date.getFullYear(), date.getMonth(), 2);
            this.state.filter_end_date = (this.state.filter_end_date) ? this.state.filter_end_date : date;
            this.state.filter_start_date = (this.state.filter_start_date) ? this.state.filter_start_date : date;
            this.state.report.practice = (this.state.report.practice) ? this.state.report.practice : [];
            this.state.report.head_id = '';  
        }
        else if(this.state.reports_lists[3].value === this.state.current_report){
            this.state.filter_start_date =  new Date();
            this.state.techLeadId = "";
            this.state.businessAnalystId = "";
            this.state.searchByTechLead = "";
            this.state.searchByBusinessAnalyst = "";
            this.state.report.head_id = "";
            this.state.submitted = false;
            this.state.mileStoneReportPractice = 1;
            this.state.filterByPractice = '';
            this.state.filterMileStoneValue = '';
            
            this.generateCollectionPotentialReport(true);
        }
    }

    //for filtering service by head
    getReportByHead = (e) =>{
        this.state.searchBy = '';
        if(e != undefined){
            this.state.bu_id =  e.bu_id;  
            this.state.head_id = e.label
            this.state.selectBuFilter = e;
        }
        else{
            this.state.head_id = 'All'; 
            this.state.bu_id = '';
            this.state.selectBuFilter = [];
        }
        if(this.state.head_id != 'All'){ // for BU filter
            this.state.report.project_practice = [];
            var service_head = [];
            this.state.report.service = [];
            this.state.report.service = [];
            this.state.sname1.forEach(service =>{
                if(this.state.bu_id == service.bu){
                    service_head.push(service);
                
                }               
            })
            this.state.serviceName = [];
            this.state.selectService = [];
            service_head.forEach(value=>{
                var opt ={};
            opt.label = value.value;
            opt.id = value.id;
            opt.value = value.value;
            opt.head = value.head;
            opt.bu = value.bu;
            this.state.serviceName.push(opt);
            })
            this.state.selectService = [] ;
         
            var service = this.state.report.service;
            if(service.length == 0){
                this.state.projectPractice=[];    
                this.state.report.practice = [];              
            }
            else{                            
                service.forEach(svalue =>{
                    this.state.report_all_practices.forEach(value =>{
                        if(svalue.id == value.serviceId){
                            this.state.projectPractice.push(value);  
                        }
                    })
                })
                if(this.state.projectPractice.length>0){
                    this.state.projectPractice.forEach(value => {
                        this.state.report.practice.push(value.id);               
                    });
                }
            }
        }else{ // when All BU is selected	
            var service_head = [];
            this.state.selectBuFilter =[];
            if(this.state.report.service.length == 0){
                this.state.sname1.forEach(service =>{
                        service_head.push(service);              
                })
                this.state.serviceName = [];
                this.state.selectService = [];
                service_head.forEach(value=>{
                    var opt ={};
                opt.label = value.value;
                opt.id = value.id;
                opt.value = value.value;
                opt.head = value.head;
                opt.bu = value.bu;
                this.state.serviceName.push(opt);
                })
                this.state.selectService = [] ;
            }
            else{
                this.state.report.service = [];
                this.state.sname.forEach(value=>{
                    var opt ={};
                opt.label = value.value;
                opt.id = value.id;
                opt.value = value.value;
                opt.head = value.head;
                opt.bu = value.bu;
                this.state.serviceName.push(opt);
                })
                this.state.selectService = (this.state.serviceName[0]) ;
                this.state.report.service.push(this.state.serviceName[0])
            }
        }
        this.setState({
            selectBuFilter: this.state.selectBuFilter
        })
        this.generateCollectionPotentialReport(true);
       
    }

    msvalueSum = (e,data) => {
        let resultArray = []
      
        data.isChecked = !data.isChecked;
        if(data.isChecked == true){
            this.state.SumofMileStoneValue += (data.userAchievedMilestoneValue) ? data.userAchievedMilestoneValue : data.userMilestoneValue;
        }
        else{
            this.state.SumofMileStoneValue -= (data.userAchievedMilestoneValue) ? data.userAchievedMilestoneValue : data.userMilestoneValue;
        }
        this.setState({
            SumofMileStoneValue:this.state.SumofMileStoneValue
        })
    }

    ToastAlert(icon, title) {
        return Toast.fire({
          target: document.getElementById("form-modal"),
          icon: `${icon}`,
          title: `${title}`,
        });
    }

    HandleClick() {  
        Swal.fire({  
          title: 'Success',  
          type: 'success',  
          text: 'Your work has been saved.',  
        });  
      } 

    selectMileStoneReportPractice = (e,selectedPractice) => {
        this.state.mileStoneReportPractice = selectedPractice;
        var allService = this.state.allService;
        this.state.allService = [];
        this.state.sname = [];
        this.state.serviceName =[];
        if(this.state.bu_id != '' && this.state.bu_id != undefined && this.state.bu_id !=0 ){
            allService.forEach(service=>{
              if(this.state.bu_id == service.bu){
                  this.state.allService.push(service);
              }
          }); 
        }
        else{
          this.state.allService = allService;
        }
        this.state.filterByPractice = '';
        if(selectedPractice == 0){//to reset service when view all practice is selected
            this.state.sname = [];
            this.state.report.service = [];
            this.state.isAllPractices = true;
            this.state.report.practice = [];
            this.state.report.service = [];
            this.state.view_all = 0;
            this.state.selectService = [];
            this.state.selectBuFilter = [];
            this.state.sname = this.state.allService;
        }else{
              this.state.sname = this.state.sname1;
              this.state.isAllPractices = false;
              this.state.view_all = 1;
        }
        this.state.sname.forEach(value=>{
            var opt ={};
           opt.label = value.value;
           opt.id = value.id;
           opt.value = value.value;
           opt.head = value.head;
           opt.bu = value.bu;
           this.state.serviceName.push(opt);
        })
        this.state.report.service.push(this.state.serviceName[0]);
        this.state.selectService = this.state.serviceName[0];
        this.state.filterByPractice = '';    
         this.generateCollectionPotentialReport(true);   
       
    };

     //generate milestone/collections/collection-potential report excel
     generateCollectionPotentialReportAsExcel = (e) => {
        e.currentTarget.blur(); // removing focus
        this.state.milestoneReport = [];
        this.state.showNodata = false;
        this.setState({ loading: true });
        var link = this.state.apiLink+'export_as_excel_generate_milestone_report';
        this.state.milestoneReport = this.state.filteredMilestoneData;
        this.state.mileStoneValueReport.milestoneReport = this.state.milestoneReport;//with filter data export
        if(this.state.token == null){
            this.state.token = Cookies.get('auth_data');
        }
        return fetch(link,{
            method: "POST",                
            body: JSON.stringify({
                reportDetails:{
                    fileName: "Collection-potential-report",
                    milestoneReport : this.state.milestoneReport,
                    monthName :this.state.mileStoneValueReport.monthName,
                    practiceReport : this.state.practiceReport,
                    totalCollectedValue : this.state.totalCollectedValue,
                    totalNewValue :this.state.totalNewValue,
                    totalMileStoneValue :this.state.totalMileStoneValue,
                    sumOfNotSureValue :this.state.sumOfNotSureValue,
                    totalPendingValue : this.state.totalPendingValue,
                    totalNotYetDueValue : this.state.totalNotYetDueValue,
                    totalOnDueValue : this.state.totalOnDueValue,
                    totalRaisedValue : this.state.totalRaisedValue,
                    notUpdatedValue : this.state.notUpdatedValue,
                    overallMileStoneValue : this.state.overallMileStoneValue,
                    year: this.state.mileStoneValueReport.Year
                }
            }),               
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Authorization" :"Bearer "+ this.state.token 
            }
        }).then(res => res.json()).then(res => {
               this.setState({ loading: false });
                var result = res.result;
                document.location = this.state.excelUrl+result.filename;
        }).catch((error) => {
            this.setState({ loading: false });
            this.ToastAlert("error", 'Something went wrong!');           
        })
    }

    resetReport =(e) =>{
        e.currentTarget.blur(); // removing focus
        this.state.selectedInvoiceStatus = this.state.selectedInvoice;
        this.state.searchBy = '';
        this.state.mileStoneReportPractice = 1;
        this.state.view_all = 1;
        this.setState({
            selectedInvoiceStatus : this.state.selectedInvoiceStatus
        })
        this.getPracticeService();
    }

        // class applying based on invoice status
    msPercentageClass = (allocated, achieved) =>
    {
        var percentate = (((achieved/allocated) * 100));
        var returnClass;
        if((!achieved || achieved==0)) //user not given achieved value
        {
            returnClass = "m-t-5";
        }else{
            if(percentate >= 80)
            {
            returnClass = "green_report_row m-t-5"; //for good
            }else
            {
            returnClass = "danger_report_row m-t-5"; //for average
            }
        }
        return returnClass;
    }

    searchBy = (e) =>{
        var searchInput = e.target.value;
        var  filteredData = [];
        if(searchInput){
            filteredData = this.state.filteredMilestoneDatas1.filter((item) => {
                return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
            })
            this.state.searchBy = searchInput;           
        }
        else{
            this.state.searchBy ='';
            filteredData = this.state.filteredMilestoneDatas1;          
        }
        this.state.filteredMilestoneData = filteredData;
        this.setState({
            filteredMilestoneData : this.state.filteredMilestoneData,
            totalMileStoneValue : this.state.totalMileStoneValue,
            sumOfNotSureValue : this.state.sumOfNotSureValue
        })
        this.changeInventoryView('pending_view');
    }

    //convert invoice to not sure in collection potential
    changeToNotSure = (e,idx) => {
        if(e.target.checked)   {
            this.state.value = 1;
            this.state.checked_data = {
            'it_id': idx.iterationId ?idx.iterationId : '' , 'project_id': idx.projectId , 'value': this.state.value
            };    
            this.state.true_data.push(this.state.checked_data);
            this.state.filteredMilestoneData.forEach(value =>{
                if(value.iterationId == idx.iterationId){
                    value.is_not_sure = true;
                }
            })
        } else {
            this.state.value = 0;
            this.state.checked_data = {
            'it_id': idx.iterationId ? idx.iterationId : '' , 'project_id': idx.projectId, 'value': this.state.value
            };
            this.state.true_data.push(this.state.checked_data);
            this.state.filteredMilestoneData.forEach(value =>{
                if(value.iterationId == idx.iterationId){
                    value.is_not_sure = false;
                }
            })
        }
        this.setState({
            filteredMilestoneData : this.state.filteredMilestoneData
        })
    }

    NotSure = ()=>{
        this.setState({ loading: true });
        var link = this.state.apiLink+'convert_to_not_sure';
        if(this.state.token == null){
            this.state.token = Cookies.get('auth_data');
        }
        return fetch(link,{
            method: "POST",                
            body: JSON.stringify({
                true_data: this.state.true_data
                
            }),               
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Authorization" :"Bearer "+ this.state.token 
            }
        }).then(res => res.json()).then(res => {
               this.setState({ loading: false });
               this.ToastAlert("success", "Report Updated successfully");
               this.generateCollectionPotentialReport();
        })      
    }

      //get milestone report by tech lead
     getMilestoneReportByTechHead = (e,techLeadId) =>{
        if(e != null){
            this.state.selectTechLeadId = e.id;
            this.state.selectTechLead = e;
        }
        else{
            this.state.selectTechLeadId= '';
            this.state.selectTechLead= this.state.selectTech;
        }
        if(this.state.filter_start_date){      
            this.generateCollectionPotentialReport();
        }     
    };
    
    //get milestone report by business analyst
    getMilestoneReportByBusinessAnalyst = (e,businessAnalystId) =>{
        if(e != null){
          this.state.selectBusinessAnalystId= e.id;
          this.state.selectBusinessAnalyst= e;
        }
        else{
          this.state.selectBusinessAnalystId= '';
          this.state.selectBusinessAnalyst= this.state.selectBusinessAnal;
        }
        this.setState({
            selectBusinessAnalyst : this.state.selectBusinessAnalyst
        })
        if(this.state.filter_start_date){      
            this.generateCollectionPotentialReport();
        }
    }

    addComments = (iteration) => {
        this.setState({ 
            showCommentModal: true, 
            iterationDetails: iteration, 
        });
    };
    
    handleCloseModal = () => {
        const filterId = this.state.filterId;
        this.setState({
            filterId: null,
            filterName: null,
            showCommentModal: false,
            iterationDetails: []
        }, () => {
            if(filterId){
                this.props.history.push('/collections_potential_report');
                this.generateCollectionPotentialReport();
            }
        });
    };

    changeCriticalChecked = (evt, item) => {
        if(evt.target.checked)   {
            this.state.filteredMilestoneData.forEach(value =>{
                if(value.projectId == item.projectId){
                    value.is_critical = true;
                }
            })
        } else {
            this.state.filteredMilestoneData.forEach(value =>{
                if(value.projectId == item.projectId){
                    value.is_critical = false;
                }
            })
        }

        this.setState({
            filteredMilestoneData : this.state.filteredMilestoneData
        })

        var link = this.state.apiLink+'update_critical_status';
        return fetch(link,{
            method: "POST",                
            body: JSON.stringify({
                projectid : item.projectId,
                is_critical: evt.target.checked
            }),               
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Authorization" :"Bearer "+ this.state.token 
            }
        }).then(res => res.json()).then(res => {
            
        }).catch((error) => {
            this.setState({ loading: false });
        });
    }

    changeInventoryView = (tabName) => {
        if(tabName=='pending_view'){
            this.setState({
                commonInvCritAcc: 0,
                invoiceDetails: [],
            });
        }

        this.setState({
          defaultActiveKey: tabName,
        });
    };

    showInvoiceDetails = (projectId) => {
        let invoiceDetails = [];

        if(this.state.filteredMilestoneData.length > 0){
            invoiceDetails = this.state.filteredMilestoneData.filter(fm => fm.projectId == projectId);
        }

        this.setState({
            commonInvCritAcc: invoiceDetails.length,
            invoiceDetails
        });
        this.changeInventoryView('invoice_view');
    }
    /**
     * main render
     * @returns 
     */
	render() {
        const {practiceHead,invoiceStatusOptions,dueDateOptions,invoiceValue} = this.state;
        const currentDate = new Date();

        var buName = [];
        var myPracList = [];
        var allPracList = [];

        var commonCritAcc = [];
        this.state.practiceReport.forEach(pracValue =>{
            if(pracValue.myPractice == 0){
                allPracList.push(pracValue);
            }
            else{
                myPracList.push(pracValue);
            }
        })
        practiceHead.forEach(value=>{
            var opt ={};
           opt.label = value.bu;
           opt.value = value.bu;
           opt.bu_id = value.bu_id;
           opt.id = value.id;
           opt.bu = value.bu;
           opt.name = value.name;
           buName.push(opt);
        });
      
        if(this.state.filteredMilestoneData.length > 0){
            let previousProjId = 0;
            let previousProjIds = 0;
            let projCount = 0;
            let totalPending = 0;

            this.state.filteredMilestoneData.sort((a, b) => a.projectName.localeCompare(b.projectName)).map((item) => {
                let projId = item.projectId;
                let pendingval = item.userPendingMilestoneValue;
                
                item.due_value = 'NA';
                if(item.due_date!='NA'){
                    const dueDate = new Date(moment(item.due_date, 'DD/MM/YYYY').format('YYYY-MM-DD').toString() + " 0:00:00"); 
                    var diff = Math.floor(currentDate.getTime() - dueDate.getTime());
                    var day = 1000 * 60 * 60 * 24;

                    var days = Math.floor(diff/day);
                    var months = Math.floor(days/31);
                    var years = Math.floor(months/12);

                    if(days < 30 && dueDate <= currentDate){ item.due_value = "Below 30 Days";}
                    if(days >= 30 && days < 60 && dueDate <= currentDate){ item.due_value = "Between 30 to 60 Days";} 
                    if(days >= 60 && days < 90 && dueDate <= currentDate){ item.due_value = "Between 60 to 90 Days";}
                    if(months >= 3 && months < 6 && dueDate <= currentDate){ item.due_value = "Between 3 to 6 Months";}
                    if(months >= 6 && years < 1 && dueDate <= currentDate){ item.due_value = "Between 6 to 1 Year";}
                    if(years >= 1 && dueDate <= currentDate){ item.due_value = "More than a year";}
                    if(dueDate > currentDate){ item.due_value = "Yet to Due" ;}
                }

                if(previousProjId==projId){
                    totalPending += +pendingval || 0;
                    item.totalPendingValue = parseFloat(totalPending).toFixed(2);

                    this.state.filteredMilestoneData = this.state.filteredMilestoneData.map(x => (x.projectId === projId ? { ...x, totalPendingValue: item.totalPendingValue } : x));
                }
                
                if(previousProjId!=projId){
                    totalPending = +pendingval || 0;  
                    item.totalPendingValue = parseFloat(totalPending).toFixed(2);
                    previousProjId = projId;
                }

                return item;
            });

            this.state.filteredMilestoneData = this.state.filteredMilestoneData.sort((a, b) => parseFloat(b.totalPendingValue) - parseFloat(a.totalPendingValue));
            this.state.filteredMilestoneData.map((item,index) => {
                let projId = item.projectId;
                if(previousProjIds==projId){
                    projCount++;
                    let projIndex = commonCritAcc.findIndex(ac => ac.mergeProjId == projId);
                    if(projIndex > -1){
                        commonCritAcc[projIndex].mergeCount = projCount;
                    }
                    commonCritAcc.push({mergeProjId: 0, mergeIndex: -1, mergeCount: 0});
                }

                if(previousProjIds!=projId){
                    projCount = 1;
                    commonCritAcc.push({mergeProjId: projId, mergeIndex: index, mergeCount: projCount});
                    previousProjIds = projId;
                }
            });
        }

        if (this.state.redirect == 1) {
          return <PageNotFound url={this.state.angularUrl}/>      
        }   
        else{
            return (
                <>   
                <div className={"page-content px-4 " + (this.props.addClass ? "active" : "")}>   
                    <Row>
                        <div className="col-xs-12 fourty-fluid fourty-fluid">
                            <Row className="mb-3"> 
                                <div className="col-sm-4 col-sm-4 col-xs-12 text-left">
                                    <h1 className="B-G-bold f-s-18 d-gray t-t-upper pull-left mt-10 mr-20 page-head">Reports</h1>
                                </div>
                                <div className="col-lg-3 col-sm-4 col-xs-12">
                                    <div className="selectpicker_dropdown hide_cross hide_bar orange_arrow remove_border_btm select_dropdown_picker">       
                                        <Dropdown title={this.state.current_report.value} activeKey="4">
                                            <Dropdown.Item as="a" eventKey="1" href="/pw_report" target="_blank">Practice Wise Allocation Report</Dropdown.Item>
                                            <Dropdown.Item as="a" eventKey="2" href="/ms_report" target="_blank">Milestone Value Report</Dropdown.Item>
                                            <Dropdown.Item as="a" eventKey="3" href="/collections_report" target="_blank">Collections Report</Dropdown.Item>
                                            <Dropdown.Item as="a" eventKey="4">Collection Potential Report</Dropdown.Item>
                                            <Dropdown.Item as="a" eventKey="5" href="/inventory_report" target="_blank">Inventory Report</Dropdown.Item>
                                            <Dropdown.Item as="a" eventKey="6" href="/inventory_allocation_report" target="_blank">Inventory Allocation Metrics</Dropdown.Item>
                                            <Dropdown.Item as="a" eventKey="7" href="/inventory_milestone_report" target="_blank">Inventory Milestone Metrics</Dropdown.Item>
                                            <Dropdown.Item as="a" eventKey="8" href="/utilization_report" target="_blank">Resource Utilization Report</Dropdown.Item>
                                            <Dropdown.Item as="a" eventKey="9" href="/csm_report" target="_blank">Csm Report</Dropdown.Item>
                                            <Dropdown.Item as="a" eventKey="10" href="/pmo_ms_report" target="_blank">Pmo Ms Report</Dropdown.Item>
                                            <Dropdown.Item as="a" eventKey="11" href="/acv_report" target="_blank">Annual Contract Value Report</Dropdown.Item>
                                            <Dropdown.Item as="a" eventKey="12" href="/ms-allocation-report" target="_blank">Ms Allocation Report</Dropdown.Item>
                                            <Dropdown.Item as="a" eventKey="13" href="/squad-review-report" target="_blank">Squad Review Report</Dropdown.Item>
                                        </Dropdown>
                                    </div>                                             
                                </div>
                                {/* <div className="col-lg-5 col-sm-4 col-xs-12 justify-content-end text-end">                  
                                     <span class="hidden-xs l-gray B-G-bold f-s-16">Welcome, <b class="d-gray">{this.state.loginUserData.first_name} {this.state.loginUserData.last_name}</b></span>
                                </div> */}
                            </Row>
                            {/* <Row>
                                <div className="col-lg-6 clearfix m-b-20">
                                    <div className="row align-items-center">                            
                                        <label class="f-s-12 text-muted pt-1 w-13 col-lg-2 col-md-6 col-sm-12 text-left">Line of Service :</label> 
                                        <div className="col-lg-7 col-sm-5 text-left ">
                                            <Row>
                                                <div className="col-lg-10 p-l-0">
                                                    <div className="selectpicker_dropdown w-100 hide_bar selectpicker_dropdown_orange">   
                                                           <Select
                                                            className="basic-single-select"
                                                            classNamePrefix={"select_dropdown"}
                                                            placeholder="All"
                                                            isClearable
                                                            isSearchable={true}
                                                            onChange={(e)=>this.filterService(e)}
                                                            value={this.state.selectService}
                                                            options={this.state.serviceName} isMulti>                                                      
                                                        </Select>
                                                    </div>
                                                </div>                                        
                                                <div className="col-lg-2 p-1-0">
                                                    {this.state.report.service != undefined && this.state.report.service.length >0 &&
                                                               <a href="javascript:void(0)" title="Reset" tooltip-placement="top" className="reset-icon_old reset-color p-l-5 m-l-4" onClick={this.reset_page}><i class="fs-16 fa fa-refresh"></i></a>
                                                    }
                                                </div> 
                                            </Row>
                                        </div>
                                    </div>
                                </div>  
                                {this.state.loginUserData.role_id!=8 &&                   
                                <div className="col-lg-4 clearfix m-b-20">      
                                    <div className="row">
                                        <div class="col-sm-5 col-xs-12"> 
                                            <label className="text-uppercase m-b-0 fs-13 p-t-5">Business Unit :</label> 
                                        </div>
                                        <div className="col-sm-7 col-xs-12">    
                                            <div className="selectpicker_dropdown hide_bar w-100 selectpicker_dropdown_orange">  
                                             <Select
                                                className="basic-single-select"
                                                classNamePrefix={"select_dropdown"}
                                                placeholder="All"
                                                isClearable
                                                isSearchable={true}
                                                onChange={(e)=>this.getReportByHead(e)}
                                                value={this.state.selectBuFilter}
                                                options={buName} >                                                      
                                             </Select>
                                            </div>             
                                        </div>
                                    </div>
                                </div> }    
                                <div className="col-lg-2 col-sm-2 col-xs-12 justify-content-end text-end">                  
                                    <a href={this.state.angularUrl} className="btn btn-primary">Back</a>
                                </div>              
                            </Row>
                            <hr className="m-t-5 col-sm-12 m-b-8 p-0"></hr>    */}
                            <div className="col-lg-12 col-sm-12 col-xs-12 p-b-15-xs p-r-15-xs">
                                <Row className=" mt-3 mb-3">
                                    {/* <Col className="col-lg-4 ">
                                        <Row>
                                            <Col className="col-lg-4 px-0 general-content text-center">
                                                <label className="pt-2">Business Analyst :</label> 
                                            </Col>
                                            <Col className="col-lg-6">                  
                                                <div className="selectpicker_dropdown hide_bar w-100 selectpicker_dropdown_orange">  
                                                <Select
                                                    className="basic-single-select"
                                                    classNamePrefix={"select_dropdown"}
                                                    placeholder="Select Business Analyst"
                                                    isClearable
                                                    isSearchable={true}
                                                    onChange={(e)=>this.getMilestoneReportByBusinessAnalyst(e,this.state.selectBusinessAnalyst)}
                                                    value={this.state.selectBusinessAnalyst}
                                                    options={this.state.businessAnalystList} >                                                      
                                                </Select>
                                                </div>    
                                            </Col>     
                                        </Row>
                                    </Col>
                                    <Col className="col-lg-4 text-end">
                                        <Row>
                                            <Col className="col-lg-4 px-0 general-content text-center">
                                                <label className="pt-2">Tech Lead :</label> 
                                            </Col>
                                            <Col className="col-lg-6">                  
                                                <div className="selectpicker_dropdown hide_bar w-100 selectpicker_dropdown_orange">  
                                                    <Select
                                                        className="basic-single-select"
                                                        classNamePrefix={"select_dropdown"}
                                                        placeholder="Select Tech Lead"
                                                        isClearable
                                                        isSearchable={true}
                                                        onChange={(e)=>this.getMilestoneReportByTechHead(e,this.state.selectTechLead)}
                                                        value={this.state.selectTechLead}
                                                        options={this.state.techLeadList} >                                                      
                                                    </Select>
                                                </div>      
                                            </Col>     
                                        </Row>
                                    </Col>                               
                                     */}
                                    <Col className="col-lg-3 text-end">
                                        <Row>
                                            <Col className="col-lg-5 general-content text-left p-r-0">
                                                <label className="pt-2">Client Name :</label> 
                                            </Col>
                                            <Col className="col-lg-7">                  
                                                <div className="selectpicker_dropdown hide_bar w-100 selectpicker_dropdown_orange">  
                                                    <Select
                                                        className="basic-single-select"
                                                        classNamePrefix={"select_dropdown"}
                                                        placeholder="Select Client Name"
                                                        isClearable
                                                        isSearchable={true}
                                                        onChange={this.getClientName}
                                                        value={this.state.selectClientName}
                                                        options={this.state.clientNameList} >                                                      
                                                    </Select>
                                                </div>      
                                            </Col>     
                                        </Row>
                                    </Col>                               
                                    <Col className="col-lg-3 text-end">
                                        <Row>
                                            <Col className="col-lg-5 general-content text-left p-r-0">
                                                <label className="pt-2">Invoice Status :</label> 
                                            </Col>
                                            <Col className="col-lg-7">                  
                                                <div className="selectpicker_dropdown hide_bar w-100 selectpicker_dropdown_orange">  
                                                    <Select
                                                        className="basic-single-select"
                                                        classNamePrefix={"select_dropdown"}
                                                        placeholder="Select Status"
                                                        isClearable
                                                        isSearchable={true}
                                                        onChange={this.handleInvStatusChanged}
                                                        options={invoiceStatusOptions}
                                                        value={this.state.selectInvoiceStatus}
                                                        >                                                      
                                                    </Select>
                                                </div>      
                                            </Col>     
                                        </Row>
                                    </Col>   
                                    <Col className="col-lg-3 text-end">
                                        <Row>
                                            <Col className="col-lg-5 general-content  text-left p-r-0">
                                                <label className="pt-2">Due Status :</label> 
                                            </Col>
                                            <Col className="col-lg-7">                  
                                                <div className="selectpicker_dropdown hide_bar w-100 selectpicker_dropdown_orange">  
                                                    <Select
                                                        className="basic-single-select"
                                                        classNamePrefix={"select_dropdown"}
                                                        placeholder="Select Due Status"
                                                        isClearable
                                                        isSearchable={true}
                                                        onChange={this.handleDuedateChanged}
                                                        options={dueDateOptions}
                                                        value={this.state.selectDueDate}
                                                        >                                                      
                                                    </Select>
                                                </div>      
                                            </Col>     
                                        </Row>
                                    </Col>
                                    <Col className="col-lg-3 text-end">
                                        <Row>
                                            <Col className="col-lg-5 general-content text-left p-r-0">
                                                <label className="pt-2">Ageing Period :</label> 
                                            </Col>
                                            <Col className="col-lg-7">                  
                                                <div className="selectpicker_dropdown hide_bar w-100 selectpicker_dropdown_orange">  
                                                    <Select
                                                        className="basic-single-select"
                                                        classNamePrefix={"select_dropdown"}
                                                        placeholder="Select Ageing Period"
                                                        isClearable
                                                        isSearchable={true}
                                                        onChange={this.handleInvValueChanged}
                                                        options={invoiceValue}
                                                        value={this.state.selectInvoiceValue}
                                                        >                                                      
                                                    </Select>
                                                </div>      
                                            </Col>     
                                        </Row>
                                    </Col>
                                </Row>                   
                            </div>
                            <div className="col-lg-12 col-sm-12 col-xs-12 p-b-15-xs p-r-15-xs">
                            <Row className=" mt-3 mb-3">
                                <Col className="col-lg-5 text-start col">
                                     <div className="form-group search-icon col-sm-12 col-xs-12">
                                        <input type="text" name="project_filter" value={this.state.searchBy} className="form-control B-G-bold f-s-16 d-gray pl-30" placeholder="Search..." onChange={(e) => this.searchBy(e)}  />
                                        <i class="fa fa-search m-t-3" style={{ "font-size": "16px" }}></i>                                   
                                    </div>   
                                </Col>   
                                <Col className="col-lg-7 text-end">
                                    {/* <Button onClick={(e) => this.resetReport(e)} value="Generate" className="me-2" >Generate</Button> */}
                                    { this.state.filteredMilestoneData.length > 0 && <Button  onClick={(e) => this.generateCollectionPotentialReportAsExcel(e)} value="Excel" className="me-2">Export as Excel</Button>}
                                </Col>                                
                            </Row>                   
                            </div> 
                            <div className="center-block text-center">                             
                                <div className="col-lg-12 col-sm-12 col-xs-12 p-b-15-xs">
                                    <div className="p-0 B-G-bold f-s-16 d-gray m-t-8 m-b-0 page-head">                  
                                        <Row className="my-3">
                                            {/* { this.state.filterByPractice.length == 0 && <Col className="col-lg-4 text-start" style={{"font-size": "15px"}}><div>Expected Collection Potential: $ {this.state.totalMileStoneValue}</div></Col> }
                                            { this.state.filterByPractice.length > 0 && <Col className="col-lg-4 text-start" style={{"font-size": "15px"}}><div>Expected Collection Potential: $ {this.state.filterMileStoneValue}</div></Col> }
                                            { this.state.filterByPractice.length == 0 && <Col className="col-lg-3" style={{"font-size": "15px"}}><div >Not Sure Value: $ {this.state.sumOfNotSureValue}</div></Col>}
                                            { this.state.filterByPractice.length > 0 && <Col className="col-lg-3" style={{"font-size": "15px"}}><div >Not Sure Value: $ {this.state.filterNotSure}</div></Col>} */}
                                            {/* { this.state.filterByPractice.length == 0 && <Col className="col-lg-3 text-start" style={{"font-size": "15px"}}>Yet to Update: $ {this.state.totalNewValue} | {this.state.notUpdatedValue}<span style={{"font-size": "12px"}}  className="p-l-5">(New | NA)</span>    </Col>}             
                                            { this.state.filterByPractice.length > 0 && <Col className="col-lg-3 text-start" style={{"font-size": "15px"}}>Yet to Update: $ {this.state.filterNewValue} | {this.state.filterNotUpdated}<span style={{"font-size": "12px"}} className="p-l-5">(New | NA)</span>     </Col>} */} 
                                            <Col className="col-lg-3 text-start" style={{"font-size": "15px"}}><div>Not Yet Due Value: $ {this.state.totalNotYetDueValue.toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                            })}</div>  </Col>  
                                            <Col className="col-lg-3 text-start" style={{"font-size": "15px"}}><div>On Due Value: $ {this.state.totalOnDueValue.toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                            })}</div>  </Col>  
                                            { this.state.filterByPractice.length == 0 &&    <Col className="col-lg-3 text-start" style={{"font-size": "15px"}}><div>Total Pending Value: $ {this.state.totalPendingValue.toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                            })}</div>  </Col> }  
                                            { this.state.filterByPractice.length > 0 &&    <Col className="col-lg-3 text-start" style={{"font-size": "15px"}}><div>Total Pending Value: $ {this.state.filterPendingValue!= undefined && this.state.filterPendingValue.toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                            })}</div>  </Col> }
                                        </Row> 
                                        <Row className="my-3">
                                        {/* { this.state.filterByPractice.length == 0 && <Col className="col-lg-4 text-start" style={{"font-size": "15px"}}><div>Total invoice Raised: $ {this.state.totalRaisedValue}</div></Col> }
                                        { this.state.filterByPractice.length > 0 && <Col className="col-lg-4 text-start" style={{"font-size": "15px"}}><div>Total invoice Raised: $ {this.state.filterRaisedValue}</div></Col> }
                                        { this.state.filterByPractice.length == 0 &&    <Col className="col-lg-3" style={{"font-size": "15px"}}><div>Total Collected Value: $ {this.state.totalCollectedValue.toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                            })}</div>  </Col>  }
                                        { this.state.filterByPractice.length > 0 &&    <Col className="col-lg-3" style={{"font-size": "15px"}}><div>Total Collected Value: $ {this.state.filterCollectedValue != undefined && this.state.filterCollectedValue.toLocaleString(undefined, {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2
                                                            })}</div>  </Col>  } */}
                                        </Row>                                   
                                    </div>
                                </div>                           
                            </div>    
                            {/* <Row>
                                <div className="col-md-12 p-r-0">
                                    <div className="col-lg-12 col-sm-12 col-xs-12 p-b-15-xs" >
                                        <h3 className="text-left text-uppercase heading--table m-t-10 m-b-5 fs-15 pull-left" style={{"font-size": "18px"}}>My Practice</h3>
                                        <div className= "col-md-12 p-0 m-b-15 text-end">
                                            <button onClick={this.NotSure} className={this.state.mileStoneReportPractice ==0 ? "btn btn-primary B-G-bold t-t-upper pull-right" : "btn btn-primary B-G-bold t-t-upper"}>
                                                Update
                                            </button>
                                        </div>
                                        <div className="white-table-class utilize-box p-t-10 p-b-10 clearfix practicebox--list scrollbar-design my-collection min-h100">
                                            <Row className="text-start list_clr">
                                                <div className={this.state.mileStoneReportPractice == 0 ? "col-sm-3" : "col-sm-1"} onClick={(e)=>this.filterPractice(e,'',myPracList)} >
                                                    <div class="col-sm-12 p-l-0 p-r-5" className={this.state.filter_class}>
                                                        <p className="m-b-5 m-t-5 fs-12">All</p>
                                                        <h3 className="fs-14" style={{"font-size": "15px"}}>$ {this.state.myPracticeMileStone }</h3>
                                                        <hr className="m-t-7 m-b-0"></hr>
                                                    </div>
                                                </div>
                                                
                                                { myPracList.map((practiceItem) => (                                           
                                                <div className={practiceItem.isChecked  ? 'col-sm-1 active': 'col-sm-1'}   >
                                                    <div className="col-sm-12 p-l-0 p-r-5 text-start" id="class_grey_row_">
                                                        <input type="checkbox" id={practiceItem.practiceName} value={practiceItem.practiceName} className ={practiceItem.isChecked  ? 'B-G-bold f-s-12 l-gray pl-5 pr-5 pr-list mr-5 active': 'B-G-bold f-s-12 l-gray pl-5 pr-5 pr-list mr-5'}   style={{"float": "left","marginRight":"7px","verticalAlign":"top"}} onClick= {(e)=>this.filterPractice(e,practiceItem,myPracList)}
                                                        />
                                                        <p class="m-b-5 m-t-5 fs-12 text-truncate" title={`${practiceItem.practiceName}`}>{practiceItem.practiceName}</p>
                                                        <h3 class="fs-13" style={{"font-size": "13px"}}>$ {practiceItem.mileStoneValue}</h3>
                                                        <hr class="m-t-7 m-b-0"></hr>
                                                    </div>
                                                </div>                                             
                                                ))}
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 p-r-0" >
                                    {this.state.mileStoneReportPractice == 0 &&
                                        <div className="col-lg-12 col-sm-12 col-xs-12 p-b-15-xs">
                                            <h3 className="text-left text-uppercase heading--table m-t-10 m-b-5 fs-15" style={{ "font-size": "18px" }}>Other Practices</h3>
                                            <div className="white-table-class utilize-box p-t-10 p-b-10 clearfix practicebox--list scrollbar-design my-collection min-h100">
                                            <Row className="text-start list_clr">
                                                <div className="col-sm-2" onClick={(e)=>this.filterPractice(e,'',allPracList)} >
                                                        <div class="col-sm-12 p-l-0 p-r-5" className={this.state.filter_class}>
                                                            <p className="m-b-5 m-t-5 fs-12">All</p>
                                                            <h3 className="fs-14" style={{"font-size": "15px"}}>$ {this.state.allPracticeMileStone }</h3>
                                                            <hr className="m-t-7 m-b-0"></hr>
                                                        </div>
                                                    </div>
                                                
                                                    { allPracList.map((practiceItem) => (                                           
                                                    <div className={practiceItem.isChecked  ? 'col-sm-2 active': 'col-sm-2'}   >
                                                        <div className="col-sm-12 p-l-0 p-r-5 text-start" id="class_grey_row_">
                                                            <input type="checkbox" id={practiceItem.practiceName} value={practiceItem.practiceName} className ={practiceItem.isChecked  ? 'B-G-bold f-s-12 l-gray pl-5 pr-5 pr-list mr-5 active': 'B-G-bold f-s-12 l-gray pl-5 pr-5 pr-list mr-5'}   style={{"float": "left","marginRight":"7px","verticalAlign":"top"}} onClick= {(e)=>this.filterPractice(e,practiceItem,allPracList)}
                                                            />
                                                            <p class="m-b-5 m-t-5 fs-12 text-truncate" title={`${practiceItem.practiceName}`}>{practiceItem.practiceName}</p>
                                                            <h3 class="fs-14" style={{"font-size": "15px"}}>$ {practiceItem.mileStoneValue}</h3>
                                                            <hr class="m-t-7 m-b-0"></hr>
                                                        </div>
                                                    </div>                                             
                                                    ))}
                                                </Row>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </Row>
                            <hr className="m-t-5 col-sm-12 m-b-8 p-0"></hr> */}
                            <div className="invoice--report bg-transparent inventory_allo_table inventory_allo_tablereact p-l-0 p-r-0 p-t-0 p-b-0 m-t-5">
                                <Tabs
                                activeKey={this.state.defaultActiveKey}
                                id="main_view"
                                className="mb-3"
                                onSelect={(event) => {
                                    this.changeInventoryView(event);
                                }}
                                >
                                    <Tab
                                    eventKey="pending_view"
                                    title="Main"
                                    >
                                        <div className="table-responsive white-table-class reports--grid ">
                                        <div className="scrollable">
                                            <div className="scrollable-content">
                                                <table className="table m-b-0 table-wrap">
                                                    <thead className="bg-blue">
                                                        <tr className="theadInner">
                                                            <th className="text-start thead1" style={{"width": "10%","padding": "10px"}}>Critical Account</th>
                                                            <th className="text-start thead1" style={{"width": "30%","padding": "15px"}}>Client Name</th>
                                                            <th className="text-start thead1" style={{"width": "30%","padding": "10px"}}>Project Name</th>
                                                            <th className="text-start thead1" style={{"width": "15%","padding": "10px"}}>Total Pending Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {this.state.loading == true &&
                                                        <div id="preloader">
                                                            <div id="status"></div>
                                                        </div>
                                                    }
                                                    { this.state.loading == false &&
                                                    this.state.filteredMilestoneData.length == 0 &&
                                                        <tr>
                                                            <td colspan="8" className="r-no-data text-center">No data available</td>
                                                        </tr>
                                                    }
                                                    { this.state.loading == false && this.state.filteredMilestoneData.length > 0 && this.state.filteredMilestoneData.map((item,index) => (
                                                    <tr className={item.is_critical ? "light_red_row" :""}>
                                                        { 
                                                        commonCritAcc.some(ac => ac.mergeIndex==index) && 
                                                            <td className="text-start" rowSpan={ commonCritAcc[index].mergeCount}>
                                                                <input type="checkbox" id="criticalacc_{{idx}}" style={{"accentColor": "#e74c3c"}} data-toggle="tooltip" title="Click to add critical account" data-trigger="hover" value={item.is_critical} checked={item.is_critical} onChange={(e)=> this.changeCriticalChecked(e,item)}
                                                                />
                                                            </td>
                                                        }
                                                        
                                                        { 
                                                        commonCritAcc.some(ac => ac.mergeIndex==index) && 
                                                            <td className="text-start" rowSpan={ commonCritAcc[index].mergeCount}>
                                                                { (item.clientName) ? 
                                                                    ([2,3,4].includes(item.invoice_status)) ?
                                                                        <button type="button" class="btn popover-link fs-14 p-0 text-left" data-toggle="tooltip" title="Add/View Comments" data-trigger="hover" onClick={(e) => this.addComments(item)}>{item.clientName}
                                                                        </button> : <span>{item.clientName}</span>
                                                                    : <span>NA</span>
                                                                }
                                                            </td>
                                                        }
                                                        
                                                        { 
                                                        commonCritAcc.some(ac => ac.mergeIndex==index) && 
                                                            <td className="text-start" rowSpan={ commonCritAcc[index].mergeCount}>
                                                                {/* <span data-toggle="tooltip" title={item.practiceName} data-trigger="hover" className="t-t-upper practise-li python pull-left">{practiceRename(item.practiceName)}</span> */}
                                                                <span className="name p-l-5 text-truncate txt-black p-t-3 fs-14" style={{"maxWidth": "200px"}} title={item.projectName}>{item.projectName}
                                                                </span>
                                                                <span className="s-gray lead-name">{item.techLead} / {item.businessAnalyst}</span>
                                                            </td>
                                                        }
                                                        { 
                                                        commonCritAcc.some(ac => ac.mergeIndex==index) && 
                                                            <td className="text-start" rowSpan={ commonCritAcc[index].mergeCount}>
                                                                <div onClick={() => this.showInvoiceDetails(item.projectId)} className='bench_data' title='Please Click to view invoice details'>
                                                                    ${item.totalPendingValue}
                                                                </div>
                                                            </td>
                                                        } 
                                                    </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    </Tab>
                                    <Tab
                                    eventKey="invoice_view"
                                    title="List of Invoices"
                                    >
                                        <div className="table-responsive white-table-class reports--grid ">
                                            <div className="scrollable">
                                                <div className="scrollable-content">
                                                    <table className="table m-b-0 table-wrap">
                                                        <thead className="bg-blue">
                                                            <tr className="theadInner">
                                                                    <th className="text-start thead1" style={{"width": "20%","padding": "10px"}}>Client Name</th>
                                                                    <th className="text-start thead1" style={{"width": "20%","padding": "10px"}}>Project Name</th>
                                                                    <th className="text-start thead1" style={{"width": "10%","padding": "10px"}}>Invoice No</th>                
                                                                    <th className="text-start thead1" style={{"width": "15%","padding": "10px"}}>Invoice Pending</th>
                                                                    <th className="text-start thead1" style={{"width": "10%","padding": "10px"}}>Due Date</th>    
                                                                    <th className="text-start thead1" style={{"width": "10%","padding": "10px"}}>Ageing Period</th>                    
                                                                    <th className="text-start thead1" style={{"width": "30%"}}>Milestone status</th>
                                                                </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.loading == true &&
                                                                <div id="preloader">
                                                                        <div id="status"></div>
                                                                    </div>
                                                            }
                                                            { this.state.loading == false &&
                                                            this.state.filteredMilestoneData.length == 0 &&
                                                                <tr>
                                                                    <td colspan="8" className="r-no-data text-center">No data available</td>
                                                                </tr>
                                                            }
                                                            { this.state.loading == false &&
                                                            this.state.invoiceDetails.length == 0 && this.state.filteredMilestoneData.map((item,index) => (
                                                                <tr className={item.is_critical ? "light_red_row" :""}>
                                                                    { 
                                                                    commonCritAcc.some(ac => ac.mergeIndex==index) && 
                                                                        <td className="text-start" rowSpan={ commonCritAcc[index].mergeCount}>
                                                                            { (item.clientName) ? 
                                                                                ([2,3,4].includes(item.invoice_status)) ?
                                                                                    <button type="button" class="btn popover-link fs-14 p-0 text-left" data-toggle="tooltip" title="Add/View Comments" data-trigger="hover" onClick={(e) => this.addComments(item)}>{item.clientName}
                                                                                    </button> : <span>{item.clientName}</span>
                                                                                : <span>NA</span>
                                                                            }
                                                                        </td>
                                                                    }
                                                                    
                                                                    { 
                                                                    commonCritAcc.some(ac => ac.mergeIndex==index) && 
                                                                        <td className="text-start" rowSpan={ commonCritAcc[index].mergeCount}>
                                                                            {/* <span data-toggle="tooltip" title={item.practiceName} data-trigger="hover" className="t-t-upper practise-li python pull-left">{practiceRename(item.practiceName)}</span> */}
                                                                            <span className="name p-l-5 text-truncate txt-black p-t-3 fs-14" style={{"maxWidth": "200px"}} title={item.projectName}>{item.projectName}
                                                                            </span>
                                                                            <span className="s-gray lead-name">{item.techLead} / {item.businessAnalyst}</span>
                                                                        </td>
                                                                    }
                                                                    
                                                                    <td className="text-start">
                                                                        { (item.invoice_no) ? 
                                                                            <span>{item.invoice_no}</span>
                                                                            : <span>NA</span>
                                                                        }
                                                                    </td>
                                                                    
                                                                    <td className="text-start">
                                                                        <span> ${item.userPendingMilestoneValue  ? item.userPendingMilestoneValue.toLocaleString(undefined, {
                                                                            minimumFractionDigits: 2,
                                                                            maximumFractionDigits: 2
                                                                        }) : 0}
                                                                        </span>
                                                                    </td>
                                                                    <td className="text-start">
                                                                        <span>{item.due_date}</span>
                                                                    </td>
                                                                    <td className="text-start">
                                                                        <span>{item.due_value}</span>
                                                                    </td>
                                                                    <td className={!item.is_critical ? item.inv_class : ''}>                                                      
                                                                            { item.invoice_status  && this.state.collection_potential_invoicestatus.map((invoice) => ( 
                                                                                <span>  
                                                                                    { invoice.id == item.invoice_status &&                                             
                                                                                    <span className="pull-left" >
                                                                                        <span title="Invoice NO: {item.invoice_no}" tooltip-placement="left">{invoice.value}</span>       
                                                                                        </span>}
                                                                                </span> ))
                                                                            }  
                                                                    </td> 
                                                                </tr>
                                                                ))
                                                            }
                                                            { this.state.loading == false && this.state.invoiceDetails.length > 0 && this.state.invoiceDetails.map((item,index) => (
                                                            <tr className={item.is_critical ? "light_red_row" :""}>
                                                                { (this.state.commonInvCritAcc==0 ? commonCritAcc.some(ac => ac.mergeIndex==index) :
                                                                index==0) && 
                                                                    <td className="text-start" rowSpan={ this.state.commonInvCritAcc==0? commonCritAcc[index].mergeCount : this.state.commonInvCritAcc}>
                                                                        { (item.clientName) ? 
                                                                            ([2,3,4].includes(item.invoice_status)) ?
                                                                                <button type="button" class="btn popover-link fs-14 p-0 text-left" data-toggle="tooltip" title="Add/View Comments" data-trigger="hover" onClick={(e) => this.addComments(item)}>{item.clientName}
                                                                                </button> : <span>{item.clientName}</span>
                                                                            : <span>NA</span>
                                                                        }
                                                                    </td>
                                                                }
                                                                { (this.state.commonInvCritAcc==0 ? commonCritAcc.some(ac => ac.mergeIndex==index) :
                                                                index==0) && 
                                                                    <td className="text-start" rowSpan={ this.state.commonInvCritAcc==0? commonCritAcc[index].mergeCount :  this.state.commonInvCritAcc}>
                                                                        {/* <span data-toggle="tooltip" title={item.practiceName} data-trigger="hover" className="t-t-upper practise-li python pull-left">{practiceRename(item.practiceName)}</span> */}
                                                                        <span className="name p-l-5 text-truncate txt-black p-t-3 fs-14" style={{"maxWidth": "200px"}} title={item.projectName}>{item.projectName}
                                                                        </span>
                                                                        <span className="s-gray lead-name">{item.techLead} / {item.businessAnalyst}</span>
                                                                    </td>
                                                                }
                                                                <td className="text-start">
                                                                    { (item.invoice_no) ? 
                                                                        <span>{item.invoice_no}</span>
                                                                        : <span>NA</span>
                                                                    }
                                                                </td>
                                                                <td className="text-start">
                                                                    <span> ${item.userPendingMilestoneValue  ? item.userPendingMilestoneValue.toLocaleString(undefined, {
                                                                        minimumFractionDigits: 2,
                                                                        maximumFractionDigits: 2
                                                                    }) : 0}
                                                                    </span>
                                                                </td>
                                                                <td className="text-start">
                                                                    <span>{item.due_date}</span>
                                                                </td>
                                                                <td className="text-start">
                                                                    <span>{item.due_value}</span>
                                                                </td>
                                                                <td className={!item.is_critical ? item.inv_class : ''}>                      
                                                                    { item.invoice_status  && this.state.collection_potential_invoicestatus.map((invoice) => ( 
                                                                        <span>  
                                                                            { invoice.id == item.invoice_status && 
                                                                            <span className="pull-left" >
                                                                                <span title="Invoice NO: {item.invoice_no}" tooltip-placement="left">{invoice.value}</span>
                                                                                {/* <label className="control control--checkbox " style={{"font-size":"10px"}}>
                                                                                {(item.invoice_status) && (item.invoice_status != 3) &&
                                                                                <p ><input type="checkbox" id="convertnotsure_{{idx}}" value={item.is_not_sure} checked={item.is_not_sure} onChange={(e)=>this.changeToNotSure(e,item)}
                                                                                />Not Sure</p>}
                                                                                </label> */}
                                                                            
                                                                                </span>}
                                                                            </span> ))
                                                                    } 
                                                                </td> 
                                                            </tr>
                                                            ))}
                                                            </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>   
                                    </Tab>
                                </Tabs>
                            </div>
                        </div>                           
                    </Row>
                </div>  
                {(this.state.showCommentModal) && 
                    <CommentSection
                        filterId={this.state.filterId}
                        iterationDetails={this.state.iterationDetails}
                        userName={this.state.loginUserData.first_name +' '+(this.state.loginUserData.last_name || '')}
                        showCommentModal={this.state.showCommentModal}
                        handleCloseModal={this.handleCloseModal}
                    /> 
                }
                </>  
            );
        }
	}
}


const mapStateToProps = (state) => {
    return {
        addClass: state.pmsDetails.addClass,
    };
  };
  
  const mapDispatchToProps = {
    setAddClass
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(CollectionPotentialReport);
  
import React, { Component, useEffect, useState } from "react";
import { Urls } from "../../Config/Config";
import { Link } from "react-router-dom";
import "../Reports/Report.css";
import Select from "react-select";
import {
  Grid,
  Row,
  Col,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import moment from "moment";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import PW_report from "../Reports/PW_report";
import PageNotFound from "../Reports/PageNotFound";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MileStoneReport from "../Reports/msreport";
import CollectionPotentialReport from "../Reports/Collection_potential_report";
import CollectionsReport from "../Reports/Collections_report";
import InventoryReport from "../Reports/InventoryReport";
import InventoryMilestoneReport from "../Reports/Inventory_milestone_report";
import InventoryAllocationReport from "../Reports/Inventory_Allocation_report";
import {
  getUtilizationReport, getLoginUser, getReportFilter, utilizationExportExcel, getLoginUserPermission, getCheckSum, setAddClass
} from "../../Action";
import { connect } from "react-redux";
import * as constants from "../../Config/Constant";
import AllocationView from "./AllocationView";
import BenchResource from "./BenchResource";
import BenchView from "./BenchView";
import accessdenied from "../../access-denied.png";
import CsmReport from '../CsmReportComponent/CsmReport';
import { getUserService, getReportFilterService, getUtilizationService } from "../../Utilities/Helper";
import DeveloperView from "./DeveloperView";
import { Dropdown } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import ActualView from "./ActualView";
import ProjectionView from "./ProjectionView";
var _ = require('lodash');

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  customClass: {
    popup: "colored-toast",
    icon: "white-toast-icon",
  },
  timer: 3000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});


class ResourceUtilizationReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: new URLSearchParams(window.location.search),
      selected: "",
      setSelected: "",
      redirectUrl: "",
      redirect: 0,
      user_types: Urls.user_types,
      active: false,
      all_active: true,
      reports_lists: Urls.reports_lists,
      reports_lists1: Urls.reports_lists1,
      current_report: Urls.reports_lists[5],
      report: {},
      projectPractice: [],
      project_practice: [],
      isAllPractices: false,
      getUserscount: "",
      practiceleadName: "",
      gridListBackup: [],
      gridList: [],
      planned_hrs: 0,
      support_hrs: 0,
      unallocated_hrs: 0,
      effective_yield: 0,
      expected_yield: 0,
      gap_in_allocation: 0,
      deficit: 0,
      userTypeId: 0,
      totalResAvHrCal: 0,
      msAlloc: 0,
      supphrs: 0,
      totalResourceAvailHrsFrontendCalcution: 0,
      msAllocatedHrs: 0,
      supportHrs: 0,
      benchHrs: 0,
      userTypes: Urls.user_types,
      isGanttView: false,
      updateLoader: false,
      current_Gant_popover: {},
      head_id: "",
      // for inventory report
      support_practice: [6, 12, 13, 21],
      inventory_isDetailedView: true,
      isAllPractices_inventory: false,
      searchByPracticeHead: "",
      // for TL and BA
      techLeadList: [],
      businessAnalystList: [],
      techLeadId: "",
      businessAnalystId: "",
      searchByTechLead: "",
      searchByBusinessAnalyst: "",
      searchBy: "",
      isChecked: false,
      // for milestone report
      allPracticeMileStone: 0,
      mileStoneValueReport: {},
      filteredMilestoneData: [],
      mileStoneReportPractice: 1,
      filterByPractice: "",
      filterMileStoneValue: "",
      milestone_achieved_dropdown_value: Urls.milestone_achieved_dropdown_value,
      invoice_dropdown_value: Urls.milestone_achieved_dropdown_value,
      // for collection-potential report
      currentFilter: { id: 0, value: "All" },
      filterNotSure: "",
      true_data: [],
      checked_data: {},
      sumoffilterMileStoneValue: 0,
      SumofMileStoneValue: 0,
      collection_potential_invoicestatus: Urls.invoice_status,
      return_to_pwreport: 0,
      resource_types: Urls.resource_types,
      resource_types_new: Urls.resource_types_new,
      // resourceFilter : {'id':0, 'value':'All'},
      resourceFilter: "All",
      resourceAllFilter: "All",
      filter_start_date: new Date(),
      // filter_start_date : '2022-01-01',
      filter_end_date: new Date(),
      sname: [],
      sname1: [],
      report_practice: [],
      report_service: [],
      practiceHead: [],
      totalMileStoneValue: 0,
      totalKickoffValue: 0,
      totalCarryValue: 0,
      milestoneReport: [],
      submitted: false,
      practiceReport: [],
      overallMileStoneValue: 0,
      sumOfNotSureValue: 0,
      notUpdatedValue: 0,
      totalNewValue: 0,
      totalRaisedValue: 0,
      totalCollectedValue: 0,
      totalPendingValue: 0,
      mileStoneReportMonth: "",
      mileStoneReportYear: "",
      myPracticeMileStone: 0,
      filter: [],
      filterByPractice: [],
      filterMileStoneValue: 0,
      filterNotSure: 0,
      filterNotUpdated: 0,
      filterNewValue: 0,
      filterRaisedValue: 0,
      filterCollectedValue: 0,
      filterPendingValue: 0,
      selectedInvoiceStatus: "All",
      selectedInvoice: "All",
      loginUserData: {},
      loginUserPermission: {},
      token: "",
      // token : JSON.parse(Cookies.get('globals')).currentUser.authdata,
      loading: false,
      start_month: moment().utcOffset("+05:30").format("YYYY-MM"),
      end_month: moment().utcOffset("+05:30").format("YYYY-MM"),
      project_name_filter: "",
      bu_id: "",
      utilizationCounts: {},
      yieldCounts: {},
      reportPrac: [],
      practice_length: 0,
      selectService: [],
      selectBu: [],
      selectBuFilter: [],
      reportList: [],
      serviceName: [],
      monthSeries: [],
      moolahDeficit: 0,
      boolahDeficit: 0,
      bcDeficit: 0,
      vendorDeficit: 0,
      externDeficit: 0,
      checkedList: [],
      start: new Date(),
      reportF: 0,
      inventoryReportList: [],
      inventorybu_data: [],
      bu_qes: Urls.bu_qes,
      practice_qes: Urls.practice_qes,
      service_qes: Urls.service_qes,
      buName: [],
      defaultActiveKey: 'allocation_view',
      bu_total_user: 0,
      buTotalUser: 0,
      bu_totalAvailHrs: 0,
      bu_totalMSHrs: 0,
      bu_totalBench: 0,
      bu_total_utilization: 0,
      bu_total_cost: 0,
      bu_totalMS: 0,
      bu_totalMSAcheived: 0,
      bu_bench_user: 0, bu_totalSuppHrs: 0, total_intern_user: 0, total_resource: 0, targetMargin: 0, marginValue: 0, gapInAllocation: 0,
      benchUserTab: 'all_resource',
      utilizationReport: [],
      checkSum: false,
      month_size: 0,
      subBenchResourceCount: 0,
      subTotalVendor: 0,
      subEmployeeCount: 0,
      subTotalEmployee: 0,
      subTotalInterns: 0,
      subTotalResource: 0,
      subAvailableHrs: 0,
      subMsHours: 0,
      subSupportHrs: 0,
      subTotalBenchHrs: 0,
      subTotalUtilization: 0,
      subTotalCost: 0,
      subTargetMargin: 0,
      subMsAllocation: 0,
      subActualAllocation: 0,
      subAllocationYield: 0,
      subMsInvoice: 0,
      subGapInMsAllocation: 0,
      subMargin: 0,
      subBenchResource: 0,
      grandTotalEmployee: 0,
      grandTotalInterns: 0,
      grandTotalResource: 0,
      grandTotalAvailableHrs: 0,
      grandMsHours: 0,
      grandSupportHrs: 0,
      grandTotalBenchHrs: 0,
      grandTotalUtilization: 0,
      grandTotalCost: 0,
      grandTargetMargin: 0,
      grandMsAllocation: 0,
      grandMsYield: 0,
      grandGapInMsAllocation: 0,
      grandMargin: 0,
      grandBenchResource: 0,
      allUserList: [],
      grandTotal: [],
      checkboxEnable: true,
      filterDate: '2022-04-01',
      usersService: []
    };
  }

  reset_page = () => {
    this.state.report.service = [];
    this.state.report.practice = [];
    this.state.selectService = [];
    this.generateInventory();
  };

  setStartDateForFilter = (e) => {
    this.state.start_month = e;
    let today = new Date(e);
    this.state.filter_start_date = today;
    this.setState({
      filter_start_date: this.state.filter_start_date,
    });

    if(this.state.filter_start_date > this.state.filter_end_date){
      this.state.filter_end_date = today;
      this.ToastAlert("warning", "End date should greater than start date");
    } 
  };

  setEndDateForFilter = (e) => {
    this.state.end_month = e;
    let today = new Date(e);
    this.setState({
      filter_end_date: today,
    });
  };

  componentDidMount() {
    this.state.reports_lists.forEach((value) => {
      let opt = {};
      opt.label = value.value;
      opt.id = value.id;
      opt.value = value.value;
      this.state.reportList.push(opt);
    });
    this.state.current_report = this.state.reportList[7];
    this.state.token =
      this.state.queryParams.get("token") == null
        ? Cookies.get("auth_data")
        : this.state.queryParams.get("token");

    this.state.apiLink = constants.API_LINK;
    this.state.angularUrl = constants.FRONT_LINK;
    this.state.excelUrl = constants.EXCEL_LINK;
    if (this.state.apiLink === constants.STAGING) {
      this.state.bu_qes = Urls.staging_bu_qes;
      this.state.practice_qes = Urls.staging_practice_qes;
      this.state.service_qes = Urls.staging_service_qes;
    } else if (this.state.apiLink === constants.UAT) {
      this.state.reportList = [];
      this.state.reports_lists1.forEach((value) => {
        let opt = {};
        opt.label = value.value;
        opt.id = value.id;
        opt.value = value.value;
        this.state.reportList.push(opt);
      });
    } else {
      // do nothing
    }

    this.setState({
      token: this.state.token,
    });
    this.getUser();
    this.getPracticeService();

  }


  /**
   * Get the login user details
   */
  getUser = () => {
    this.setState({ loading: true });
    this.props.getLoginUser().then((response) => {
      this.state.loginUserData = this.props.loginDetails.loginDetails;
      this.props.getLoginUserPermission().then((response) => {
        this.state.loginUserPermission = this.props.loginDetails.loginUserPermission;
        this.setState({ loading: false });
      });
    });
  }

  //filter practice based on service and head
  filterService = (e) => {
    if (e) {
      let selectedOption = e;
      this.state.selectService = selectedOption;
      this.state.report.service = selectedOption;
    }
    this.setState({
      report: {
        service: this.state.report.service,
      },
    });
    this.state.report.practice = [];
    this.state.projectPractice = [];

    this.state.report_all_practices.forEach((value) => {
      value.is_active = false;
    });
    if (this.state.isAllPractices) {
      this.state.projectPractice = this.state.report_all_practices;
    } else {
      let service = this.state.report.service;
      this.state.projectPractice = [];
      if (service.length == 0) {
        this.state.projectPractice = [];
      } else {
        service.forEach((svalue) => {
          this.state.report_all_practices.forEach((value) => {
            if (svalue.id == value.serviceId) {
              this.state.projectPractice.push(value);
            }
          });
        });
      }
    }
    this.setState({
      projectPractice: this.state.projectPractice,
    });
    // for sending all practice while reset
    if (this.state.projectPractice.length > 0) {
      this.state.projectPractice.forEach((value) => {
        this.state.report.practice.push(value.id);
      });
    }
    this.setState({
      report: {
        practice: this.state.report.practice,
        service: this.state.report.service,
      },
      // report_service:this.state.report_service
    });

    this.generateInventory(true);
    // }
  };

  changeViewPracite = (flag) => {
    if (this.state.loginUserData.role_id == 8 && this.state.isAllPractices) {
      this.state.isAllPractices = flag;
    }
    this.selectReport(this.state.current_report);
  };

  selectReport = (report) => {
    this.state.previous_report = this.state.current_report;
    this.state.current_report = report;
    this.state.project_filter = {};
    this.state.searchBy = "$";
    let dateValue = new Date();
    let date = new Date(dateValue.getFullYear(), dateValue.getMonth(), 2);
    this.state.filter_end_date = this.state.filter_end_date
      ? this.state.filter_end_date
      : date;
    this.state.filter_start_date = this.state.filter_start_date
      ? this.state.filter_start_date
      : date;
    this.state.report.practice = this.state.report.practice
      ? this.state.report.practice
      : [];
    this.state.report.head_id = "";
    this.generateInventory(true);
  };

  changeViewPracite_inventory = (flag) => {
    if (this.state.filter_start_date > this.state.filter_end_date) {
      this.state.submitted = true;
      return false;
    }
    if (this.state.loginUserData.role_id == 8 && this.state.isAllPractices_inventory) {
      this.state.isAllPractices_inventory = flag;
    }
    this.state.userPracticesList = [];
    if (this.state.isAllPractices_inventory) {
      if (this.state.report_all_practices != undefined) {
        this.state.report_all_practices.forEach((value_practice) => {
          this.state.userPracticesList.push(value_practice.id);
        });
      }
    }
    if (this.state.report_all_practices != undefined) {
      this.getPracticeService("", flag);
    }
  };

  generateInventory = () => {
    let invoice_status = {};
    this.state.invoice_dropdown_value.forEach((invoice) => {
      if (invoice.value == this.state.selectedInvoiceStatus) {
        invoice_status.id = invoice.id;
        invoice_status.value = invoice.value;
      }
    });
    let services = [];
    services.push(this.state.report.service);
    if (this.state.report.service.length == 0) { // getting user associated service list
      this.state.report.service = getReportFilterService(this.state.serviceName, this.state.loginUserData, this.state.usersService);
    }
    let dataObj = {};
    dataObj.allpractice = this.state.isAllPractices_inventory;
    let serviceId = [];
    this.state.report.service.forEach((sId) => {
      serviceId.push(sId.id);
    });
    dataObj.service = serviceId;
    if (!dataObj.allpractice || dataObj.service.length > 0) {
      if (dataObj.service.length == 0) {
        dataObj.business_unit = this.state.searchByPracticeHead;
      } else if (dataObj.allpractice) {
        dataObj.business_unit = this.state.searchByPracticeHead;
      } else {
        dataObj.business_unit = "";
      }
    } else {
      dataObj.business_unit = this.state.searchByPracticeHead;
    }
    dataObj.business_unit = (this.state.bu_id != null && this.state.bu_id != undefined) ? this.state.bu_id : dataObj.business_unit;
    dataObj.start_date = moment(this.state.filter_start_date)
      .utcOffset('+05:30')
      .format('YYYY-MM-01');
    dataObj.end_date = moment(this.state.filter_end_date)
      .utcOffset('+05:30')
      .format('YYYY-MM-01');
    dataObj.checkSum = this.state.checkSum;
    dataObj.current_report = this.state.current_report;
    dataObj.inventoryAll = (!dataObj.service.length && dataObj.business_unit == '') ? 1 : 0;
    let resourceFilter = {};
    this.state.resource_types.forEach((user) => {
      if (user.value == this.state.resourceFilter) {
        resourceFilter.id = user.id;
        resourceFilter.value = user.value;
      }
    });
    dataObj.selectedUserType = resourceFilter;
    let reportData = {};
    reportData['reportData'] = dataObj;
    this.state.inventoryReportList = [];
    this.state.inventorybu_data = [];
    this.state.levels = [];
    this.setState({ loading: true, businessUnit: dataObj.business_unit, selectedOption: '' });

    this.props.getUtilizationReport(reportData).then((response) => {
      this.setState({ loading: false, checkboxEnable: true });
      localStorage.setItem('record', '');
      this.state.utilizationReport = [];
      this.getUtilizationGrandTotal();
    }, (error) => {
      this.setState({ loading: false });
      this.setState({
        utilizationReport: [],
      });
      this.ToastAlert("error", error);
    });
  };

  getUtilizationGrandTotal = () => {
    this.state.utilizationReport = [];

    let grandTotal;
    this.props.utilizationReport.forEach((value, i) => {
      let report = [];
      let sortBuName = _.sortBy(value.data.service,
        [function (dataBu) { return dataBu.businessUnit; }]);
      this.state.inventorybu_data = sortBuName;
      report.inventorybu_data = sortBuName;
      { console.log("this.state.inventorybu_data", this.state.inventorybu_data) }
      this.state.utilizationReport.push(value);
      this.state.levels = _.values(value.data.level);
      this.state.resourceLevel = _.values(value.data.level);
      this.state.resourceLevel.push('intern');
      let subEmployeeCount = 0, subAllocatedResource = 0, subTotalAvailableHrs = 0, subMsHrs = 0, subSupportHrs = 0, subTotalBenchHrs = 0, subTotalUtilization = 0, subTotalCost = 0, subTargetMargin = 0, subMsAllocation = 0, subMsYield = 0, subGapInMsAllocation = 0, subMargin = 0, subBenchResource = 0, subGapCost = 0, count = 0, subReserveStrength = 0, subReserveMilestone = 0,
        subEmpCount = 0, subInternCount = 0, subMsMargin = 0, subActualMargin = 0, subActualAllocation = 0, subExpectedGrossMargin = 0, subAllocationYield = 0, subTargetRevenue = 0, subRevenueGap = 0;
      let grandTotalEmployee = 0, grandTotalAllocatedEmployee = 0, grandTotalAvailableHrs = 0, grandMsHours = 0
        , grandSupportHrs = 0, grandTotalBenchHrs = 0, grandTotalUtilization = 0, grandTotalCost = 0, grandTargetMargin = 0,
        grandMsAllocation = 0, grandMsYield = 0, grandGapInMsAllocation = 0, grandMargin = 0, grandBenchResource = 0, grandGapCost = 0,
        grandReserveStrength = 0, grandReserveMilestone = 0, grandEmpCount = 0, grandInternCount = 0, grandExpectedGrossMargin = 0, grandMsActual = 0, grandMsMargin = 0, grandActualMargin = 0, grandMsAllocationYield = 0, grandTargetRevenue = 0, grandRevenueGap = 0;
      sortBuName.forEach((element, key) => {
        Object.values(element).forEach((reportValue) => {
          // item.totalMSValue / ((item.allotedHrs) / 160))
          subAllocationYield = ((reportValue.allotedHrs) > 0 ?
            ((((reportValue.totalMsValue)) / (((reportValue.allotedHrs) / 160)))) : 0.00);
          subAllocationYield += subAllocationYield;
          // subAllocationYield += ((reportValue.totalMSValue) / ((reportValue.allotedHrs) / 160));
          { console.log("subAllocationYield", subAllocationYield) }
          subEmployeeCount += (reportValue.totalBenchHrs + reportValue.allotedHrs) / 160;
          subAllocatedResource += (reportValue.allotedHrs) / 160;
          subMsHrs += (reportValue.allotedHrs);
          subTotalBenchHrs += reportValue.totalBenchHrs;
          subTotalAvailableHrs = (subMsHrs + subTotalBenchHrs);
          subTotalUtilization += (reportValue.allotedHrs + reportValue.totalBenchHrs) > 0 ? (((reportValue.allotedHrs) / (reportValue.allotedHrs + reportValue.totalBenchHrs)) * 100) : 0;
          subTotalCost += reportValue.totalCost;
          subTargetMargin += (reportValue.totalCost) * 1.25;
          subMsAllocation += reportValue.totalMSValue;
          subActualAllocation += reportValue.totalMSAcheived;
          subGapCost += (reportValue.totalMSAcheived - reportValue.totalCost);
          subGapInMsAllocation += reportValue.totalMSAcheived - (reportValue.totalCost * 1.25);
          subActualMargin += (reportValue.totalMSAcheived > 0) ? (((reportValue.totalMSAcheived - reportValue.totalCost) / (reportValue.totalMSAcheived)) * 100) : 0;
          subMsMargin += (reportValue.totalMSValue > 0) ? (((reportValue.totalMSValue - reportValue.totalCost) / (reportValue.totalMSAcheived)) * 100) : 0;
          { console.log("subMsMargin", subMsMargin) }
          subBenchResource += (reportValue.totalBenchHrs / 160);
          subMsYield += ((reportValue.allotedHrs) > 0 ? (reportValue.totalMSAcheived / ((reportValue.allotedHrs) / 160)) : 0.00);
          /*  subAllocationYield += ((reportValue.allotedHrs) > 0 ? (reportValue.totalMsValue / ((reportValue.allotedHrs) / 160)) : 0.00);
  */
          subExpectedGrossMargin += reportValue.expectedGrossMargin;
          subEmpCount += (reportValue.totalEmployees);
          subInternCount += (reportValue.totalInterns);
          subTargetRevenue += reportValue.targetRevenue;
          subRevenueGap += reportValue.revenueGap;
          count += 1;
        });
      });


      /* {
        (reportValue.allotedHrs) > 0 ?
          ((((reportValue.totalMsValue)) / ((reportValue.allotedHrs) / 160))) : 0.00
      } */
      grandMsHours += subMsHrs;
      grandTotalEmployee += subEmployeeCount;
      grandTotalAllocatedEmployee += subAllocatedResource;
      grandTotalAvailableHrs += subTotalAvailableHrs;
      grandTotalBenchHrs += subTotalBenchHrs;
      grandTotalUtilization += subTotalUtilization;
      grandTotalCost += subTotalCost;
      grandTargetMargin += subTargetMargin;
      grandMsAllocation += subMsAllocation;
      grandMsActual += subActualAllocation;
      grandMsYield += subMsYield;
      grandMsAllocationYield += subAllocationYield;
      { console.log("grandMsAllocationYield", grandMsAllocationYield) }
      grandGapInMsAllocation += subGapInMsAllocation;
      grandMsMargin += subMsMargin;
      grandActualMargin += subActualMargin;
      grandBenchResource += subBenchResource;
      grandGapCost += subGapCost;
      grandReserveStrength += subReserveStrength;
      grandReserveMilestone += subReserveMilestone;
      grandEmpCount += subEmpCount;
      grandInternCount += subInternCount;
      grandExpectedGrossMargin += subExpectedGrossMargin;
      grandTargetRevenue += subTargetRevenue;
      grandRevenueGap += subRevenueGap;
      grandTotal = {
        grandTotalEmployee: grandTotalEmployee, grandMsHours: grandMsHours,
        grandTotalAvailableHrs: grandTotalAvailableHrs, grandTotalAllocatedEmployee: grandTotalAllocatedEmployee,
        support: grandSupportHrs, grandTotalBenchHrs: grandTotalBenchHrs, grandTotalUtilization: grandTotalUtilization, grandTotalCost: grandTotalCost,
        grandTargetMargin: grandTargetMargin, grandMsAllocation: grandMsAllocation, grandMsYield: grandMsYield, grandGapInMsAllocation: grandGapInMsAllocation, grandReserveMilestone: grandReserveMilestone,
        grandMargin: grandMargin, grandBenchResource: grandBenchResource, grandGapCost: grandGapCost, grandReserveStrength: grandReserveStrength,
        grandEmpCount: grandEmpCount, grandInternCount: grandInternCount, grandExpectedGrossMargin: grandExpectedGrossMargin, grandMsActual: grandMsActual, grandMsMargin: grandMsMargin, grandMsAllocationYield: grandMsAllocationYield, grandTargetRevenue: grandTargetRevenue,grandRevenueGap: grandRevenueGap,
      };
      value.data.grandtotal = grandTotal;
      this.setState({
        utilizationReport: this.state.utilizationReport,
        grandTotal: grandTotal
      });
      console.log(this.state.grandTotal);
    });
  }

  getReportByHead = (e) => {
    if (e != undefined) {
      this.state.bu_id = e.bu_id;
      this.state.head_id = e.label;
      this.state.selectBuFilter = e;
    } else {
      this.state.head_id = "All";
      this.state.bu_id = "";
      this.state.selectBuFilter = [];
    }
    if (this.state.head_id != "All") {
      // for BU filter
      this.state.report.project_practice = [];
      let service_head = [];

      this.state.report.service = [];
      this.state.report.service = [];
      this.state.sname1.forEach((service) => {
        if (this.state.bu_id == service.bu) {
          service_head.push(service);
        }
      });
      this.state.serviceName = [];
      this.state.selectService = [];
      this.state.serviceName = getUtilizationService(service_head);
      this.state.selectService = [];

      let service = this.state.report.service;
      if (service.length == 0) {
        this.state.projectPractice = [];
        this.state.report.practice = [];
      } else {
        service.forEach((svalue) => {
          this.state.report_all_practices.forEach((value) => {
            if (svalue.id == value.serviceId) {
              this.state.projectPractice.push(value);
            }
          });
        });
        if (this.state.projectPractice.length > 0) {
          this.state.projectPractice.forEach((value) => {
            this.state.report.practice.push(value.id);
          });
        }
      }
    } else {
      // when All BU is selected
      let service_head = [];
      this.state.selectBuFilter = [];
      if (this.state.report.service.length == 0) {
        //nothing
        this.state.sname1.forEach((service) => {
          service_head.push(service);
        });
        this.state.serviceName = [];
        this.state.selectService = [];
        this.state.serviceName = getUtilizationService(service_head);
      } else {
        this.state.report.service = [];
        this.state.serviceName = getUtilizationService(this.state.sname);
        this.state.selectService = this.state.serviceName[0];
        this.state.report.service.push(this.state.serviceName[0]);
      }
    }
    this.state.searchByPracticeHead = this.state.bu_id;
    this.setState({
      selectBuFilter: this.state.selectBuFilter,
    });

    this.changeViewPracite_inventory("", true);
  };

  change_inventoryView = (tabName) => {
    let exportExcelFlag = false;
    if (tabName == "detailed_view") {
      exportExcelFlag = true;
    }
    /* if (tabName == 'projection_view') {
      this.setState({
        checkboxEnable: true,
      });
    } */
    this.setState({
      defaultActiveKey: tabName,
      benchUserTab: 'all_resource'
    });
  };

  ToastAlert(icon, title) {
    return Toast.fire({
      target: document.getElementById("form-modal"),
      icon: `${icon}`,
      title: `${title}`,
    });
  }

  resetReport = (e) => {
    e.currentTarget.blur(); // removing focus
    this.state.resourceFilter = this.state.resourceAllFilter;
    this.state.searchBy = "";
    this.setState({
      resourceFilter: this.state.resourceFilter,
    });
    this.getPracticeService();
  };

  generateInventoryExportAsExls = (e) => {
    this.setState({ loading: true });
    e.currentTarget.blur(); // removing focus
    let invoice_status = {};
    this.state.invoice_dropdown_value.forEach((invoice) => {
      if (invoice.value == this.state.selectedInvoiceStatus) {
        invoice_status.id = invoice.id;
        invoice_status.value = invoice.value;
      }
    });
    let services = [];
    services.push(this.state.report.service);
    let dataObj = {};
    dataObj.allpractice = this.state.isAllPractices_inventory;
    let serviceId = [];
    this.state.report.service.forEach((sId) => {
      serviceId.push(sId.id);
    });
    dataObj.service = serviceId;
    if (!dataObj.allpractice || dataObj.service.length > 0) {
      if (dataObj.service.length == 0) {
        dataObj.business_unit = this.state.searchByPracticeHead;
      } else if (dataObj.allpractice) {
        dataObj.business_unit = this.state.searchByPracticeHead;
      } else {
        dataObj.business_unit = "";
      }
    } else {
      dataObj.business_unit = this.state.searchByPracticeHead;
    }
    dataObj.business_unit = (this.state.bu_id != null && this.state.bu_id != undefined) ? this.state.bu_id : dataObj.business_unit;
    dataObj.start_date = moment(this.state.filter_start_date)
      .utcOffset('+05:30')
      .format('YYYY-MM-01');
    dataObj.end_date = moment(this.state.filter_end_date)
      .utcOffset('+05:30')
      .format('YYYY-MM-01');
    dataObj.checkSum = this.state.checkSum;
    dataObj.current_report = this.state.current_report;
    dataObj.inventoryAll = (!dataObj.service.length && dataObj.business_unit == '') ? 1 : 0;
    let resourceFilter = {};
    this.state.resource_types.forEach((user) => {
      if (user.value == this.state.resourceFilter) {
        resourceFilter.id = user.id;
        resourceFilter.value = user.value;
      }
    });
    dataObj.selectedUserType = resourceFilter;
    dataObj.defaultActiveKey = this.state.defaultActiveKey;
    let reportData = {};
    reportData['reportData'] = dataObj;
    this.props.utilizationExportExcel(reportData).then((response) => {
      this.setState({ loading: false });
      this.ToastAlert("success", "Report generated Successfully");
      document.location = this.state.excelUrl + this.props.utilizationExport.filename;
    }, (error) => {
      this.setState({ loading: false });
      this.ToastAlert("error", error);
    });

  };

  getPracticeService = (isAllPractice) => {
    this.state.isAllPractices = isAllPractice;
    this.setState({ loading: true });
    this.state.filterService = [];
    let dateValue = new Date();
    let date = new Date(dateValue.getFullYear(), dateValue.getMonth(), 2);

    let start_date = this.state.filter_start_date
      ? this.state.filter_start_date
      : date;
    let end_date = this.state.filter_end_date
      ? this.state.filter_end_date
      : date;

    // this.state.report.service = [];
    let isAllPractices = isAllPractice;
    this.state.sname = [];
    this.state.allService = [];
    this.state.serviceName = [];
    let dataObject = {};
    dataObject.start_date = start_date;
    dataObject.end_date = end_date;
    dataObject.filter = isAllPractice;
    let c = 0;
    this.props.getReportFilter(dataObject).then((response) => {
      this.setState({
        loading: false,
      });
      this.props.utilizationReportFilter.directors.forEach((value) => {
        if (this.state.bu_id == value.bu_id) {
          c = 1;
        }
      });
      this.state.bu_id = c == 1 ? this.state.bu_id : "";
      this.state.selectBuFilter = c == 1 ? this.state.selectBuFilter : [];
      if (
        this.state.bu_id != "" &&
        this.state.bu_id != undefined &&
        this.state.bu_id != 0
      ) {
        this.props.utilizationReportFilter.services.forEach((service) => {
          if (this.state.bu_id == service.bu) {
            this.state.sname.push(service);
          }
        });
        this.props.utilizationReportFilter.allservices.forEach((service) => {
          if (this.state.bu_id == service.bu) {
            this.state.allService.push(service);
          }
        });
      } else {
        this.state.sname = this.props.utilizationReportFilter.services;
        this.state.allService = this.props.utilizationReportFilter.allservices;
      }
      this.state.sname1 = this.props.utilizationReportFilter.services;
      this.state.report.project_practice = [];
      this.state.serviceName = getUtilizationService(this.state.sname);
      if (isAllPractice) {
        this.state.selectService = [];
        this.state.report.service = [];
        this.state.report.practice = [];
        this.state.projectPractice = [];
        this.state.head_id = "All";
        this.state.selectBU = [];
        this.props.utilizationReportFilter.practices.forEach((pvalue) => {
          this.state.projectPractice.push(pvalue);
        });
      } else {
        let count = 0;
        if (this.state.report.service) {
          this.state.report.service.forEach((value) => {
            this.state.sname.forEach((svalue) => {
              if (svalue.id == value.id) {
                count = 1;
              }
            });
          });
          if (count == 0) {
            this.state.report.service = [];
            let service = getUserService(this.state.serviceName, this.state.loginUserData, this.state.filter_start_date);
            this.state.report.service.push(service);
            this.state.selectService = service;
          }
        } else {
          this.state.report.service = [];
          let service = getUserService(this.state.serviceName, this.state.loginUserData, this.state.filter_start_date);
          this.state.report.service.push(service);
          this.state.selectService = service;
        }
      }
      this.setState({
        report: {
          service: this.state.report.service,
          practice: this.state.report.practice,
        },
        //selectService : this.state.selectService
      });
      let service1 = [];
      service1.push(this.state.sname[0]);
      this.state.usersService = this.props.utilizationReportFilter.usersService;
      this.state.qes_list = this.props.utilizationReportFilter.qes_list;
      this.setState({
        report_all_practices: this.props.utilizationReportFilter.practices,
        practiceHead: this.state.practiceHead,
      });

      // for practicehead
      this.props.utilizationReportFilter.directors.forEach((id) => {
        this.state.practiceHead.push(id);
      });
      let buName = [];

      this.props.utilizationReportFilter.directors.forEach((value) => {
        let opt = {};
        opt.label = value.bu;
        opt.value = value.bu;
        opt.bu_id = value.bu_id;
        opt.id = value.id;
        opt.bu = value.bu;
        opt.name = value.name;
        buName.push(opt);
      });

      this.setState({
        practiceHead: this.state.practiceHead,
        buName: buName,
        bu_id: this.state.bu_id,
      });
      if (this.state.loginUserData.role_id == 8) {
        this.state.searchByPracticeHead = this.state.loginUserData.role_id;
      } else {
        this.state.searchByPracticeHead = this.state.searchByPracticeHead;
      }
      if (!this.state.isAllPractices) {
        // this.filterService("");
      }
      this.changeViewPracite(this.state.isAllPractices);
    }, (error) => {

    });

  };

  /**
   * 
   * @param {*} tabName 
   * @param {*} benchUser 
   * redirect of 
   */
  showBenchResource(tabName, benchUser) {
    let benchUserTab;
    if (benchUser == 'bench') {
      benchUserTab = 'bench';
    }
    else if (benchUser == 'allocated_resource') {
      benchUserTab = 'allocated_resource';
    }
    else {
      benchUserTab = 'all_resource';
    }
    this.setState({
      benchUserTab: benchUserTab,
      resource_view: tabName,
      defaultActiveKey: tabName,
      inventory_isDetailedView: false,
    });
  }

  // Predicton part
  showRadio(e) {
    this.setState({
      selectedOption: e.target.value
    });
  }

  predictionReport = (e) => {
    e.currentTarget.blur(); // removing focus
    this.setState({
      checkboxEnable: false,
    });
  };

  resetPrediction = (e) => {
    e.currentTarget.blur(); // removing focus
    this.generateInventory();
  };


  /**
   * main render
   * @returns
   */
  render() {
    const { practiceHead } = this.state;
    let buName = [];
    practiceHead.forEach((value) => {
      let opt = {};
      opt.label = value.bu;
      opt.value = value.bu;
      opt.bu_id = value.bu_id;
      opt.id = value.id;
      opt.bu = value.bu;
      opt.name = value.name;
      buName.push(opt);
    });
    this.state.inventoryReportList.forEach((value) => {
      value.index = 1;
    });
    let resource = {};
    this.state.resource_types.forEach((user) => {
      if (user.value == this.state.resourceFilter) {
        resource.id = user.id;
        resource.value = user.value;
      }
    });
    this.state.selectedUser = resource;
    if (this.state.redirect == 1) {
      return <PageNotFound url={this.state.angularUrl} />;
    } else {
      return (
        <>
          <div className={"page-content px-4 h-auto minht " + (this.props.addClass ? "active" : "")}>
            <Row>
              <div className="col-xs-12 fourty-fluid fourty-fluid">
                <Row className="mb-3">
                  <div className="col-sm-4 col-sm-4 col-xs-12 text-left">
                    <h1 className="B-G-bold f-s-18 d-gray t-t-upper pull-left mt-10 mr-20 page-head">
                      Reports
                    </h1>
                  </div>
                  <div className="col-lg-3 col-sm-4 col-xs-12">
                    <div className="selectpicker_dropdown hide_cross hide_bar orange_arrow remove_border_btm select_dropdown_picker drop ">
                      <Dropdown title={this.state.current_report.value} activeKey="8">
                        <Dropdown.Item as="a" eventKey="1" href="/pw_report" target="_blank">Practice Wise Allocation Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="2" href="/ms_report" target="_blank">Milestone Value Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="3" href="/collections_report" target="_blank">Collections Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="4" href="/collections_potential_report" target="_blank">Collection Potential Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="5" href="/inventory_report" target="_blank">Inventory Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="6" href="/inventory_allocation_report" target="_blank">Inventory Allocation Metrics</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="7" href="/inventory_milestone_report" target="_blank">Inventory Milestone Metrics</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="8">Resource Utilization Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="9" href="/csm_report" target="_blank">Csm Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="10" href="/pmo_ms_report" target="_blank">Pmo Ms Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="11" href="/acv_report" target="_blank">Annual Contract Value Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="12" href="/ms-allocation-report" target="_blank">MS Allocation Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="13" href="/squad-review-report" target="_blank">Squad Review Report</Dropdown.Item>
                      </Dropdown>
                    </div>
                  </div>
                  {/* <div className="col-lg-5 col-sm-4 col-xs-12 justify-content-end text-end">
                    <span className="hidden-xs l-gray B-G-bold f-s-16">
                      Welcome,{" "}
                      <b className="d-gray">
                        {this.state.loginUserData.first_name}{" "}
                        {this.state.loginUserData.last_name}
                      </b>
                    </span>
                  </div> */}
                </Row>
                <Row>
                  <div className="col-lg-6 clearfix m-b-20">
                    <div className="row align-items-center">
                      <label className="f-s-12 text-muted pt-1 w-13 col-lg-2 col-md-6 col-sm-12 text-left">
                        Line of Service :
                      </label>
                      <div className="col-lg-7 col-md-6 col-sm-12 text-left ">
                        <Row>
                          <div className="col-lg-10 p-l-0">
                            <div className="selectpicker_dropdown hide_bar w-100 selectpicker_dropdown_orange">
                              <Select
                                className="basic-single-select"
                                classNamePrefix={"select_dropdown"}
                                placeholder="All"
                                isClearable
                                isSearchable={true}
                                onChange={(e) => this.filterService(e)}
                                value={this.state.selectService}
                                options={this.state.serviceName}
                                isMulti
                              ></Select>
                            </div>
                          </div>
                          <div className="col-lg-2 p-1-0">
                            {this.state.report.service != undefined &&
                              this.state.report.service.length > 0 && (
                                <a
                                  href="#"
                                  title="Reset"
                                  tooltip-placement="top"
                                  className="reset-icon_old reset-color p-l-5 m-l-4"
                                  onClick={this.reset_page}
                                >
                                  <i className="fs-16 fa fa-refresh"></i>
                                </a>
                              )}
                          </div>
                        </Row>
                      </div>
                    </div>
                  </div>
                  {this.state.loginUserData.role_id != 8 && (
                    <div className="col-lg-4 clearfix m-b-20">
                      <div className="row">
                        <div className="col-sm-5 col-xs-12">
                          <label className="text-uppercase m-b-0 fs-13 p-t-5">
                            Business Unit :
                          </label>
                        </div>
                        <div className="col-sm-7 col-xs-12">
                          <div className="selectpicker_dropdown hide_bar w-100 selectpicker_dropdown_orange">
                            <Select
                              className="basic-single-select"
                              classNamePrefix={"select_dropdown"}
                              placeholder="All"
                              isClearable
                              isSearchable={true}
                              onChange={(e) => this.getReportByHead(e)}
                              value={this.state.selectBuFilter}
                              options={this.state.buName}
                            ></Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-lg-2 col-sm-2 col-xs-12 justify-content-end text-end">
                    <a href={this.state.angularUrl} className="btn btn-primary">
                      Back
                    </a>
                  </div>
                </Row>
                <hr className="m-t-5 col-sm-12 m-b-8 p-0"></hr>
                {this.props.loginDetails.loginUserPermission && this.props.loginDetails.loginUserPermission.permission && this.props.loginDetails.loginUserPermission.permission.resource_utilization_report &&
                  <>
                    <div className="col-lg-12 col-sm-12 col-xs-12 p-b-15-xs p-r-15-xs">
                      <Row className=" mt-2 mb-3">
                        <Col className="col-lg-10 text-start col p-0">
                          <div className="d-flex">
                            <label className="p-r-25 p-l-15 B-G-bold f-s-18 d-gray m-t-3 m-b-10 page-head pull-left ">
                              Month Filter:
                            </label>
                            <label className="p-r-15 B-G-bold f-s-18 d-gray m-t-3 m-b-10 page-head pull-left ">
                              From:
                            </label>
                            <div className="p-r-15">
                              <div className="align-items-center mb-2 date_picker_align">
                                <DatePicker
                                  className="datepicker-control-section"
                                  onChange={(e) => this.setStartDateForFilter(e)}
                                  dateFormat="MM/yyyy"
                                  showMonthYearPicker
                                  todayButton={"Today"}
                                  minDate={new Date(this.state.filterDate)}
                                  name="start_date"
                                  selected={this.state.filter_start_date}
                                />
                                <span
                                  className="cal_icon_date"
                                  role="button"
                                  tabIndex="0"
                                >
                                  {" "}
                                  <i className="icon-calendar"></i>
                                </span>
                              </div>
                            </div>
                            <label className="p-r-15 B-G-bold f-s-18 d-gray m-t-3 m-b-10 page-head ">
                              To:
                            </label>
                            <div className="p-r-20">
                              <div className="align-items-center mb-2 date_picker_align">
                                <DatePicker
                                  className="datepicker-control-section"
                                  onChange={(e) => this.setEndDateForFilter(e)}
                                  dateFormat="MM/yyyy"
                                  showMonthYearPicker
                                  minDate={this.state.filter_start_date}
                                  todayButton={"Today"}
                                  //dateFormat="MM/yyyy"
                                  name="end_date"
                                  selected={this.state.filter_end_date}
                                />
                                <span
                                  className="cal_icon_date"
                                  role="button"
                                  tabIndex="0"
                                >
                                  {" "}
                                  <i className="icon-calendar"></i>
                                </span>
                              </div>
                            </div>
                            <div className="p-r-15">
                              {/* <div>
                                <input type="checkbox" onClick={(e) => this.generateCheckSum(e)}/> <label className="B-G-bold f-s-18 d-gray">Precise Info</label> 
                                </div> */}
                            </div>
                            <Button
                              onClick={(e) => this.resetReport(e)}
                              value="Generate"
                              className="me-2 h-28"
                            >
                              Generate
                            </Button>
                            {/* <Button
                                    onClick={(e) => this.predictionReport(e)}
                                    value="Prediction"
                                    className="me-2 h-28 btn:focus"
                                  >
                                    Prediction
                               </Button> */}
                            <a
                              href="#"
                              title="Prediction Reset"
                              tooltip-placement="top"
                              className="reset-icon_old reset-color p-l-5 m-l-4"
                              onClick={(e) => this.resetPrediction(e)}
                            >
                              <i className=" fa fa-refresh"></i> &nbsp;&nbsp;
                            </a>
                            <div className="">
                              {this.state.loginUserData.role_id != undefined &&
                                this.state.loginUserData.role_id != 1 &&
                                this.state.isAllPractices_inventory == true && (
                                  <Button
                                    onClick={(e) =>
                                      this.changeViewPracite_inventory(e, false)
                                    }
                                    value="My Practices"
                                    className="me-2 mt-0"
                                  >
                                    My Practices{" "}
                                  </Button>
                                )}
                              {/* {this.state.loginUserData.role_id != undefined &&
                                    this.state.loginUserData.role_id != 1 &&
                                    this.state.isAllPractices_inventory ==
                                    false && (
                                      <Button
                                        onClick={(e) =>
                                          this.changeViewPracite_inventory(e, true)
                                        }
                                        value="View All Practices"
                                        className="me-2 mt-0"
                                      >
                                        View All Practices{" "}
                                      </Button>
                                    )} */}
                            </div>

                          </div>
                        </Col>
                        <Col className="col-lg-2 text-end">
                          <div className="form-group search-icon col-sm-12 col-xs-12">
                            {this.state.defaultActiveKey == 'projection_view' ? null : (
                              <Button
                                onClick={(e) => this.generateInventoryExportAsExls(e)}
                                value="Excel"
                                className="me-2"
                                disabled={this.state.utilizationReport?.length==0 || this.state.grandTotal.grandMsAllocation==0}
                              >
                                Export as Excel
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="pro_listng bor-no">
                      <div className="invoice--report bg-transparent inventory_allo_table inventory_allo_tablereact p-l-0 p-r-0 p-t-0 p-b-0 m-t-5">
                        <Tabs
                          activeKey={this.state.defaultActiveKey}
                          id="uncontrolled-tab-example"
                          className="mb-3"
                          onSelect={(event) => {
                            this.change_inventoryView(event);
                          }}
                        >
                          <Tab
                            eventKey="allocation_view"
                            title="Allocation"
                          >
                            <AllocationView
                              loading={this.state.loading}
                              serviceName={this.state.serviceName}
                              selectBuFilter={this.state.selectBuFilter}
                              showBenchResource={() => this.showBenchResource('resource_view', 'bench')}
                              showAllocatedResource={() => this.showBenchResource('resource_view', 'allocated_resource')}
                              showAllResource={() => this.showBenchResource('resource_view', 'all_resource')}
                              showAllEmployee={() => this.showBenchResource('employee_view', 'employee_view')}
                              showPrediction={(e, key, item) => this.showPrediction(e, key, item)}
                              showPredictionPopup={(e, key, item) => this.showPredictionPopup(e, key, item)}
                              showRadio={(e) => this.showRadio(e)}
                              utilizationReport={this.state.utilizationReport ? this.state.utilizationReport : []}
                              grandTotal={this.state.grandTotal}
                              selectedOption={this.state.selectedOption}
                              updateGrandTotal={this.getUtilizationGrandTotal}
                            />
                          </Tab>
                          <Tab
                            eventKey="actual_view"
                            title="Actual"
                          >
                            <ActualView
                              loading={this.state.loading}
                              serviceName={this.state.serviceName}
                              selectBuFilter={this.state.selectBuFilter}
                              showBenchResource={() => this.showBenchResource('resource_view', 'bench')}
                              showAllocatedResource={() => this.showBenchResource('resource_view', 'allocated_resource')}
                              showAllResource={() => this.showBenchResource('resource_view', 'all_resource')}
                              showAllEmployee={() => this.showBenchResource('employee_view', 'employee_view')}
                              showPrediction={(e, key, item) => this.showPrediction(e, key, item)}
                              showPredictionPopup={(e, key, item) => this.showPredictionPopup(e, key, item)}
                              showRadio={(e) => this.showRadio(e)}
                              utilizationReport={this.state.utilizationReport ? this.state.utilizationReport : []}
                              grandTotal={this.state.grandTotal}
                              selectedOption={this.state.selectedOption}
                              updateGrandTotal={this.getUtilizationGrandTotal}
                            />
                          </Tab>
                          <Tab
                            eventKey="projection_view"
                            title="Projection"
                          >
                            <ProjectionView
                              loading={this.state.loading}
                              serviceName={this.state.serviceName}
                              selectBuFilter={this.state.selectBuFilter}
                              showBenchResource={() => this.showBenchResource('resource_view', 'bench')}
                              showAllocatedResource={() => this.showBenchResource('resource_view', 'allocated_resource')}
                              showAllResource={() => this.showBenchResource('resource_view', 'all_resource')}
                              showAllEmployee={() => this.showBenchResource('employee_view', 'employee_view')}
                              showPrediction={(e, key, item) => this.showPrediction(e, key, item)}
                              showPredictionPopup={(e, key, item) => this.showPredictionPopup(e, key, item)}
                              showRadio={(e) => this.showRadio(e)}
                              utilizationReport={this.state.utilizationReport ? this.state.utilizationReport : []}
                              grandTotal={this.state.grandTotal}
                              selectedOption={this.state.selectedOption}
                              checkboxEnable={true}
                            />
                          </Tab>
                          {
                            <Tab eventKey="resource_view" title="Reserve Strength View">
                              <BenchResource
                                utilizationReport={this.state.utilizationReport}
                                levels={this.state.resourceLevel}
                                loading={this.state.loading}
                                selectBuFilter={this.state.selectBuFilter}
                                selectService={this.state.selectService}
                                selectedUser={this.state.selectedUser}
                                serviceName={this.state.serviceName}
                                benchUserTab={this.state.benchUserTab}
                                subTotalVendor={this.state.subTotalVendor}
                                subTotalEmployee={this.state.subTotalEmployee}
                                subTotalInterns={this.state.subTotalInterns}
                                subBenchResource={this.state.subBenchResource}
                                subBenchResourceCount={this.state.subBenchResourceCount}
                              />
                            </Tab>
                          }

                          {/* <Tab eventKey="bench_view" title="Reserve Strength View">
                            <BenchView inventoryReportList={this.state.inventoryReportList}
                              levels={this.state.levels}
                              businessUnits={this.state.businessUnit}
                              utilizationReport={this.state.utilizationReport}
                              loading={this.state.loading}
                              selectBuFilter={this.state.selectBuFilter}
                              selectService={this.state.selectService}
                              selectedUser={this.state.selectedUser}
                              serviceName={this.state.serviceName}
                              resource_view={this.state.resource_view}
                            />
                          </Tab> */}

                          {/* <Tab eventKey="employee_view" title="Employee View">
                            <DeveloperView inventoryReportList={this.state.inventoryReportList}
                              levels={this.state.resourceLevel}
                              businessUnits={this.state.businessUnit}
                              utilizationReport={this.state.utilizationReport}
                              loading={this.state.loading}
                              selectBuFilter={this.state.selectBuFilter}
                              selectService={this.state.selectService}
                              selectedUser={this.state.selectedUser}
                              serviceName={this.state.serviceName}
                              resource_view={this.state.resource_view}
                            />
                          </Tab> */}
                        </Tabs>
                      </div>
                    </div>
                  </>
                }
                {this.props.loginDetails.loginUserPermission && this.props.loginDetails.loginUserPermission.permission && !this.props.loginDetails.loginUserPermission.permission.resource_utilization_report &&
                  <div className="page-content">
                    <div className="col-md-12 m-b-30">
                      <div className="four_not_err access_denied">
                        <div className="for_content">
                          <img alt="sad" width="" src={accessdenied} />
                          <h1>You don't have permission to access this page</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </Row>
          </div>
        </>

      );
    }
  }
}

const mapStateToProps = state => {
  return {
    utilizationReport: state.reportDetails.utilizationReport,
    loginDetails: state.loginDetails,
    utilizationExport: state.reportDetails.utilizationExport,
    utilizationReportFilter: state.reportFilterDetails.reportFilter,
    addClass: state.pmsDetails.addClass
  };
};

const mapDispatchToProps = {
  getUtilizationReport,
  getLoginUser,
  getReportFilter,
  utilizationExportExcel,
  getLoginUserPermission,
  getCheckSum,
  setAddClass
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceUtilizationReport);
import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Button } from "react-bootstrap";
import { getLoginUser, getLoginUserPermission, setAddClass } from "../../Action";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import PageNotFound from "../Reports/PageNotFound";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import * as constants from "../../Config/Constant";
import './chapterreview.css';

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  customClass: {
    popup: "colored-toast",
    icon: "white-toast-icon",
  },
  timer: 3000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

class ChapterReviewInferenceView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: new URLSearchParams(window.location.search),
      redirect: 0,
      loginUserData: {},
      loginUserPermission: {},
      defaultActiveKey: 'utilization_margin',
      loading: false,
    };
  }

  componentDidMount() {
    let token =
      this.state.queryParams.get("token") === null
        ? Cookies.get("auth_data")
        : this.state.queryParams.get("token");
    let url =
      this.state.queryParams.get("url") == null
        ? Cookies.get("url_code")
        : this.state.queryParams.get("url");
    this.setState({
      token: token,
      apiLink: constants.API_LINK,
      angularUrl: constants.FRONT_LINK,
      excelUrl: constants.EXCEL_LINK,
      url: url,
    });
    Cookies.set("auth_data", token);
    Cookies.set("url_code", url);
    this.state.queryParams.delete("token");
    this.state.queryParams.delete("url");
    if (this.props.history != undefined) {
      this.props.history.replace({
        search: this.state.queryParams.toString(),
      });
    }
    if (token == "undefined" || token == undefined || token == null) {
      this.state.redirect = 1;
    } else {
      this.getUser();
    }
  }

  /**
   * Get the login user details
   */
   getUser = () => {
    this.setState({ loading: true });
    this.props.getLoginUser().then((response) => {
        this.props.getLoginUserPermission().then((response) => {
        this.setState({ loading: false,loginUserData :this.props.loginDetails.loginDetails,
            loginUserPermission :this.props.loginDetails.loginUserPermission});
        });
    });
  }

  /* toast message function  */
  ToastAlert(icon, title) {
    return Toast.fire({
      target: document.getElementById("form-modal"),
      icon: `${icon}`,
      title: `${title}`,
    });
  }

  getClassname = () => {
    return {
      Completed: "success-li",
      "Not Yet Started": "warning-li",
      "Not Completed": "danger-li",
    };
  };

  changeTabView = (tabName) => {
    this.setState({
      defaultActiveKey: tabName,
    });
  };

  render() {
    const { redirect,loginUserData } = this.state;
    const utilizationQuestions = ['What is you chapter size?', 'What is the current utilization, yeild and gross margin? what is target gross margin?', 'How does it look when we project it to 85% utilization? Are we good at achieving the target margin at 85% utilization?', 'What will be the impact of the march appraisal in cost, utilization and margin?', 'What are the action items you have,to bring the chapter in right structure(Achieving target margin at 85% utilization)?'];
    const pulseScoreQuestions = ['Overall Pulse Score', 'Latest Glint/MES/QES Survey score', 'Office Presence', 'One on One Meetings'];
    const CIQuestions = ['When was last assessment scheduled and when is the next schedule?', 'What is the current score of both technical and behavioural competencies?','Did all members in the chapter cross the benchmark? If not, how many members didn\'t achieve the benchmark and what is the plan for them?', 'What is the current learning path? why are we going with this learning path and what is your plan around this?', 'Are the chapter members are aware of the competency framework initiatives? If not, what is your plan letting them know the context?'];
    const otherMetricsQuestions = ['What is the ICR for this month? Any insights to share from your observations on interview recordings', 'Do you have attrition in your chapter? If yes, what is the attrition %', 'Do you have plans for recuirment? Explain.', 'Do you have members in PIP?', 'Highlight the hits and misses in your chapter.'];
    const questionkeys = ['question1','question2','question3','question4','question5']

    if (redirect === 1 || loginUserData === null) {
      return <PageNotFound url={constants.FRONT_LINK} />;
    } else {
      return (
        <div
          className={`page-content px-4 pms-dashboard-container ${this.props.addClass ? "active" : ""
            }`}
        >
          <Row>
            <div className="col-xs-12 fourty-fluid fourty-fluid p-t-6">
              <Row className="row">
                  <div className="col-sm-4 col-sm-4 col-xs-12 col-md-12 text-left">
                    <h1 className="B-G-bold f-s-18 d-gray t-t-upper pull-left mt-15 mr-20 page-head lh-10">
                        CHAPTER REVIEW / {this.props.viewMonth} / <span className="text-orange">VIEW DETAILS</span>
                    </h1>
                  </div>
              </Row>
            </div>
          </Row>
          <div className="scrollable-content bgwhite p-3 mt-3 pms-tabs m-t-4 m-b-15">
            <Tabs
              activeKey={this.state.defaultActiveKey}
              id="main_view"
              className="mb-3"
              onSelect={(event) => {
                  this.changeTabView(event);
              }}
            >
              <Tab
                eventKey="utilization_margin"
                title="Utilization Margin"
              >
                { utilizationQuestions.map((ques, key) => {
                  return (
                    <Row className="border-bottom">
                      <div className="col-lg-7 fourty-fluid fourty-fluid p-t-6">
                        <div className="p-2 text-color-black text-left">
                            {key + 1}. {ques}
                        </div>
                      </div>
                      <div className="col-lg-5 text-left p-t-6">
                        <div className="pb-2 text-color-black font-weight-bold">Inference
                        </div>
                        {this.props.chapterMonthDetails?.[3].answers[questionkeys[key]] || 'NA'}
                      </div>
                    </Row>
                  )
                })
              }
              </Tab>
              <Tab
                eventKey="pulse_score"
                title="Pulse Score"
              >
                { pulseScoreQuestions.map((ques, key) => {
                  return (
                    <Row className="border-bottom">
                      <div className="col-lg-3 fourty-fluid fourty-fluid p-t-6">
                        <div className="p-2 text-color-black text-left">
                            {key + 1}. {ques}
                        </div>
                      </div>
                      <div className="col-lg-2 text-left p-t-6">
                        <div className="pb-2 text-color-black font-weight-bold">Score
                        </div>
                        {this.props.chapterMonthDetails?.[2].answers[questionkeys[key]].score || 'NA'}
                      </div>
                      <div className="col-lg-3 text-left p-t-6">
                        <div className="pb-2 text-color-black font-weight-bold">Inference
                        </div>
                        {this.props.chapterMonthDetails?.[2].answers[questionkeys[key]].inference || 'NA'}
                      </div>
                      <div className="col-lg-3 text-left p-t-6">
                        <div className="pb-2 text-color-black font-weight-bold">Action Items
                        </div>
                        {this.props.chapterMonthDetails?.[2].answers[questionkeys[key]].action_items || 'NA'}
                      </div>
                    </Row>
                  )
                })
              }
              </Tab>
              <Tab
                eventKey="competency_index"
                title="Competency Index"
              >
              { CIQuestions.map((ques, key) => {
                  return (
                    <Row className="border-bottom">
                      <div className="col-lg-7 fourty-fluid fourty-fluid p-t-6">
                        <div className="p-2 text-color-black text-left">
                            {key + 1}. {ques}
                        </div>
                      </div>
                      <div className="col-lg-5 text-left p-t-6">
                        <div className="pb-2 text-color-black font-weight-bold">Inference
                        </div>
                        {this.props.chapterMonthDetails?.[1].answers[questionkeys[key]] || 'NA'}
                      </div>
                    </Row>
                  )
                })
              }
              </Tab>
              <Tab
                eventKey="other_metrics"
                title="Other Metrics"
              >
              { otherMetricsQuestions.map((ques, key) => {
                return (
                    <Row className="border-bottom">
                      <div className="col-lg-6 fourty-fluid fourty-fluid p-t-6">
                        <div className="p-2 text-color-black text-left">
                            {key + 1}. {ques}
                        </div>
                      </div>
                      <div className="col-lg-3 text-left p-t-6">
                        <div className="pb-2 text-color-black font-weight-bold">Inference
                        </div>
                        {this.props.chapterMonthDetails?.[0].answers[questionkeys[key]].inference || 'NA'}
                      </div>
                      <div className="col-lg-3 text-left p-t-6">
                        <div className="pb-2 text-color-black font-weight-bold">Action Items
                        </div>
                        {this.props.chapterMonthDetails?.[0].answers[questionkeys[key]].action_items || 'NA'}
                      </div>
                    </Row>
                  )
                })
              }
              </Tab>
            </Tabs>
            <div className="B-G-bold d-gray pull-right mt-10 page-footer default-bg">
              <Button
                value="back"
                className="me-2"
                onClick={this.props.backToMonthLists}
              >
                BACK
              </Button>
            </div>
          </div>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    loginDetails: state.loginDetails,
    addClass: state.pmsDetails.addClass,
  };
};

const mapDispatchToProps = {
  getLoginUser,
  getLoginUserPermission,
  setAddClass,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChapterReviewInferenceView);
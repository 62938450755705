import React  from "react";
import { Row, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import PageNotFound from "../Reports/PageNotFound";
import {
    getLoginUser, getLoginUserPermission, saveChapterAnswer
} from "../../Action";
import { connect } from "react-redux";
import  * as constants from "../../Config/Constant";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./chapterreview.css";

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    showCloseButton: true,
    customClass: {
      popup: "colored-toast",
      icon: "white-toast-icon",
    },
    timer: 3000,
    onOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});

class ChapterReviewInterfaceUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        formCount: 0, 
        queryParams: new URLSearchParams(window.location.search),         
        token: "",
        loginUserData: {},
        redirect : 0,
        loading: false,
        answers: [],
        isUtilityFormFilled: this.props.chapterMonthDetails ? Object.values(this.props.chapterMonthDetails[3].answers).every(value => value !== null)  : false,
        isPulseFormFilled: this.props.chapterMonthDetails ? Object.values(this.props.chapterMonthDetails[2].answers).every(answer =>Object.values(answer).every(value => value !== null)) : false,
        isCompetencyFormFilled: this.props.chapterMonthDetails ? Object.values(this.props.chapterMonthDetails[1].answers).every(value => value !== null)  : false,
        isOthersFormFilled: this.props.chapterMonthDetails ? Object.values(this.props.chapterMonthDetails[0].answers).every(value => value !== null)  : false,
        isSavedFormFilled : false,
        utilizationAnswers: {
            question1: this.props?.chapterMonthDetails?.[3]?.answers['question1'] || '',
            question2: this.props?.chapterMonthDetails?.[3]?.answers['question2'] || '',
            question3: this.props?.chapterMonthDetails?.[3]?.answers['question3'] || '',
            question4: this.props?.chapterMonthDetails?.[3]?.answers['question4'] || '',
            question5: this.props?.chapterMonthDetails?.[3]?.answers['question5'] || '',
        },
        pulseAnswers: {
            question1: {
                score: this.props.chapterMonthDetails?.[2]?.answers['question1'].score || '',
                inference: this.props.chapterMonthDetails?.[2]?.answers['question1'].inference || '',
                action_items: this.props.chapterMonthDetails?.[2]?.answers['question1'].action_items || '',                
            },
            question2: {
                score: this.props.chapterMonthDetails?.[2]?.answers['question2'].score || '',
                inference: this.props.chapterMonthDetails?.[2]?.answers['question2'].inference || '',
                action_items: this.props.chapterMonthDetails?.[2]?.answers['question2'].action_items || '',                
            },
            question3: {
                score: this.props.chapterMonthDetails?.[2]?.answers['question3'].score || '',
                inference: this.props.chapterMonthDetails?.[2]?.answers['question3'].inference || '',
                action_items: this.props.chapterMonthDetails?.[2]?.answers['question3'].action_items || '',               
            },
            question4: {
                score: this.props.chapterMonthDetails?.[2]?.answers['question4'].score || '',
                inference: this.props.chapterMonthDetails?.[2]?.answers['question4'].inference || '',
                action_items: this.props.chapterMonthDetails?.[2]?.answers['question4'].action_items || '',                  
            },
            question5: {
                score: this.props.chapterMonthDetails?.[2]?.answers['question5'].score || '',
                inference: this.props.chapterMonthDetails?.[2]?.answers['question5'].inference || '',
                action_items: this.props.chapterMonthDetails?.[2]?.answers['question5'].action_items || '',              
            },
        },
        competencyAnswers: {
            question1: this.props.chapterMonthDetails?.[1]?.answers['question1'] || '',
            question2: this.props.chapterMonthDetails?.[1]?.answers['question2'] || '',
            question3: this.props.chapterMonthDetails?.[1]?.answers['question3'] || '',
            question4: this.props.chapterMonthDetails?.[1]?.answers['question4'] || '',
            question5: this.props.chapterMonthDetails?.[1]?.answers['question5'] || '',
        }, 
        otherAnswers: {
            question1: {
                inference: this.props.chapterMonthDetails?.[0]?.answers['question1'].inference || '',
                action_items: this.props.chapterMonthDetails?.[0]?.answers['question1'].action_items || '',                
            },
            question2: {
                inference: this.props.chapterMonthDetails?.[0]?.answers['question2'].inference || '',
                action_items: this.props.chapterMonthDetails?.[0]?.answers['question2'].action_items || '',                
            },
            question3: {
                inference: this.props.chapterMonthDetails?.[0]?.answers['question3'].inference || '',
                action_items: this.props.chapterMonthDetails?.[0]?.answers['question3'].action_items || '',               
            },
            question4: {
                inference: this.props.chapterMonthDetails?.[0]?.answers['question4'].inference || '',
                action_items: this.props.chapterMonthDetails?.[0]?.answers['question4'].action_items || '',                  
            },
            question5: {
                inference: this.props.chapterMonthDetails?.[0]?.answers['question5'].inference || '',
                action_items: this.props.chapterMonthDetails?.[0]?.answers['question5'].action_items || '',              
            },
        }, 
        utilizationQuestions: [
            "What is your chapter size?",
            "What is the current Utilization, Yield and Gross Margin? What is the target of gross margin?",
            "How does it look when we project it to 85% utilization? Are we good at achieving the target margin at 85% utilization? ",
            "What will e the impact of the March appraisal in cost, utilization and margin?",
            "What are the items you have, to bring the chapter in right structure (Achieving target margin at 85% utilization)?"
        ],
        pulseQuestions: [
            "Overall Pulse Score?",
            "Latest Glint/MES/QES survey score?",
            "Office Presence?",
            "One on One Meetings?",
            "Chapter Huddle?"
        ],
        competencyQuestions: [
            "When was the last assessment scheduled and when is the next schedule?",
            "What is the current score of both technical behavioral competence?",
            "Did all mrmbers in the chapter cross the benchmark? If not, how many members didn't achieve the benchmark and what is the plan for them?",
            "What is the current learning path? Why are we going with this learning path and what is your plan around this?",
            "Are the chapter members aware of the competency framework initiatives? If not, what is your plan in letting them know the context?"
        ],
        otherQuestions: [
            "What is the ICR for this month? Any insights to share from your observations on interview recordings?",
            "Do you have atrrition in your chapter? If yes, what is the attrition %?",
            "Do you have plkans for recruitment? Explain.",
            "Do you have members in PIP?",
            "Highlight the hits and misses in your chapter."
        ],
        };
    }

    componentDidMount() {
        let token = this.state.queryParams.get("token") == null ? Cookies.get("auth_data") : 
        this.state.queryParams.get("token");
        this.setState({
            token: token,
            apiLink: constants.API_LINK,
            angularUrl: constants.FRONT_LINK,
            excelUrl: constants.EXCEL_LINK
        });   
        this.getUser();
    }
    
    /**
     * Get the login user details
     */
    getUser = () => {
        this.setState({ loading: true });
        this.props.getLoginUser().then((response) => {
            this.props.getLoginUserPermission().then((response) => {
            this.setState({ loading: false,loginUserData :this.props.loginDetails.loginDetails,
                loginUserPermission :this.props.loginDetails.loginUserPermission});
            this.getPracticeService();
            });
        });
    }

    handleUtilizationChange = (index, e) => {
        const { utilizationAnswers } = this.state;
        const updatedUtilizationAnswers = {
            ...utilizationAnswers,
            ['question' + (index + 1)]: e.target.value,
        };
        const isUtilityFormFilled = Object.values(updatedUtilizationAnswers).every(value => value.trim() !== '');
        const isSavedFormFilled = Object.values(updatedUtilizationAnswers).some(value => value.trim() !== '');
        this.setState({
            utilizationAnswers: updatedUtilizationAnswers,
            isUtilityFormFilled: isUtilityFormFilled,
            isSavedFormFilled : isSavedFormFilled
        });
    };

    handlePulseChange = (index, propertyName, e) => {
        const { pulseAnswers } = this.state;
        const updatedAnswers = {
            ...pulseAnswers,
            ['question' + (index + 1)]: {
                ...pulseAnswers['question' + (index + 1)],
                [propertyName]: e.target.value,
            },
        };        
        const isPulseFormFilled = Object.values(updatedAnswers).every(answer =>Object.values(answer).every(value => value.trim() !== ''));
        this.setState({ 
            pulseAnswers: updatedAnswers,
            isPulseFormFilled: isPulseFormFilled
        });
    };

    handleCompetencyChange = (index, e) => {
        const { competencyAnswers } = this.state;      
        const updatedCompetencyAnswers = {
            ...competencyAnswers,
            ['question' + (index + 1)]: e.target.value,
        };
        const isCompetencyFormFilled = Object.values(updatedCompetencyAnswers).every(value => value.trim() !== '');
    
        this.setState({
            competencyAnswers: updatedCompetencyAnswers,
            isCompetencyFormFilled: isCompetencyFormFilled
        });
    };

    handleOtherChange = (index, propertyName, e) => {
        const { otherAnswers } = this.state;
        const updatedOtherAnswers = {
            ...otherAnswers,
            ['question' + (index + 1)]: {
                ...otherAnswers['question' + (index + 1)],
                [propertyName]: e.target.value,
            },
        };
        const isOthersFormFilled = Object.values(updatedOtherAnswers).every(answer =>Object.values(answer).every(value => value.trim()!== '')
        );
    
        this.setState({
            otherAnswers: updatedOtherAnswers,
            isOthersFormFilled: isOthersFormFilled
        });
    };

    handleFormSubmit = (e, formKey) => {
        e.preventDefault();
        this.setState({ loading: true });
        const { utilizationAnswers, pulseAnswers, competencyAnswers, otherAnswers} = this.state;
        let requestData = { 
            answers: {
                utilization: utilizationAnswers,
                pulse: pulseAnswers,
                competency: competencyAnswers,
                other: otherAnswers,
            },
            month : this.props.viewMonthInNumber,
            year : this.props.viewYear
        }
        this.props.saveChapterAnswer(requestData).then((response) => {
            this.setState({ loading: false });
            this.props.backToMonthLists();
            this.ToastAlert("success", "Chapter review was submitted successfully.");
        },(error) => {
            this.setState({ loading: false });
            this.ToastAlert("error", error);  
        });
    };

    saveAnswer = () =>{
        this.setState({ loading: true });
        const { utilizationAnswers, pulseAnswers, competencyAnswers, otherAnswers} = this.state;
        let requestData = { 
            answers: {
                utilization: utilizationAnswers,
                pulse: pulseAnswers,
                competency: competencyAnswers,
                other: otherAnswers,
            },
            month : this.props.viewMonthInNumber,
            year : this.props.viewYear,
            is_saved : '0'
        }
        this.props.saveChapterAnswer(requestData).then((response) => {
            this.setState({ loading: false });
            this.ToastAlert("success", "Chapter review saved on draft successfully.");
        },(error) => {
            this.setState({ loading: false });
            this.ToastAlert("error", error);  
        });
    }

    ToastAlert(icon, title) {
        return Toast.fire({
          target: document.getElementById("form-modal"),
          icon: `${icon}`,
          title: `${title}`,
        });
    }
    
    getPracticeService= () =>{
        this.incrementCount();
    }

    incrementCount = () => {
        if (this.state.formCount < 4) {
            this.setState({ formCount: this.state.formCount + 1 });
        }
    }
    decrementCount = () => {
        if (this.state.formCount > 1) {
            this.setState({ formCount: this.state.formCount - 1 });
        }
    }    

    render() {
        const { utilizationQuestions,utilizationAnswers,isUtilityFormFilled,pulseQuestions, pulseAnswers,isPulseFormFilled, competencyQuestions, competencyAnswers, isCompetencyFormFilled, otherQuestions, otherAnswers,  isOthersFormFilled, isSavedFormFilled } = this.state;
        const pulseLabels = ["Score", "Inference", "Action Items"];
        const otherLabels = ["Inference", "Action Items"];
        if (this.state.redirect === 1 || this.state.loginUserData === null) {
            return <PageNotFound url={this.state.angularUrl} />;
        } else {
            return (
            <>
                {this.state.loading && (
                    <div id="preloader">
                    <div id="status"></div>
                    </div>
                )}
                <div className="page-content px-4 chapter-review-page">
                    <Row>
                        <div className="col-xs-12 fourty-fluid fourty-fluid">
                            <Row className="mb-3">
                                <div className="col-sm-4 col-sm-4 col-xs-12 text-left">
                                    <h1 className="B-G-bold f-s-18 d-gray t-t-upper pull-left mt-15 mr-20 page-head lh-10">
                                        CHAPTER REVIEW / <span className="text-orange">{this.props.viewMonth} MONTH</span>
                                    </h1>
                                </div>
                            </Row>
                            <div className="scrollable-content bgwhite p-3 mt-3 pms-tabs m-t-4">
                                <div className="m-t-5"></div>
                                {this.state.formCount === 1 && (
                                    <form onSubmit={(e) => this.handleFormSubmit(e, 'utilization')} >
                                        <div className="scrollable">
                                            <div className="scrollable-content">
                                                {utilizationQuestions.map((question, index) => (
                                                    <div className="col-md-12 questions-list" key={index}>
                                                        <p className="m-b-5">{index + 1}. {question}</p>
                                                        <span className="f-s-14 text-left font-bold m-l-25" style={{ color: '#f5821f' }}>
                                                            <div className="form-group w100">
                                                                <b>Inference</b>
                                                                <textarea
                                                                    className="form-control inference-text"
                                                                    value={utilizationAnswers['question'+(index + 1)] || ''}
                                                                    onChange={(e) => this.handleUtilizationChange(index, e)}
                                                                    required
                                                                />
                                                            </div>
                                                        </span>
                                                        <hr className="m-t-5 col-sm-12 m-b-8 p-0"></hr>
                                                    </div>
                                                ))}
                                                <div className="B-G-bold d-gray pull-right mt-10 page-footer default-bg">
                                                    <Button
                                                        value="back"
                                                        className="me-2"
                                                        onClick={this.props.backToMonthLists}
                                                    >
                                                        BACK
                                                    </Button>
                                                    <button type="button" onClick={(e) => this.saveAnswer()} disabled={!isUtilityFormFilled}  className="btn btn-primary me-2">Save as Draft</button>
                                                    <button type="button" disabled onClick={this.decrementCount} className="btn btn-primary me-2">Previous</button>
                                                    <button type="button" onClick={this.incrementCount} disabled={!isUtilityFormFilled} className="btn btn-primary me-2">Next</button>
                                                    <button type="submit" disabled className="btn btn-primary me-2">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </form>
                                )}
                                {this.state.formCount === 2 && (
                                    <form onSubmit={(e) => this.handleFormSubmit(e, 'form2')} >
                                        <div className="scrollable">
                                            <div className="scrollable-content">
                                                {pulseQuestions.map((question, index) => (
                                                    <div className="col-md-12 questions-list" key={index}>
                                                        <p className="m-b-5">{index + 1}. {question}</p>
                                                        <span className="f-s-14 text-left font-bold m-l-25" style={{ color: '#f5821f' }}>
                                                            <div class="row">
                                                                {pulseLabels.map((pulseLabel, inc) => (
                                                                    <div className="form-group col-md-4" key={inc}>
                                                                        <label htmlFor={`textarea-${inc}`}>{pulseLabel}</label>
                                                                        <textarea
                                                                            id={`textarea-${inc}`}
                                                                            className="form-control inference-text"
                                                                            value={pulseAnswers['question'+(index + 1)][pulseLabel.toLowerCase().replace(/ /g, "_")] || ''}
                                                                            onChange={(e) => this.handlePulseChange(index, pulseLabel.toLowerCase().replace(/ /g, "_"), e)}
                                                                            required
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </span>
                                                        <hr className="m-t-5 col-sm-12 m-b-8 p-0"></hr>
                                                    </div>
                                                ))}
                                                <div className="B-G-bold d-gray pull-right mt-10 page-footer default-bg">
                                                    <Button
                                                        value="back"
                                                        className="me-2"
                                                        onClick={this.props.backToMonthLists}
                                                    >
                                                        BACK
                                                    </Button>
                                                    <button type="button" onClick={(e) => this.saveAnswer()} disabled={!isPulseFormFilled} className="btn btn-primary me-2">Save as Draft</button>
                                                    <button type="button" onClick={this.decrementCount} className="btn btn-primary me-2">Previous</button>
                                                    <button type="button" onClick={this.incrementCount} disabled={!isPulseFormFilled} className="btn btn-primary me-2">Next</button>
                                                    <button type="submit" disabled className="btn btn-primary me-2">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </form>
                                )}
                                {this.state.formCount === 3 && (
                                    <form onSubmit={(e) => this.handleFormSubmit(e, 'form3')} >
                                        <div className="scrollable">
                                            <div className="scrollable-content">
                                                {competencyQuestions.map((question, index) => (
                                                    <div className="col-md-12 questions-list" key={index}>
                                                        <p className="m-b-5">{index + 1}. {question}</p>
                                                        <span className="f-s-14 text-left font-bold m-l-25" style={{ color: '#f5821f' }}>
                                                            <div className="form-group w100">
                                                                <b>Inference</b>
                                                                <textarea
                                                                    className="form-control inference-text"
                                                                    value={competencyAnswers['question'+(index + 1)] || ''}
                                                                    onChange={(e) => this.handleCompetencyChange(index, e)}
                                                                    required
                                                                />
                                                            </div>
                                                        </span>
                                                        <hr className="m-t-5 col-sm-12 m-b-8 p-0"></hr>
                                                    </div>
                                                ))}
                                                <div className="B-G-bold d-gray pull-right mt-10 page-footer default-bg">
                                                    <Button
                                                        value="back"
                                                        className="me-2"
                                                        onClick={this.props.backToMonthLists}
                                                    >
                                                        BACK
                                                    </Button>
                                                    <button type="button" onClick={(e) => this.saveAnswer()} disabled={!isCompetencyFormFilled} className="btn btn-primary me-2">Save as Draft</button>
                                                    <button type="button" onClick={this.decrementCount} className="btn btn-primary me-2">Previous</button>
                                                    <button type="button" onClick={this.incrementCount} disabled={!isCompetencyFormFilled} className="btn btn-primary me-2">Next</button>
                                                    <button type="submit" disabled className="btn btn-primary me-2">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </form>
                                )}
                                {this.state.formCount === 4 && (
                                    <form onSubmit={(e) => this.handleFormSubmit(e, 'form4')} >
                                        <div className="scrollable">
                                            <div className="scrollable-content">
                                                {otherQuestions.map((question, index) => (
                                                    <div className="col-md-12 questions-list" key={index}>
                                                    <p className="m-b-5">{index + 1}. {question}</p>
                                                    <span className="f-s-14 text-left font-bold m-l-25" style={{ color: '#f5821f' }}>
                                                        <div class="row">
                                                        {otherLabels.map((otherLabel, inc) => (
                                                            <div className="form-group col-md-6" key={inc}>
                                                                <label htmlFor={`textarea-${inc}`}>{otherLabel}</label>
                                                                <textarea
                                                                    id={`textarea-${inc}`}
                                                                    className="form-control inference-text"
                                                                    value={otherAnswers['question'+(index + 1)][otherLabel.toLowerCase().replace(/ /g, "_")] || ''}
                                                                    onChange={(e) => this.handleOtherChange(index, otherLabel.toLowerCase().replace(/ /g, "_"), e)}
                                                                    required
                                                                />
                                                            </div>
                                                        ))}
                                                        </div>
                                                    </span>
                                                    <hr className="m-t-5 col-sm-12 m-b-8 p-0"></hr>
                                                </div>
                                                ))}
                                                <div className="B-G-bold d-gray pull-right mt-10 page-footer default-bg">
                                                    <Button
                                                        value="back"
                                                        className="me-2"
                                                        onClick={this.props.backToMonthLists}
                                                    > BACK </Button>
                                                    <button type="button" onClick={(e) => this.saveAnswer()} disabled={!isOthersFormFilled} className="btn btn-primary me-2">Save as Draft</button>
                                                    <button type="button" onClick={this.decrementCount} className="btn btn-primary me-2">Previous</button>
                                                    <button type="button" disabled onClick={this.incrementCount} className="btn btn-primary me-2">Next</button>
                                                    <button type="submit" disabled={!isOthersFormFilled} className="btn btn-primary me-2">Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </form>
                                )}
                            </div>
                        </div>
                    </Row>
                </div>
            </>);
          }
    }
}

const mapStateToProps = state => {
    return {
        loginDetails: state.loginDetails,
    };
};
  
const mapDispatchToProps = {
    getLoginUser,
    getLoginUserPermission,
    saveChapterAnswer
};
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChapterReviewInterfaceUpdate);
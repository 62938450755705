import { postData,getData } from "../Services/Helper";
import { handleResponse } from "../Services/HandleResponse";
import { getBackEndConstant } from "../Config/Constant";
import * as types from "../Utilities/Type";
export function getBuList() {
  return (dispatch) => {
    const requestOptions = getData();
    let url = getBackEndConstant().backend_url + "get_business_unit";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.GET_BU_LIST,
          payload: response ? response : [],
        });
      });
  };
}
import * as types from "../Utilities/Type"

const INITIAL_STATE = {
    reportFilter: [],
    pmoFilter: [],
    commentSaveResponse: "",
    reportLead: [],
    commentResponse: [],
};
export function getReportFilter(state=INITIAL_STATE,action){
    switch (action.type){
        case types.GET_REPORT_FILTER:
             return{
                ...state,
                reportFilter:action.payload
             }
        case types.GET_PMO_FILTER: 
            return{
                ...state,
                pmoFilter:action.payload
            } 
        case types.SAVE_COMMENTS: 
            return{
                ...state,
                commentSaveResponse:action.payload
            } 
        case types.REPORT_LEAD: 
            return{
                ...state,
                reportLead:action.payload
            } 
        case types.ITERATION_COMMENTS: 
            return{
                ...state,
                commentResponse:action.payload
            } 
            
        case '3':  
             break;  
        default:return state        

    }
}


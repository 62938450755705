import React, { Component, useEffect, useState } from "react";
import { Urls } from "../../Config/Config";
import "../Reports/Report.css";
import Select from "react-select";
import {
  Row,
  Col,
  Button,
  FloatingLabel
} from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import moment from "moment";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import PW_report from "../Reports/PW_report";
import PageNotFound from "../Reports/PageNotFound";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MileStoneReport from "../Reports/msreport";
import CollectionPotentialReport from "../Reports/Collection_potential_report";
import CollectionsReport from "../Reports/Collections_report";
import InventoryReport from "../Reports/InventoryReport";
import InventoryMilestoneReport from "../Reports/Inventory_milestone_report";
import InventoryAllocationReport from "../Reports/Inventory_Allocation_report";
import ResourceUtilizationReport from "../UtilizationComponent/ResourceUtilizationReport";
import {
  getCsmReport, getLoginUser, getReportFilter, getLoginUserPermission, getCsmSummaryReport, csmExportExcel, sendCsmEmail, saveCsmApprovalStatus, setAddClass
} from "../../Action";
import { connect } from "react-redux";
import * as constants from "../../Config/Constant";
import accessdenied from "../../access-denied.png";
import { CsmDetailedView } from "./CsmDetailedView";
import { CsmSummaryView } from "./CsmSummaryView";
import { CsmCarryDataView } from "./CsmCarryDataView";
import { getUserService, getReportFilterService } from "../../Utilities/Helper";
import { Dropdown } from "rsuite";
import "rsuite/dist/rsuite.min.css";
var _ = require('lodash');

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  customClass: {
    popup: "colored-toast",
    icon: "white-toast-icon",
  },
  timer: 3000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

class CsmReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: new URLSearchParams(window.location.search),
      selected: "",
      redirect: 0,
      user_types: Urls.user_types,
      active: false,
      all_active: true,
      reports_lists: Urls.reports_lists,
      reports_lists1: Urls.reports_lists1,
      current_report: Urls.reports_lists[8],
      report: {},
      projectPractice: [],
      project_practice: [],
      isAllPractices: false,
      userTypes: Urls.user_types,
      head_id: "",
      isAllPractices_inventory: false,
      searchByPracticeHead: "",
      searchBy: "",
      isChecked: false,
      filter_start_date: new Date(),
      filter_end_date: new Date(),
      sname: [],
      sname1: [],
      report_practice: [],
      report_service: [],
      practiceHead: [],
      totalMileStoneValue: 0,
      totalCarryValue: 0,
      practiceReport: [],
      totalCollectedValue: 0,
      loginUserData: {},
      loginUserPermission: {},
      token: "",
      loading: false,
      start_month: moment().utcOffset("+05:30").format("YYYY-MM"),
      end_month: moment().utcOffset("+05:30").format("YYYY-MM"),
      bu_id: "",
      selectService: [],
      selectBu: [],
      selectBuFilter: [],
      reportList: [],
      serviceName: [],
      checkedList: [],
      start: new Date(),
      reportF: 0,
      csmReportList: [],
      csmProjectList: [],
      months_list: [],
      total_invoice_data: 0,
      csmSummaryList: [],
      //......quarterly filter
      quarter: Math.floor((new Date().getMonth() + 3) / 3),
      startquarterDate: null,
      quarterDate: null,
      quarterinterval: null,
      //......quarterly filter
      userId: '',
      userArray: [],
      total_carry_value: 0,
      usersService: []
    };
  }

  reset_page = () => {
    this.state.report.service = [];
    this.state.report.practice = [];
    this.state.selectService = [];
    this.getCsmReport();
  };

  setStartDateForFilter = (e) => {
    let today = new Date(e);
    this.setState({
      filter_start_date: today,
    });

    if(this.state.filter_start_date > this.state.filter_end_date){
      this.state.filter_end_date = today;
      this.ToastAlert("warning", "End date should greater than start date");
    }
  };

  setEndDateForFilter = (e) => {
    let today = new Date(e);
    this.setState({
      filter_end_date: today,
    });
  };

  componentDidMount() {
    this.state.reports_lists.forEach((value) => {
      let opt = {};
      opt.label = value.value;
      opt.id = value.id;
      opt.value = value.value;
      this.state.reportList.push(opt);
    });
    this.state.current_report = this.state.reportList[8];

    this.state.token =
      this.state.queryParams.get("token") == null
        ? Cookies.get("auth_data")
        : this.state.queryParams.get("token");

    this.state.apiLink = constants.API_LINK;
    this.state.angularUrl = constants.FRONT_LINK;
    this.state.excelUrl = constants.EXCEL_LINK;
    if (this.state.apiLink === constants.STAGING) {
      this.state.bu_qes = Urls.staging_bu_qes;
      this.state.practice_qes = Urls.staging_practice_qes;
      this.state.service_qes = Urls.staging_service_qes;
    } else if (this.state.apiLink === constants.UAT) {
      this.state.reportList = [];
      this.state.reports_lists1.forEach((value) => {
        let opt = {};
        opt.label = value.value;
        opt.id = value.id;
        opt.value = value.value;
        this.state.reportList.push(opt);
      });
    } else {
      // this.state.reportList = [];
      // this.state.reports_lists1.forEach((value) => {
      //   let opt = {};
      //   opt.label = value.value;
      //   opt.id = value.id;
      //   opt.value = value.value;
      //   this.state.reportList.push(opt);
      // });
    }

    this.setState({
      token: this.state.token,
    });

    this.getUser();
    this.getPracticeService();
  }

  /**
   * Get the login user details
   */
  getUser = () => {
    this.setState({ loading: true });
    this.props.getLoginUser().then((response) => {
      this.props.getLoginUserPermission().then((response) => {
        // this.state.loginUserPermission = this.props.loginDetails.loginUserPermission;
        this.setState({ loading: false, loginUserData: this.props.loginDetails.loginDetails, loginUserPermission: this.props.loginDetails.loginUserPermission });
      });
    });
  }

  //filter practice based on service and head
  filterService = (e) => {
    if (e) {
      let selectedOption = e;
      this.state.selectService = selectedOption;
      this.state.report.service = selectedOption;
    }
    this.setState({
      report: {
        service: this.state.report.service,
      },
    });
    this.state.report.practice = [];
    this.state.projectPractice = [];

    this.state.report_all_practices.forEach((value) => {
      value.is_active = false;
    });
    if (this.state.isAllPractices) {
      this.state.projectPractice = this.state.report_all_practices;
    } else {
      let service = this.state.report.service;
      this.state.projectPractice = [];
      if (service.length == 0) {
        this.state.projectPractice = [];
      } else {
        service.forEach((svalue) => {
          this.state.report_all_practices.forEach((value) => {
            if (svalue.id == value.serviceId) {
              this.state.projectPractice.push(value);
            }
          });
        });
      }
    }
    this.setState({
      projectPractice: this.state.projectPractice,
    });
    // for sending all practice while reset
    if (this.state.projectPractice.length > 0) {
      this.state.projectPractice.forEach((value) => {
        this.state.report.practice.push(value.id);
      });
    }
    this.setState({
      report: {
        practice: this.state.report.practice,
        service: this.state.report.service,
      },
      // report_service:this.state.report_service
    });

    this.getCsmReport();
    // }
  };

  changeViewPracite = (flag) => {
    if (this.state.loginUserData.role_id == 8 && this.state.isAllPractices) {
      this.setState({
        isAllPractices: flag
      })
    }
    this.selectReport(this.state.current_report);
  };

  selectReport = (report) => {
    let dateValue = new Date();
    let date = new Date(dateValue.getFullYear(), dateValue.getMonth(), 2);
    this.state.filter_end_date = this.state.filter_end_date
      ? this.state.filter_end_date
      : date;
    this.state.filter_start_date = this.state.filter_start_date
      ? this.state.filter_start_date
      : date;
    this.state.report.practice = this.state.report.practice
      ? this.state.report.practice
      : [];

    this.state.report.head_id = "";
    this.setState({
      previous_report: this.state.current_report,
      current_report: report,
      project_filter: {},
      searchBy: "$"
    })
    this.getCsmReport();
  };

  getCsmReport1 = () => {
    let dataObj = {}, reportData = {};
    reportData.business_unit = this.state.bu_id;
    let service_head = [], services = [];
    if (reportData.business_unit != "") {
      this.state.report_service = [];
      this.state.report_practice = [];
      this.state.projectPractice = [];
      this.state.sname1.forEach(service => {
        if (this.state.bu_id == service.bu) {
          service_head.push(service);

        }
      })
      services = service_head;
      if (this.state.projectPractice.length > 0) {
        this.state.projectPractice.forEach(value => {
          this.state.report.practice.push(value.id);
        });
      }
    }
    else {
      this.state.sname1.forEach(service => {
        services.push(service);
      })
    }
    let ser = [];
    if (this.state.report.service.length > 0) {
      reportData.service = [this.state.report.service[0].id]
      this.state.report.service.forEach(svalue => {
        ser.push(svalue.id)
      })
      reportData.service = ser;
    }
    else {
      reportData.service = [];
    }
    if (this.state.report.practice.length == 0) {
      reportData.practice = this.state.report_practice;
      this.state.report_all_practices.forEach(value => {
        this.state.projectPractice.push(value);
      })
    } else {
      reportData.practice = this.state.report.practice;
      this.state.report.practice.forEach(pracvalue => {
        this.state.report_all_practices.forEach(value => {
          if (pracvalue == value.id)
            this.state.projectPractice.push(value);
        })
      })
    }

    let start_data = (this.state.quarterinterval == null || this.state.quarterinterval == undefined) ? this.state.startquarterDate : this.state.quarterinterval;
    reportData.start_date = moment(this.state.filter_start_date)
      .utcOffset('+05:30')
      .format('YYYY-MM-01');
    reportData.end_date = moment(this.state.filter_end_date)
      .utcOffset('+05:30')
      .format('YYYY-MM-01');
    reportData.allpractice = this.state.isAllPractices;
    dataObj['reportData'] = reportData;
    let csmReportList = [], csmSummaryList = [];
    this.setState({ loading: true });
    this.props.getCsmReport(dataObj).then((response) => {
      this.setState({ loading: false });
      csmReportList = this.props.csmReport;
      let csm_report_array = [];
      csmReportList.data.map(function (csmList) {
        let csm_array = [];
        Object.values(csmList).map(cvalue => {
          let single_project = {};
          let project_invoice_value = 0, project_collected_value = 0, project_carry_collected_value = 0, project_carry_invoice_value = 0, invoice_diff = 0, carry_diff = 0, carry_sum = 0;
          {
            cvalue && Object.values(cvalue).map(function (value) {
              project_invoice_value = value.total_milestone_achieved_value;
              project_collected_value += value.collected_value;
              invoice_diff += (value.invoice_status == 3 && value.collected_value > 0) ? 0 : (value.total_milestone_achieved_value - value.collected_value);
              project_carry_collected_value += value.carryforward_collected_value;
              {
                value.carryforwarded_iteration && value.carryforwarded_iteration.map(function (carryforward) {
                  project_carry_invoice_value += carryforward.carried_value;
                })
              }
              single_project.project_name = cvalue[0].project_name;
              single_project.project_code = cvalue[0].project_code;
              single_project.invoice = project_invoice_value;
              single_project.kickoff = cvalue[0].kickoff_ms;
              single_project.collection = project_collected_value;
              single_project.carry_collection = project_carry_collected_value;
              single_project.carry_invoice = project_carry_invoice_value;
              carry_diff += (value.invoice_status == 3) ? 0 : (project_carry_invoice_value - project_carry_collected_value)
              single_project.csm_users = cvalue[0].csm_users;
              single_project.users = cvalue[0].users;
              single_project.invoice_diff = invoice_diff;
              single_project.carry_diff = carry_diff;
              single_project.carry_month = cvalue[0].month_name;
              single_project.carry_sum = value.carry_invoice_amount;
            })
          }
          if (single_project.project_name != undefined)
            csm_array.push(single_project)
        })
        csm_report_array.push(csm_array)
      });

      let total_carry = 0;
      csmReportList.project && csmReportList.project.map((value) => {
        let invoice_value = 0, collection_value = 0, invoice_diff = 0, project_array = [], carry_sum = 0;
        Object.values(csm_report_array).map(cvalue => {
          invoice_value = invoice_value + (cvalue && cvalue.reduce((sum, current) => {
            return sum + (value.project_name === current.project_name ? (current.invoice + current.kickoff + current.carry_invoice) : 0)
          }, 0));
          collection_value = collection_value + (cvalue && cvalue.reduce((sum, current) => {
            return sum + (value.project_name === current.project_name ? (current.collection + current.kickoff + current.carry_collection) : 0)
          }, 0));
          invoice_diff = invoice_diff + (cvalue && cvalue.reduce((sum, current) => {
            current.collection_value = (current.invoice_diff == 0) ? current.invoice : current.collection;
            current.carry_collection_value = (current.carry_diff == 0) ? current.carry_invoice : current.carry_collection;
            return sum + (value.project_name === current.project_name ? ((current.invoice + current.kickoff + current.carry_invoice) - (current.collection_value + current.kickoff + current.carry_collection_value)) : 0);
          }, 0));
          carry_sum = carry_sum + (cvalue && cvalue.reduce((sum, current) => {
            return sum + (value.project_name === current.project_name ? (current.carry_sum) : 0)
          }, 0));
          let getInvoiceData = _.filter(cvalue, function (data) {
            return data.project_name == value.project_name;
          });
          project_array.push(getInvoiceData);
        })
        total_carry += invoice_diff;
        value.invoice = invoice_value;
        value.collection = collection_value;
        // value.diff = invoice_diff;
        value.diff = carry_sum;
        value.project_array = project_array;
      })
      let data = {};
      data.month = moment(this.state.filter_start_date)
        .utcOffset('+05:30')
        .format('YYYY-MM-01');
      data.end_month = moment(this.state.filter_end_date)
        .utcOffset('+05:30')
        .format('YYYY-MM-01');
      data.csm_report = csm_report_array
      this.props.getCsmSummaryReport(data).then((response) => {
        Object.values(this.props.csmSummaryData.data).map(cvalue => {
          let count = (cvalue && cvalue.data.reduce((sum, current) => {
            return sum + (current.collection)
          }, 0));
          if (count > 0) {
            csmSummaryList.push(cvalue);
          }
        })
        this.setState({
          csmProjectList: csmReportList.project,
          total_carry_value: total_carry,
          months_list: csmReportList.months,
          csmSummaryList: csmSummaryList
        });
      })
    }, (error) => {
      this.setState({ loading: false });
      this.setState({
        csmProjectList: [],
        total_carry_value: 0,
        months_list: [],
        csmSummaryList: []
      });
      this.ToastAlert("error", error);
    });


  };

  getCsmReport = () => {
    let dataObj = {}, reportData = {};
    reportData.business_unit = this.state.bu_id;
    let service_head = [], services = [];
    if (reportData.business_unit != "") {
      this.state.report_service = [];
      this.state.report_practice = [];
      this.state.projectPractice = [];
      this.state.sname1.forEach(service => {
        if (this.state.bu_id == service.bu) {
          service_head.push(service);

        }
      })
      services = service_head;
      if (this.state.projectPractice.length > 0) {
        this.state.projectPractice.forEach(value => {
          this.state.report.practice.push(value.id);
        });
      }
    }
    else {
      this.state.sname1.forEach(service => {
        services.push(service);
      })
    }
    let ser = [];
    if (this.state.report.service.length > 0) {
      reportData.service = [this.state.report.service[0].id]
      this.state.report.service.forEach(svalue => {
        ser.push(svalue.id)
      })
      reportData.service = ser;
    }
    else {
      // getting user associated service list
      reportData.service = getReportFilterService(this.state.serviceName, this.state.loginUserData, this.state.usersService);
    }
    if (this.state.report.practice.length == 0) {
      reportData.practice = this.state.report_practice;
      this.state.report_all_practices.forEach(value => {
        this.state.projectPractice.push(value);
      })
    } else {
      reportData.practice = this.state.report.practice;
      this.state.report.practice.forEach(pracvalue => {
        this.state.report_all_practices.forEach(value => {
          if (pracvalue == value.id)
            this.state.projectPractice.push(value);
        })
      })
    }
    reportData.start_date = moment(this.state.filter_start_date)
      .utcOffset('+05:30')
      .format('YYYY-MM-01');
    reportData.end_date = moment(this.state.filter_end_date)
      .utcOffset('+05:30')
      .format('YYYY-MM-01');
    reportData.allpractice = this.state.isAllPractices;
    dataObj['reportData'] = reportData;
    let csmReportList = [], csmSummaryList = [], total_invoice = 0, inv = 0, col = 0, pen = 0;
    this.setState({ loading: true });
    this.props.getCsmReport(dataObj).then((response) => {
      this.props.csmReport.data.map(sum => {
        inv += _.sumBy(sum, 'invoice');
        col += _.sumBy(sum, 'collection');
        pen += _.sumBy(sum, 'pending');
      })
      total_invoice = inv + col + pen;
      let data = {};
      data.month = moment(this.state.filter_start_date)
        .utcOffset('+05:30')
        .format('YYYY-MM-01');
      data.end_month = moment(this.state.filter_end_date)
        .utcOffset('+05:30')
        .format('YYYY-MM-01');
      // data.csm_report = this.props.csmReport.data;
      data.csm_report = dataObj;
      this.props.getCsmSummaryReport(data).then((response) => {
        console.log(this.props.csmSummaryData.data)
        Object.values(this.props.csmSummaryData.data).map(cvalue => {
          let invCount = (cvalue && cvalue.data.reduce((sum, current) => {
            return sum + (current.invoice)
          }, 0));
          let collectionCount = (cvalue && cvalue.data.reduce((sum, current) => {
            return sum + (current.collection)
          }, 0));
          if ((invCount + collectionCount) > 0) {
            csmSummaryList.push(cvalue);
          }
        })
        console.log("csmSummaryList", csmSummaryList)
        this.setState({
          loading: false,
          csmProjectList: this.props.csmReport.data,
          total_carry_value: this.props.csmReport.total_carry,
          total_invoice_data: total_invoice,
          months_list: this.props.csmReport.months,
          csmSummaryList: csmSummaryList
        });
      }, (error) => {
        this.setState({
          loading: false,
          csmProjectList: this.props.csmReport.data,
          total_carry_value: this.props.csmReport.total_carry,
          total_invoice_data: total_invoice,
          months_list: this.props.csmReport.months,
          csmSummaryList: []
        });
        this.ToastAlert("error", error);
      });
    }, (error) => {
      this.setState({ loading: false });
      this.setState({
        csmProjectList: [],
        total_carry_value: 0,
        months_list: [],
        csmSummaryList: [],
        total_invoice_data: 0
      });
      this.ToastAlert("error", error);
    });


  };

  getReportByHead = (e) => {
    if (e != undefined) {
      this.state.bu_id = e.bu_id;
      this.state.head_id = e.label;
      this.state.selectBuFilter = e;
    } else {
      this.state.head_id = "All";
      this.state.bu_id = "";
      this.state.selectBuFilter = [];
    }

    if (this.state.head_id != "All") {
      // for BU filter
      this.state.report.project_practice = [];
      let service_head = [];

      this.state.report.service = [];
      this.state.report.service = [];
      this.state.sname1.forEach((service) => {
        if (this.state.bu_id == service.bu) {
          service_head.push(service);
        }
      });
      this.state.serviceName = [];
      this.state.selectService = [];
      service_head.forEach((value) => {
        let opt = {};
        opt.label = value.value;
        opt.id = value.id;
        opt.value = value.value;
        opt.head = value.head;
        opt.bu = value.bu;
        this.state.serviceName.push(opt);
      });
      this.state.selectService = [];

      let service = this.state.report.service;
      if (service.length == 0) {
        this.state.projectPractice = [];
        this.state.report.practice = [];
      } else {
        service.forEach((svalue) => {
          this.state.report_all_practices.forEach((value) => {
            if (svalue.id == value.serviceId) {
              this.state.projectPractice.push(value);
            }
          });
        });
        if (this.state.projectPractice.length > 0) {
          this.state.projectPractice.forEach((value) => {
            this.state.report.practice.push(value.id);
          });
        }
      }
    } else {
      // when All BU is selected
      let service_head = [];
      this.state.selectBuFilter = [];
      if (this.state.report.service.length == 0) {
        //nothing
        this.state.sname1.forEach((service) => {
          service_head.push(service);
        });
        this.state.serviceName = [];
        this.state.selectService = [];
        service_head.forEach((value) => {
          let opt = {};
          opt.label = value.value;
          opt.id = value.id;
          opt.value = value.value;
          opt.head = value.head;
          opt.bu = value.bu;
          this.state.serviceName.push(opt);
        });
        this.state.selectService = [];
      } else {
        this.state.report.service = [];
        this.state.sname.forEach((value) => {
          let opt = {};
          opt.label = value.value;
          opt.id = value.id;
          opt.value = value.value;
          opt.head = value.head;
          opt.bu = value.bu;
          this.state.serviceName.push(opt);
        });
        this.state.selectService = this.state.serviceName[0];
        this.state.report.service.push(this.state.serviceName[0]);
      }
    }
    this.state.searchByPracticeHead = this.state.bu_id;
    this.setState({
      selectBuFilter: this.state.selectBuFilter,
    });
    this.changeViewPracite_inventory("", true);
  };

  changeView = (flag) => {
    this.setState({
      detailed_view: flag,
    });
  };

  ToastAlert(icon, title) {
    return Toast.fire({
      target: document.getElementById("form-modal"),
      icon: `${icon}`,
      title: `${title}`,
    });
  }

  resetReport = (e) => {
    e.currentTarget.blur(); // removing focus
    this.state.resourceFilter = this.state.resourceAllFilter;
    this.state.searchBy = "";
    this.setState({
      resourceFilter: this.state.resourceFilter,
    });
    this.getPracticeService();
  };

  generateCsmReportExportAsExls = (e) => {
    e.currentTarget.blur(); // removing focus
    this.setState({ loading: true });
    let dataObj = {}, reportData = {};
    reportData.business_unit = this.state.bu_id;
    let service_head = [], services = [];
    if (reportData.business_unit != "") {
      this.state.report_service = [];
      this.state.report_practice = [];
      this.state.projectPractice = [];
      this.state.sname1.forEach(service => {
        if (this.state.bu_id == service.bu) {
          service_head.push(service);

        }
      })
      services = service_head;
      if (this.state.projectPractice.length > 0) {
        this.state.projectPractice.forEach(value => {
          this.state.report.practice.push(value.id);
        });
      }
    }
    else {
      this.state.sname1.forEach(service => {
        services.push(service);
      })
    }
    let ser = [];
    if (this.state.report.service.length > 0) {
      reportData.service = [this.state.report.service[0].id]
      this.state.report.service.forEach(svalue => {
        ser.push(svalue.id)
      })
      reportData.service = ser;
    }
    else {
      reportData.service = [];
    }
    if (this.state.report.practice.length == 0) {
      reportData.practice = this.state.report_practice;
      this.state.report_all_practices.forEach(value => {
        this.state.projectPractice.push(value);
      })
    } else {
      reportData.practice = this.state.report.practice;
      this.state.report.practice.forEach(pracvalue => {
        this.state.report_all_practices.forEach(value => {
          if (pracvalue == value.id)
            this.state.projectPractice.push(value);
        })
      })
    }
    reportData.start_date = moment(this.state.filter_start_date)
      .utcOffset('+05:30')
      .format('YYYY-MM-01');
    reportData.end_date = moment(this.state.filter_end_date)
      .utcOffset('+05:30')
      .format('YYYY-MM-01');
    reportData.allpractice = this.state.isAllPractices;
    reportData.total_carry = this.state.total_carry_value;
    reportData.months_list = this.state.months_list;
    reportData.csmSummaryList = this.state.csmSummaryList;
    dataObj['reportData'] = reportData;
    this.props.csmExportExcel(dataObj).then((response) => {
      this.setState({ loading: false });
      this.ToastAlert("success", "Report generated Successfully");
      document.location = this.state.excelUrl + this.props.csmExport.filename;
    }, (error) => {
      this.setState({ loading: false });
      this.ToastAlert("error", error);
    });

  };

  getPracticeService = (isAllPractice) => {
    this.state.isAllPractices = isAllPractice;
    this.setState({ loading: true });
    this.state.filterService = [];
    let dateValue = new Date();
    let date = new Date(dateValue.getFullYear(), dateValue.getMonth(), 2);
    let start_date = this.state.filter_start_date
      ? this.state.filter_start_date
      : date;
    let end_date = this.state.filter_end_date
      ? this.state.filter_end_date
      : date;
    // this.state.report.service = [];
    let isAllPractices = isAllPractice;
    this.state.sname = [];
    this.state.allService = [];
    this.state.serviceName = [];
    let dataObject = {};
    dataObject.start_date = start_date;
    dataObject.end_date = end_date;
    dataObject.filter = isAllPractice;

    let c = 0;
    this.props.getReportFilter(dataObject).then((response) => {
      this.setState({
        loading: false,
      });
      this.props.reportFilter.directors.forEach((value) => {
        if (this.state.bu_id == value.bu_id) {
          c = 1;
        }
      });
      this.state.bu_id = c == 1 ? this.state.bu_id : "";
      this.state.selectBuFilter = c == 1 ? this.state.selectBuFilter : [];
      if (
        this.state.bu_id != "" &&
        this.state.bu_id != undefined &&
        this.state.bu_id != 0
      ) {
        this.props.reportFilter.services.forEach((service) => {
          if (this.state.bu_id == service.bu) {
            this.state.sname.push(service);
          }
        });
        this.props.reportFilter.allservices.forEach((service) => {
          if (this.state.bu_id == service.bu) {
            this.state.allService.push(service);
          }
        });
      } else {
        this.state.sname = this.props.reportFilter.services;
        this.state.allService = this.props.reportFilter.allservices;
      }
      this.state.sname1 = this.props.reportFilter.services;
      this.state.usersService = this.props.reportFilter.usersService;
      this.state.report.project_practice = [];
      this.state.sname.forEach((value) => {
        let opt = {};
        opt.label = value.value;
        opt.id = value.id;
        opt.value = value.value;
        opt.head = value.head;
        opt.bu = value.bu;
        this.state.serviceName.push(opt);
      });
      if (isAllPractice) {
        this.state.selectService = [];
        this.state.report.service = [];
        this.state.report.practice = [];
        this.state.projectPractice = [];
        this.state.head_id = "All";
        this.state.selectBU = [];
        this.props.reportFilter.practices.forEach((pvalue) => {
          this.state.projectPractice.push(pvalue);
        });
      } else {
        let count = 0;
        if (this.state.report.service) {
          this.state.report.service.forEach((value) => {
            this.state.sname.forEach((svalue) => {
              if (svalue.id == value.id) {
                count = 1;
              }
            });
          });
          if (count == 0) {
            this.state.report.service = [];
            let service = getUserService(this.state.serviceName, this.props.loginDetails.loginDetails, this.state.filter_start_date);
            this.state.report.service.push(service);
            this.state.selectService = service;
          }
        } else {
          this.state.report.service = [];
          let service = getUserService(this.state.serviceName, this.props.loginDetails.loginDetails, this.state.filter_start_date);
          this.state.report.service.push(service);
          this.state.selectService = service;
        }
      }
      this.setState({
        report: {
          service: this.state.report.service,
          practice: this.state.report.practice,
        },
        //selectService : this.state.selectService
      });
      let service1 = [];
      service1.push(this.state.sname[0]);
      this.state.qes_list = this.props.reportFilter.qes_list;
      this.setState({
        report_all_practices: this.props.reportFilter.practices,
        practiceHead: this.state.practiceHead,
      });

      // for practicehead
      this.props.reportFilter.directors.forEach((id) => {
        this.state.practiceHead.push(id);
      });
      let buName = [];
      this.props.reportFilter.directors.forEach((value) => {
        let opt = {};
        opt.label = value.bu;
        opt.value = value.bu;
        opt.bu_id = value.bu_id;
        opt.id = value.id;
        opt.bu = value.bu;
        opt.name = value.name;
        buName.push(opt);
      });
      this.setState({
        practiceHead: this.state.practiceHead,
        buName: buName,
        bu_id: this.state.bu_id,
      });
      if (this.state.loginUserData.role_id == 8) {
        this.state.searchByPracticeHead = this.state.loginUserData.role_id;
      } else {
        this.state.searchByPracticeHead = this.state.searchByPracticeHead;
      }
      if (!this.state.isAllPractices) {
        // this.filterService("");
      }
      this.changeViewPracite(this.state.isAllPractices);
    }, (error) => {

    });

  };

  //......quarter filter
  setStartQuarter = (month) => {
    switch (month.getMonth()) {
      case 0:
        this.state.quarterDate = new Date(month.getFullYear(), 0, 1);
        break;
      case 3:
        this.state.quarterDate = new Date(month.getFullYear(), 3);
        break;
      case 6:
        this.state.quarterDate = new Date(month.getFullYear(), 6, 1);
        break;
      case 9:
        this.state.quarterDate = new Date(month.getFullYear(), 9, 1);
        break;
      default:
        break;
    }
    this.setState({
      quarterinterval: this.state.quarterDate
    });
  }
  //......quarter filter

  /**	
   * Send email to the csm user	
   */
  sendCsmReportEmail = (user, event) => {
    let dataObject = {};
    this.setState({ loading: true });
    dataObject.csmList = user;
    dataObject.start_date = moment(this.state.filter_start_date)
      .utcOffset('+05:30')
      .format('YYYY-MM-01');
    dataObject.end_date = moment(this.state.filter_end_date)
      .utcOffset('+05:30')
      .format('YYYY-MM-01');
    this.props.sendCsmEmail(dataObject).then((response) => {
      this.setState({ loading: false });
      this.ToastAlert("success", "Mail Send to the CSM user Successfully");
    }, (error) => {
      this.setState({ loading: false });
      this.ToastAlert("error", error);
    });
  }

  /**
 * Send the apporval status to finance team
 */
  saveCsmApprovalStatus = (item, isApprove) => {
    let dataObject = {};
    dataObject.userName = item.data[0].user_name;
    dataObject.userId = item.data[0].user;
    let start_data = (this.state.quarterinterval == null || this.state.quarterinterval == undefined) ? this.state.startquarterDate : this.state.quarterinterval;
    dataObject.quarter = moment(this.state.filter_start_date)
      .utcOffset('+05:30')
      .format('YYYY-MM-01');
    dataObject.isApproval = isApprove;
    this.setState({ loading: true });
    let csmSummaryData = this.state.csmSummaryList;
    this.props.saveCsmApprovalStatus(dataObject).then((response) => {
      this.props.csmSummaryData.data[dataObject.userId].approval_status = isApprove;
      csmSummaryData.map(item => {
        if (item.data && item.data[0].user == dataObject.userId) {
          item.data[0].is_approval = isApprove
          item.approval_status = isApprove
        }
      })
      this.setState({ loading: false, csmSummaryList: csmSummaryData });
      this.ToastAlert("success", "CSM Approved Successfully");
    }, (error) => {
      this.setState({ loading: false });
      this.ToastAlert("error", error);
    });
  }

  /**
   * main render
   * @returns
   */
  render() {
    const { practiceHead } = this.state;
    let buName = [];
    practiceHead.forEach((value) => {
      let opt = {};
      opt.label = value.bu;
      opt.value = value.bu;
      opt.bu_id = value.bu_id;
      opt.id = value.id;
      opt.bu = value.bu;
      opt.name = value.name;
      buName.push(opt);
    });
    //......quarter filter
    switch (this.state.quarter) {
      case 1:
        this.state.startquarterDate = new Date(new Date().getFullYear(), 0, 1);
        break;
      case 2:
        this.state.startquarterDate = new Date(new Date().getFullYear(), 3);
        break;
      case 3:
        this.state.startquarterDate = new Date(new Date().getFullYear(), 6, 1);
        break;
      case 4:
        this.state.startquarterDate = new Date(new Date().getFullYear(), 9, 1);
        break;
      default:
        break;
    }
    //......quarter filter

    if (this.state.redirect == 1) {
      return <PageNotFound url={this.state.angularUrl} />;
    } else {
      return (
        <>
          <div className={"px-4 h-auto minht page-content " + (this.props.addClass ? "active" : "")} >
            <Row>
              <div className="col-xs-12 fourty-fluid fourty-fluid">
                <Row className="mb-3">
                  <div className="col-sm-4 col-sm-4 col-xs-12 text-left">
                    <h1 className="B-G-bold f-s-18 d-gray t-t-upper pull-left mt-10 mr-20 page-head">
                      Reports
                    </h1>
                  </div>
                  <div className="col-lg-3 col-sm-4 col-xs-12">
                    <div className="selectpicker_dropdown hide_cross hide_bar orange_arrow remove_border_btm select_dropdown_picker drop ">
                      <Dropdown title={this.state.current_report.value} activeKey="9">
                        <Dropdown.Item as="a" eventKey="1" href="/pw_report" target="_blank">Practice Wise Allocation Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="2" href="/ms_report" target="_blank">Milestone Value Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="3" href="/collections_report" target="_blank">Collections Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="4" href="/collections_potential_report" target="_blank">Collection Potential Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="5" href="/inventory_report" target="_blank">Inventory Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="6" href="/inventory_allocation_report" target="_blank">Inventory Allocation Metrics</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="7" href="/inventory_milestone_report" target="_blank">Inventory Milestone Metrics</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="8" href="/utilization_report" target="_blank">Resource Utilization Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="9">Csm Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="10" href="/pmo_ms_report" target="_blank">Pmo Ms Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="11" href="/acv_report" target="_blank">Annual Contract Value Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="12" href="/ms-allocation-report" target="_blank">MS Allocation Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="13" href="/squad-review-report" target="_blank">Squad Review Report</Dropdown.Item>
                      </Dropdown>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col-lg-6 clearfix m-b-20">
                    <div className="row align-items-center">
                      <label className="f-s-12 text-muted pt-1 w-13 col-lg-2 col-md-6 col-sm-12 text-left">
                        Line of Service :
                      </label>
                      <div className="col-lg-7 col-md-6 col-sm-12 text-left ">
                        <Row>
                          <div className="col-lg-10 p-l-0">
                            <div className="selectpicker_dropdown hide_bar w-100 selectpicker_dropdown_orange">
                              <Select
                                className="basic-single-select"
                                classNamePrefix={"select_dropdown"}
                                placeholder="All"
                                isClearable
                                isSearchable={true}
                                onChange={(e) => this.filterService(e)}
                                value={this.state.selectService}
                                options={this.state.serviceName}
                                isMulti
                              ></Select>
                            </div>
                          </div>
                          <div className="col-lg-2 p-1-0">
                            {this.state.report.service != undefined &&
                              this.state.report.service.length > 0 && (
                                <a
                                  href="#"
                                  title="Reset"
                                  tooltip-placement="top"
                                  className="reset-icon_old reset-color p-l-5 m-l-4"
                                  onClick={this.reset_page}
                                >
                                  <i className="fs-16 fa fa-refresh"></i>
                                </a>
                              )}
                          </div>
                        </Row>
                      </div>
                    </div>
                  </div>
                  {this.state.loginUserData.role_id != 8 && (
                    <div className="col-lg-4 clearfix m-b-20">
                      <div className="row">
                        <div className="col-sm-5 col-xs-12">
                          <label className="text-uppercase m-b-0 fs-13 p-t-5">
                            Business Unit :
                          </label>
                        </div>
                        <div className="col-sm-7 col-xs-12">
                          <div className="selectpicker_dropdown hide_bar w-100 selectpicker_dropdown_orange">
                            <Select
                              className="basic-single-select"
                              classNamePrefix={"select_dropdown"}
                              placeholder="All"
                              isClearable
                              isSearchable={true}
                              onChange={(e) => this.getReportByHead(e)}
                              value={this.state.selectBuFilter}
                              options={buName}
                            ></Select>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-lg-2 col-sm-2 col-xs-12 justify-content-end text-end">
                    <a href={this.state.angularUrl} className="btn btn-primary">
                      Back
                    </a>
                  </div>
                </Row>
                <hr className="m-t-5 col-sm-12 m-b-8 p-0"></hr>
                {this.props.loginDetails.loginUserPermission && this.props.loginDetails.loginUserPermission.permission && this.props.loginDetails.loginUserPermission.permission.csm_report &&
                  <>
                    <div className="col-lg-12 col-sm-12 col-xs-12 p-b-15-xs p-r-15-xs">
                      <Row className=" mt-2 mb-3">
                        <Col className="col-lg-10 text-start col p-0">
                          <div className="d-flex">
                            <label className="p-r-25 p-l-15 B-G-bold f-s-18 d-gray m-t-3 m-b-10 page-head pull-left ">
                              Month Filter:
                            </label>
                            <label className="p-r-15 B-G-bold f-s-18 d-gray m-t-3 m-b-10 page-head pull-left ">
                              From:
                            </label>
                            <div className="p-r-15">
                              <div className="align-items-center mb-2 date_picker_align">
                                <DatePicker
                                  className="datepicker-control-section"
                                  onChange={(e) => this.setStartDateForFilter(e)}
                                  dateFormat="MM/yyyy"
                                  showMonthYearPicker
                                  todayButton={"Today"}
                                  //dateFormat="MM/yyyy"
                                  name="start_date"
                                  selected={this.state.filter_start_date}
                                />
                                <span
                                  className="cal_icon_date"
                                  role="button"
                                  tabIndex="0"
                                >
                                  {" "}
                                  <i className="icon-calendar"></i>
                                </span>
                              </div>
                            </div>
                            <label className="p-r-15 B-G-bold f-s-18 d-gray m-t-3 m-b-10 page-head ">
                              To:
                            </label>
                            <div className="p-r-20">
                              <div className="align-items-center mb-2 date_picker_align">
                                <DatePicker
                                  className="datepicker-control-section"
                                  onChange={(e) => this.setEndDateForFilter(e)}
                                  dateFormat="MM/yyyy"
                                  showMonthYearPicker
                                  minDate={this.state.filter_start_date}
                                  todayButton={"Today"}
                                  //dateFormat="MM/yyyy"
                                  name="end_date"
                                  selected={this.state.filter_end_date}
                                />
                                <span
                                  className="cal_icon_date"
                                  role="button"
                                  tabIndex="0"
                                >
                                  {" "}
                                  <i className="icon-calendar"></i>
                                </span>
                              </div>
                            </div>

                            <Button
                              onClick={(e) => this.resetReport(e)}
                              value="Generate"
                              className="me-2 h-28"
                            >
                              Generate
                            </Button>
                            <Button
                              onClick={(e) => this.generateCsmReportExportAsExls(e)}
                              value="Excel"
                              className="me-2 h-28"
                              disabled={this.state.csmProjectList.length == 0 ? true : false}
                            >
                              Export as Excel
                            </Button>
                          </div>
                        </Col>
                        <Col className="col-lg-2 text-end">

                        </Col>
                      </Row>
                    </div>
                    {this.state.loading == true &&
                      <div id="preloader">
                        <div id="status"></div>
                      </div>
                    }
                    <div className="pro_listng bor-no">
                      <div className="invoice--report bg-transparent inventory_allo_table inventory_allo_tablereact p-l-0 p-r-0 p-t-0 p-b-0 m-t-5">
                        <Tabs
                          defaultActiveKey="detailed_view"
                          id="uncontrolled-tab-example"
                          className="mb-3"
                          onSelect={(event) => {
                            this.changeView(event);
                          }}
                        >
                          <Tab
                            eventKey="detailed_view"
                            title="Detailed View"
                            onChange={(event) => {
                              this.changeView(false, event);
                            }}
                          >
                            <CsmDetailedView reportList={this.state.reportList}
                              csmReportList={this.state.csmReportList}
                              csmProjectList={this.state.csmProjectList}
                              months_list={this.state.months_list}
                              total_invoice_data={this.state.total_invoice_data}
                              selectBuFilter={this.state.selectBuFilter}
                              selectService={this.state.selectService}
                              loading={this.state.loading}
                              serviceName={this.state.serviceName}
                            />
                          </Tab>
                          {
                            (this.state.loginUserData.role_id == constants.FINANCE_TEAM_ROLE || this.state.loginUserData.role_id == 1 || this.props.loginDetails.loginDetails.roles.includes(constants.SUB_ADMIN_ROLE) || this.props.loginDetails.loginDetails.roles.includes(constants.CSM_HEAD_ROLE)) &&
                            <Tab eventKey="summary_view" title="Summary View">
                              <CsmSummaryView reportList={this.state.reportList}
                                months_list={this.state.months_list}
                                csmSummaryList={this.state.csmSummaryList}
                                selectBuFilter={this.state.selectBuFilter}
                                selectService={this.state.selectService}
                                loading={this.state.loading}
                                serviceName={this.state.serviceName}
                                sendCsmReportEmail={(data, event) => this.sendCsmReportEmail(data, event)}
                                saveCsmApprovalStatus={(data, isApprove) => this.saveCsmApprovalStatus(data, isApprove)}
                                userData={this.state.loginUserData}
                              />
                            </Tab>
                          }
                          <Tab eventKey="carry_data_view" title="CarryData View">
                            <CsmCarryDataView reportList={this.state.reportList}
                              selectBuFilter={this.state.selectBuFilter}
                              months_list={this.state.months_list}
                              csmProjectList={this.state.csmProjectList}
                              selectService={this.state.selectService}
                              loading={this.state.loading}
                              serviceName={this.state.serviceName}
                              total_carry_value={this.state.total_carry_value}
                            />
                          </Tab>
                        </Tabs>

                      </div>
                    </div>
                  </>
                }
                {this.props.loginDetails.loginUserPermission && this.props.loginDetails.loginUserPermission.permission && !this.props.loginDetails.loginUserPermission.permission.csm_report &&
                  <div className={"page-content " + (this.props.addClass ? "active" : "")} >
                    <div className="col-md-12 m-b-30">
                      <div className="four_not_err access_denied">
                        <div className="for_content">
                          <img alt="sad" width="" src={accessdenied} />
                          <h1>You don't have permission to access this page</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </Row>
          </div>
        </>

      );
    }
  }
}

const mapStateToProps = state => {
  return {
    csmReport: state.csmReportDetails.csmReport,
    loginDetails: state.loginDetails,
    csmExport: state.csmReportDetails.csmExport,
    reportFilter: state.reportFilterDetails.reportFilter,
    csmSummaryData: state.csmReportDetails.csmData,
    addClass: state.pmsDetails.addClass,
  };
};

const mapDispatchToProps = {
  getCsmReport,
  getLoginUser,
  getReportFilter,
  getLoginUserPermission,
  getCsmSummaryReport,
  csmExportExcel,
  sendCsmEmail,
  saveCsmApprovalStatus,
  setAddClass
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CsmReport);

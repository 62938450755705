import React from "react";
import { Urls } from "../../Config/Config";
import "../Reports/Report.css";
import Select from "react-select";
import {
  Row,
  Col,
  Button,
} from "react-bootstrap";
import moment from "moment";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import PageNotFound from "../Reports/PageNotFound";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  getLoginUser, getReportFilter, getLoginUserPermission, getPmoReportData, pmoExportExcel, getReportFilterPmo, setAddClass
} from "../../Action";
import { connect } from "react-redux";
import * as constants from "../../Config/Constant";
import { getReportFilterService, getUserService } from "../../Utilities/Helper";
import { Dropdown } from "rsuite";
import { getBackEndConstant } from "../../Config/Constant";
import "rsuite/dist/rsuite.min.css";
import accessdenied from "../../access-denied.png";
var _ = require('lodash');

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  showCloseButton: true,
  customClass: {
    popup: "colored-toast",
    icon: "white-toast-icon",
  },
  timer: 3000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

class PmoMsReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      queryParams: new URLSearchParams(window.location.search),
      redirect: 0,
      current_report: Urls.reports_lists[9],
      userTypes: Urls.user_types,
      filter_start_date: new Date(),
      filter_end_date: new Date(),
      sname: [],
      sname1: [],
      report_practice: [],
      report_service: [],
      practiceHead: [],
      loginUserData: {},
      loginUserPermission: {},
      token: "",
      loading: false,
      start_month: moment().utcOffset("+05:30").format("YYYY-MM"),
      end_month: moment().utcOffset("+05:30").format("YYYY-MM"),
      bu_id: "",
      selectService: [],
      selectBu: [],
      selectBuFilter: [],
      reportList: [],
      serviceName: [],
      checkedList: [],
      reportF: 0,
      usersService: [],
      pmoReportData: [],
      totalAllocationValue: 0,
      pmoReportDataClone: [],
      report: {
        service: []
      },
      reportService: [],
      totalDevelopers: 0,
    };
  }

  reset_page = () => {
    this.setState({
      report: {
        service: [],
        practice: [],
      },
      selectService: [],
      searchBy: '',
      reportService: []
    }, () => {
      this.getPmoReportData(this.state);
    });
  };

  setStartDateForFilter = (e) => {
    let today = new Date(e);
    this.setState({
      filter_start_date: today,
    });
  };

  componentDidMount() {
    let token = this.state.queryParams.get("token") == null ? Cookies.get("auth_data") :
      this.state.queryParams.get("token");
    this.setState({
      token: token,
      apiLink: constants.API_LINK,
      angularUrl: constants.FRONT_LINK,
      excelUrl: constants.EXCEL_LINK
    });
    this.getUser();
  }

  /**
   * Get the login user details
   */
  getUser = () => {
    this.setState({ loading: true });
    this.props.getLoginUser().then((response) => {
      this.props.getLoginUserPermission().then((response) => {
        this.setState({
          loading: false, loginUserData: this.props.loginDetails.loginDetails,
          loginUserPermission: this.props.loginDetails.loginUserPermission
        });
        this.getPracticeService();
      });
    });
  }

  //filter practice based on service and head
  filterService = (e) => {
    this.state.searchBy = '';
    if (e) {
      let selectedOption = e;
      this.setState({
        report: {
          service: selectedOption,
        },
        selectService: selectedOption,
      }, () => {
        this.getPmoReportData(this.state);
      });
    }
    else {
      this.getPmoReportData();
    }

  };

  getPmoReportData = () => {
    let dataObj = this.getInputData();
    this.setState({ loading: true });
    this.props.getPmoReportData(dataObj).then((response) => {
      let sortReport = _.sortBy(this.props.pmoReportDetails.milestoneReport,
        [function (data) { return data.projectName; }]);
      this.setState({
        loading: false,
        pmoReportData: sortReport,
        pmoReportDataClone: sortReport,
        totalAllocationValue: this.props.pmoReportDetails.totalAllocationValue,
        totalDevelopers: this.props.pmoReportDetails.totalDevelopers
      });
    }, (error) => {
      this.setState({
        loading: false,
        pmoReportData: [],
        pmoReportDataClone: [],
        totalAllocationValue: 0,
        totalDevelopers: 0
      });
      this.ToastAlert("error", error);
    });


  };

  generatePmoMsReportAsExcel = (e) => {
    e.currentTarget.blur(); // removing focus
    let dataObj = this.getInputData();
    this.setState({ loading: true });
    this.props.pmoExportExcel(dataObj).then((response) => {
      this.setState({ loading: false });
      this.ToastAlert("success", "Report generated Successfully");
      document.location = this.state.excelUrl + this.props.pmoExport.filename;
    }, (error) => {
      this.setState({ loading: false });
      this.ToastAlert("error", error);
    });
  };

  getInputData = () => {
    let dataObj = {}, reportData = {};
    reportData.business_unit = this.state.bu_id;
    let ser = [];
    if (this.state.report.service.length > 0) {
      reportData.service = [this.state.report.service[0].id]
      this.state.report.service.forEach(svalue => {
        ser.push(svalue.id)
      })
      reportData.service = ser;
    }
    else {
      // getting user associated service list
      reportData.service = getReportFilterService(this.state.serviceName, this.state.loginUserData, this.state.usersService);
    }
    reportData.start_date = moment(this.state.filter_start_date)
      .utcOffset('+05:30')
      .format('YYYY-MM-01');
    reportData.end_date = moment(this.state.filter_end_date)
      .utcOffset('+05:30')
      .format('YYYY-MM-01');
    dataObj['reportData'] = reportData;
    return dataObj;
  }

  ToastAlert(icon, title) {
    return Toast.fire({
      target: document.getElementById("form-modal"),
      icon: `${icon}`,
      title: `${title}`,
    });
  }

  resetReport = (e) => {
    e.currentTarget.blur(); // removing focus
    this.setState({
      searchBy: '',
    });
    if (moment(this.state.filter_start_date)
      .utcOffset("+05:30")
      .format("YYYY-MM-01") >= getBackEndConstant().filterDate) {
      this.getPmoReportData()
    } else {
      this.getPracticeService();
    }
  };

  searchBy = (e) => {
    let searchInput = e.target.value;
    let filteredData = [];
    if (searchInput) {
      filteredData = this.state.pmoReportDataClone.filter((item) => {
        return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
      })
      this.setState({
        searchBy: searchInput
      });
    }
    else {
      this.setState({
        searchBy: ''
      });
      filteredData = this.state.pmoReportDataClone;
    }
    let totalMs = _.sumBy(filteredData, 'userMilestoneValue');
    let totalCount = _.sumBy(filteredData, 'resourceCount');
    totalCount = (totalCount == undefined) ? 0 : totalCount;
    this.setState({
      pmoReportData: filteredData,
      totalAllocationValue: totalMs,
      totalDevelopers: totalCount
    })
  }

  getPracticeService = (isAllPractice) => {
    this.setState({
      loading: true,
      isAllPractices: isAllPractice,
      filterService: [],
      report: {
        service: []
      },
      sname: [],
      allService: [],
      serviceName: []
    });
    let dateValue = new Date();
    let date = new Date(dateValue.getFullYear(), dateValue.getMonth(), 2);
    let start_date = this.state.filter_start_date
      ? this.state.filter_start_date
      : date;
    let end_date = this.state.filter_end_date
      ? this.state.filter_end_date
      : date;
    let dataObject = {};
    dataObject.start_date = start_date;
    dataObject.end_date = end_date;
    dataObject.filter = isAllPractice;

    let c = 0;
    this.props.getReportFilterPmo(dataObject).then((response) => {
      this.props.pmoFilter.directors.forEach((value) => {
        if (this.state.bu_id == value.bu_id) {
          c = 1;
        }
      });
      let bu_id = c == 1 ? this.state.bu_id : "";
      let selectBuFilter = c == 1 ? this.state.selectBuFilter : [];
      let sname = [];
      let allService = [], serviceName = [];
      if (
        this.state.bu_id != "" &&
        this.state.bu_id != undefined &&
        this.state.bu_id != 0
      ) {
        this.props.pmoFilter.services.forEach((service) => {
          if (this.state.bu_id == service.bu) {
            sname.push(service);
          }
        });
        this.props.pmoFilter.allservices.forEach((service) => {
          if (this.state.bu_id == service.bu) {
            allService.push(service);
          }
        });
      } else {
        sname = this.props.pmoFilter.services;
        allService = this.props.pmoFilter.allservices;
      }
      this.setState({
        usersService: this.props.pmoFilter.services,
        sname1: this.props.pmoFilter.services,
        bu_id: bu_id,
        selectBuFilter: selectBuFilter,
        sname: sname,
        allService: allService
      })
      this.state.sname.forEach((value) => {
        let opt = {};
        opt.label = value.value;
        opt.id = value.id;
        opt.value = value.value;
        opt.head = value.head;
        opt.bu = value.bu;
        serviceName.push(opt);
      });
      // for practicehead
      let practiceHead = [];
      this.props.pmoFilter.directors.forEach((id) => {
        practiceHead.push(id);
      });
      let buName = [];
      this.props.pmoFilter.directors.forEach((value) => {
        let opt = {};
        opt.label = value.bu;
        opt.value = value.bu;
        opt.bu_id = value.bu_id;
        opt.id = value.id;
        opt.bu = value.bu;
        opt.name = value.name;
        buName.push(opt);
      });
      this.setState({
        practiceHead: practiceHead,
        buName: buName,
        report_all_practices: this.props.pmoFilter.practices,
        qes_list: this.props.pmoFilter.qes_list,
        serviceName: serviceName
      });
      if (this.state.loginUserData.role_id == 8) {
        this.setState({
          searchByPracticeHead: this.state.loginUserData.role_id,
        })
      }
    }, (error) => {
      this.setState({
        usersService: [],
        sname1: [],
        bu_id: '',
        selectBuFilter: [],
        sname: [],
        allService: []
      })
    });
    this.getPmoReportData();
  };

  getReportByHead = (e) => {
    this.state.searchBy = '';
    if (e != undefined) {
      this.setState({
        bu_id: e.bu_id,
        head_id: e.label,
        selectBuFilter: e
      }, () => {
        this.getBuFilter(this.state);
      });
    } else {
      this.setState({
        bu_id: '',
        head_id: 'All',
        selectBuFilter: []
      }, () => {
        this.getBuFilter(this.state);
      });
    }
  };

  getBuFilter = () => {
    let serviceArray = [];
    let service_head = [];
    if (this.state.head_id != "All") {
      // for BU filter    
      this.state.sname1.forEach((service) => {
        if (this.state.bu_id == service.bu) {
          service_head.push(service);
        }
      });
      this.setState({
        serviceName: [],
        selectService: [],
        report: {
          service: []
        }
      })
    } else {
      // when All BU is selected
      this.setState({
        serviceName: [],
        selectService: [],
        selectBuFilter: []
      })
      if (this.state.report.service.length == 0) {
        this.state.sname1.forEach((service) => {
          service_head.push(service);
        });
      } else {
        service_head = this.state.sname;
        this.setState({
          report: {
            service: []
          }
        })
      }
    }
    service_head.forEach((value) => {
      let opt = {};
      opt.label = value.value;
      opt.id = value.id;
      opt.value = value.value;
      opt.head = value.head;
      opt.bu = value.bu;
      serviceArray.push(opt);
    });
    this.setState({
      serviceName: serviceArray,
    }, () => {
      this.getPmoReportData(this.state);
    });
  }
  /**
   * main render
   * @returns
   */
  render() {
    if (this.state.redirect == 1 || this.state.loginUserData == null) {
      return <PageNotFound url={this.state.angularUrl} />
    }
    else {
      return (
        <>
          <div className={"page-content px-4 " + (this.props.addClass ? "active" : "")}>
            <Row>
              <div className="col-xs-12 fourty-fluid fourty-fluid">
                <Row className="mb-3">
                  <div className="col-sm-4 col-sm-4 col-xs-12 text-left">
                    <h1 className="B-G-bold f-s-18 d-gray t-t-upper pull-left mt-10 mr-20 page-head">Reports</h1>
                  </div>
                  <div className="col-lg-3 col-sm-4 col-xs-12">
                    <div className="selectpicker_dropdown hide_cross hide_bar orange_arrow remove_border_btm select_dropdown_picker">
                      <Dropdown title={this.state.current_report.value} activeKey="10">
                        <Dropdown.Item as="a" eventKey="1" href="/pw_report" target="_blank">Practice Wise Allocation Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="2" href="/ms_report" target="_blank">Milestone Value Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="3" href="/collections_report" target="_blank">Collections Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="4" href="/collections_potential_report" target="_blank">Collection Potential Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="5" href="/inventory_report" target="_blank">Inventory Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="6" href="/inventory_allocation_report" target="_blank">Inventory Allocation Metrics</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="7" href="/inventory_milestone_report" target="_blank">Inventory Milestone Metrics</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="8" href="/utilization_report" target="_blank">Resource Utilization Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="9" href="/csm_report" target="_blank">Csm Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="10">Pmo Ms Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="11" href="/acv_report" target="_blank">Annual Contract Value Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="12" href="/ms-allocation-report" target="_blank">MS Allocation Report</Dropdown.Item>
                        <Dropdown.Item as="a" eventKey="13" href="/squad-review-report" target="_blank">Squad Review Report</Dropdown.Item>
                      </Dropdown>
                    </div>
                  </div>
                </Row>
                <Row>
                  <div className="col-lg-6 clearfix m-b-20">
                    <div className="row align-items-center">
                      <label class="f-s-12 text-muted pt-1 w-13 col-lg-2 col-md-6 col-sm-12 text-left">Line of Service :</label>
                      <div className="col-lg-7 col-sm-5 text-left ">
                        <Row>
                          <div className="col-lg-10 p-l-0">
                            <div className="selectpicker_dropdown w-100 hide_bar w-100 selectpicker_dropdown_orange">
                              <Select
                                className="basic-single-select"
                                classNamePrefix={"select_dropdown"}
                                placeholder="All"
                                isClearable
                                isSearchable={true}
                                onChange={(e) => this.filterService(e)}
                                value={this.state.selectService}
                                options={this.state.serviceName} isMulti>
                              </Select>
                            </div>
                          </div>
                          <div className="col-lg-2 p-1-0">
                            {this.state.report.service != undefined && this.state.report.service.length > 0 &&
                              <a href="javascript:void(0)" title="Reset" tooltip-placement="top" className="reset-icon_old  reset-color p-l-5 m-l-4" onClick={this.reset_page}><i class="fs-16 fa fa-refresh"></i></a>
                            }
                          </div>
                        </Row>
                      </div>
                    </div>
                  </div>
                  {this.state.loginUserData.role_id != 8 &&
                    <div className="col-lg-4 clearfix m-b-20">
                      <div className="row">
                        <div class="col-sm-5 col-xs-12">
                          <label className="text-uppercase m-b-0 fs-13 p-t-5">Business Unit :</label>
                        </div>
                        <div className="col-sm-7 col-xs-12">
                          <div className="selectpicker_dropdown hide_bar w-100 selectpicker_dropdown_orange">
                            <Select
                              className="basic-single-select"
                              classNamePrefix={"select_dropdown"}
                              placeholder="All"
                              isClearable
                              isSearchable={true}
                              onChange={(e) => this.getReportByHead(e)}
                              value={this.state.selectBuFilter}
                              options={this.state.buName} >
                            </Select>

                          </div>
                        </div>
                      </div>
                    </div>}
                  <div className="col-lg-2 col-sm-2 col-xs-12 justify-content-end text-end">
                    <a href={this.state.angularUrl} className="btn btn-primary">Back</a>
                  </div>
                </Row>
                <hr className="m-t-5 col-sm-12 m-b-8 p-0"></hr>
                {this.props.loginDetails.loginUserPermission && this.props.loginDetails.loginUserPermission.permission && this.props.loginDetails.loginUserPermission.permission.view_pmo_report &&
                  <>
                    <div className="col-lg-12 col-sm-12 col-xs-12 p-b-15-xs p-r-15-xs">
                      <Row className=" mt-3 mb-3">
                        <Col className="col-lg-8 text-start col p-0">
                          <Row className="m-0 mt-1">
                            <Col className="col-lg-2 text-start">
                              <label className="p-0 B-G-bold f-s-18 d-gray m-t-3 m-b-10 page-head pull-left">Month Filter:</label>
                            </Col>
                            <Col className="col-lg-1 px-0 general-content text-center">
                              <label className="pt-2">Month :</label>
                            </Col>
                            <Col className="col-lg-2">
                              <div className="row align-items-center mb-2 date_picker_align"  >
                                <DatePicker
                                  className="datepicker-control-section"
                                  onChange={(e) => this.setStartDateForFilter(e)}
                                  dateFormat="MM/yyyy"
                                  showMonthYearPicker
                                  todayButton={"Today"}
                                  name="start_date"
                                  selected={this.state.filter_start_date}
                                />
                                <span class="cal_icon_date" role="button" tabindex="0"> <i class="icon-calendar"></i></span>
                              </div>
                            </Col>
                            <Col className="col-lg-6 text-end">
                              <Button onClick={(e) => this.resetReport(e)} value="Generate" className="me-2" >Generate</Button>
                              <Button onClick={(e) => this.generatePmoMsReportAsExcel(e)} value="Excel" className="me-2" disabled={this.state.pmoReportData.length==0}>Export as Excel</Button>
                            </Col>
                          </Row>
                        </Col>
                        <Col className="col-lg-4 text-end">
                          <div className="form-group search-icon col-sm-12 col-xs-12">
                            <input type="text" name="project_filter" value={this.state.searchBy} className="form-control B-G-bold f-s-16 d-gray pl-30" placeholder="Search..." onChange={(e) => this.searchBy(e)} />
                            <i class="fa fa-search"></i>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <div className="center-block text-center">
                      <div className="col-lg-12 col-sm-12 col-xs-12 p-b-15-xs">
                        <div className="p-0 B-G-bold f-s-16 d-gray m-t-8 m-b-0 page-head">
                          <Row className="my-3">
                            <Col className="col-lg-5" style={{ "font-size": "15px" }}><div>Total MS Value: $ {this.state.totalAllocationValue}</div></Col>
                            <Col className="col-lg-6 text-right" style={{ "font-size": "15px" }}><div>Total Developers Count: {this.state.totalDevelopers}</div></Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                    <hr className="m-t-5 col-sm-12 m-b-8 p-0"></hr>

                    <div className="table-responsive white-table-class">
                      <div className="scrollable">
                        <div className="scrollable-content">
                          <table className="table m-b-0 table-wrap">
                            <thead>
                              <tr className="theadInner busi_unit_bg">
                                <th className="text-start" style={{ "width": "20%" }}>Project Name</th>
                                <th className="text-start" style={{ "width": "20%" }}>Business Unit</th>
                                <th className="text-start" style={{ "width": "15%" }}>Line of Service</th>
                                <th className="text-start" style={{ "width": "15%" }}>Practice</th>
                                <th className="text-start" style={{ "width": "15%" }}>Developers Count</th>
                                <th className="text-start" style={{ "width": "15%" }}>Milestone Value</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.loading &&
                                <div id="preloader">
                                  <div id="status"></div>
                                </div>
                              }
                              {!this.state.loading &&
                                this.state.pmoReportData.length == 0 &&
                                <tr>
                                  <td colSpan="8" className="r-no-data text-center">No data available</td>
                                </tr>
                              }
                              {!this.state.loading && this.state.pmoReportData.length > 0 && this.state.pmoReportData.map((item) => (
                                <tr>
                                  <td className="text-start">
                                    <span className="name p-l-5 pmo-truncate p-t-3 fs-13" style={{ "maxWidth": "200px", "textAlign": "left" }} title={`${item.projectName}`}>{item.projectName}</span>
                                  </td>
                                  <td className="text-start">
                                    <span>{item.buName} </span>
                                  </td>
                                  <td className="text-start">
                                    <span>{item.serviceName} </span>
                                  </td>
                                  <td className="text-start">
                                    <span>{item.practiceName} </span>
                                  </td>
                                  <td className="text-start">
                                    <span> {item.resourceCount && item.resourceCount || 'kickoff'} </span>
                                  </td>
                                  <td className="text-start">
                                    <span>$ {item.userMilestoneValue} </span>
                                  </td>
                                </tr>
                              ))}</tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </>
                }
                {this.props.loginDetails.loginUserPermission && this.props.loginDetails.loginUserPermission.permission && !this.props.loginDetails.loginUserPermission.permission.view_pmo_report &&
                  <div className={"page-content px-4 " + (this.props.addClass ? "active" : "")}>
                    <div className="col-md-12 m-b-30">
                      <div className="four_not_err access_denied">
                        <div className="for_content">
                          <img alt="sad" width="" src={accessdenied} />
                          <h1>You don't have permission to access this page</h1>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </Row>
          </div>
        </>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    pmoReportDetails: state.pmoReportDetails.pmoReport,
    pmoExport: state.pmoReportDetails.pmoExport,
    loginDetails: state.loginDetails,
    reportFilter: state.reportFilterDetails.reportFilter,
    pmoFilter: state.reportFilterDetails.pmoFilter,
    addClass: state.pmsDetails.addClass,
  };
};

const mapDispatchToProps = {
  getPmoReportData,
  pmoExportExcel,
  getLoginUser,
  getReportFilter,
  getLoginUserPermission,
  getReportFilterPmo,
  setAddClass
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PmoMsReport);

import { postData,getData } from "../Services/Helper";
import { handleResponse } from "../Services/HandleResponse";
import { getBackEndConstant } from "../Config/Constant";
import * as types from "../Utilities/Type";

export function getChapterReviewInference(dataObj) {
    return (dispatch) => {
        const requestOptions = postData(dataObj);
        let url = getBackEndConstant().backend_url + "get_chapter_review_inference";
        return fetch(url, requestOptions)
        .then((response) => handleResponse(response))
        .then((response) => {
            dispatch({
            type: types.GET_CHAPTER_INFERENCE,
            payload: (response.result) ? response.result : [],
            });
        });
    };
}

export function saveChapterAnswer(dataObj) {
    return (dispatch) => {
      const requestOptions = postData(dataObj);
      let url = getBackEndConstant().backend_url + "save_chapter_review";
      return fetch(url, requestOptions)
        .then((response) => handleResponse(response))
        .then((response) => {
          dispatch({
            type: types.SAVE_CHAPTER_ANSWER,
            payload: response.result ? response.result : {},
          });
          return response;
        });
    };
}

export function savePulseScore(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "save_pulse_score";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.SAVE_PULSE_SCORE,
          payload: response.result ? response.result : {},
        });
        return response;
      });
  };
}

export function getPulseScore(dataObj) {
  return (dispatch) => {
      const requestOptions = postData(dataObj);
      let url = getBackEndConstant().backend_url + "get_pulse_score";
      return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
          dispatch({
          type: types.GET_PULSE_SCORE,
          payload: (response.result) ? response.result : [],
          });
      });
  };
}

export function saveCompetencyIndex(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "save_competency_index";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.SAVE_COMPETENCY_INDEX,
          payload: response.result ? response.result : {},
        });
        return response;
      });
  };
}

export function getCompetencyIndex(dataObj) {
  return (dispatch) => {
      const requestOptions = postData(dataObj);
      let url = getBackEndConstant().backend_url + "get_competency_index";
      return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
          dispatch({
          type: types.GET_COMPETENCY_INDEX,
          payload: (response.result) ? response.result : [],
          });
          return response;
      });
  };
}

export function getPip(dataObj) {
  return (dispatch) => {
      const requestOptions = postData(dataObj);
      let url = getBackEndConstant().backend_url + "get_pip";
      return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
          dispatch({
          type: types.GET_PIP,
          payload: (response.result) ? response.result : [],
          });
      });
  };
}

export function getAttrition(dataObj) {
  return (dispatch) => {
      const requestOptions = postData(dataObj);
      let url = getBackEndConstant().backend_url + "get_attrition";
      return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
          dispatch({
          type: types.GET_ATTRITION,
          payload: (response.result) ? response.result : [],
          });
      });
  };
}

export function getRecruitment(dataObj) {
  return (dispatch) => {
      const requestOptions = postData(dataObj);
      let url = getBackEndConstant().backend_url + "get_recruitment";
      return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
          dispatch({
          type: types.GET_RECRUITMENT,
          payload: (response.result) ? response.result : [],
          });
      });
  };
}

export function saveReserveActivity(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "save_reserve_activity";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.SAVE_RESERVE_ACTIVITY,
          payload: response.result ? response.result : {},
        });
        return response;
      });
  };
}

export function getReserveActivity(dataObj) {
  return (dispatch) => {
      const requestOptions = postData(dataObj);
      let url = getBackEndConstant().backend_url + "get_reserve_activity";
      return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
          dispatch({
          type: types.GET_RESERVE_ACTIVITY,
          payload: (response.result) ? response.result : [],
          });
      });
  };
}

export function saveChapterReviewData(dataObj) {
  return (dispatch) => {
    const requestOptions = postData(dataObj);
    let url = getBackEndConstant().backend_url + "save_chapter_review_data";
    return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
        dispatch({
          type: types.SAVE_CHAPTER_REVIEW,
          payload: response.result ? response.result : {},
        });
        return response;
      });
  };
}

export function getHitsMisses(dataObj) {
  return (dispatch) => {
      const requestOptions = postData(dataObj);
      let url = getBackEndConstant().backend_url + "get_hits_misses";
      return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
          dispatch({
          type: types.GET_HITS_MISSES,
          payload: (response.result) ? response.result : [],
          });
      });
  };
}
export function getIcr(dataObj) {
  return (dispatch) => {
      const requestOptions = postData(dataObj);
      let url = getBackEndConstant().backend_url + "get_icr";
      return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
          dispatch({
          type: types.GET_ICR,
          payload: (response.result) ? response.result : [],
          });
      });
  };
}
export function getActionItems(dataObj) {
  return (dispatch) => {
      const requestOptions = postData(dataObj);
      let url = getBackEndConstant().backend_url + "get_action_items";
      return fetch(url, requestOptions)
      .then((response) => handleResponse(response))
      .then((response) => {
          dispatch({
          type: types.GET_ACTION_ITEMS,
          payload: (response.result) ? response.result : [],
          });
      });
  };
}
import React, { Component, useEffect, useState } from 'react';
import { Urls } from '../../Config/Config';
import { Link } from 'react-router-dom';
import './Report.css';
import Select from 'react-select';
import ReactDatePicker from 'react-datepicker';
import { Grid, Row, Col, Button, Form, Spinner, Accordion } from 'react-bootstrap';
import Datepicker from "react-datepicker";
import moment from 'moment';
import Swal from "sweetalert2";
import Cookies from 'js-cookie';
import PW_report from './PW_report';
import PageNotFound from './PageNotFound';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import MileStoneReport from './msreport';
import exchange from '../../exchange.png';
import CollectionPotentialReport from './Collection_potential_report';
import CollectionsReport from './Collections_report';
import InventoryReport from './InventoryReport';
import InventoryMilestoneReport from './Inventory_milestone_report';
import ResourceUtilizationReport from '../UtilizationComponent/ResourceUtilizationReport';
import CsmReport from '../CsmReportComponent/CsmReport';
import { getUserService, getReportFilterService } from "../../Utilities/Helper";
import { Dropdown } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { setAddClass } from "../../Action";
import { connect } from "react-redux";
var _ = require("lodash");

const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    showCloseButton: true,
    customClass: {
        popup: 'colored-toast',
        icon: 'white-toast-icon'
    },
    timer: 3000,
    onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});

class InventoryAllocationReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            queryParams: new URLSearchParams(window.location.search),
            selected: '',
            setSelected: '',
            redirectUrl: '',
            redirect: 0,
            user_types: Urls.user_types,
            active: false,
            all_active: true,
            reports_lists: Urls.reports_lists,
            reports_lists1: Urls.reports_lists1,
            current_report: Urls.reports_lists[5],
            report: {},
            projectPractice: [],
            project_practice: [],
            isAllPractices: false,
            getUserscount: '',
            practiceleadName: '',
            report_practice: [],
            gridListBackup: [],
            gridList: [],
            planned_hrs: 0,
            support_hrs: 0,
            unallocated_hrs: 0,
            effective_yield: 0,
            expected_yield: 0,
            gap_in_allocation: 0,
            deficit: 0,
            userTypeId: 0,
            totalResAvHrCal: 0,
            msAlloc: 0,
            supphrs: 0,
            totalResourceAvailHrsFrontendCalcution: 0,
            msAllocatedHrs: 0,
            supportHrs: 0,
            benchHrs: 0,
            userTypes: Urls.user_types,
            isGanttView: false,
            updateLoader: false,
            current_Gant_popover: {},
            head_id: '',
            // for inventory report
            support_practice: [6, 12, 13, 21],
            inventory_isDetailedView: true,
            isAllPractices_inventory: false,
            searchByPracticeHead: '',
            // for TL and BA    
            techLeadList: [],
            businessAnalystList: [],
            techLeadId: '',
            businessAnalystId: '',
            searchByTechLead: '',
            searchByBusinessAnalyst: '',
            searchBy: '',
            isChecked: false,
            // for milestone report    
            myPracticeMileStone: 0,
            allPracticeMileStone: 0,
            mileStoneValueReport: {},
            filteredMilestoneData: [],
            mileStoneReportPractice: 1,
            filterByPractice: '',
            filterMileStoneValue: '',
            milestone_achieved_dropdown_value: Urls.milestone_achieved_dropdown_value,
            invoice_dropdown_value: Urls.milestone_achieved_dropdown_value,
            // for collection-potential report    
            currentFilter: { 'id': 0, 'value': 'All' },
            filterNotSure: '',
            true_data: [],
            checked_data: {},
            sumoffilterMileStoneValue: 0,
            SumofMileStoneValue: 0,
            collection_potential_invoicestatus: Urls.invoice_status,
            return_to_pwreport: 0,
            resource_types: Urls.resource_types,
            resource_types_new: Urls.resource_types_new,
            // resourceFilter : {'id':0, 'value':'All'},
            resourceFilter: 'All',
            resourceAllFilter: 'All',
            filter_start_date: new Date(),
            // filter_start_date : '2022-01-01',
            filter_end_date: new Date(),
            sname: [],
            sname1: [],
            report_practice: [],
            report_service: [],
            projectPractice: [],
            practiceHead: [],
            totalMileStoneValue: 0,
            totalKickoffValue: 0,
            totalCarryValue: 0,
            filterMileStoneValue: 0,
            filteredMilestoneData: [],
            milestoneReport: [],
            mileStoneValueReport: {},
            submitted: false,
            practiceReport: [],
            overallMileStoneValue: 0,
            sumOfNotSureValue: 0,
            notUpdatedValue: 0,
            totalNewValue: 0,
            totalRaisedValue: 0,
            totalCollectedValue: 0,
            totalPendingValue: 0,
            mileStoneReportMonth: '',
            mileStoneReportYear: '',
            myPracticeMileStone: 0,
            allPracticeMileStone: 0,
            filter: [],
            filterByPractice: [],
            filterMileStoneValue: 0,
            filterNotSure: 0,
            filterNotUpdated: 0,
            filterNewValue: 0,
            filterRaisedValue: 0,
            filterCollectedValue: 0,
            filterPendingValue: 0,
            selectedInvoiceStatus: 'All',
            selectedInvoice: 'All',
            loginUserData: {},
            token: "",
            // token : JSON.parse(Cookies.get('globals')).currentUser.authdata,
            loading: false,
            start_month: moment()
                .utcOffset('+05:30')
                .format('YYYY-MM'),
            end_month: moment()
                .utcOffset('+05:30')
                .format('YYYY-MM'),
            project_name_filter: '',
            bu_id: '',
            utilizationCounts: {},
            yieldCounts: {},
            reportPrac: [],
            practice_length: 0,
            selectService: [],
            selectBu: [],
            selectBuFilter: [],
            reportList: [],
            serviceName: [],
            monthSeries: [],
            moolahDeficit: 0,
            boolahDeficit: 0,
            bcDeficit: 0,
            vendorDeficit: 0,
            externDeficit: 0,
            checkedList: [],
            start: new Date(),
            reportF: 0,
            inventoryReportList: [],
            inventorybu_data: [],
            bu_qes: Urls.bu_qes,
            practice_qes: Urls.practice_qes,
            service_qes: Urls.service_qes,
            buName: [],
            usersService: []
        }

    }

    reset_page = () => {
        this.state.report.service = [];
        this.state.report.practice = [];
        this.state.selectService = [];
        this.generateInventory();
    }

    setStartDateForFilter = (e) =>{
        this.state.start_month = (e)
        var today = new Date(e);  
        this.state.filter_start_date =today;
        this.setState({
            filter_start_date : this.state.filter_start_date,
        });
        if(this.state.filter_start_date > this.state.filter_end_date){
            this.state.filter_end_date = today;
            this.ToastAlert("warning", "End date should greater than start date");
        }   
    }

    setEndDateForFilter = (e) => {
        this.state.end_month = (e)
        var today = new Date(e);
        this.setState({
            filter_end_date: today
        })
    }

    componentDidMount() {
        this.state.reports_lists.forEach(value => {
            var opt = {};
            opt.label = value.value;
            opt.id = value.id;
            opt.value = value.value;
            this.state.reportList.push(opt);
        })
        this.state.current_report = this.state.reportList[5];
        this.state.token = (this.state.queryParams.get('token') == null) ? Cookies.get('auth_data') : this.state.queryParams.get('token');
        this.state.url = (this.state.queryParams.get('url') == null) ? Cookies.get('url_code') : this.state.queryParams.get('url');
        if (atob(this.state.url) === 'localhost') {
            this.state.apiLink = 'http://127.0.0.1:8000/api/'
            this.state.angularUrl = 'http://localhost:22400/#!/'
            this.state.excelUrl = "http://localhost:8000/"
        }
        else if (atob(this.state.url) === 'staging') {
            this.state.apiLink = 'https://reflections-staging.optisolbusiness.com/api/'
            this.state.angularUrl = 'https://reflectionsstaging.optisolbusiness.com/#!/'
            this.state.excelUrl = "https://reflections-staging.optisolbusiness.com/"
        }
        else if (atob(this.state.url) === 'uat') {
            // this.state.reportList = [];
            this.state.apiLink = 'https://reflectionsservicestg.optisolbusiness.com/api/'
            this.state.angularUrl = 'https://reflectionsstg.optisolbusiness.com/#!/'
            this.state.excelUrl = "https://reflectionsservicestg.optisolbusiness.com/"
            // this.state.reports_lists1.forEach(value=>{
            //     var opt ={};
            //    opt.label = value.value;
            //    opt.id = value.id;        
            //    opt.value = value.value;
            //    this.state.reportList.push(opt);
            // })
        }
        else if (atob(this.state.url) === 'live') {
            // this.state.reportList = [];
            this.state.apiLink = 'https://reflectionsservice.optisolbusiness.com/api/'
            this.state.angularUrl = 'https://reflections.optisolbusiness.com//#!/'
            this.state.excelUrl = "https://reflectionsservice.optisolbusiness.com/"
            // this.state.reports_lists1.forEach(value=>{
            //     var opt ={};
            //    opt.label = value.value;
            //    opt.id = value.id;        
            //    opt.value = value.value;
            //    this.state.reportList.push(opt);
            // })
        }
        else {
            // do nothing
        }

        this.setState({
            token: this.state.token
        })
        this.getUser();
        this.getPracticeService();
    }

    getUser = () => {
        var link = this.state.apiLink + 'get_login_user';

        if (this.state.token == null) {
            this.state.token = Cookies.get('auth_data');
        }
        if (this.state.token == 'undefined') {
            this.state.redirect = 1;
        }
        else {
            return fetch(link, {
                method: "GET",

                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": "Bearer " + this.state.token
                }
            }).then(res => res.json()).then(res => {
                this.state.loginUserData = res.result;

            })
        }
    }

    selectFilter = (e, id) => {
        //this.state.currentFilter = e.target.value;
        this.state.selectedInvoiceStatus = e.target.value;
        this.setState({
            selectedInvoiceStatus: this.state.selectedInvoiceStatus
        })
        this.generateInventory(true);
    }


    getPracticeService = (e, isAllPractice) => {
        this.state.isAllPractices = isAllPractice;
        console.log(this.state.isAllPractices)
        this.setState({ loading: true });
        this.state.filterService = [];
        var date = new Date();
        var date = new Date(date.getFullYear(), date.getMonth(), 2);
        var start_date = (this.state.filter_start_date) ? this.state.filter_start_date : date;
        var end_date = (this.state.filter_end_date) ? this.state.filter_end_date : date;
        // this.state.report.service = [];
        var isAllPractices = isAllPractice;
        var link = this.state.apiLink + 'get_report_filters_new';
        this.state.sname = [];
        this.state.allService = [];
        this.state.serviceName = [];
        if (this.state.token == null) {
            this.state.token = Cookies.get('auth_data');
        }
        if (this.state.token == 'undefined') {
            this.state.redirect = 1;
        }
        else {
            return fetch(link, {
                method: "POST",
                body: JSON.stringify({
                    start_date,
                    end_date,
                    filter: isAllPractice
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": "Bearer " + this.state.token
                }
            }).then(res => res.json()).then(res => {
                this.setState({
                    loading: false,
                    // sname : res.result.services,
                    // sname1 : res.result.services,
                    // allService : res.result.allservices,
                })

                console.log(this.state.head_id)
                var c = 0;
                res.result.directors.forEach(value => {
                    if (this.state.bu_id == value.bu_id) {
                        c = 1;
                    }
                })
                this.state.bu_id = (c == 1) ? this.state.bu_id : '';
                this.state.selectBuFilter = (c == 1) ? this.state.selectBuFilter : [];
                if (this.state.bu_id != '' && this.state.bu_id != undefined && this.state.bu_id != 0) {
                    res.result.services.forEach(service => {
                        if (this.state.bu_id == service.bu) {
                            this.state.sname.push(service);
                        }
                    });
                    res.result.allservices.forEach(service => {
                        if (this.state.bu_id == service.bu) {
                            this.state.allService.push(service);
                        }
                    });
                }
                else {
                    this.state.sname = res.result.services;
                    this.state.allService = res.result.allservices;
                }
                this.state.sname1 = res.result.services;
                this.state.usersService = res.result.usersService;
                this.state.report.project_practice = [];
                console.log(this.state.bu_id)
                this.state.sname.forEach(value => {
                    var opt = {};
                    opt.label = value.value;
                    opt.id = value.id;
                    opt.value = value.value;
                    opt.head = value.head;
                    opt.bu = value.bu;
                    this.state.serviceName.push(opt);
                })
                if (isAllPractice) {
                    this.state.selectService = [];
                    this.state.report.service = [];
                    this.state.report.practice = [];
                    this.state.projectPractice = [];
                    // this.state.selectBuFilter = [];
                    this.state.head_id = 'All';
                    // this.state.bu_id = '';
                    this.state.selectBU = [];
                    res.result.practices.forEach(pvalue => {
                        this.state.projectPractice.push(pvalue)
                    })

                }
                else {
                    var count = 0;
                    console.log(this.state.report.service)
                    if (this.state.report.service) {
                        this.state.report.service.forEach(value => {
                            this.state.sname.forEach(svalue => {
                                if (svalue.id == value.id) {
                                    count = 1;
                                }
                            })
                        })
                        if (count == 0) {
                            this.state.report.service = [];
                            let service = getUserService(this.state.serviceName, this.state.loginUserData, this.state.filter_start_date);
                            this.state.report.service.push(service);
                            this.state.selectService = service;
                        }
                    }
                    else {
                        this.state.report.service = [];
                        let service = getUserService(this.state.serviceName, this.state.loginUserData, this.state.filter_start_date);
                        this.state.report.service.push(service);
                        this.state.selectService = service;
                    }
                }
                this.setState({
                    report: {
                        service: this.state.report.service,
                        practice: this.state.report.practice
                    },
                    //selectService : this.state.selectService
                })
                var service1 = [];
                service1.push(this.state.sname[0]);
                this.state.qes_list = res.result.qes_list;
                var head = {};

                this.setState({
                    report_all_practices: res.result.practices,
                    practiceHead: this.state.practiceHead
                })
                // for practicehead      
                res.result.directors.forEach(id => {
                    this.state.practiceHead.push(id)
                });

                var buName = [];
                res.result.directors.forEach(value => {
                    var opt = {};
                    opt.label = value.bu;
                    opt.value = value.bu;
                    opt.bu_id = value.bu_id;
                    opt.id = value.id;
                    opt.bu = value.bu;
                    opt.name = value.name;
                    buName.push(opt);
                })
                this.setState({
                    practiceHead: this.state.practiceHead,
                    buName: buName
                })
                // // this.state.practiceHeadId = this.state.practiceHead[1]['id'];
                if (this.state.loginUserData.role_id == 8) {
                    this.state.searchByPracticeHead = this.state.loginUserData.role_id;
                } else {
                    this.state.searchByPracticeHead = this.state.searchByPracticeHead;
                }

                if (!this.state.isAllPractices) {
                    // this.filterService('');
                }
                this.changeViewPracite(this.state.isAllPractices);
            })
        }

    }

    //filter practice based on service and head
    filterService = (e) => {
        if (e) {
            let selectedOption = (e);
            this.state.selectService = selectedOption;
            this.state.report.service = selectedOption;
        }
        this.setState({
            report: {
                service: this.state.report.service
            }
        })
        var ser = [];
        var report = [];
        if (this.state.reports_lists[5].value === this.state.current_report.value) {
            // if(this.state.reports_lists[1].value === 'Milestone Value Report'){
            this.state.report.practice = [];
            this.state.projectPractice = [];
            this.state.report_all_practices.forEach(value => {
                value.is_active = false;
            })
            if (this.state.isAllPractices) {
                this.state.projectPractice = this.state.report_all_practices;
            } else {

                var service = (this.state.report.service);
                this.state.projectPractice = [];
                if (service.length == 0) {
                    this.state.projectPractice = [];
                }
                else {
                    var proj_practice = [];
                    service.forEach(svalue => {
                        this.state.report_all_practices.forEach(value => {
                            if (svalue.id == value.serviceId) {
                                this.state.projectPractice.push(value);
                            }
                        })
                    })
                }
            }
            this.setState({
                projectPractice: this.state.projectPractice
            })
            // for sending all practice while reset
            if (this.state.projectPractice.length > 0) {
                this.state.projectPractice.forEach(value => {
                    this.state.report.practice.push(value.id);
                });
            }
            this.setState({
                report: {
                    practice: this.state.report.practice,
                    service: this.state.report.service
                },
                // report_service:this.state.report_service
            })

            this.generateInventory(true);
        }

    }

    changeViewPracite = (flag) => {
        if (this.state.loginUserData.role_id == 8 && this.state.isAllPractices) {
            this.state.isAllPractices = flag;
        }
        else {
            this.state.isAllPractices = flag;
        }
        this.selectReport(this.state.current_report);
    }

    selectReport = (report) => {
        this.state.previous_report = this.state.current_report;
        this.state.current_report = report;
        this.state.project_filter = {};
        this.state.searchBy = '$';
        var date = new Date();
        var date = new Date(date.getFullYear(), date.getMonth(), 2);
        this.state.filter_end_date = (this.state.filter_end_date) ? this.state.filter_end_date : date;
        this.state.filter_start_date = (this.state.filter_start_date) ? this.state.filter_start_date : date;
        this.state.report.practice = (this.state.report.practice) ? this.state.report.practice : [];
        this.state.report.head_id = '';
        this.generateInventory(true);
    }

    changeViewPracite_inventory = (e, flag) => {
        if (this.state.filter_start_date > this.state.filter_end_date) {
            this.state.submitted = true;
            return false;
        }
        if (this.state.loginUserData.role_id == 8 && this.state.isAllPractices_inventory) {
            this.state.isAllPractices_inventory = flag;
        }
        else {
            this.state.isAllPractices_inventory = flag;
        }
        this.state.userPracticesList = [];
        if (this.state.isAllPractices_inventory) {
            if (this.state.report_all_practices != undefined) {
                this.state.report_all_practices.forEach(value_practice => {
                    this.state.userPracticesList.push(value_practice.id);
                });
            }
        }
        // this.setState({
        //     isAllPractices_inventory : this.state.isAllPractices_inventory
        // })
        if (this.state.report_all_practices != undefined) {
            this.getPracticeService('', flag);
        }

    }

    generateInventory = (valid) => {
        this.setState({ loading: true });
        var link = this.state.apiLink + 'get_inventory_data';

        var invoice_status = {};
        this.state.invoice_dropdown_value.forEach(invoice => {
            if (invoice.value == this.state.selectedInvoiceStatus) {
                invoice_status.id = invoice.id
                invoice_status.value = invoice.value
            }
        })
        var services = [];
        services.push(this.state.report.service);
        if (this.state.report.service.length == 0) { // getting user associated service list
            this.state.report.service = getReportFilterService(this.state.serviceName, this.state.loginUserData, this.state.usersService);
        }
        if (this.state.token == null) {
            this.state.token = Cookies.get('auth_data');
        }
        var dataObj = {};
        dataObj.allpractice = this.state.isAllPractices_inventory;
        dataObj.service = this.state.report.service;
        if ((!dataObj.allpractice) || (dataObj.service.length > 0)) {
            if (dataObj.service.length == 0) {
                dataObj.business_unit = this.state.searchByPracticeHead;
            } else if (dataObj.allpractice == true) {
                dataObj.business_unit = this.state.searchByPracticeHead;
            } else {
                dataObj.business_unit = '';
            }
        } else {
            dataObj.business_unit = this.state.searchByPracticeHead;
        }
        dataObj.business_unit = (this.state.bu_id != null && this.state.bu_id != undefined) ? this.state.bu_id : dataObj.business_unit;
        dataObj.start_date = this.state.filter_start_date;
        dataObj.end_date = this.state.filter_end_date;
        dataObj.current_report = this.state.current_report;
        var resourceFilter = {};
        this.state.resource_types.forEach(user => {
            if (user.value == this.state.resourceFilter) {
                resourceFilter.id = user.id
                resourceFilter.value = user.value
            }
        })
        dataObj.selectedUserType = resourceFilter;
        var reportData = dataObj
        return fetch(link, {
            method: "POST",
            body: JSON.stringify({
                reportData
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Authorization": "Bearer " + this.state.token
            }
        }).then(res => res.json()).then(res => {
            this.state.inventoryReportList = [];
            this.state.inventorybu_data = [];
            this.setState({ loading: false });
            if (this.state.current_report.id == 6) {
                Object.values(res.result.report.busi_array).forEach(reportValue => {
                    this.state.inventorybu_data.push(reportValue);
                })
                Object.values(res.result.report.service_array).forEach(reportValue => {
                    this.state.inventoryReportList.push(reportValue);
                })
                this.state.inventorybu_data.forEach(reportValue => {
                    reportValue.open = true;
                });
                this.state.inventoryReportList.forEach(reportValue => {
                    reportValue.open = true;
                });
            }
            if (dataObj.allpractice == true) {
                this.state.searchByPracticeHead = '';
            } else {
                if (dataObj.service.length == 0) {
                    this.state.searchByPracticeHead = dataObj.practice_head;
                } else {
                    this.state.searchByPracticeHead = '';
                }
            }
            this.setState({
                inventoryReportList: this.state.inventoryReportList,
                inventorybu_data: this.state.inventorybu_data,
                totalResourceAvailHrs: res.result.totalResourceAvailHrs,
            })
            this.state.inventoryReport = res.result.report;
            this.state.totalResourceAvailHrs = res.result.totalResourceAvailHrs;
            this.state.totalMileStoneHrs = res.result.totalMileStoneHrs;
            this.state.totalSupportHrs = res.result.totalSupportHrs;
            this.state.totalBenchHrs = res.result.totalBenchHrs;
            this.state.totalMoolahMileStoneHrs = res.result.totalMoolahMileStoneHrs;
            this.state.totalBoolahMileStoneHrs = res.result.totalBoolahMileStoneHrs;
            this.state.totalSupportUserMileStoneHrs = res.result.totalSupportUserMileStoneHrs;
            this.state.totalVendorMileStoneHrs = res.result.totalVendorMileStoneHrs;
            this.state.totalUiMileStoneHrs = res.result.totalUiMileStoneHrs;
            this.state.totalExternMileStoneHrs = res.result.totalExternMileStoneHrs;

            this.state.totalMoolahSuppportHrs = res.result.totalMoolahSuppportHrs;
            this.state.totalBoolahSuppportHrs = res.result.totalBoolahSuppportHrs;
            this.state.totalSupportUserSupportHrs = res.result.totalSupportUserSupportHrs;
            this.state.totalVendorSupportHrs = res.result.totalVendorSupportHrs;
            this.state.totalUiSupportHrs = res.result.totalUiSupportHrs;
            this.state.totalExternSupportHrs = res.result.totalExternSupportHrs;

            this.state.totalMoolahBenchHrs = res.result.totalMoolahBenchHrs;
            this.state.totalBoolahBenchHrs = res.result.totalBoolahBenchHrs;
            this.state.totalSupportUserBenchHrs = res.result.totalSupportUserBenchHrs;
            this.state.totalVendorBenchHrs = res.result.totalVendorBenchHrs;
            this.state.totalUiBenchHrs = res.result.totalUiBenchHrs;
            this.state.totalExternBenchHrs = res.result.totalExternBenchHrs;

            this.state.totalExpectYield = res.result.totalExpectYield;
            this.state.totalAllottedMSvalue = res.result.totalAllottedMSvalue;
            this.state.totalMoolahAllottedMSvalue = res.result.totalMoolahAllottedMSvalue;
            this.state.totalBoolahAllottedMSvalue = res.result.totalBoolahAllottedMSvalue;
            this.state.totalVendorAllocatedMSvalue = res.result.totalVendorAllocatedMSvalue;
            this.state.totalUiAllocatedMSvalue = res.result.totalUiAllocatedMSvalue;
            this.state.totalExternAllocatedMSvalue = res.result.totalExternAllocatedMSvalue;

            this.state.totalAchievedMSValue = res.result.totalAchievedMSValue;
            this.state.totalMoolahAchievedMSValue = res.result.totalMoolahAchievedMSValue;
            this.state.totalBoolahAchievedMSValue = res.result.totalBoolahAchievedMSValue;
            this.state.totalVendorAcheivedMSvalue = res.result.totalVendorAcheivedMSvalue;
            this.state.totalUiAcheivedMSvalue = res.result.totalUiAcheivedMSvalue;
            this.state.totalExternAcheivedMSvalue = res.result.totalExternAcheivedMSvalue;

            this.state.totalMoolahSupportMSValue = res.result.totalMoolahSupportMSValue;
            this.state.totalBoolahSupportMSValue = res.result.totalBoolahSupportMSValue;
            this.state.totalVendorSupportMSvalue = res.result.totalVendorSupportMSvalue;
            this.state.totalUiSupportMSvalue = res.result.totalUiSupportMSvalue;
            this.state.totalUiSupportMSvalue = res.result.totalUiSupportMSvalue;
            this.state.totalMoolahSurplusAmount = res.result.totalMoolahSurplusAmount;
            this.state.totalExternSupportMSvalue = res.result.totalExternSupportMSvalue;

            if (this.state.inventoryReportList.length > 0) {
                this.state.showNodata = false;
                var arr = [];
                var reportList = this.state.inventoryReportList;
                reportList.forEach(function (entry) {
                    var uid = entry.user_id;
                    if (arr.indexOf(uid) == -1) {
                        arr.push(uid);
                    }
                });
                this.state.getUserscount = arr;
            } else {
                this.state.showNodata = true;
            }
        })

    }

    getReportByHead = (e) => {
        if (e != undefined) {
            //  e.selected = true;
            this.state.bu_id = e.bu_id;
            this.state.head_id = e.label
            this.state.selectBuFilter = e;
        }
        else {
            this.state.head_id = 'All';
            this.state.bu_id = '';
            this.state.selectBuFilter = [];
        }

        if (this.state.head_id != 'All') { // for BU filter
            this.state.report.project_practice = [];
            var service_head = [];

            this.state.report.service = [];
            this.state.report.service = [];
            this.state.sname1.forEach(service => {
                if (this.state.bu_id == service.bu) {
                    service_head.push(service);

                }
            })
            this.state.serviceName = [];
            this.state.selectService = [];
            service_head.forEach(value => {
                var opt = {};
                opt.label = value.value;
                opt.id = value.id;
                opt.value = value.value;
                opt.head = value.head;
                opt.bu = value.bu;
                this.state.serviceName.push(opt);
            })
            this.state.selectService = [];

            var service = this.state.report.service;
            if (service.length == 0) {
                this.state.projectPractice = [];
                this.state.report.practice = [];
            }
            else {
                service.forEach(svalue => {
                    this.state.report_all_practices.forEach(value => {
                        if (svalue.id == value.serviceId) {
                            this.state.projectPractice.push(value);
                        }
                    })
                })
                if (this.state.projectPractice.length > 0) {
                    this.state.projectPractice.forEach(value => {
                        this.state.report.practice.push(value.id);
                    });
                }
            }
        } else { // when All BU is selected	
            var service_head = [];
            this.state.selectBuFilter = [];
            if (this.state.report.service.length == 0) {
                //nothing
                this.state.sname1.forEach(service => {
                    service_head.push(service);
                })
                this.state.serviceName = [];
                this.state.selectService = [];
                service_head.forEach(value => {
                    var opt = {};
                    opt.label = value.value;
                    opt.id = value.id;
                    opt.value = value.value;
                    opt.head = value.head;
                    opt.bu = value.bu;
                    this.state.serviceName.push(opt);
                })
                this.state.selectService = [];
            }
            else {
                this.state.report.service = [];
                this.state.sname.forEach(value => {
                    var opt = {};
                    opt.label = value.value;
                    opt.id = value.id;
                    opt.value = value.value;
                    opt.head = value.head;
                    opt.bu = value.bu;
                    this.state.serviceName.push(opt);
                })
                this.state.selectService = (this.state.serviceName[0]);
                this.state.report.service.push(this.state.serviceName[0])
            }
        }
        this.state.searchByPracticeHead = this.state.bu_id;
        this.setState({
            selectBuFilter: this.state.selectBuFilter
        })
        this.changeViewPracite_inventory('', true);

    }

    change_inventoryView = (e, flag) => {
        this.state.inventory_isDetailedView = flag;
        this.setState({
            inventory_isDetailedView: this.state.inventory_isDetailedView
        })
    }

    ToastAlert(icon, title) {
        return Toast.fire({
            target: document.getElementById("form-modal"),
            icon: `${icon}`,
            title: `${title}`,
        });
    }

    resetReport = (e) => {
        e.currentTarget.blur(); // removing focus
        this.state.resourceFilter = this.state.resourceAllFilter;
        this.state.searchBy = '';
        this.setState({
            resourceFilter: this.state.resourceFilter
        })
        this.getPracticeService();
    }

    selectResource = (e) => {
        var index = e.target.selectedIndex;
        var el = e.target.childNodes[index]
        var user_id = el.getAttribute('id');
        this.state.resourceFilter = e.target.value;
        this.state.searchByPracticeHead = this.state.bu_id;
        if (this.state.filter_start_date) {
            this.state.submitted = false;
            this.generateInventory(true);
        }
        else {
            this.state.submitted = true;
        }
    }

    generateInventoryExportAsExls = (e) => {
        e.currentTarget.blur(); // removing focus
        this.setState({ loading: true });
        var link = this.state.apiLink + 'export_as_excel_inventory';
        var token = this.state.queryParams.get('token');
        if (this.state.token == null) {
            this.state.token = Cookies.get('auth_data');
        }
        var resourceFilter = {};
        this.state.resource_types.forEach(user => {
            if (user.value == this.state.resourceFilter) {
                resourceFilter.id = user.id
                resourceFilter.value = user.value
            }
        })
        return fetch(link, {
            method: "POST",
            body: JSON.stringify({
                current_report: this.state.current_report,
                inventoryView: this.state.inventory_isDetailedView,
                reportDetails: this.state.inventoryReport,
                resourcefilter: resourceFilter
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Authorization": "Bearer " + this.state.token
            }
        }).then(res => res.json()).then(res => {
            this.setState({ loading: false });
            this.ToastAlert("success", "Report generated Successfully");
            var result = res.result;
            document.location = this.state.excelUrl + result.filename;
        })

    }

    filterByUserType = (e, id) => {
        var array = [];
        this.state.gridListBackup.forEach(value => {
            if (value.user_type == id) {
                array.push(value);
            }
        })
        this.state.gridList = array;
        this.setState({
            gridList: this.state.gridList
        })
        var effective_yield = 0;
        var expected_yield = 0;
        var gap_in_allocation = 0;
        var deficit = 0;
        this.state.msAllocatedHrs = 0;
        this.state.supportHrs = 0;
        this.state.benchHrs = 0;
        this.state.totalResourceAvailHrsFrontendCalcution = 0;

        this.state.utilizationCounts = {};
        this.state.utilizationCounts.totalAvailHrs = 0;
        this.state.utilizationCounts.milestoneAllocated = 0;
        this.state.utilizationCounts.supportHrs = 0;
        this.state.utilizationCounts.benchHrs = 0;

        this.state.utilizationCounts.moolahTotalAvailHrs = 0;
        this.state.utilizationCounts.moolahMilestoneHrs = 0;
        this.state.utilizationCounts.moolahSupportHrs = 0;
        this.state.utilizationCounts.moolahSupportHrs_value = 0;
        this.state.utilizationCounts.moolahBenchHrs = 0;

        this.state.utilizationCounts.boolahTotalAvailHrs = 0;
        this.state.utilizationCounts.boolahMilestoneHrs = 0;
        this.state.utilizationCounts.boolahSupportHrs = 0;
        this.state.utilizationCounts.boolahSupportHrs_value = 0;
        this.state.utilizationCounts.boolahBenchHrs = 0;

        this.state.utilizationCounts.supportUserMilestoneHrs = 0;
        this.state.utilizationCounts.supportUserSupportHrs = 0;
        this.state.utilizationCounts.supportUserTotalAvailHrs = 0;
        this.state.utilizationCounts.supportUserSupportHrs_value = 0;
        this.state.utilizationCounts.supportUserBenchHrs = 0;

        this.state.utilizationCounts.vendorTotalAvailHrs = 0;
        this.state.utilizationCounts.vendorMilestoneHrs = 0;
        this.state.utilizationCounts.vendorSupportHrs = 0;
        this.state.utilizationCounts.vendorBenchHrs = 0;
        this.state.utilizationCounts.vendorSupportHrs_value = 0;

        this.state.utilizationCounts.externTotalAvailHrs = 0;
        this.state.utilizationCounts.externMilestoneHrs = 0;
        this.state.utilizationCounts.externSupportHrs = 0;
        this.state.utilizationCounts.externBenchHrs = 0;
        this.state.utilizationCounts.externSupportHrs_value = 0;
        this.state.front_actual_allocation_m = 0;
        this.state.moolahDeficit = 0;
        this.state.boolahDeficit = 0;
        this.state.bcDeficit = 0;
        this.state.vendorDeficit = 0;

        this.state.yieldCounts = {};
        this.state.yieldCounts.yieldValue = 0;
        this.state.yieldCounts.allocatedAmount = 0;
        this.state.yieldCounts.allocatedAmount_m = 0;
        this.state.yieldCounts.allocatedAmount_b = 0;
        this.state.yieldCounts.allocatedAmount_s = 0;
        this.state.yieldCounts.allocatedAmount_v = 0;
        this.state.yieldCounts.achievedAmount = 0;
        this.state.yieldCounts.moolahAllocatedAmount = 0;
        this.state.yieldCounts.moolahAchievedAmount = 0;
        this.state.yieldCounts.boolahAllocatedAmount = 0;
        this.state.yieldCounts.boolahAchievedAmount = 0;

        this.state.totalResourceYieldValue = 0;
        this.state.totaleffectiveYieldValue = 0;
        this.state.sum_bench_value = 0;

        var tempUserAvailHrs = [];
        var filtered_user_list = [];
        this.state.gridList1 = [];
        var name = [];

        this.state.report.service.forEach(servalue => {
            this.state.practiceHead.forEach(ph => {
                if (ph.id == servalue.head) {
                    name.push(ph.name);
                }
            });
            this.state.practiceleadName = name.join();
        });
        this.state.gridList.forEach((value) => {
            if (typeof tempUserAvailHrs[value.user_id] === 'undefined') {
                tempUserAvailHrs[value.user_id] = value.available_hrs;
            }
            filtered_user_list.push(value);
            var arr = [];
            filtered_user_list.forEach(entry => {
                var uid = entry.id;
                if (arr.indexOf(uid) == -1) {
                    arr.push(uid);
                }
            });
            this.state.getUserscount = arr;
            value.teamlead = (value.teamlead) ? value.teamlead : 'NA';
            this.state.unallocated_hrs = 0;
            this.state.unallocated_hrs = (Number(value.available_hrs) > 0) ? Number(value.available_hrs) - (Number(value.planned_hrs) + Number(value.support_hrs)) : 0;
            value.unallocated_hrs = this.state.unallocated_hrs;
            this.state.benchHrs += value.unallocated_hrs;

            effective_yield = value.available_hrs * value.cost;
            this.state.effective_yield = effective_yield;
            value.effective_yield = parseFloat(this.state.effective_yield).toFixed(2);

            expected_yield = value.available_hrs * value.price;
            this.state.expected_yield = expected_yield;

            if (Number.isInteger(this.state.expected_yield)) {
                value.expected_yield = this.state.expected_yield;
            } else {
                value.expected_yield = parseFloat(this.state.expected_yield).toFixed(2);
            }


            gap_in_allocation = (value.expected_yield - value.allocated_ms);
            this.state.gap_in_allocation = gap_in_allocation;
            if (Math.sign(gap_in_allocation) == 0) {
                value.gap_in_allocation = this.state.gap_in_allocation;
            } else {
                value.gap_in_allocation = parseFloat(this.state.gap_in_allocation).toFixed(2);
            }


            if ((value.gap_in_allocation > 0) && (value.expected_yield)) {
                value.deficit = (((value.gap_in_allocation / value.expected_yield) * 100)).toFixed(2);
            } else {
                value.deficit = 0;
            }
            this.state.gridList1.push(value);

            this.state.totalResourceYieldValue += Number(value.expected_yield);
            this.state.totaleffectiveYieldValue += Number(value.effective_yield);

            this.state.totalResourceAvailHrsFrontendCalcution = this.state.totalResourceAvailHrsFrontendCalcution + Number(value.available_hrs);
            this.state.totalResAvHrCal = this.state.totalResourceAvailHrsFrontendCalcution;
            this.state.msAllocatedHrs = this.state.msAllocatedHrs + Number(value.planned_hrs);
            this.state.msAlloc = this.state.msAllocatedHrs;
            this.state.supportHrs = this.state.supportHrs + Number(value.support_hrs);
            this.state.supphrs = this.state.supportHrs;
            // }
            this.state.yieldCounts.allocatedAmount = this.state.yieldCounts.allocatedAmount + parseInt(value.allocated_ms);
            this.state.yieldCounts.yieldValue = this.state.yieldCounts.yieldValue + (value.price * value.available_hrs);
            this.state.sum_bench_value += Number(value.price * value.unallocated_hrs);
            this.state.yieldCounts.achievedAmount = this.state.yieldCounts.achievedAmount + parseInt(value.achieved_ms);

            if (value.user_type == this.state.user_types[0].id) //for moolah
            {
                this.state.utilizationCounts.moolahTotalAvailHrs = this.state.utilizationCounts.moolahTotalAvailHrs + parseInt(value.available_hrs);
                this.state.utilizationCounts.moolahMilestoneHrs = Number(this.state.utilizationCounts.moolahMilestoneHrs) + Number(value.planned_hrs);
                this.state.utilizationCounts.moolahSupportHrs = Number(this.state.utilizationCounts.moolahSupportHrs) + Number(value.support_hrs);
                this.state.utilizationCounts.moolahSupportHrs_value = Number(this.state.utilizationCounts.moolahSupportHrs_value) + (value.support_hrs * value.price);
                this.state.utilizationCounts.moolahBenchHrs = Number(this.state.utilizationCounts.moolahBenchHrs) + (value.unallocated_hrs * value.price);
                this.state.moolahDeficit += parseFloat(value.gap_in_allocation);
                this.state.yieldCounts.allocatedAmount_m += value.allocated_ms;
            } else if (value.user_type == this.state.user_types[1].id) //for boolah
            {
                this.state.utilizationCounts.boolahTotalAvailHrs = this.state.utilizationCounts.boolahTotalAvailHrs + parseInt(value.available_hrs);
                this.state.utilizationCounts.boolahMilestoneHrs = Number(this.state.utilizationCounts.boolahMilestoneHrs) + Number(value.planned_hrs);
                this.state.utilizationCounts.boolahSupportHrs = Number(this.state.utilizationCounts.boolahSupportHrs) + Number(value.support_hrs);
                this.state.utilizationCounts.boolahSupportHrs_value = Number(this.state.utilizationCounts.boolahSupportHrs_value) + (value.support_hrs * value.price);
                this.state.utilizationCounts.boolahBenchHrs = Number(this.state.utilizationCounts.boolahBenchHrs) + (value.unallocated_hrs * value.price);
                this.state.boolahDeficit += parseFloat(value.gap_in_allocation);
                this.state.yieldCounts.allocatedAmount_b += value.allocated_ms;
            } else if (value.user_type == this.state.user_types[2].id) //for support user
            {
                this.state.utilizationCounts.supportUserTotalAvailHrs = this.state.utilizationCounts.supportUserTotalAvailHrs + parseInt(value.available_hrs);
                this.state.utilizationCounts.supportUserMilestoneHrs = Number(this.state.utilizationCounts.supportUserMilestoneHrs) + Number(value.planned_hrs);
                this.state.utilizationCounts.supportUserSupportHrs = Number(this.state.utilizationCounts.supportUserSupportHrs) + Number(value.support_hrs);
                this.state.utilizationCounts.supportUserSupportHrs_value = Number(this.state.utilizationCounts.supportUserSupportHrs_value) + (value.support_hrs * value.price);
                this.state.utilizationCounts.supportUserBenchHrs = Number(this.state.utilizationCounts.supportUserBenchHrs) + (value.unallocated_hrs * value.price);
                this.state.bcDeficit += parseFloat(value.gap_in_allocation);
                this.state.yieldCounts.allocatedAmount_s += value.allocated_ms;
            }
            else if (value.user_type == this.state.user_types[4].id) // for vendor users
            {
                this.state.utilizationCounts.vendorTotalAvailHrs = this.state.utilizationCounts.vendorTotalAvailHrs + parseInt(value.available_hrs);
                this.state.utilizationCounts.vendorMilestoneHrs = Number(this.state.utilizationCounts.vendorMilestoneHrs) + Number(value.planned_hrs);
                this.state.utilizationCounts.vendorSupportHrs = Number(this.state.utilizationCounts.vendorSupportHrs) + Number(value.support_hrs);
                this.state.utilizationCounts.vendorSupportHrs_value = Number(this.state.utilizationCounts.vendorSupportHrs_value) + (value.support_hrs * value.price);
                this.state.utilizationCounts.vendorBenchHrs = Number(this.state.utilizationCounts.vendorBenchHrs) + (value.unallocated_hrs * value.price);
                this.state.vendorDeficit += parseFloat(value.gap_in_allocation);
                this.state.yieldCounts.allocatedAmount_v += value.allocated_ms;
            }
            else if (value.user_type == this.state.user_types[5].id) // for extern users
            {
                this.state.utilizationCounts.externTotalAvailHrs = this.state.utilizationCounts.externTotalAvailHrs + parseInt(value.available_hrs);
                this.state.utilizationCounts.externMilestoneHrs = Number(this.state.utilizationCounts.externMilestoneHrs) + Number(value.planned_hrs);
                this.state.utilizationCounts.externSupportHrs = Number(this.state.utilizationCounts.externSupportHrs) + Number(value.support_hrs);
                this.state.utilizationCounts.externSupportHrs_value = Number(this.state.utilizationCounts.externSupportHrs_value) + (value.support_hrs * value.price);
                this.state.utilizationCounts.externBenchHrs = Number(this.state.utilizationCounts.externBenchHrs) + (value.unallocated_hrs * value.price);
                this.state.externDeficit += parseFloat(value.gap_in_allocation);
                this.state.yieldCounts.allocatedAmount_v += value.allocated_ms;
            }
            this.state.sum_bench_value = this.state.totalResourceYieldValue - this.state.sum_bench_value;


        });


        //   });

        if (this.state.gridList1.length == 0) {
            this.state.totalResAvHrCal = 0;
            this.state.msAlloc = 0;
            this.state.supphrs = 0;
            this.state.getUserscount = '';
        }
    }

    returnFloatingValueforInventory = (arg) => {
        var int_number = arg;
        if (int_number != undefined) {
            var return_value = ((int_number % 1) == 0) ? parseInt(int_number) : int_number.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            })
        }
        else return_value = 0;
        return return_value;
    }

    inventoryBenchAmountSum = (practiceDatas) => {
        var returnSumValue = 0;
        practiceDatas.forEach(value => {
            returnSumValue += parseInt(value.gap_allocation);
        });
        return returnSumValue;
    }

    /**
     * main render
     * @returns 
     */
    render() {
        const { practiceHead } = this.state;
        var buName = [];
        practiceHead.forEach(value => {
            var opt = {};
            opt.label = value.bu;
            opt.value = value.bu;
            opt.bu_id = value.bu_id;
            opt.id = value.id;
            opt.bu = value.bu;
            opt.name = value.name;
            buName.push(opt);
        })
        this.state.inventoryReportList.forEach(value => {
            value.index = 1;
        })
        var resource = {};
        this.state.resource_types.forEach(user => {
            if (user.value == this.state.resourceFilter) {
                resource.id = user.id
                resource.value = user.value
            }
        })
        this.state.selectedUser = resource;

        if (this.state.redirect == 1 || this.state.loginUserData == null) {
            return <PageNotFound url={this.state.angularUrl} />
        }
        else {
            return (
                <>
                    <div className={"page-content px-4 " + (this.props.addClass ? "active" : "")}>
                        <Row>
                            <div className="col-xs-12 fourty-fluid fourty-fluid">
                                <Row className="mb-3">
                                    <div className="col-sm-4 col-sm-4 col-xs-12 text-left">
                                        <h1 className="B-G-bold f-s-18 d-gray t-t-upper pull-left mt-10 mr-20 page-head">Reports</h1>
                                    </div>
                                    <div className="col-lg-3 col-sm-4 col-xs-12">
                                        <div className="selectpicker_dropdown hide_cross hide_bar orange_arrow remove_border_btm select_dropdown_picker drop ">
                                            <Dropdown title={this.state.current_report.value} activeKey="6">
                                                <Dropdown.Item as="a" eventKey="1" href="/pw_report" target="_blank">Practice Wise Allocation Report</Dropdown.Item>
                                                <Dropdown.Item as="a" eventKey="2" href="/ms_report" target="_blank">Milestone Value Report</Dropdown.Item>
                                                <Dropdown.Item as="a" eventKey="3" href="/collections_report" target="_blank">Collections Report</Dropdown.Item>
                                                <Dropdown.Item as="a" eventKey="4" href="/collections_potential_report" target="_blank">Collection Potential Report</Dropdown.Item>
                                                <Dropdown.Item as="a" eventKey="5" href="/inventory_report" target="_blank">Inventory Report</Dropdown.Item>
                                                <Dropdown.Item as="a" eventKey="6">Inventory Allocation Metrics</Dropdown.Item>
                                                <Dropdown.Item as="a" eventKey="7" href="/inventory_milestone_report" target="_blank">Inventory Milestone Metrics</Dropdown.Item>
                                                <Dropdown.Item as="a" eventKey="8" href="/utilization_report" target="_blank">Resource Utilization Report</Dropdown.Item>
                                                <Dropdown.Item as="a" eventKey="9" href="/csm_report" target="_blank">Csm Report</Dropdown.Item>
                                                <Dropdown.Item as="a" eventKey="10" href="/pmo_ms_report" target="_blank">Pmo Ms Report</Dropdown.Item>
                                                <Dropdown.Item as="a" eventKey="11" href="/acv_report" target="_blank">Annual Contract Value Report</Dropdown.Item>
                                                <Dropdown.Item as="a" eventKey="12" href="/ms-allocation-report" target="_blank">MS Allocation Report</Dropdown.Item>
                                                <Dropdown.Item as="a" eventKey="13" href="/squad-review-report" target="_blank">Squad Review Report</Dropdown.Item>
                                            </Dropdown>
                                        </div>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="col-lg-6 clearfix m-b-20">
                                        <div className="row align-items-center">
                                            <label class="f-s-12 text-muted pt-1 w-13 col-lg-2 col-md-6 col-sm-12 text-left">Line of Service :</label>
                                            <div className="col-lg-7 col-md-6 col-sm-12 text-left ">
                                                <Row>
                                                    <div className="col-lg-10 p-l-0">
                                                        <div className="selectpicker_dropdown hide_bar w-100 selectpicker_dropdown_orange">
                                                            <Select
                                                                className="basic-single-select"
                                                                classNamePrefix={"select_dropdown"}
                                                                placeholder="All"
                                                                isClearable
                                                                isSearchable={true}
                                                                onChange={(e) => this.filterService(e)}
                                                                value={this.state.selectService}
                                                                options={this.state.serviceName} isMulti>
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 p-1-0">
                                                        {this.state.report.service != undefined && this.state.report.service.length > 0 &&
                                                            <a href="javascript:void(0)" title="Reset" tooltip-placement="top" className="reset-icon_old reset-color p-l-5 m-l-4" onClick={this.reset_page}><i class="fs-16 fa fa-refresh"></i></a>
                                                        }
                                                    </div>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.loginUserData.role_id != 8 &&
                                        <div className="col-lg-4 clearfix m-b-20">
                                            <div className="row">
                                                <div class="col-sm-5 col-xs-12">
                                                    <label className="text-uppercase m-b-0 fs-13 p-t-5">Business Unit :</label>
                                                </div>
                                                <div className="col-sm-7 col-xs-12">
                                                    <div className="selectpicker_dropdown hide_bar w-100 selectpicker_dropdown_orange">
                                                        <Select
                                                            className="basic-single-select"
                                                            classNamePrefix={"select_dropdown"}
                                                            placeholder="All"
                                                            isClearable
                                                            isSearchable={true}
                                                            onChange={(e) => this.getReportByHead(e)}
                                                            value={this.state.selectBuFilter}
                                                            options={this.state.buName} >
                                                        </Select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                    <div className="col-lg-2 col-sm-2 col-xs-12 justify-content-end text-end">
                                        <a href={this.state.angularUrl} className="btn btn-primary">Back</a>
                                    </div>
                                </Row>

                                <div className="center-block text-center">
                                    <div className="col-lg-12 col-sm-12 col-xs-12 p-b-15-xs">
                                        <div className="p-0 B-G-bold f-s-16 d-gray m-t-8 m-b-0 page-head">
                                            <Row className="my-3">

                                                <Col className="col-lg-1 text-start align-items-center">
                                                    <h1 className="f-s-16 d-gray my-0">Users:</h1>
                                                </Col>
                                                <Col className="col-lg-2">
                                                    <div className="selectpicker_dropdown">
                                                        <select onChange={(e) => this.selectResource(e)} >
                                                            {this.state.resource_types.map(category =>
                                                                <option id={category.id}>
                                                                    {category.value}
                                                                </option>
                                                            )}
                                                        </select>
                                                        <span className="icon-arrow-down pull-right pt-5 p-l-10"></span>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </div>
                                    </div>
                                </div>
                                <hr className="m-t-5 col-sm-12 m-b-8 p-0"></hr>
                                <div className="col-lg-12 col-sm-12 col-xs-12 p-b-15-xs p-r-15-xs">
                                    <Row className=" mt-2 mb-3">
                                        <Col className="col-lg-10 text-start col p-0">
                                            <Row className="m-0">
                                                <Col className="col-lg-2 text-start">
                                                    <label className="p-0 B-G-bold f-s-18 d-gray m-t-3 m-b-10 page-head pull-left">Month Filter:</label>
                                                </Col>
                                                <Col className="col-lg-1 px-0 general-content text-center">
                                                    <label className="pt-2">Start :</label>
                                                </Col>
                                                <Col className="col-lg-2">
                                                    <div className="row align-items-center mb-2 date_picker_align">

                                                        {/* <Form.Control className="form_date col-lg-8 col-sm-6 w-auto d-inline-block" type="month" name='start_date' value={this.state.start_month} onChange={(e)=>this.setStartDateForFilter(e)}  />      */}
                                                        <DatePicker
                                                            className="datepicker-control-section"
                                                            onChange={(e) => this.setStartDateForFilter(e)}
                                                            dateFormat="MM/yyyy"
                                                            showMonthYearPicker
                                                            todayButton={"Today"}
                                                            //dateFormat="MM/yyyy" 
                                                            name="start_date"
                                                            selected={this.state.filter_start_date}
                                                        />
                                                        <span class="cal_icon_date" role="button" tabindex="0"> <i class="icon-calendar"></i></span>
                                                    </div>
                                                </Col>
                                                <Col className="col-lg-1 px-0 general-content text-center">
                                                    <label className="pt-2">End :</label>
                                                </Col>
                                                <Col className="col-lg-2">
                                                    <div className="row align-items-center mb-2 date_picker_align">
                                                        {/* <Form.Control className="form_date col-lg-8 col-sm-6 w-auto d-inline-block" type="month" name='end_date' value={this.state.end_month} onChange={(e)=>this.setEndDateForFilter(e)} />    */}
                                                        <DatePicker
                                                            className="datepicker-control-section"
                                                            onChange={(e) => this.setEndDateForFilter(e)}
                                                            dateFormat="MM/yyyy"
                                                            showMonthYearPicker
                                                            minDate={this.state.filter_start_date}
                                                            todayButton={"Today"}
                                                            //dateFormat="MM/yyyy" 
                                                            name="end_date"
                                                            selected={this.state.filter_end_date}
                                                        />
                                                        <span class="cal_icon_date" role="button" tabindex="0"> <i class="icon-calendar"></i></span>
                                                    </div>
                                                </Col>
                                                <Col className="col-lg-4">
                                                    <Row>
                                                        <Col>
                                                            <Button onClick={(e) => this.resetReport(e)} value="Generate" className="me-2 mt-0">Generate</Button>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className="col-lg-2 text-end">
                                            <div className="form-group search-icon col-sm-12 col-xs-12">
                                            <Button  onClick={(e) => this.generateInventoryExportAsExls(e)} value="Excel" className="me-2"  disabled={this.state.inventorybu_data.length==0 || this.state.totalResourceAvailHrs==0}>Export as Excel</Button>
                                            </div>
                                        </Col>
                                    </Row>

                                </div>
                                <div className="pro_listng bor-no">
                                    <div className="invoice--report bg-transparent inventory_allo_table p-l-0 p-r-0 p-t-0 p-b-0 m-t-5">
                                        <div className="scrollable-content">

                                            {this.state.loading == true &&
                                                <div id="preloader">
                                                    <div id="status"></div>
                                                </div>
                                            }
                                            {this.state.loading == false &&
                                                this.state.inventorybu_data.length == 0 &&
                                                <div>
                                                    <div className="r-no-data text-center">No data available</div>
                                                </div>
                                            }
                                            {this.state.loading == false && this.state.inventoryReportList.length > 0 &&

                                                <div className="reports--grid accordian_servicesdiv">
                                                    {this.state.inventorybu_data.map((bu_data) => (
                                                        <Accordion defaultActiveKey="0" className="accor_alignment mb-2" alwaysopen="true">
                                                            <div uib-accordion-group="true" className="panel-default" is-open={(bu_data.open).toString()}>

                                                                <Accordion.Header className="bg-white">
                                                                    <h5 className="d-inline-block w-100 text-start p-0 my-2">{bu_data.business_unit} </h5>
                                                                </Accordion.Header>
                                                                <Accordion.Body className="bg-white">
                                                                    {this.state.selectedUser.id == 0 &&
                                                                        <table className="table m-b-0 border-bottom table_layoutfixed" >
                                                                            <thead>
                                                                                <tr className="theadInner invntry-mw-th">
                                                                                    <th rowSpan="2" className='thead'>BU</th>
                                                                                    <th rowSpan="2" className='thead'>Resource count</th>
                                                                                    <th rowSpan="2" className='thead'>Available Hour</th>
                                                                                    <th rowSpan="2" className='thead'>MS Hour</th>
                                                                                    <th rowSpan="2" className='thead'>Support Hours</th>
                                                                                    <th colSpan="3" className='thead'>Bench Hours</th>
                                                                                    <th rowSpan="2" className='thead'>Total Bench Hours</th>
                                                                                    <th rowSpan="2" className='thead'>Total Utilisation %</th>
                                                                                    <th className='thead'>Support Hours</th>
                                                                                    <th colSpan="4" className='thead'>Bench Hours</th>
                                                                                    <th className='thead'>MS Hours</th>
                                                                                </tr>
                                                                                <tr className="theadInner invntry-mw-th">
                                                                                    <th className="thead">Moolah</th>
                                                                                    <th className="thead">Boolah</th>
                                                                                    <th className="thead">Vendor</th>
                                                                                    <th className="thead">Utilisation %</th>
                                                                                    <th className="thead">Moolah Un Utilisation %</th>
                                                                                    <th className="thead">Boolah Un Utilisation %</th>
                                                                                    <th className="thead">Vendor Un Utilisation %</th>
                                                                                    <th className="thead">Total Un Utilisation %</th>
                                                                                    <th className="thead">Good/Bad</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr className="bor-no busi_unit_bg">
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5">
                                                                                        <span className="text-truncate">{bu_data.business_unit}</span>
                                                                                    </td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_total_user)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_totalAvailHrs)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_totalMSHrs)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_totalSuppHrs)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_support_moolah)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_support_boolah)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_support_vendor)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_totalBench)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="{{msHoursStatus(bu_data.bu_total_utilization)}} text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_total_utilization)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_supp_utilization)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_totalmoolahuntil)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_totalboolahuntil)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_totalvendoruntil)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_total_unutil)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5">
                                                                                        {(bu_data.bu_total_utilization) > 85 &&
                                                                                            <span className="text-black p-t-5 fs-12 dis-b">Good</span>}
                                                                                        {(bu_data.bu_total_utilization) < 85 &&
                                                                                            <span class="text-black p-t-5 fs-12 dis-b">Bad</span>}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                            {this.state.inventoryReportList.map((services) => (
                                                                                <tbody>
                                                                                    {(bu_data.business_unit == services.business_unit) &&
                                                                                        <tr className="service_unit_bg">
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-truncate text-truncate-width">{services.serviceName}</span>
                                                                                            </td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.total_user)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.totalAvailHrs)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.totalMSHrs)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.totalSuppHrs)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.support_moolah)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.support_boolah)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.support_vendor)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.totalBench)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="{{msHoursStatus(services.total_utilization)}} text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.total_utilization)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.supp_utilization)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.totalmoolahuntil)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.totalboolahuntil)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.totalvendoruntil)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.total_unutil)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5">
                                                                                                {(services.total_utilization) > 85 &&
                                                                                                    <span className="text-black p-t-5 fs-12 dis-b">Good</span>}
                                                                                                {(services.total_utilization) < 85 &&
                                                                                                    <span className="text-black p-t-5 fs-12 dis-b" >Bad</span>}
                                                                                            </td>
                                                                                        </tr>
                                                                                    }
                                                                                    {bu_data.business_unit == services.business_unit && services.practiceList.map((report) => (
                                                                                        <tr className="bor-no">
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-truncate text-truncate-width">{report.practice}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.total_user)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.availHrs)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.mileStoneHrs)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.supportHrs)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.totalmoolahBenchHrs)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.totalboolahBenchHrs)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.totalVendorBenchHrs)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.benchHrs)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="{{msHoursStatus(report.total_util)}} text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.total_util)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.supp_util)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.totalmoolahuntil)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.totalboolahuntil)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.totalvendoruntil)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.total_unutilliza)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5">
                                                                                                {(report.total_util) > 85 &&
                                                                                                    <span className="text-black p-t-5 fs-12 dis-b">Good</span>}
                                                                                                {(report.total_util) < 85 &&
                                                                                                    <span className="text-black p-t-5 fs-12 dis-b">Bad</span>}
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            ))}
                                                                        </table>
                                                                    }
                                                                    {this.state.selectedUser.id == 1 &&
                                                                        <table className="table m-b-0 border-bottom table_layoutfixed" >
                                                                            <thead>
                                                                                <tr className="theadInner invntry-mw-th">
                                                                                    <th rowSpan="2" className='thead'>BU</th>
                                                                                    <th rowSpan="2" className='thead'>Resource count</th>
                                                                                    <th rowSpan="2" className='thead'>Available Hour</th>
                                                                                    <th rowSpan="2" className='thead'>MS Hour</th>
                                                                                    <th rowSpan="2" className='thead'>Support Hours</th>
                                                                                    <th colSpan="2" className='thead'>Bench Hours</th>
                                                                                    <th rowSpan="2" className='thead'>Total Bench Hours</th>
                                                                                    <th rowSpan="2" className='thead'>Total Utilisation %</th>
                                                                                    <th className='thead'>Support Hours</th>
                                                                                    <th colSpan="3" className='thead'>Bench Hours</th>
                                                                                    <th className='thead'>MS Hours</th>
                                                                                </tr>
                                                                                <tr className="theadInner invntry-mw-th">
                                                                                    <th className="thead">Moolah</th>
                                                                                    <th className="thead">Boolah</th>
                                                                                    <th className="thead">Utilisation %</th>
                                                                                    <th className="thead">Moolah Un Utilisation %</th>
                                                                                    <th className="thead">Boolah Un Utilisation %</th>
                                                                                    <th className="thead">Total Un Utilisation %</th>
                                                                                    <th className="thead">Good/Bad</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr className="bor-no busi_unit_bg">
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5">
                                                                                        <span className="text-truncate">{bu_data.business_unit}</span>
                                                                                    </td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_total_user)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_totalAvailHrs)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_totalMSHrs)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_totalSuppHrs)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_support_moolah)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_support_boolah)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_totalBench)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="{{msHoursStatus(bu_data.bu_total_utilization)}} text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_total_utilization)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_supp_utilization)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_totalmoolahuntil)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_totalboolahuntil)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_total_unutil)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5">
                                                                                        {(bu_data.bu_total_utilization) > 85 &&
                                                                                            <span className="text-black p-t-5 fs-12 dis-b">Good</span>}
                                                                                        {(bu_data.bu_total_utilization) < 85 &&
                                                                                            <span class="text-black p-t-5 fs-12 dis-b">Bad</span>}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                            {this.state.inventoryReportList.map((services) => (
                                                                                <tbody>
                                                                                    {(bu_data.business_unit == services.business_unit) &&
                                                                                        <tr className="bor-no main_top_one">
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-truncate">{services.serviceName}</span>
                                                                                            </td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.total_user)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.totalAvailHrs)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.totalMSHrs)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.totalSuppHrs)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.support_moolah)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.support_boolah)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.totalBench)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="{{msHoursStatus(services.total_utilization)}} text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.total_utilization)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.supp_utilization)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.totalmoolahuntil)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.totalboolahuntil)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.total_unutil)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5">
                                                                                                {(services.total_utilization) > 85 &&
                                                                                                    <span className="text-black p-t-5 fs-12 dis-b">Good</span>}
                                                                                                {(services.total_utilization) < 85 &&
                                                                                                    <span className="text-black p-t-5 fs-12 dis-b" >Bad</span>}
                                                                                            </td>
                                                                                        </tr>
                                                                                    }
                                                                                    {bu_data.business_unit == services.business_unit && services.practiceList.map((report) => (
                                                                                        <tr className="bor-no">
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-truncate">{report.practice}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.total_user)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.availHrs)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.mileStoneHrs)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.supportHrs)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.totalmoolahBenchHrs)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.totalboolahBenchHrs)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.benchHrs)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="{{msHoursStatus(report.total_util)}} text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.total_util)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.supp_util)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.totalmoolahuntil)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.totalboolahuntil)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.total_unutilliza)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5">
                                                                                                {(report.total_util) > 85 &&
                                                                                                    <span className="text-black p-t-5 fs-12 dis-b">Good</span>}
                                                                                                {(report.total_util) < 85 &&
                                                                                                    <span className="text-black p-t-5 fs-12 dis-b">Bad</span>}
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            ))}
                                                                        </table>
                                                                    }
                                                                    {this.state.selectedUser.id == 2 &&
                                                                        <table className="table m-b-0 border-bottom table_layoutfixed" >
                                                                            <thead>
                                                                                <tr className="theadInner invntry-mw-th">
                                                                                    <th rowSpan="2" className='thead'>BU</th>
                                                                                    <th rowSpan="2" className='thead'>Resource count</th>
                                                                                    <th rowSpan="2" className='thead'>Available Hour</th>
                                                                                    <th rowSpan="2" className='thead'>MS Hour</th>
                                                                                    <th rowSpan="2" className='thead'>Support Hours</th>
                                                                                    <th rowSpan="2" className='thead'>Bench Hours</th>
                                                                                    <th rowSpan="2" className='thead'>Total Utilisation %</th>
                                                                                    <th className='thead'>Support Hours</th>
                                                                                    <th rowSpan="2" className='thead'>Total UnUtilization</th>
                                                                                    <th className='thead'>MS Hours</th>
                                                                                </tr>
                                                                                <tr className="theadInner invntry-mw-th">
                                                                                    <th className="thead">Utilisation %</th>
                                                                                    <th className="thead">Good/Bad</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr className="bor-no busi_unit_bg">
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5">
                                                                                        <span className="text-truncate">{bu_data.business_unit}</span>
                                                                                    </td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_total_user)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_totalAvailHrs)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_totalMSHrs)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_totalSuppHrs)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_totalBench)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="{{msHoursStatus(bu_data.bu_total_utilization)}} text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_total_utilization)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_supp_utilization)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(bu_data.bu_total_unutil)}</span></td>
                                                                                    <td className="PigmentIndigo-text p-t-5 p-b-5">
                                                                                        {(bu_data.bu_total_utilization) > 85 &&
                                                                                            <span className="text-black p-t-5 fs-12 dis-b">Good</span>}
                                                                                        {(bu_data.bu_total_utilization) < 85 &&
                                                                                            <span class="text-black p-t-5 fs-12 dis-b">Bad</span>}
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                            {this.state.inventoryReportList.map((services) => (
                                                                                <tbody>
                                                                                    {(bu_data.business_unit == services.business_unit) &&
                                                                                        <tr className="bor-no main_top_one">
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-truncate">{services.serviceName}</span>
                                                                                            </td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.total_user)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.totalAvailHrs)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.totalMSHrs)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.totalSuppHrs)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.totalBench)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="{{msHoursStatus(services.total_utilization)}} text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.total_utilization)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.supp_utilization)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(services.total_unutil)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5">
                                                                                                {(services.total_utilization) > 85 &&
                                                                                                    <span className="text-black p-t-5 fs-12 dis-b">Good</span>}
                                                                                                {(services.total_utilization) < 85 &&
                                                                                                    <span className="text-black p-t-5 fs-12 dis-b" >Bad</span>}
                                                                                            </td>
                                                                                        </tr>
                                                                                    }
                                                                                    {bu_data.business_unit == services.business_unit && services.practiceList.map((report) => (
                                                                                        <tr className="bor-no">
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-truncate">{report.practice}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.total_user)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.availHrs)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.mileStoneHrs)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.supportHrs)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.benchHrs)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="{{msHoursStatus(report.total_util)}} text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.total_util)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.supp_util)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5"><span className="text-black p-t-5 fs-12 dis-b">{this.returnFloatingValueforInventory(report.total_unutilliza)}</span></td>
                                                                                            <td className="PigmentIndigo-text p-t-5 p-b-5">
                                                                                                {(report.total_util) > 85 &&
                                                                                                    <span className="text-black p-t-5 fs-12 dis-b">Good</span>}
                                                                                                {(report.total_util) < 85 &&
                                                                                                    <span className="text-black p-t-5 fs-12 dis-b">Bad</span>}
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            ))}
                                                                        </table>
                                                                    }
                                                                </Accordion.Body>
                                                            </div>
                                                        </Accordion>
                                                    ))}
                                                </div>

                                            }

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </Row>
                    </div>
                </>
            );
        }
    }
}
const mapStateToProps = (state) => {
    return {
        addClass: state.pmsDetails.addClass,
    };
};
const mapDispatchToProps = {
    setAddClass
};

export default connect(mapStateToProps, mapDispatchToProps)(InventoryAllocationReport);
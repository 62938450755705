import { getBackEndConstant } from "../Config/Constant";
import moment from "moment";
var _ = require("lodash");

export function getUserService(serviceArray,loginUserData,startDate) {
    let userService = _.filter(serviceArray, 
        { 'id': (loginUserData.role_id == getBackEndConstant().financeRoleId) ? getBackEndConstant().cloudService : loginUserData.service  }
    );
    let filterDate = moment(startDate)
    .utcOffset("+05:30")
    .format("YYYY-MM-01");
    
    if(loginUserData.service && filterDate >= getBackEndConstant().filterDate){
        return userService.length > 0 ? userService[0] : serviceArray[0];
    }
    else{
        return serviceArray[0];
    }  
}

export function getReportFilterService(serviceArray,loginUserData,usersService) {
    let service = [];
    if(!loginUserData.is_all_service && loginUserData.id > 1){
        serviceArray.forEach((ser_value) => {
            if(usersService && usersService.includes(ser_value.id)){
                service.push(ser_value);                    
            }
        });
    }
    return service;
}

export function getUtilizationService(serviceArray) {
    let service = [];
    serviceArray.forEach((value) => {
          let opt = {};
          opt.label = value.value;
          opt.id = value.id;
          opt.value = value.value;
          opt.head = value.head;
          opt.bu = value.bu;
          service.push(opt);
    });  
    return service;
}

export function practiceRename(practice) {
    let practiceArray = getBackEndConstant().renamedPracticeArray;
    let filteredArray = _.find(practiceArray, function(value) {
            return value.practice === practice;
        }
    );
    if (filteredArray != undefined) {
        return filteredArray.renamedPractice;
    } else {
        return practice;
    } 
}

export function Capitalize(str){
    return str?.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}
import Cookies from "js-cookie";
import { handleResponse } from "../Services/HandleResponse";
import { getData } from "../Services/Helper";
import * as types from "../Utilities/Type";
import { getBackEndConstant } from "../Config/Constant";

export function getLoginUser() {
  return (dispatch) => {
      const requestOptions = getData();
      let url = getBackEndConstant().backend_url + "get_login_user";
      if (Cookies.get("auth_data") == "undefined" || Cookies.get("auth_data") == null) {
        return null; // Optionally return null
      } 
      return fetch(url, requestOptions)
          .then((response) => handleResponse(response, dispatch))
          .then(response => {
              dispatch({
                  type: types.GET_LOGIN_USER,
                  payload: (response.result) ? response.result : {},
              });
          });
  }
}

export function getLoginUserPermission() {
    return (dispatch) => {
        const requestOptions = getData();
        let url = getBackEndConstant().backend_url + "get_user_role_permissions";
        return fetch(url, requestOptions)
            .then((response) => handleResponse(response, dispatch))
            .then(response => {
                dispatch({
                    type: types.GET_LOGIN_USER_PERMISSION,
                    payload: (response.result) ? response.result : {},
                });
                return response;
            });
    }
   
}
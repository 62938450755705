import * as types from "../Utilities/Type";
const INITIAL_STATE = {
  buList: [],
};
export function getBuList(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.GET_BU_LIST:
      return {
        ...state,
        buList: action.payload,
      };      
    case "3":
      break;
    default:
      return state;
  }
}